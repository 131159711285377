import { Injectable } from '@angular/core';
import { SelectOption } from '@app/shared';

@Injectable({
  providedIn: 'root'
})
export class SelectService {
  private optionsMap = new Map<string, SelectOption[]>();

  saveOptions(key: string, options: SelectOption[]) {
    this.optionsMap.set(key, options);
  }

  getOptions(key: string): SelectOption[] | null {
    return this.optionsMap.get(key) || null;
  }
}
