<div class="modal-body" *ngIf="!modalType && isLoading">
  <div class="centermodal__form">
    <app-small-loader></app-small-loader>
  </div>
</div>

<form class="modal-body" [formGroup]="preSignForm" (ngSubmit)="chooseOrganization()" novalidate [class.d-none]="modalType !== ModalType.preSign">
  <p class="centermodal__title" [ngClass]="{ 'mb-2': hasFailedBiometry }">{{ 'documents.checkDataToSign' | translate }}</p>

  <div class="modal-alert modal-alert__danger mb-2" *ngIf="hasFailedBiometry">
    <div class="modal-alert__icon"><i class="fal fa-exclamation-triangle"></i></div>
    <div class="modal-alert__content">
      <div class="modal-alert__description" [innerHTML]="'documents.biometricVerificationFailed' | translate"></div>
    </div>
  </div>

  <p class="centermodal__description" [ngClass]="{ 'm-0 text-left': hasFailedBiometry }">{{ 'documents.signAs' | translate }}</p>
  <div class="centermodal__form">
    <div class="preview" *ngIf="currentUser">
      <ng-container *ngIf="document?.qualified && certInfo; else signerInfo">
        <div class="row">
          <div class="col-8 preview-wrapper" *ngIf="certInfo.serial">
            <div class="preview-label">{{ 'documents.certificateSerialNumber' | translate }}</div>
            <div class="preview-content">{{ certInfo.serial }}</div>
          </div>
          <div class="col-8 preview-wrapper" *ngIf="!certInfo.serial">
            <div class="preview-label">{{ 'documents.certificate' | translate }}</div>
            <div class="preview-content">{{ certInfo.cn }}</div>
          </div>
          <div class="col-4 preview-wrapper text-right">
            <a href="javascript:void(0)" class="preview-link" (click)="modalType = ModalType.qualifiedOptions">{{ 'documents.changeCertificate' | translate }}</a>
          </div>
        </div>

        <div class="row">
          <div class="col-6 preview-wrapper">
            <div class="preview-label">{{ 'documents.certificateType' | translate }}</div>
            <div class="preview-content">{{ certInfo.serial && certInfo.type ? cloudSignatureInfo[certInfo.type].label : 'A1' }}</div>
          </div>
          <div class="col-6 preview-wrapper" *ngIf="certInfo.o">
            <div class="preview-label">{{ 'documents.keyChain' | translate }}</div>
            <div class="preview-content">{{ certInfo.o }}</div>
          </div>
        </div>
      </ng-container>
      <ng-template #signerInfo>
        <div class="preview-wrapper">
          <div class="preview-label">{{ 'profile.fullName' | translate }}</div>
          <div class="preview-content">{{ currentUser.name }}</div>
        </div>

        <div class="row">
          <div class="col-6 preview-wrapper" *ngIf="currentUser?.cpf">
            <div class="preview-label">{{ 'profile.cpf' | translate }}</div>
            <div class="preview-content">{{ currentUser.cpf }}</div>
          </div>
          <div class="col-6 preview-wrapper">
            <div class="preview-label">{{ 'profile.birthDate' | translate }}</div>
            <div class="preview-content">{{ dateService.normalizeDate(currentUser.birthday) }}</div>
          </div>
        </div>
      </ng-template>

      <div class="preview-wrapper">
        <div class="preview-label">{{ 'documents.signatureAppearance' | translate }}</div>
        <div class="preview-signature forceDefaultColors">
          <div class="preview-signature__handwriting {{ (currentUser.settings.font || '').toLowerCase() }}" *ngIf="format === SignatureFormat.Handwriting">
            {{ currentUser.name | signatureName }}
          </div>
          <div class="preview-signature__eletronic" *ngIf="format === SignatureFormat.Eletronic">{{ 'documents.electronicallySigned' | translate }}</div>
          <div
            class="preview-signature__img"
            [ngStyle]="{ backgroundImage: 'url(' + currentUser.settings.signature + ')' }"
            *ngIf="format === SignatureFormat.Image && currentUser.settings.signature"
          ></div>
          <div
            class="preview-signature__img"
            [ngStyle]="{ backgroundImage: 'url(' + currentUser.settings.signature_draw + ')' }"
            *ngIf="format === SignatureFormat.Draw && currentUser.settings.signature_draw"
          ></div>
        </div>
      </div>
    </div>

    <div *ngIf="!appService.isEnterpriseChild" [class.d-none]="(organizations || []).length <= 1">
      <label class="field__holder select" [appFormControlWrap]="preSignForm.get('organization')">
        <select class="field" formControlName="organization" required>
          <option [ngValue]="organization.id" *ngFor="let organization of organizations">{{ organization.name }}</option>
        </select>
        <p class="field__placeholder">{{ 'documents.organization' | translate }}</p>
      </label>
      <div class="message"><i class="fal fa-info-circle"></i>&nbsp; {{ 'documents.documentWillBeLinked' | translate }}</div>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="preSignForm.invalid || isLoading">
      {{ 'sign_action_' + (action || ActionEnum.Sign).toLowerCase() | translate }}
    </button>
  </div>
</form>

<div class="modal-body" *ngIf="modalType === ModalType.qualifiedOptions">
  <p class="centermodal__title">{{ 'documents.selectCertificateType' | translate }}</p>
  <p class="centermodal__description text-left">{{ (isWhitelabel() ? 'documents.haveCertificateA1' : 'documents.haveCertificateAll') | translate }}</p>

  <div class="centermodal__form">
    <div class="centermodal__button-group centermodal__button-group--vertical forceDefaultColors">
      <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-a1" [disabled]="isLoading" (click)="modalType = ModalType.a1">
        <i class="fal fa-file-certificate"></i>
        A1&nbsp; <span class="font-weight-light">({{ 'documents.qualified.file' | translate }})</span>
      </button>
      <ng-container *ngIf="!isWhitelabel()">
        <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-a3" [disabled]="isLoading" (click)="modalType = ModalType.a3; focusPasswordInput()">
          <i class="fal fa-usb-drive"></i>
          A3&nbsp; <span class="font-weight-light">({{ 'documents.qualified.token' | translate }})</span>
        </button>
        <button
          type="button"
          class="centermodal__button centermodal__button--large centermodal__button--custom-safeid"
          [disabled]="isLoading"
          (click)="modalType = ModalType.safeId; focusCpfCnpjInput()"
        >
          <figure style="background-image: url('assets/safeid.svg')" alt="SafeID" class="icon"></figure>
          SafeID&nbsp; <span class="font-weight-light">({{ 'documents.qualified.cloud' | translate }})</span>
        </button>
        <button
          type="button"
          class="centermodal__button centermodal__button--large centermodal__button--custom-vidaas"
          [disabled]="isLoading"
          (click)="modalType = ModalType.vidaas; focusCpfCnpjInput()"
        >
          <figure style="background-image: url('assets/vidaas.png')" alt="VIDaaS" class="icon"></figure>
          VIDaaS&nbsp; <span class="font-weight-light">({{ 'documents.qualified.cloud' | translate }})</span>
        </button>
        <button
          type="button"
          class="centermodal__button centermodal__button--large centermodal__button--custom-birdid"
          [disabled]="isLoading"
          (click)="modalType = ModalType.birdid; focusCpfCnpjInput()"
        >
          <figure style="background-image: url('assets/birdid.png')" alt="BirdID" class="icon"></figure>
          BirdID&nbsp; <span class="font-weight-light">({{ 'documents.qualified.cloud' | translate }})</span>
        </button>
      </ng-container>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.close' | translate }}</button>
  </div>
</div>

<form class="modal-body" [formGroup]="a1Form" [class.d-none]="modalType !== ModalType.a1" (ngSubmit)="padesNextStep()" novalidate>
  <ng-container *ngIf="!isLoading && a1FormStep === A1FormStep.upload">
    <p class="centermodal__title">{{ 'documents.selectICPBrasilCertificateA1' | translate }}</p>
    <p class="centermodal__description text-left">
      {{ 'documents.qualifiedElectronicSignatureA1' | translate }}
    </p>
  </ng-container>
  <ng-container *ngIf="!isLoading && a1FormStep === A1FormStep.password">
    <p class="centermodal__title">{{ 'documents.fillInCertPassword' | translate }}</p>
    <p class="centermodal__description text-left">{{ 'documents.enterCertPassword' | translate }}</p>
  </ng-container>

  <div class="centermodal__form">
    <div [class.d-none]="isLoading">
      <div class="upload-field" [class.d-none]="a1FormStep !== A1FormStep.upload" #uploadWrapper>
        <label>
          <input type="file" accept=".pfx,.p12" (change)="a1Form.get('file').setValue($event.target['files'][0]); $any($event.target).value = ''; padesNextStep()" />
          <i class="fal fa-file-upload"></i>&nbsp; {{ 'documents.clickOrDragFile' | translate }}
        </label>
        <div class="upload-field__error" *ngIf="a1Form.get('file').hasError('server')"><i class="fal fa-exclamation-triangle"></i>{{ a1Form.get('file').getError('server') }}</div>
        <div class="upload-field__footer">{{ 'documents.fileFormat' | translate }}</div>
      </div>

      <label class="field__holder" [appFormControlWrap]="a1Form.get('password')" [class.d-none]="a1FormStep !== A1FormStep.password">
        <input type="password" class="field" formControlName="password" name="password" autocomplete="none" required appShowPassword data-sentry-mask #a1PasswordInput />
        <span class="field__placeholder">{{ 'documents.certificatePassword' | translate }}</span>
      </label>
    </div>
    <app-small-loader *ngIf="isLoading"></app-small-loader>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="a1FormStep === A1FormStep.upload ? (modalType = ModalType.qualifiedOptions) : (a1FormStep = A1FormStep.upload)">
      {{ 'button.back' | translate }}
    </button>
    <button
      type="submit"
      class="centermodal__button centermodal__button--custom-next"
      [disabled]="isLoading || (a1FormStep === A1FormStep.upload && !a1Form.get('file').value) || (a1FormStep === A1FormStep.password && a1Form.get('password').invalid)"
    >
      {{ a1FormStep === A1FormStep.password ? ('sign_action_' + (action || ActionEnum.Sign).toLowerCase() | translate) : 'Continuar' }}
    </button>
  </div>
</form>

<div class="modal-body" [class.d-none]="modalType !== ModalType.a3">
  <ng-container *ngIf="!isElectron">
    <div class="centermodal__form">
      <p class="centermodal__title">{{ 'documents.openSigner' | translate }}</p>
      <p class="centermodal__description text-left">
        {{ 'documents.signInAppRequired' | translate }}
      </p>

      <div class="centermodal__button-group centermodal__button-group--vertical centermodal__button-group--vertical-a3">
        <a [href]="desktopAppUrl()" class="centermodal__button centermodal__button--large centermodal__button--custom-blue">
          <div class="mx-auto">{{ 'documents.alreadyInstalled' | translate }}</div>
        </a>
        <a href="javascript:void(0)" class="centermodal__button centermodal__button--large centermodal__button--custom-blue-outlined" (click)="downloadDesktopApp()">
          <div class="mx-auto">{{ 'documents.downloadApp' | translate }}&nbsp;&nbsp; <i class="fal fa-external-link"></i></div>
        </a>
      </div>
    </div>

    <div class="centermodal__button-group">
      <button type="button" class="centermodal__button" (click)="modalType = ModalType.qualifiedOptions">{{ 'button.back' | translate }}</button>
    </div>
  </ng-container>

  <form [formGroup]="a3Form" (ngSubmit)="padesNextStep()" *ngIf="isElectron" novalidate>
    <ng-container *ngIf="!isLoading">
      <p class="centermodal__title">{{ 'documents.selectICPBrasilCertificateA3' | translate }}</p>
      <p class="centermodal__description text-left">
        {{ 'documents.qualifiedElectronicSignatureA3' | translate }}
      </p>
    </ng-container>

    <div class="centermodal__form">
      <div [class.d-none]="isLoading">
        <label class="field__holder" [appFormControlWrap]="a3Form.get('password')">
          <input type="password" class="field" formControlName="password" name="password" autocomplete="none" required appShowPassword data-sentry-mask #a3PasswordInput />
          <span class="field__placeholder">{{ 'documents.certificatePassword' | translate }}</span>
        </label>

        <ng-container *ngIf="canSelectPkcs11Library">
          <div class="modal-alert modal-alert__danger mb-2">
            <div class="modal-alert__icon"><i class="fal fa-exclamation-triangle"></i></div>
            <div class="modal-alert__content">
              <div class="modal-alert__description">
                {{ 'documents.unableToFindLibrary' | translate }}
              </div>
            </div>
          </div>
          <a href="javascript:void(0)" class="d-block text-center mt-3" (click)="selectPkcs11Lib()">{{ 'documents.selectLibrary' | translate }}</a>
        </ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="loader" *ngIf="isLoading"></ng-container>
    </div>

    <div class="centermodal__button-group">
      <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modalType = ModalType.qualifiedOptions">{{ 'button.back' | translate }}</button>
      <button type="submit" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading || a3Form.get('password').invalid">
        {{ 'sign_action_' + (action || ActionEnum.Sign).toLowerCase() | translate }}
      </button>
    </div>
  </form>
</div>

<form class="modal-body" [formGroup]="safeIdForm" [class.d-none]="modalType !== ModalType.safeId" (ngSubmit)="padesNextStep()" novalidate>
  <ng-container *ngIf="safeIdFormStep === SafeIdFormStep.cpfCnpj">
    <p class="centermodal__title">{{ 'documents.safeID' | translate }}</p>
    <p class="centermodal__description text-left">{{ 'documents.fillInCNPJorCPFCloud' | translate: { label: cloudSignatureLabels[modalType] } }}</p>
  </ng-container>
  <ng-container *ngIf="safeIdFormStep === SafeIdFormStep.waitingAuthorization">
    <p class="centermodal__title">{{ 'documents.safeID' | translate }}</p>
  </ng-container>
  <ng-container *ngIf="safeIdFormStep === SafeIdFormStep.password">
    <p class="centermodal__title">{{ 'documents.fillInCertPassword' | translate }}</p>
    <p class="centermodal__description text-left">{{ 'documents.enterCertPassword' | translate }}</p>
  </ng-container>

  <div class="centermodal__form">
    <label class="field__holder" [appFormControlWrap]="safeIdForm.get('cpfCnpj')" [class.d-none]="safeIdFormStep != SafeIdFormStep.cpfCnpj">
      <input
        type="tel"
        class="field"
        formControlName="cpfCnpj"
        name="cpfCnpj"
        autocomplete="cpfCnpj"
        mask="000.000.000-00||00.000.000/0000-00"
        [readOnly]="safeIdFormStep !== SafeIdFormStep.cpfCnpj"
        [appValidCpf]="safeidCpfCnpjInput.value.length <= 14"
        [appValidCnpj]="safeidCpfCnpjInput.value.length > 14"
        (change)="dispatchInputEvent(safeidCpfCnpjInput)"
        required
        #safeidCpfCnpjInput
      />
      <span class="field__placeholder">{{ 'documents.CNPJorCPFCertificate' | translate }}</span>
    </label>

    <label class="field__holder" [appFormControlWrap]="safeIdForm.get('password')" [class.d-none]="safeIdFormStep !== SafeIdFormStep.password">
      <input type="password" class="field" formControlName="password" name="password" autocomplete="none" required appShowPassword data-sentry-mask #safeidPasswordInput />
      <span class="field__placeholder">{{ 'documents.certificatePassword' | translate }}</span>
    </label>

    <div class="modal-alert" *ngIf="safeIdFormStep === SafeIdFormStep.waitingAuthorization">
      <div class="modal-alert__icon"><i class="fal fa-user-clock"></i></div>
      <div class="modal-alert__content">
        <div class="modal-alert__title" [innerHTML]="'documents.awaitingAuthorization' | translate: { cpfCnpj: safeidCpfCnpjInput.value }"></div>
        <div class="modal-alert__description" [innerHTML]="'documents.authorizeCloud' | translate: { label: cloudSignatureLabels[modalType] }"></div>
      </div>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button
      type="button"
      class="centermodal__button"
      [disabled]="isLoading"
      (click)="safeIdFormStep === SafeIdFormStep.cpfCnpj ? (modalType = ModalType.qualifiedOptions) : (safeIdFormStep = SafeIdFormStep.cpfCnpj)"
    >
      {{ 'button.back' | translate }}
    </button>
    <button
      type="submit"
      class="centermodal__button centermodal__button--custom-next"
      [disabled]="isLoading || (safeIdFormStep === SafeIdFormStep.cpfCnpj && safeIdForm.get('cpfCnpj').invalid) || (safeIdFormStep === SafeIdFormStep.password && safeIdForm.get('password').invalid)"
      *ngIf="safeIdFormStep !== SafeIdFormStep.waitingAuthorization"
    >
      {{ safeIdFormStep === SafeIdFormStep.password ? ('signature.signAction.' + (action || ActionEnum.Sign).toLowerCase() | translate) : ('button.continue' | translate) }}
    </button>
  </div>
</form>

<form class="modal-body" [formGroup]="defaultCloudForm" [class.d-none]="![ModalType.vidaas, ModalType.birdid].includes($any(modalType))" (ngSubmit)="padesNextStep()" novalidate>
  <ng-container *ngIf="defaultCloudFormStep === DefaultCloudFormStep.cpfCnpj">
    <p class="centermodal__title">{{ cloudSignatureLabels[modalType] }}</p>
    <p class="centermodal__description text-left">{{ 'documents.fillInCNPJorCPFCloud' | translate: { label: cloudSignatureLabels[modalType] } }}</p>
  </ng-container>
  <ng-container *ngIf="defaultCloudFormStep === DefaultCloudFormStep.waitingAuthorization">
    <p class="centermodal__title">{{ cloudSignatureLabels[modalType] }}</p>
  </ng-container>

  <div class="centermodal__form">
    <label class="field__holder" [appFormControlWrap]="defaultCloudForm.get('cpfCnpj')" [class.d-none]="defaultCloudFormStep != DefaultCloudFormStep.cpfCnpj">
      <input
        type="tel"
        class="field"
        formControlName="cpfCnpj"
        name="cpfCnpj"
        autocomplete="cpfCnpj"
        mask="000.000.000-00||00.000.000/0000-00"
        [readOnly]="defaultCloudFormStep !== DefaultCloudFormStep.cpfCnpj"
        [appValidCpf]="defaultCloudCpfCnpjInput.value.length <= 14"
        [appValidCnpj]="defaultCloudCpfCnpjInput.value.length > 14"
        (change)="dispatchInputEvent(defaultCloudCpfCnpjInput)"
        required
        #defaultCloudCpfCnpjInput
      />
      <span class="field__placeholder">{{ 'documents.CNPJorCPFCertificate' | translate }}</span>
    </label>

    <!-- Novo input para BirdID -->
    <ng-container *ngIf="modalType === ModalType.birdid && defaultCloudFormStep === DefaultCloudFormStep.cpfCnpj">
      <label class="field__holder" [appFormControlWrap]="defaultCloudForm.get('otp')">
        <input type="tel" class="field" autocomplete="none" formControlName="otp" minlength="6" maxlength="6" required />
        <span class="field__placeholder">{{ 'documents.otpCode' | translate }}</span>
      </label>
    </ng-container>

    <div class="modal-alert" *ngIf="defaultCloudFormStep === DefaultCloudFormStep.waitingAuthorization">
      <div class="modal-alert__icon"><i class="fal fa-user-clock"></i></div>
      <div class="modal-alert__content">
        <div class="modal-alert__title" [innerHTML]="'documents.awaitingAuthorization' | translate: { cpfCnpj: defaultCloudCpfCnpjInput.value }"></div>
        <div class="modal-alert__description" [innerHTML]="'documents.authorizeCloud' | translate: { label: cloudSignatureLabels[modalType] }"></div>
      </div>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button
      type="button"
      class="centermodal__button"
      [disabled]="isLoading"
      (click)="defaultCloudFormStep === DefaultCloudFormStep.cpfCnpj ? (modalType = ModalType.qualifiedOptions) : (defaultCloudFormStep = DefaultCloudFormStep.cpfCnpj)"
    >
      {{ 'button.back' | translate }}
    </button>
    <button
      type="submit"
      class="centermodal__button centermodal__button--custom-next"
      [disabled]="
        isLoading || (defaultCloudFormStep === DefaultCloudFormStep.cpfCnpj && defaultCloudForm.get('cpfCnpj').invalid) || (modalType === ModalType.birdid && defaultCloudForm.get('otp').invalid)
      "
      *ngIf="defaultCloudFormStep !== DefaultCloudFormStep.waitingAuthorization"
    >
      {{ defaultCloudFormStep === DefaultCloudFormStep.cpfCnpj ? ('signature.signAction.' + (action || ActionEnum.Sign).toLowerCase() | translate) : ('button.continue' | translate) }}
    </button>
  </div>
</form>
