<div class="modal-body" [ngClass]="{ camera__mobile: isMobile() && [Steps.Front, Steps.Back, Steps.Selfie].includes($any(step)) }">
  <ng-container [ngTemplateOutlet]="tipsTitleTemplate" *ngIf="step === Steps.Intro"></ng-container>
  <ng-container [ngTemplateOutlet]="hasBackPromptTemplate" *ngIf="step === Steps.HasBackPrompt"></ng-container>
  <ng-container *ngIf="[Steps.Front, Steps.Back, Steps.Selfie].includes($any(step))">
    <ng-container *ngIf="step === Steps.Front">
      <p class="centermodal__title">{{ 'documents.takePhotoOfDocument' | translate }}</p>
      <p class="centermodal__description">{{ 'documents.alignDocumentInImage' | translate }}</p>
    </ng-container>
    <ng-container *ngIf="step === Steps.Back">
      <p class="centermodal__title">{{ 'documents.takePhotoOfDocumentBack' | translate }}</p>
      <p class="centermodal__description">{{ 'documents.takePhotoOfDocumentBackDescription' | translate }}</p>
    </ng-container>
    <ng-container *ngIf="step === Steps.Selfie">
      <p class="centermodal__title">{{ 'documents.takePhotoOfFace' | translate }}</p>
      <p class="centermodal__description">{{ 'documents.alignFaceInTheImage' | translate }}</p>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="step === Steps.MobilePrompt">
    <p class="centermodal__title">{{ 'documents.smartphoneVerification' | translate }}</p>
    <p class="centermodal__description">{{ 'documents.scanQRCode' | translate }}</p>
  </ng-container>
  <ng-container *ngIf="step === Steps.CameraError">
    <p class="centermodal__title">{{ 'documents.biometricVerification' | translate }}</p>
    <p class="centermodal__description">
      {{ 'documents.cameraAccessError' | translate }}
    </p>
  </ng-container>
  <ng-container *ngIf="step === Steps.VerificationError">
    <p class="centermodal__title">{{ 'documents.verificationFailure' | translate }}</p>
    <p class="centermodal__description" *ngIf="verification?.type === SecurityVerificationEnum.Manual">
      {{ 'documents.verificationFailureMessage' | translate }}
    </p>
    <p class="centermodal__description" *ngIf="verification?.type !== SecurityVerificationEnum.Manual">
      {{ 'documents.lowSimilarityErrorMessage' | translate }}
    </p>
  </ng-container>

  <div class="centermodal__form">
    <div class="mx-5 mb-5" *ngIf="step === Steps.Intro">
      <ng-container [ngTemplateOutlet]="tipsContentTemplate"></ng-container>
      <div class="centermodal__button-group centermodal__button-group--vertical mb-3" *ngIf="!isMobile()">
        <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue justify-content-center" (click)="loadCamera()">
          {{ 'documents.useComputerCamera' | translate }}
        </button>
        <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue-outlined justify-content-center" (click)="selectDesktopMobilePrompt()">
          {{ 'documents.useSmartphoneCamera' | translate }}
        </button>
      </div>
    </div>

    <div class="camera" [class.d-none]="![Steps.Front, Steps.Back, Steps.Selfie].includes($any(step))" [ngClass]="{ 'camera-loading': isLoading && isMobile() && firstLoad }">
      <div class="camera-focus {{ step }}" *ngIf="!isLoading" [attr.data-loading]="translateService.instant('shared.loading')"></div>
      <!--      Mobile Buttons -->
      <ng-container *ngIf="isMobile()">
        <div class="camera-background"></div>
        <button class="camera-button back" (click)="goBack()">
          <i class="fa-regular fa-chevron-left"></i>
        </button>
        <button class="camera-button" [disabled]="isLoading" (click)="takePicture()">
          <i class="fa-regular fa-camera"></i>
        </button>
        <button class="camera-button switch" type="button" (click)="toggleCamera()"><i class="fa-regular fa-rotate"></i></button>
      </ng-container>
      <canvas id="cameraCanvas" #canvas></canvas>
    </div>

    <ng-container *ngIf="step === Steps.HasBackPrompt">
      <div class="centermodal__button-group centermodal__button-group--vertical mb-3">
        <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue justify-content-center" (click)="step = Steps.Back">
          {{ 'documents.takeBackPhoto' | translate }}
        </button>
        <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue-outlined justify-content-center" (click)="useFrontAsBackPicture()">
          {{ 'documents.noBackPhoto' | translate }}
        </button>
      </div>
    </ng-container>

    <div class="centermodal__button-group centermodal__button-group--vertical mb-5" *ngIf="[Steps.CameraError, Steps.VerificationError].includes($any(step))">
      <div class="text-center text-danger mb-3">
        <i class="fal fa-5x" [ngClass]="{ 'fa-video-slash': step === Steps.CameraError, 'fa-user-slash': step === Steps.VerificationError }"></i>
      </div>
      <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue justify-content-center" (click)="loadCamera()">
        {{ 'documents.tryAgain' | translate }}
      </button>
      <button
        type="button"
        class="centermodal__button centermodal__button--large centermodal__button--custom-blue-outlined justify-content-center"
        *ngIf="!isMobile()"
        (click)="selectDesktopMobilePrompt()"
      >
        {{ 'documents.useSmartphoneCamera' | translate }}
      </button>
    </div>
  </div>

  <div class="centermodal__form" *ngIf="step === Steps.MobilePrompt">
    <div class="text-center mb-4">
      <qrcode [cssClass]="'qr-code__canvas'" [qrdata]="currentUrl" [width]="200" [margin]="0" *ngIf="currentUrl"></qrcode>
    </div>
    <div class="qr-code__description">{{ 'documents.accessValidationLink' | translate }}</div>
    <div class="text-center" *ngIf="referenceCode">
      <div class="qr-code__code">{{ referenceCode.reference }}</div>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="goBack()">{{ 'button.back' | translate }}</button>
    <button
      type="button"
      class="centermodal__button centermodal__button--custom-next"
      [disabled]="isLoading"
      (click)="takePicture()"
      *ngIf="!isMobile() && [Steps.Front, Steps.Back, Steps.Selfie].includes($any(step))"
    >
      {{ 'documents.takePhoto' | translate }}
    </button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading" (click)="loadCamera()" *ngIf="isMobile() && step === Steps.Intro">
      {{ 'button.forward' | translate }}
    </button>
  </div>
</div>

<ng-template #tipsTitleTemplate>
  <p class="centermodal__title">{{ 'documents.documentVerification' | translate }}</p>
  <p class="centermodal__description">
    {{ 'documents.' + (verification?.type !== SecurityVerificationEnum.PfFacial ? 'documentVerificationPhotoAndFaceRequired' : 'documentVerificationFacePhotoRequired') | translate }}
  </p>
</ng-template>
<ng-template #tipsContentTemplate>
  <div class="font-weight-bold">{{ 'documents.tips' | translate }}</div>
  <ul class="tips-list">
    <li><i class="fal fa-lightbulb-on"></i> {{ 'documents.findWellLitLocation' | translate }}</li>
    <li><i class="fal fa-eye"></i> {{ 'documents.holdPhoneAtEyeLevel' | translate }}</li>
    <li><i class="fal fa-flashlight"></i> {{ 'documents.avoidStrongBacklights' | translate }}</li>
  </ul>
</ng-template>

<ng-template #hasBackPromptTemplate>
  <p class="centermodal__title">{{ 'documents.hasBackPhoto' | translate }}</p>
  <p class="centermodal__description">
    {{ 'documents.selectBackPhotoOption' | translate }}
  </p>
</ng-template>
