<div class="wrapper" [class.missing-canvas]="disableSignature || disableInitials">
  <div class="blur" *ngIf="isLoading"></div>
  <div class="preview-tabs" [class.d-none]="!format || disableFormat">
    <div class="preview-tab" [class.active]="format === SignatureFormat.Handwriting" (click)="selectPreviewMode(SignatureFormat.Handwriting)">{{ 'signature.text' | translate }}</div>
    <div class="preview-tab" [class.active]="format === SignatureFormat.Draw" (click)="selectPreviewMode(SignatureFormat.Draw)">{{ 'signature.draw' | translate }}</div>
    <div class="preview-tab" [class.active]="format === SignatureFormat.Image" (click)="selectPreviewMode(SignatureFormat.Image)">{{ 'signature.image' | translate }}</div>
    <div class="preview-tab" [class.active]="format === SignatureFormat.Eletronic" (click)="selectPreviewMode(SignatureFormat.Eletronic)">{{ 'signature.markup' | translate }}</div>
  </div>

  <div class="forceDefaultColors canvas-wrapper signature" *ngIf="!disableSignature">
    <canvas class="signature-canvas" [class.off]="!isPadOn" #signatureCanvas></canvas>
    <div class="draw-signature-line" [class.off]="![SignatureFormat.Handwriting, SignatureFormat.Eletronic].includes(format)" #signatureLine>
      <i class="fal fa-2x fa-times"></i>
      <div class="signature-previews" *ngIf="format">
        <div class="signature-handwriting {{ (font || '').toLowerCase() }}" *ngIf="format === SignatureFormat.Handwriting && currentUser">{{ currentUser.name | signatureName }}</div>
        <div class="signature-eletronic" *ngIf="format === SignatureFormat.Eletronic">{{ 'signature.electronicallySigned' | translate }}</div>
      </div>
    </div>
    <div class="signature-previews" *ngIf="format && format === SignatureFormat.Image">
      <img class="signature-img" [src]="selectedSignatureImageUrl | safeUrl" alt="" *ngIf="selectedSignatureImageUrl" />
    </div>
    <div class="action-button-wrapper">
      <button type="button" class="btn btn-sm btn-link" *ngIf="!format || format === SignatureFormat.Draw" (click)="clearSignature()">{{ 'button.clean' | translate }}</button>
      <label class="btn btn-sm btn-link" *ngIf="format === SignatureFormat.Image && selectedSignatureImageUrl">
        <input type="file" class="d-none" accept="image/png,image/jpg,image/jpeg" (change)="selectSignatureImage($event.target['files'][0]); $any($event.target).value = ''" />
        <i class="fal fa-edit"></i>
        {{ 'signature.change' | translate }}
      </label>
    </div>
    <div class="canvas-title">
      <div class="fal fa-signature"></div>
      {{ 'signature.signature' | translate }}
    </div>
    <div class="canvas-description">
      <span *ngIf="format === SignatureFormat.Draw && isEmptySignature() && !isLoading">
        {{ 'signature.drawSignatureHere' | translate }}
      </span>
      <label class="btn btn-sm btn-link" *ngIf="format === SignatureFormat.Image && !selectedSignatureImageUrl">
        <input type="file" class="d-none" accept="image/png,image/jpg,image/jpeg" (change)="selectSignatureImage($event.target['files'][0]); $any($event.target).value = ''" />
        <i class="fal fa-cloud-upload-alt"></i>
        {{ 'signature.select' | translate }}
      </label>
    </div>
  </div>

  <div class="forceDefaultColors canvas-wrapper initials" *ngIf="!disableInitials">
    <canvas class="signature-canvas" [class.off]="!isPadOn" #initialsCanvas></canvas>
    <div class="draw-signature-line" [class.off]="![SignatureFormat.Handwriting, SignatureFormat.Eletronic].includes(format)" #initialsLine>
      <i class="fal fa-2x fa-times"></i>
      <div class="signature-previews" *ngIf="format">
        <div class="signature-handwriting {{ (font || '').toLowerCase() }}" *ngIf="currentUser && [SignatureFormat.Handwriting, SignatureFormat.Eletronic].includes(format)">
          {{ currentUser.name | initialsName }}
        </div>
      </div>
    </div>
    <div class="signature-previews" *ngIf="format && format === SignatureFormat.Image">
      <img class="signature-img" [src]="selectedInitialsImageUrl | safeUrl" alt="" *ngIf="selectedInitialsImageUrl" />
    </div>
    <div class="action-button-wrapper">
      <button type="button" class="btn btn-sm btn-link" *ngIf="!format || format === SignatureFormat.Draw" (click)="clearInitials()">{{ 'button.clean' | translate }}</button>
      <label class="btn btn-sm btn-link" *ngIf="format === SignatureFormat.Image && selectedInitialsImageUrl">
        <input type="file" class="d-none" accept="image/png,image/jpg,image/jpeg" (change)="selectInitialsImage($event.target['files'][0]); $any($event.target).value = ''" />
        <i class="fal fa-edit"></i>
        {{ 'signature.change' | translate }}
      </label>
    </div>
    <div class="canvas-title">
      <div class="fal fa-spell-check"></div>
      {{ 'signature.initials' | translate }}
    </div>
    <div class="canvas-description">
      <span *ngIf="format === SignatureFormat.Draw && isEmptyInitials() && !isLoading">{{ 'signature.drawInitialsHere' | translate }}</span>
      <label class="btn btn-sm btn-link" *ngIf="format === SignatureFormat.Image && !selectedInitialsImageUrl">
        <input type="file" class="d-none" accept="image/png,image/jpg,image/jpeg" (change)="selectInitialsImage($event.target['files'][0]); $any($event.target).value = ''" />
        <i class="fal fa-cloud-upload-alt"></i>
        {{ 'signature.select' | translate }}
      </label>
    </div>
  </div>
</div>
