import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { FetchPolicy } from 'apollo-client';
import { get } from 'lodash';
import * as forge from 'node-forge';

import { CertificateStorage, readFileAsBinaryString, StorageService, throwOnGraphqlError } from '@app/core';
import { SignatureTypesEnum, User, CloudSignaturesEnum } from '@app/models';
import {
  AuthorizeCloudCertApplicationGQL,
  AuthorizeCloudCertApplicationMutationVariables,
  AuthorizeCloudCertSignatureGQL,
  AuthorizeCloudCertSignatureMutationVariables,
  CheckAuthorizationVidaasGQL,
  CurrentUserHasCloudCertGQL,
  StartPadesSignatureGQL,
  StartPadesSignatureMutationVariables
} from 'src/generated/graphql.default';

export interface PadesSignatureData {
  type: SignatureTypesEnum;
  padesSignature?: string;
}

@Injectable({ providedIn: 'root' })
export class PadesService {
  constructor(
    private storageService: StorageService,
    private startPadesSignatureGQL: StartPadesSignatureGQL,
    private currentUserHasCloudCertGQL: CurrentUserHasCloudCertGQL,
    private authorizeCloudCertApplicationGQL: AuthorizeCloudCertApplicationGQL,
    private authorizeCloudCertSignatureGQL: AuthorizeCloudCertSignatureGQL,
    private checkAuthorizationVidaasGQL: CheckAuthorizationVidaasGQL
  ) {}

  startPadesSignature(variables: StartPadesSignatureMutationVariables) {
    return this.startPadesSignatureGQL.mutate({ ...variables }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.acceptSignature)
    );
  }

  authorizeCloudCertApp(variables: AuthorizeCloudCertApplicationMutationVariables) {
    return this.authorizeCloudCertApplicationGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.authorizeApp)
    );
  }

  authorizeSafeIdSignature(variables: AuthorizeCloudCertSignatureMutationVariables) {
    return this.authorizeCloudCertSignatureGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.authorizeSign)
    );
  }

  checkCloudCert(options: { fetchPolicy: FetchPolicy } = { fetchPolicy: 'network-only' }) {
    return this.currentUserHasCloudCertGQL.fetch(null, options).pipe(
      throwOnGraphqlError(),
      map(response => response.data.hasCloudCert)
    );
  }

  checkDefaultCloudAuthorization(type: CloudSignaturesEnum): Observable<boolean> {
    const mutation = { [CloudSignaturesEnum.Vidaas]: this.checkAuthorizationVidaasGQL }[type];
    const responseParam = { [CloudSignaturesEnum.Vidaas]: 'checkAuthorization' }[type];
    if (!mutation) {
      return throwError(null);
    }

    return mutation.mutate().pipe(
      throwOnGraphqlError(),
      map((response: any) => (response?.data || {})[responseParam])
    );
  }

  prepareToSignPades(options: { type: SignatureTypesEnum; user: User; signatureId: string; password?: string; file?: File | string; cpfCnpj?: string }): Observable<PadesSignatureData> {
    if (options.type === SignatureTypesEnum.Safeid) {
      return (options.cpfCnpj && options.password ? this.authorizeSafeIdSignature({ cpfCnpj: options.cpfCnpj, password: options.password }) : of(true)).pipe(
        switchMap(isAuthorized => (isAuthorized ? of(null) : throwError(null))),
        tap(() => this.removeA1CertificateData(options.user)),
        map(() => ({ type: SignatureTypesEnum.Safeid } as PadesSignatureData))
      );
    } else if (options.type === SignatureTypesEnum.A3) {
      return of(null).pipe(
        map(() => {
          electronAPI.openPkcs11Session(options.user, options.password);
          return electronAPI.getPkcs11Certificate();
        }),
        switchMap(certPem => this.startPadesSignature({ id: options.signatureId, type: SignatureTypesEnum.A3, payload: certPem })),
        map(data => data.hash),
        map(messageEnc => electronAPI.signPkcs11(messageEnc)),
        tap(() => this.removeA1CertificateData(options.user)),
        map(padesSignature => ({ type: SignatureTypesEnum.A3, padesSignature })),
        finalize(() => electronAPI.closePkcs11Session())
      );
    } else if ([SignatureTypesEnum.Vidaas, SignatureTypesEnum.Birdid].includes(options.type)) {
      return of(null).pipe(
        tap(() => this.removeA1CertificateData(options.user)),
        map(() => ({ type: options.type } as PadesSignatureData))
      );
    } else {
      let fileAsBinaryString: string;
      let certificate: forge.pki.Certificate;
      let privateKey: forge.pki.rsa.PrivateKey;
      return (typeof options.file === 'string' ? of(options.file) : readFileAsBinaryString(options.file)).pipe(
        tap(data => {
          fileAsBinaryString = data;
          certificate = this.parseA1Certificate(fileAsBinaryString, options.password);
          if (this.isCertificateExpired(certificate)) {
            throw new Error('certificate expired');
          } else {
            privateKey = this.parseA1PrivateKey(fileAsBinaryString, options.password);
          }
        }),
        map(() => forge.pki.certificateToPem(certificate)),
        switchMap(certPem => this.startPadesSignature({ id: options.signatureId, type: SignatureTypesEnum.A1, payload: certPem })),
        map(data => data.hash),
        map(messageEnc => {
          const md = forge.md.sha256.create();
          md.update(forge.util.hexToBytes(messageEnc));
          return forge.util.bytesToHex(privateKey.sign(md));
        }),
        tap(() => this.storeA1CertificateData(options.user, { file: fileAsBinaryString, password: options.password })),
        map(padesSignature => ({ type: SignatureTypesEnum.A1, padesSignature }))
      );
    }
  }

  selectPkcs11LibDialog() {
    return new Observable<string>(subscriber => {
      if (electronAPI) {
        electronAPI
          .selectPkcs11LibDialog()
          .then((filePath: string) => {
            subscriber.next(filePath);
            subscriber.complete();
          })
          .catch(subscriber.error);
      } else {
        subscriber.error();
      }
    });
  }

  isCertificateExpired(certificate: forge.pki.Certificate) {
    return new Date() < certificate.validity.notBefore || new Date() > certificate.validity.notAfter;
  }

  getStoredA1CertificateData(user: User) {
    return this.storageService.getA1CertificateData(user);
  }

  storeA1CertificateData(user: User, value: CertificateStorage) {
    this.storageService.setA1CertificateData(user, value);
  }

  removeA1CertificateData(user: User) {
    this.storageService.removeA1CertificateData(user);
  }

  parseA1Certificate(fileAsBinaryString: string, password: string) {
    const certificates = this.getPkcs12(fileAsBinaryString, password).getBags({ bagType: forge.pki.oids.certBag })[forge.pki.oids.certBag];
    const issuers = certificates.map(crt => crt.cert.issuer.hash);
    return get(
      certificates.find(crt => !issuers.includes(crt.cert.subject.hash)),
      'cert'
    );
  }

  parseA1PrivateKey(fileAsBinaryString: string, password: string) {
    const pkcs12 = this.getPkcs12(fileAsBinaryString, password);
    const privateKey = get(pkcs12.getBags({ bagType: forge.pki.oids.keyBag })[forge.pki.oids.keyBag][0], 'key') as forge.pki.rsa.PrivateKey;
    return privateKey || (get(pkcs12.getBags({ bagType: forge.pki.oids.pkcs8ShroudedKeyBag })[forge.pki.oids.pkcs8ShroudedKeyBag][0], 'key') as forge.pki.rsa.PrivateKey);
  }

  private getPkcs12(fileAsBinaryString: string, password: string) {
    return forge.pkcs12.pkcs12FromAsn1(forge.asn1.fromDer(fileAsBinaryString), password);
  }
}
