import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading, UrlSerializer } from '@angular/router';

import { UrlSerializerService } from '@app/core';

const routes: Routes = [
  { path: '', redirectTo: 'documentos/todos', pathMatch: 'full' },

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
  providers: [
    {
      provide: UrlSerializer,
      useClass: UrlSerializerService
    }
  ]
})
export class AppRoutingModule {}
