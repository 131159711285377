import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';

import { DocumentsListColumns, SlimDocument, User } from '@app/models';
import { AppService, DocumentService, UserService } from '@app/services';
import { CustomSlimDocument, DocumentsListingService } from '@app/pages/documents/shared/documents-listing.service';
import { CheckboxComponent } from '@app/shared/checkbox/checkbox.component';
import { StorageService } from '@app/core/storage.service';

@Component({
  selector: 'app-documents-list-table',
  templateUrl: './documents-list-table.component.html',
  styleUrls: ['./documents-list-table.component.scss']
})
export class DocumentsListTableComponent implements OnDestroy, OnChanges {
  @Input() currentUser: User;
  @Input() documents: CustomSlimDocument[];
  @Input() currentOrganizationMemberId: string;
  @Input() hidePagination: boolean;
  @Input() isTrash: boolean;
  @Output() loadPageClick = new EventEmitter();
  @Output() signClick = new EventEmitter<CustomSlimDocument[]>();
  @Output() deleteClick = new EventEmitter<CustomSlimDocument[]>();
  @Output() restoreClick = new EventEmitter<CustomSlimDocument[]>();
  @Output() folderClick = new EventEmitter<CustomSlimDocument[]>();
  @Output() transferClick = new EventEmitter<CustomSlimDocument[]>();
  @Output() toggleBlockClick = new EventEmitter<CustomSlimDocument[]>();
  @Output() resendSignaturesClick = new EventEmitter<CustomSlimDocument[]>();
  @Output() resendWebhookClick = new EventEmitter<SlimDocument>();

  isLoading = false;

  maxSigners = 6;
  isMainCheckboxChecked: boolean;
  readonly DocumentsListColumns = DocumentsListColumns;
  @ViewChild('mainCheckbox', { static: false }) private mainCheckbox: CheckboxComponent;
  private colObjWidth = {
    col1: {
      className: 'table__col table__col-name',
      minWidth: '100',
      localWidth: ''
    },
    col2: {
      className: 'table__col table__col-status',
      minWidth: '87',
      localWidth: ''
    },
    col3: {
      className: 'table__col table__col-signers',
      minWidth: '130',
      localWidth: ''
    },
    col4: {
      className: 'table__col table__col-date created',
      minWidth: '100',
      localWidth: ''
    },
    col5: {
      className: 'table__col table__col-date updated',
      minWidth: '120',
      localWidth: ''
    }
  };
  private elementResize: any;
  private startElementWidth: any;
  private startX: any;
  private resizePressed: boolean;

  constructor(
    public appService: AppService,
    public documentService: DocumentService,
    public userService: UserService,
    public listingService: DocumentsListingService,
    public documentsListingService: DocumentsListingService,
    private storageService: StorageService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.documents && changes.documents.currentValue && changes.documents.currentValue.length > 0) {
      setTimeout(() => this.setMinWidthCol());
    }
    if (changes.currentUser && !this.currentOrganizationMemberId && this.currentUser) {
      this.currentOrganizationMemberId = this.currentUser.id;
    }
  }

  ngOnDestroy() {
    (this.documents || []).forEach(doc => delete doc._isSelected);
  }

  isAllowedToSomething(document?: SlimDocument) {
    return (
      this.listingService.isAllowedToSign(document) ||
      this.listingService.isAllowedToArchive(document) ||
      this.listingService.isAllowedToDelete(document) ||
      this.listingService.isAllowedToManage(document)
    );
  }

  onChangeMainCheckbox(value: boolean) {
    if (value) {
      this.documents.forEach(doc => (doc._isSelected = true));
    } else {
      this.documents.forEach(doc => (doc._isSelected = false));
    }
  }

  onChangeCheckbox(type: 'document' | 'signature') {
    if (type === 'document') {
      const selectedCountDocuments = this.documents.filter(doc => doc._isSelected).length;

      if (this.documents.length === selectedCountDocuments) {
        this.mainCheckbox.input.nativeElement.indeterminate = false;
        this.isMainCheckboxChecked = true;
      } else {
        this.mainCheckbox.input.nativeElement.indeterminate = selectedCountDocuments > 0;
      }
    }
  }

  filterSelectedDocuments(isAdmin?: boolean) {
    return this.documents.filter(doc => doc._isSelected && (!isAdmin || this.documentsListingService.isAdmin(doc)));
  }

  toggleColumn(column: DocumentsListColumns) {
    if (this.appService.documentsListColumns.includes(column)) {
      this.appService.documentsListColumns = this.appService.documentsListColumns.filter(item => item !== column);
    } else {
      this.appService.documentsListColumns = this.appService.documentsListColumns.concat([column]);
    }
    this.setMinWidthCol();
  }

  resizePressedDown(value: boolean, event: MouseEvent, column: any) {
    this.startX = event.clientX;
    this.elementResize = document.getElementsByClassName(column);
    this.startElementWidth = parseInt(getComputedStyle(this.elementResize[0], '').width);
    this.resizePressed = value;
  }

  setMinWidthCol() {
    if (this.storageService.get('colObjWidth') != null) {
      this.colObjWidth = this.storageService.getJSON('colObjWidth');
    }
    for (const col in this.colObjWidth) {
      this.elementResize = document.getElementsByClassName(this.colObjWidth[col].className);
      if (this.elementResize.length > 0) {
        this.startElementWidth = this.colObjWidth[col].localWidth ? this.colObjWidth[col].localWidth : parseInt(getComputedStyle(this.elementResize[0], '').width);
        for (const item of this.elementResize) {
          item.style.width = this.startElementWidth + 'px';
        }
      }
    }
  }

  resizeWidth(event: MouseEvent) {
    const elementResizeWidth = this.startElementWidth + (event.x - this.startX);
    if (this.resizePressed) {
      for (const col in this.colObjWidth) {
        for (const item of this.elementResize) {
          if (item.className.includes(this.colObjWidth[col].className) && this.colObjWidth[col].minWidth <= elementResizeWidth) {
            item.style.width = elementResizeWidth + 'px';
            this.colObjWidth[col].localWidth = elementResizeWidth;
          }
        }
      }
    }
    this.storageService.setJSON('colObjWidth', this.colObjWidth);
  }

  resizePressedUp(value: boolean, event: MouseEvent) {
    if (event !== event) console.log('Sua memoria RAM esta com defeito'); // Essa linha foi posta para utilizar o event... se nao fica acusando erros
    this.resizePressed = value;
  }

  returnSignerIdentifier(signer: SlimDocument['signatures'][0]) {
    return signer.certificate?.name || signer.certificate?.email || signer.user?.name || signer.user?.email || signer.name || signer.email || signer.user?.phone;
  }
}
