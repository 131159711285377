import { AfterContentInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize, switchMap, tap } from 'rxjs/operators';

import { ErrorHandlerService, UserService, AuthenticationService, RegistrationContext, DateService } from '@app/services';
import { Signer, User } from '@app/models';
import { AuthEmailConfirmationModalService } from '../auth-email-confirmation-modal/auth-email-confirmation-modal.service';
import { CredentialsService, I18nService } from '@app/core';
import { SelectOption } from '@app/shared';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export type ModalResult = User;
export interface ModalPublicProperties {
  signer?: Signer;
  email?: string;
  ignoreCpf?: boolean;
}

@Component({
  selector: 'app-auth-register-modal',
  templateUrl: './auth-register-modal.component.html',
  styleUrls: ['./auth-register-modal.component.scss']
})
export class AuthRegisterModalComponent implements ModalPublicProperties, AfterContentInit {
  @Input() signer: Signer;
  @Input() email: string;
  @Input() ignoreCpf = false;
  form!: FormGroup;
  isLoading = false;
  isRegistered = false;
  readonly countries: SelectOption[] = [];
  readonly languages: SelectOption[] = [];

  @ViewChild('emailInput', { static: false }) private emailInput: ElementRef<HTMLInputElement>;
  @ViewChild('nameInput', { static: false }) private nameInput: ElementRef<HTMLInputElement>;

  constructor(
    public modal: NgbActiveModal,
    public dateService: DateService,
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private errorHandlerService: ErrorHandlerService,
    private userService: UserService,
    private authEmailConfirmationModalService: AuthEmailConfirmationModalService,
    public route: ActivatedRoute,
    public i18nService: I18nService,
    public translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      email: '',
      name: '',
      cpf: '',
      birthday: '',
      password: '',
      country: null,
      language: this.translateService.currentLang || null,
      timezone: this.i18nService.timezone || null
    } as RegistrationContext);
    this.languages = this.i18nService.supportedLanguagesAsSelectOptions || null;
    this.countries = this.i18nService.countriesWithCodes || null;
  }

  ngAfterContentInit() {
    if (this.signer) {
      this.form.get('email').setValue(this.email || this.signer.email || '');
      this.form.get('name').setValue(this.signer.name || '');

      if (this.form.get('email').value) {
        setTimeout(() => this.nameInput.nativeElement.focus());
      } else {
        setTimeout(() => this.emailInput.nativeElement.focus());
      }
    }
  }

  register() {
    this.isLoading = true;
    this.form.markAllAsTouched();
    this.form.value.birthday = this.dateService.normalizeDate(this.form.value.birthday);

    this.authenticationService.register(this.form.value).subscribe(
      credentials => {
        if (credentials.isEmailConfirmed) {
          this.loadCurrentUser();
        } else {
          this.isLoading = false;
          this.isRegistered = true;
          this.openEmailConfirmationModal();
        }
      },
      error => {
        this.isLoading = false;
        this.errorHandlerService.handleValidation(this.form, error);
      }
    );
  }

  // Loop com loadCurrentUser()
  openEmailConfirmationModal() {
    this.authEmailConfirmationModalService
      .open({ email: this.form.get('email').value })
      .pipe(
        tap(() => (this.isLoading = true)),
        switchMap(() => this.authenticationService.login({ email: this.form.get('email').value, password: this.form.get('password').value }))
      )
      .subscribe(
        () => this.loadCurrentUser(),
        error => {
          this.isLoading = false;
          if (error && (error.status || (error.networkError && error.networkError.status)) === 424) {
            this.openEmailConfirmationModal();
          } else {
            this.errorHandlerService.handleValidation(this.form, error);
          }
        }
      );
  }

  changeLanguage(language: string) {
    this.translateService.use(language);
    (this.languages as SelectOption[]) = this.i18nService.supportedLanguagesAsSelectOptions || null;
    (this.countries as SelectOption[]) = this.i18nService.countriesWithCodes || null;
    if (this.form.get('language')?.value) this.form.get('language').setValue(this.form.get('language').value);
    if (this.form.get('country')?.value) this.form.get('country').setValue(this.form.get('country').value);
  }

  // Loop com openEmailConfirmationModal()
  private loadCurrentUser() {
    this.isLoading = true;
    this.userService
      .getCurrentUser({ fetchPolicy: 'network-only' })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        data => this.modal.close(data as ModalResult),
        error => this.errorHandlerService.handle(error)
      );
  }
}
