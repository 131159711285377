import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { forkJoin, Observable, of, throwError, timer } from 'rxjs';
import { catchError, filter, finalize, mergeMap, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { ApolloQueryResult, WatchQueryFetchPolicy } from 'apollo-client';
import { cloneDeep } from 'lodash';

import { environment } from '@env/environment';
import {
  ContextEnum,
  CreditTypeEnum,
  CustomSignerInput,
  DocumentInput,
  DocumentsListColumns,
  DocumentStatusEnum,
  ListFormat,
  ListSorting,
  OrderByEnum,
  SecurityVerificationEnum,
  Signer,
  SignerMainAttributes,
  ZipCodeInfo,
  CreditType,
  GraphQLResponse
} from '@app/models';
import { CredentialsService, StorageService, throwOnGraphqlError } from '@app/core';
import { NotyService } from './noty.service';
import { LoaderService } from '@app/shared/loader/loader.service';
import { CurrentCreditsPriceForDocumentGQL } from 'src/generated/graphql.default';
import { TranslateService } from '@ngx-translate/core';

export enum AppStorageKeys {
  Darkmode = 'darkmode',
  ShowSandbox = 'showSandbox',
  ListFormat = 'listFormat',
  ListSorting = 'listSorting',
  ListDirection = 'listDirection',
  DocumentStatusEnum = 'documentStatusFilterV2',
  DocumentsListColumns = 'documentsListColumns',
  LockedSigners = 'lockedSignersV2',
  LockedCC = 'lockedCC',
  LockedReplyTo = 'lockedReplyTo',
  DocumentParams = 'documentParams',
  SearchParams = 'searchParams',
  DocumentSections = 'documentSections',
  FirstPageCounts = 'firstPageCounts',
  FolderContext = 'folderContext',
  UrlChangeInfo = 'urlChangeInfoV2'
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private _isDarkmode: boolean;
  private _showSandbox: boolean;
  private _listFormat: ListFormat;
  private _listSorting: ListSorting;
  private _listDirection: OrderByEnum;
  private _documentStatusFilter: DocumentStatusEnum;
  private _documentsListColumns: DocumentsListColumns[];
  private _documentParams: DocumentInput;
  private _searchParams: { [k: string]: any };
  private _documentSections: { [k: string]: boolean };
  private _lockedSigners: CustomSignerInput[];
  private _lockedCC: string;
  private _lockedReplyTo: string;
  private _creditsPriceTable: CreditType[] = [];
  private _firstPageCounts: { [k: string]: any };
  private _folderContext: ContextEnum;
  private _urlChangeInfo: string[];
  private _isEnterpriseChild: boolean = false;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private credentialsService: CredentialsService,
    private notyService: NotyService,
    private formBuilder: FormBuilder,
    private ngZone: NgZone,
    private loaderService: LoaderService,
    private currentCreditsPriceForDocumentGQL: CurrentCreditsPriceForDocumentGQL,
    private translateService: TranslateService
  ) {
    const defaultDocumentsListColumns = [DocumentsListColumns.Name, DocumentsListColumns.Status, DocumentsListColumns.Signers, DocumentsListColumns.CreatedAt];

    // window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    this._isDarkmode = !!parseInt(this.storageService.get(AppStorageKeys.Darkmode));
    this._showSandbox = !!parseInt(this.storageService.get(AppStorageKeys.ShowSandbox));
    this._listFormat = (this.storageService.get(AppStorageKeys.ListFormat) as ListFormat) || ListFormat.Box;
    this._listSorting = (this.storageService.get(AppStorageKeys.ListSorting) as ListSorting) || ListSorting.CreatedAt;
    this._listDirection = (this.storageService.get(AppStorageKeys.ListDirection) as OrderByEnum) || OrderByEnum.Desc;
    this._documentStatusFilter = (this.storageService.get(AppStorageKeys.DocumentStatusEnum) as DocumentStatusEnum) || null;
    this._documentsListColumns = (this.storageService.get(AppStorageKeys.DocumentsListColumns) || defaultDocumentsListColumns.join(',')).split(',').filter(value => !!value) as DocumentsListColumns[];
    this._documentParams = (this.storageService.getJSON(AppStorageKeys.DocumentParams) || { expiration: {}, configs: {} }) as DocumentInput;
    this._documentSections = this.storageService.getJSON(AppStorageKeys.DocumentSections) || {};
    this._lockedSigners = this.parseLockedSigners(this.storageService.getJSON(AppStorageKeys.LockedSigners) || []);
    this._lockedCC = this.storageService.get(AppStorageKeys.LockedCC) || '';
    this._lockedReplyTo = this.storageService.get(AppStorageKeys.LockedReplyTo) || '';
    this._folderContext = (this.storageService.get(AppStorageKeys.FolderContext) as ContextEnum) || ContextEnum.User;
    this._searchParams = this.storageService.getJSON(AppStorageKeys.SearchParams) || {};
    this._firstPageCounts = this.storageService.getJSON(AppStorageKeys.FirstPageCounts) || {};
    this._urlChangeInfo = this.storageService.getJSON(AppStorageKeys.UrlChangeInfo) || [];

    this.refreshCreditsPrice();
  }

  get isDarkmode() {
    return this._isDarkmode;
  }
  set isDarkmode(value: boolean) {
    this._isDarkmode = value;
    this.storageService.set(AppStorageKeys.Darkmode, value ? 1 : 0);
  }

  get showSandbox() {
    return this._showSandbox;
  }
  set showSandbox(value: boolean) {
    this._showSandbox = value;
    this.storageService.set(AppStorageKeys.ShowSandbox, value ? 1 : 0);
  }

  get listFormat() {
    return this._listFormat;
  }
  set listFormat(value: ListFormat) {
    this._listFormat = value;
    this.storageService.set(AppStorageKeys.ListFormat, value);
  }

  get listSorting() {
    return this._listSorting;
  }
  set listSorting(value: ListSorting) {
    this._listSorting = value;
    this.storageService.set(AppStorageKeys.ListSorting, value);
  }

  get listDirection() {
    return this._listDirection;
  }
  set listDirection(value: OrderByEnum) {
    this._listDirection = value;
    this.storageService.set(AppStorageKeys.ListDirection, value);
  }

  get documentStatusFilter() {
    return this._documentStatusFilter;
  }
  set documentStatusFilter(value: DocumentStatusEnum) {
    this._documentStatusFilter = value;
    this.storageService.set(AppStorageKeys.DocumentStatusEnum, value || '');
  }

  get documentsListColumns() {
    return this._documentsListColumns;
  }
  set documentsListColumns(values: DocumentsListColumns[]) {
    this._documentsListColumns = (values || []).filter(value => !!value);
    this.storageService.set(AppStorageKeys.DocumentsListColumns, this._documentsListColumns.join(',') || '');
  }

  get documentParams() {
    return this._documentParams;
  }
  set documentParams(document: DocumentInput) {
    this._documentParams = cloneDeep(document);
    this._documentParams.expiration = this._documentParams.expiration || ({} as DocumentInput['expiration']);
    this.storageService.setJSON(AppStorageKeys.DocumentParams, {
      sortable: document.sortable || undefined,
      refusable: document.refusable || undefined,
      footer: document.footer || undefined,
      reminder: document.reminder || undefined,
      configs: document.configs || undefined,
      new_signature_style: document.new_signature_style,
      scrolling_required: document.scrolling_required,
      show_audit_page: document.show_audit_page,
      stop_on_rejected: document.stop_on_rejected, // Gravar quando false, pois default é true
      expiration: {
        days_before: (document.expiration && document.expiration.days_before) || undefined
      }
    } as DocumentInput);
  }

  get documentSections() {
    return this._documentSections;
  }
  set documentSections(value: { [k: string]: boolean }) {
    this._documentSections = value;
    if (this.storageService.isUsingLocalStorage) {
      this.storageService.setJSON(AppStorageKeys.DocumentSections, this._documentSections);
    }
  }

  get lockedSigners() {
    return this._lockedSigners;
  }
  set lockedSigners(signers: CustomSignerInput[]) {
    this._lockedSigners = signers;
    if (this.storageService.isUsingLocalStorage) {
      this.storageService.setJSON(
        AppStorageKeys.LockedSigners,
        signers.map(signer => [
          signer._extra.signerMainAttribute,
          signer.action,
          signer[signer._extra.signerMainAttribute],
          signer._extra.showCpfVerification ? (signer.configs || {}).cpf || '' : '',
          { show: signer._extra.showOptions, sms: signer._extra.showSmsVerification, cpf: signer._extra.showCpfVerification, document: signer._extra.showSecurityVerifications },
          { phoneNumber: signer._extra.showSmsVerification ? signer._extra.phoneNumber : '', documentType: signer._extra.documentType }
        ])
      );
    } else {
      this.notyService.error(this.translateService.instant('notyService.browserNoLocalStorage'));
    }
  }

  get lockedCC() {
    return this._lockedCC;
  }
  set lockedCC(cc: string) {
    this._lockedCC = cc || '';
    this.storageService.set(AppStorageKeys.LockedCC, cc || '');
  }

  get lockedReplyTo() {
    return this._lockedReplyTo;
  }
  set lockedReplyTo(replyTo: string) {
    this._lockedReplyTo = replyTo || '';
    this.storageService.set(AppStorageKeys.LockedReplyTo, replyTo || '');
  }

  get searchParams() {
    return this._searchParams;
  }
  set searchParams(params: { [k: string]: any }) {
    this._searchParams = cloneDeep(params);
    this.storageService.setJSON(AppStorageKeys.SearchParams, {
      includeArchived: params.includeArchived || undefined,
      includeDeleted: params.includeDeleted || undefined
    });
  }

  get folderContext() {
    return this._folderContext;
  }
  set folderContext(context: ContextEnum) {
    this._folderContext = context;
    this.storageService.set(AppStorageKeys.FolderContext, context);
  }

  get previousUrl() {
    return this._urlChangeInfo[1] && this._urlChangeInfo[1] === location.pathname ? this._urlChangeInfo[0] || null : null;
  }

  get creditsPriceTable() {
    return this._creditsPriceTable;
  }

  get isEnterpriseChild() {
    return this._isEnterpriseChild;
  }

  set isEnterpriseChild(value: boolean) {
    this._isEnterpriseChild = value;
  }

  creditPriceByType(type: string) {
    return type ? this.creditsPriceTable.find(credit => credit.value === type)?.cost : 999;
  }

  totalCreditPrice(signers: CustomSignerInput[]) {
    return this.creditsPriceTable.length <= 0
      ? 999
      : (signers || []).reduce((total, signer) => {
          const creditsTypeMap = {
            [CreditTypeEnum.SecurityVerificationSms]: signer._extra.showSmsVerification && [SignerMainAttributes.Email, SignerMainAttributes.Name].includes(signer._extra.signerMainAttribute),
            [CreditTypeEnum.DeliveryMethodSms]: [SignerMainAttributes.Sms].includes(signer._extra.signerMainAttribute),
            [CreditTypeEnum.DeliveryMethodWhatsapp]: [SignerMainAttributes.Whatsapp].includes(signer._extra.signerMainAttribute),
            [CreditTypeEnum.SecurityVerificationManual]: signer._extra.showSecurityVerifications && signer._extra.documentType === SecurityVerificationEnum.Manual,
            [CreditTypeEnum.SecurityVerificationUpload]: signer._extra.showSecurityVerifications && signer._extra.documentType === SecurityVerificationEnum.Upload,
            [CreditTypeEnum.SecurityVerificationLive]: signer._extra.showSecurityVerifications && signer._extra.documentType === SecurityVerificationEnum.Live,
            [CreditTypeEnum.SecurityVerificationPfFacial]: signer._extra.showSecurityVerifications && signer._extra.documentType === SecurityVerificationEnum.PfFacial
          };
          return (
            total +
            Object.keys(creditsTypeMap)
              .filter((_, i) => Object.values(creditsTypeMap)[i])
              .reduce((sum, type) => sum + (this.creditsPriceTable.find(credit => credit.value === type)?.cost || 0), 0)
          );
        }, 0);
  }

  getFirstPageCount(key: string) {
    return this._firstPageCounts[key];
  }

  addFirstPageCounts(params: { [k: string]: any }) {
    this._firstPageCounts = cloneDeep({ ...this._firstPageCounts, ...(params || {}) });
    this.storageService.setJSON(AppStorageKeys.FirstPageCounts, this._firstPageCounts);
  }

  storagePath(path: string) {
    if (/^(https?):/i.test(path)) {
      return path;
    }
    path = path.replace(/^\//, '');

    if (/\/v2/i.test(environment.serverUrl)) {
      return `${environment.serverUrl.replace(/\/v2/, '/storage')}/${path}`;
    }

    return `${environment.serverUrl}/storage/${path}`;
  }

  panelPath(path: string) {
    return `https://${environment.production ? 'painel' : 'dev.painel'}.autentique.com.br/${(path || '').replace(/^\//, '')}`;
  }

  signerLink(link: Signer['link']) {
    if (link) {
      return link.short_link || this.panelPath('/assinar/') + link.id;
    }
  }

  getZipCodeInfo(cep: string) {
    return this.httpClient
      .disableLoader()
      .get<ZipCodeInfo>('https://viacep.com.br/ws/' + cep + '/json/')
      .pipe(switchMap(data => (data.localidade ? of(data) : throwError('invalid zipcode'))));
  }

  downloadLink(url: string, fileName: string) {
    const link = document.createElement('a');
    document.body.appendChild(link);
    link.style.display = 'none';
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    link.click();
    setTimeout(() => link.remove());
  }

  downloadData(data: any, fileName: string, type: string = 'octet/stream') {
    const blob = new Blob([data], { type });
    const url = URL.createObjectURL(blob);
    this.downloadLink(url, fileName);
    URL.revokeObjectURL(url);
  }

  loadStatusPageWidget() {
    const scriptId = 'statuspage-script';
    if (environment.production && !document.getElementById(scriptId)) {
      const scriptElement = document.createElement('script');
      scriptElement.src = 'https://35j8l08fg1jh.statuspage.io/embed/script.js';
      scriptElement.type = 'text/javascript';
      scriptElement.async = true;
      scriptElement.id = scriptId;
      scriptElement.onload = () => {};
      document.getElementsByTagName('head')[0].appendChild(scriptElement);
    }
  }

  formDataToJSON(formData: { [k: string]: any }, typeMap?: { [k: string]: 'string' | 'number' | 'boolean' }) {
    formData = formData || {};
    typeMap = typeMap || {};
    const jsonEntries = Object.entries(formData).map(([k, v]) => {
      let value;
      if (typeMap[k] === 'string') {
        value = String(v);
      } else if (typeMap[k] === 'number') {
        value = String(v).match('.') ? parseFloat(String(v)) : parseInt(String(v));
      } else if (typeMap[k] === 'boolean') {
        value = v !== false && v !== 0 && String(v).toLowerCase() !== 'false' && v !== '' && v !== '0' && String(v).toLowerCase() !== 'null' && String(v).toLowerCase() !== 'undefined';
      } else {
        value = v;
      }
      return [k, value];
    });

    function parseJsonFromForm(flatJsonArray: any) {
      let json = {};
      flatJsonArray.forEach((elementInArray: any) => {
        json = parseNestedJson(elementInArray[0], elementInArray[1], json);
      });
      return json;
    }
    function parseNestedJson(childNameString: any, value: any, parentJson: any) {
      if (childNameString.indexOf('.') === -1) {
        parentJson[childNameString] = value;
      } else {
        const nestedNames = childNameString.split(/\.(.*)/, 2);
        if (!parentJson[nestedNames[0]]) {
          if (isNaN(nestedNames[0])) {
            parentJson[nestedNames[0]] = {};
          } else {
            if (!Array.isArray(parentJson)) {
              parentJson = [];
            }
          }
        }
        if (parentJson[nestedNames[0]]) {
          parentJson[nestedNames[0]] = parseNestedJson(nestedNames[1], value, parentJson[nestedNames[0]]);
        } else {
          parentJson.push(parseNestedJson(nestedNames[1], value, {}));
        }
      }
      return parentJson;
    }
    return parseJsonFromForm(jsonEntries);
  }

  refreshCreditsPrice() {
    if (this.credentialsService.isAuthenticated()) {
      this.currentCreditsPriceForDocumentGQL.fetch(null, { fetchPolicy: 'cache-first' }).subscribe(response => (this._creditsPriceTable = response.data.howMuchDoesItCost));
    }
  }

  waitUntilUrlsExist(urls: string[]) {
    const clonedUrls = cloneDeep(urls || []);
    return of(null).pipe(
      switchMap(() =>
        forkJoin(
          clonedUrls.map(url =>
            this.httpClient
              .disableLoader()
              .disableVersionUpdate()
              .disableApiPrefix()
              .get(url, { responseType: 'blob' })
              .pipe(
                tap(() =>
                  clonedUrls.splice(
                    clonedUrls.findIndex(thisUrl => thisUrl === url),
                    1
                  )
                ),
                catchError(() => of(null))
              )
          )
        )
      ),
      switchMap(() => (clonedUrls.length > 0 ? throwError(null) : of(null))),
      retryWhen(
        mergeMap((error, i) => {
          const retryAttempt = i + 1;
          if (retryAttempt > 50) {
            return throwError(error);
          }
          return timer(retryAttempt * 1000);
        })
      )
    );
  }

  downsizeImage(image: File, maxSizePx: number) {
    return new Observable<File>(subscriber => {
      const img = new Image();
      const imageSrc = URL.createObjectURL(image);
      img.onload = () => {
        let width = img.width;
        let height = img.height;

        const widthRatio = maxSizePx / width;
        const heightRatio = maxSizePx / height;
        const scale = Math.min(widthRatio, heightRatio);
        if (scale < 1) {
          width *= scale;
          height *= scale;
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          blob => {
            URL.revokeObjectURL(imageSrc);
            this.ngZone.run(() => {
              subscriber.next(new File([blob], image.name, { type: blob.type, lastModified: Date.now() }));
              subscriber.complete();
            });
          },
          'image/jpeg',
          0.9
        );
      };
      img.onerror = err => {
        URL.revokeObjectURL(imageSrc);
        this.ngZone.run(() => subscriber.error(err));
      };

      img.src = imageSrc;
    });
  }

  setNewUrl(url: string) {
    this._urlChangeInfo = [this._urlChangeInfo[1] !== url ? this._urlChangeInfo[1] || null : this._urlChangeInfo[0] || null, url];
    this.storageService.setJSON(AppStorageKeys.UrlChangeInfo, this._urlChangeInfo, true);
  }

  isNotInDocumentPage() {
    return !location.pathname.match('^/assinar/|(^/documentos/[A-Za-z0-9]{30})') && !location.host.match('^valida.ae');
  }

  isAutentiqueDomain() {
    return !location.host || new RegExp(environment.validHostsRegExp, 'i').test(location.host);
  }

  mockMessageValidationError(message: string): GraphQLResponse {
    return { data: null, status: 422, errors: [{ message }] };
  }

  mockFormValidationError(validation: { [attribute: string]: string[] }): GraphQLResponse {
    return { data: null, status: 422, errors: [{ message: 'validation', validation }] };
  }

  watchCacheAndNetworkBase<T>(observable: Observable<ApolloQueryResult<T>>, fetchPolicy: WatchQueryFetchPolicy) {
    this.loaderService.disableHttpLoader = fetchPolicy === 'cache-and-network';
    return observable.pipe(
      throwOnGraphqlError(),
      finalize(() => (this.loaderService.disableHttpLoader = fetchPolicy === 'cache-and-network' ? false : this.loaderService.disableHttpLoader)),
      take(fetchPolicy === 'cache-and-network' ? 2 : 1),
      filter(response => !!response.data)
    );
  }

  isHexaColor(color: string) {
    return color && (color.length === 7 || /^#[0-9a-fA-F]{6}$/.test(color));
  }

  getContrastColor(color: string = '#0461ff') {
    if (!this.isHexaColor(color)) {
      return '#000000';
    } else {
      const [, red, green, blue] = /#?(\w{2})(\w{2})(\w{2})/.exec(color);
      const brightness = (parseInt(red, 16) * 299 + parseInt(green, 16) * 587 + parseInt(blue, 16) * 114) / 1000;
      return brightness < 128 ? '#ffffff' : '#000000';
    }
  }

  private parseLockedSigners(json: any[][]) {
    // json: [ [signerMainAttribute, action, value, cpf, {show,sms,cpf,document}, {phoneNumber,documentType}] ]
    return json
      .map(item => {
        if (Array.isArray(item)) {
          return {
            [item[0]]: item[2],
            action: item[1],
            configs: { cpf: item[3] },
            _extra: {
              isLocked: true,
              signerMainAttribute: item[0],
              showOptions: item[4]?.show || false,
              showSmsVerification: item[4]?.sms || false,
              showCpfVerification: item[4]?.cpf || false,
              showSecurityVerifications: item[4]?.document || false,
              ...(item[5]?.phoneNumber ? { phoneNumber: item[5]?.phoneNumber } : {}),
              ...(item[5]?.documentType ? { documentType: item[5]?.documentType } : {})
            },
            security_verifications: []
          } as CustomSignerInput;
        }
      })
      .filter(item => !!item);
  }
}
