<div class="container" [ngbTooltip]="tooltipSignatureVisible" container="body">
  <i *ngIf="!disableIcon" class="fa-regular fa-shield-check"></i>
  <div *ngIf="user" class="credits">
    <div class="credits__monthly">
      {{ user.subscription.credits > 999 ? '99+' : user.subscription.credits }}
    </div>
  </div>
</div>

<ng-template #tooltipSignatureVisible>
  <div class="tooltip__descr">{{ 'shared.availableCreditsForAdditionalVerification' | translate: { credits: user.subscription.credits } }}</div>
</ng-template>
