import { AfterContentInit, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { concat, from, interval, of, Subscription, throwError, timer } from 'rxjs';
import { filter, finalize, map, switchMap, tap, toArray } from 'rxjs/operators';
import * as Bowser from 'bowser';

import { environment } from '@env/environment';
import { Logger } from '@app/core';
import { SecurityVerificationEnum, SecurityVerificationFileTypeEnum, SecurityVerificationPayloadType, SecurityVerificationType } from '@app/models';
import { AppService, DocumentService, ErrorHandlerService, NotyService } from '@app/services';
import { LoaderService } from '@app/shared/loader/loader.service';

export type ModalResult = SecurityVerificationType;
export interface ModalPublicProperties {
  verification: SecurityVerificationType;
}

const Steps = {
  Intro: 'Intro',
  HasBackPrompt: 'HasBackPrompt',
  MobilePrompt: 'MobilePrompt',
  CameraError: 'CameraError',
  VerificationError: 'VerificationError',
  ...SecurityVerificationFileTypeEnum
};
const log = new Logger('VerificationModalComponent');

@Component({
  selector: 'app-verification-modal',
  templateUrl: './verification-modal.component.html',
  styleUrls: ['./verification-modal.component.scss']
})
export class VerificationModalComponent implements ModalPublicProperties, AfterContentInit, OnDestroy {
  @Input() verification: SecurityVerificationType;
  @ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;
  videoStream: MediaStream | null = null;
  step: string;
  picturesUrls: { [key: string]: string } = {};
  picturesImages: { [key: string]: File } = {};
  isLoading = false;
  firstLoad = true;
  currentUrl: string;
  referenceCode: SecurityVerificationPayloadType;
  currentFacingMode = 'environment';

  readonly Steps = Steps;
  readonly SecurityVerificationEnum = SecurityVerificationEnum;
  private referenceSubscription: Subscription;
  private previousSteps: string[] = [];

  constructor(
    public modal: NgbActiveModal,
    public translateService: TranslateService,
    private ngZone: NgZone,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private loaderService: LoaderService,
    private documentService: DocumentService
  ) {}

  get currentStep() {
    return this.step;
  }
  set currentStep(step: string) {
    this.previousSteps.unshift(this.step || Steps.Intro);
    this.step = step || Steps.Intro;
  }

  ngAfterContentInit() {
    this.resetPictures();
    this.currentStep = null;
  }

  ngOnDestroy() {
    this.stopCamera();
    if (this.referenceSubscription) {
      this.referenceSubscription.unsubscribe();
    }
  }

  setInitialStep() {
    this.currentStep = this.verification.type === SecurityVerificationEnum.PfFacial ? Steps.Selfie : Steps.Front;
    this.currentFacingMode = this.isMobile() && this.currentStep === Steps.Selfie ? 'user' : 'environment';
  }

  goBack() {
    if (this.currentStep !== Steps.Intro) {
      this.currentStep = this.previousSteps[0] || Steps.Intro;
      this.previousSteps.splice(0, 2);
    } else {
      this.modal.dismiss();
    }
  }

  stopCamera() {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
      this.videoStream = null;
    }
  }

  loadCamera(facingMode?: string) {
    this.stopCamera();
    if (!facingMode) this.setInitialStep();
    this.isLoading = true;
    this.loaderService.show();

    const canvasElement = this.canvas.nativeElement;
    const ctx = canvasElement.getContext('2d');
    const mode = facingMode || this.currentFacingMode;

    const constraints: MediaStreamConstraints = {
      video: {
        width: { ideal: 1920 },
        height: { ideal: 1080 },
        facingMode: mode
      }
    };

    timer(500)
      .pipe(
        switchMap(() => (this.canvas && this.canvas.nativeElement ? of(null) : throwError(''))),
        switchMap(() => (navigator.mediaDevices && navigator.mediaDevices.getUserMedia ? from(navigator.mediaDevices.getUserMedia(constraints)) : throwError(''))),
        tap((stream: MediaStream) => {
          this.videoStream = stream;
          const video = document.createElement('video');

          video.srcObject = stream;
          video.play();

          video.addEventListener('loadedmetadata', () => {
            canvasElement.width = video.videoWidth;
            canvasElement.height = video.videoHeight;
          });

          const renderFrame = () => {
            if (video.readyState === video.HAVE_ENOUGH_DATA) {
              ctx.save();

              if (this.isMobile() && mode === 'user') {
                ctx.scale(-1, 1);
                ctx.translate(-canvasElement.width, 0);
              }

              ctx.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
              ctx.restore();
            }
            requestAnimationFrame(renderFrame);
          };

          renderFrame();
        }),
        finalize(() => {
          this.firstLoad = false;
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        () => {},
        error => {
          this.videoStream = null;
          this.currentStep = Steps.CameraError;
          log.error(error);
        }
      );
  }

  takePicture() {
    if (this.isLoading) return;
    this.isLoading = true;
    const currentStep = this.currentStep;
    const canvasElement = document.createElement('canvas');
    const pixelRatio = 3;
    canvasElement.setAttribute('width', (this.canvas.nativeElement.offsetWidth * pixelRatio).toString());
    canvasElement.setAttribute('height', (this.canvas.nativeElement.offsetHeight * pixelRatio).toString());
    canvasElement.style.display = 'none';

    const ctx = canvasElement.getContext('2d', { alpha: false });
    ctx.scale(pixelRatio, pixelRatio);
    ctx.drawImage(this.canvas.nativeElement, 0, 0, this.canvas.nativeElement.offsetWidth, this.canvas.nativeElement.offsetHeight);
    canvasElement.toBlob(blob => {
      this.ngZone.run(() => {
        if (this.picturesUrls[currentStep]) {
          URL.revokeObjectURL(this.picturesUrls[currentStep]);
        }
        this.picturesUrls[currentStep] = blob ? URL.createObjectURL(blob) : '';
        this.picturesImages[currentStep] = new File([blob], currentStep + '.png', { type: 'image/png', lastModified: new Date().getTime() });
        this.isLoading = false;
        if (currentStep === Steps.Selfie) {
          this.verify(this.picturesImages);
        } else if (currentStep === Steps.Back) {
          this.currentStep = Steps.Selfie;
          if (this.currentFacingMode === 'environment') this.toggleCamera();
        } else {
          this.currentStep = Steps.HasBackPrompt;
        }
      });
    }, 'image/jpeg');
  }

  useFrontAsBackPicture() {
    if (this.picturesUrls[Steps.Back]) {
      URL.revokeObjectURL(this.picturesUrls[Steps.Back]);
    }
    delete this.picturesUrls[Steps.Back];
    delete this.picturesImages[Steps.Back];
    this.toggleCamera();
    this.currentStep = Steps.Selfie;
  }

  verify(images: { [key: string]: File }) {
    this.isLoading = true;
    this.loaderService.show();
    of(Object.keys(images).map(key => this.documentService.updateSecurityVerification({ id: this.verification.id, fileType: key as SecurityVerificationFileTypeEnum, file: images[key] })))
      .pipe(
        switchMap(requests => concat(...requests).pipe(toArray())),
        map(verifications => verifications[verifications.length - 1]),
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        data => {
          this.verification = data;
          if (data?.verified_at || data?.type === SecurityVerificationEnum.Manual) {
            this.modal.close(data as ModalResult);
          } else {
            this.notyService.error((data.logs_attempt && data.logs_attempt[0][0]?.message) || this.translateService.instant('error_pf_facial_mismatch'));
            this.currentStep = Steps.VerificationError;
            this.resetPictures();
            if (data?.max_attempt === 0) {
              this.modal.close(data as ModalResult);
            }
          }
        },
        error => {
          this.currentStep = Steps.VerificationError;
          this.stopCamera();
          this.resetPictures();
          this.errorHandlerService.handle(error);
        }
      );
  }

  selectDesktopMobilePrompt() {
    this.currentStep = Steps.MobilePrompt;
    this.isLoading = true;
    this.generateSecurityVerificationCode()
      .pipe(
        switchMap(() => (this.currentUrl ? of(null) : this.documentService.signatureBySecurityVerificationCode({ code: this.referenceCode.reference }))),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        signatureId => {
          if (!this.currentUrl) {
            this.currentUrl = [environment.panelUrl, 'assinar', signatureId].join('/') + '?signPrompt=1';
            this.referenceSubscription = interval(5000)
              .pipe(
                filter(() => this.referenceCode && new Date(this.referenceCode.expiry) < new Date()),
                switchMap(() => this.generateSecurityVerificationCode())
              )
              .subscribe();
          }
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  toggleCamera() {
    const currentFacingMode = this.currentFacingMode === 'user' ? 'environment' : 'user';
    this.currentFacingMode = currentFacingMode;
    this.loadCamera(currentFacingMode);
  }

  isMobile() {
    return Bowser.getParser(window.navigator.userAgent).getPlatformType() === 'mobile';
  }

  private generateSecurityVerificationCode() {
    this.isLoading = true;
    return this.documentService.generateSecurityVerificationCode({ id: this.verification.id }).pipe(tap(data => (this.referenceCode = data)));
  }

  private resetPictures() {
    this.currentStep = null;
    Object.values(this.picturesUrls || {}).forEach(pic => pic && URL.revokeObjectURL(pic));
    this.picturesUrls = {};
    this.picturesImages = {};
  }
}
