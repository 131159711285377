import { Pipe, PipeTransform } from '@angular/core';

import { User } from '@app/models';
import { UserService } from '@app/services';
import { TranslateService } from '@ngx-translate/core';

/**
 * Replaces custom Autentique's email template variables to their corresponding values.
 */
@Pipe({ name: 'parseEmailVariables' })
export class ParseEmailVariablesPipe implements PipeTransform {
  constructor(private userService: UserService, private translateService: TranslateService) {}

  transform(text: string, user: User): string {
    const company = this.userService.getCompany(user);
    const variables = {
      nome: user ? user.name || '' : '',
      empresa: user ? company || '' : '',
      funcao: this.translateService?.instant('documentHistory.emailAction'),
      documento: this.translateService?.instant('documentHistory.emailDocumentName'),
      identificador: '5d63dda84be74ec30dcbe7beafeca38d3ed311fd4d2551ea2'
    };

    let newText = text || '';
    for (const key in variables) {
      newText = newText.replace(new RegExp('{{(\\s*)?' + key + '(\\s*)?}}', 'mig'), variables[key]);
    }
    return newText;
  }
}
