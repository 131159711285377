import { Injectable } from '@angular/core';
import { WatchQueryFetchPolicy } from 'apollo-client';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { throwOnGraphqlError } from '@app/core';
import { AppService } from '@app/services/app.service';
import {
  FolderGQL,
  FolderQueryVariables,
  CreateFolderGQL,
  CreateFolderMutationVariables,
  UpdateFolderGQL,
  UpdateFolderMutationVariables,
  DeleteFolderGQL,
  DeleteFolderMutationVariables,
  MoveDocumentToFolderGQL,
  MoveDocumentToFolderMutationVariables,
  MoveFolderGQL,
  MoveFolderMutationVariables,
  FoldersGQL,
  FoldersQueryVariables
} from 'src/generated/graphql.default';

@Injectable({ providedIn: 'root' })
export class FolderService {
  constructor(
    private appService: AppService,
    private foldersGQL: FoldersGQL,
    private folderGQL: FolderGQL,
    private createFolderGQL: CreateFolderGQL,
    private updateFolderGQL: UpdateFolderGQL,
    private deleteFolderGQL: DeleteFolderGQL,
    private moveDocumentToFolderGQL: MoveDocumentToFolderGQL,
    private moveFolderGQL: MoveFolderGQL
  ) {}

  folders(variables: FoldersQueryVariables, fetchPolicy: WatchQueryFetchPolicy = 'no-cache') {
    return this.appService.watchCacheAndNetworkBase(this.foldersGQL.watch(variables, { fetchPolicy }).valueChanges, fetchPolicy).pipe(
      throwOnGraphqlError(),
      map(response => response.data.folders)
    );
  }

  folder(variables: FolderQueryVariables) {
    return this.folderGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.folder)
    );
  }

  create(variables: CreateFolderMutationVariables) {
    return this.createFolderGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.createFolder)
    );
  }

  update(variables: UpdateFolderMutationVariables) {
    return this.updateFolderGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateFolder)
    );
  }

  delete(variables: DeleteFolderMutationVariables) {
    return this.deleteFolderGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.deleteFolder)
    );
  }

  moveDocumentsToFolder(documentIds: string[], variables: Omit<MoveDocumentToFolderMutationVariables, 'documentId'>) {
    return forkJoin(
      documentIds.map(documentId => {
        return this.moveDocumentToFolderGQL.mutate({ ...variables, documentId }).pipe(
          throwOnGraphqlError(),
          map(response => response.data.moveDocumentToFolder)
        );
      })
    );
  }

  removeDocumentsFromFolder(documentIds: string[], variables: Omit<MoveDocumentToFolderMutationVariables, 'documentId' | 'folderId'>) {
    return forkJoin(
      documentIds.map(documentId => {
        return this.moveDocumentToFolderGQL.mutate({ ...variables, documentId, folderId: null }).pipe(
          throwOnGraphqlError(),
          map(response => response.data.moveDocumentToFolder)
        );
      })
    );
  }

  moveFolder(folderIds: string[], variables: Omit<MoveFolderMutationVariables, 'id'>) {
    return forkJoin(
      folderIds.map(folderId => {
        return this.moveFolderGQL.mutate({ ...variables, id: folderId }).pipe(
          throwOnGraphqlError(),
          map(response => response.data.moveFolder)
        );
      })
    );
  }
}
