import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize } from 'rxjs/operators';
import { ContextEnum, Folder, ListFormat, SlimDocument, User } from '@app/models';
import { untilDestroyed } from '@app/core';
import { merge, omit } from 'lodash';
import { slideDownUpTrigger } from '@app/core/animations';

import { AppService, DocumentService, ErrorHandlerService, FolderService, NotyService, UserService, WhitelabelService } from '@app/services';
import { LoaderService } from '@app/shared';
import { DocumentsListingService } from '../shared/documents-listing.service';
import { FoldersListingService } from '../shared/folders-listing.service';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss'],
  animations: [slideDownUpTrigger]
})
export class DocumentsListComponent implements OnInit, OnDestroy {
  ListFormat = ListFormat;
  documents: SlimDocument[];
  documentsWithoutOrg: SlimDocument[];
  folders: Folder[];
  currentFolder: Folder;
  currentUser: User;
  isLoading = false;
  isLastPage = false;
  isLastPageFolders = false;
  pageNumber: number;
  pageNumberFolders: number;
  searchQuery: any;
  mobileSearch = '';
  isOnFolder: boolean;
  isSearchOpen = false;
  openTaxonomyMenu = false;
  readonly perPage = 60;

  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    public userService: UserService,
    public documentsListingService: DocumentsListingService,
    public foldersListingService: FoldersListingService,
    public whitelabelService: WhitelabelService,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private documentService: DocumentService,
    private folderService: FolderService,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isOnFolder = !!this.route.snapshot.params.folderId;
    if (this.isOnFolder) {
      this.folderService.folder({ id: this.route.snapshot.params.folderId }).subscribe(
        data => (this.currentFolder = data),
        error => this.errorHandlerService.handle(error)
      );
      this.documents = [].constructor(7);
    } else {
      this.documents = [].constructor(this.appService.getFirstPageCount('mine') || 7);
      this.documentsWithoutOrg = [].constructor(this.appService.getFirstPageCount('noOrg') || 0);
    }

    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        if (!(this.documents || [])[0]) {
          this.isSearchOpen = this.documentsListingService.isSearchParams(this.route.snapshot.queryParams);
          this.searchQuery = this.documentsListingService.searchQueryFromQueryParams(this.route.snapshot.queryParams);
        }

        if (this.currentUser && (this.currentUser.organization.id !== user.organization.id || this.currentUser.member.group.id !== user.member.group.id)) {
          this.currentUser = user; // required to have user before load
          this.loadDocuments(undefined, { showLoader: true });
          this.loadFolders();
        } else if (!this.currentUser) {
          this.currentUser = user; // required to have user before load
          this.loadDocuments({ search: this.searchQuery });
          this.loadFolders({ search: this.searchQuery });
        }
      });

    this.isLoading = true;
  }

  ngOnDestroy() {}

  loadDocuments(options: { page?: number; search?: any } = {}, extraOptions: { showLoader?: boolean } = {}) {
    this.isLoading = true;

    this.pageNumber = options.page || 1;
    const showLoader = extraOptions?.showLoader;
    if (showLoader) {
      this.loaderService.show();
    }
    this.documentsListingService.pushSearchQueryToUrl(options.search);

    this.documentService[this.isOnFolder ? 'folderDocuments' : 'unarchivedDocuments'](this.documentsSearchParams(options.search), this.pageNumber === 1 ? 'cache-and-network' : 'no-cache')
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        page => {
          if (!!this.route.snapshot.params.folderId || (this.isSearchOpen && this.searchQuery)) {
            this.documents = page ? (this.pageNumber === 1 ? [] : this.documents || []).concat(page.data) : null;
          } else {
            this.documents = page ? (this.pageNumber === 1 ? [] : this.documents || []).concat(this.filterWithOrg(page.data)) : null;
            this.documentsWithoutOrg = page ? (this.pageNumber === 1 ? [] : this.documentsWithoutOrg || []).concat(this.filterWithoutOrg(page.data)) : null;
            if (this.pageNumber === 1) {
              this.appService.addFirstPageCounts({ mine: this.documents.length, noOrg: this.documentsWithoutOrg.length });
            }
          }
          this.isLastPage = !page || (page.data || []).length < this.perPage;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  sign(documents: SlimDocument[]) {
    this.documentsListingService.sign(documents).subscribe(
      () => {
        if (documents.filter(doc => (doc as any)._isSelected).length > 0) {
          this.notyService.info(this.translateService.instant('couldnt_sign_documents_selected'));
        } else if (!this.route.snapshot.params.folderId) {
          const allDocuments = (this.documents || []).concat(this.documentsWithoutOrg || []);
          this.documents = this.filterWithOrg(allDocuments);
          this.documentsWithoutOrg = this.filterWithoutOrg(allDocuments);
        }
      },
      error => this.errorHandlerService.handle(error)
    );
  }

  delete(targetDocuments: SlimDocument[], documents: SlimDocument[]) {
    this.documentsListingService.delete(documents, targetDocuments, { context: ContextEnum.User }).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  transfer(documents: SlimDocument[], isRestoration?: boolean) {
    this.documentsListingService.transfer(this.documents, documents, { context: ContextEnum.User, isRestoration }).subscribe(
      () => this.loadDocuments({ page: 1, search: this.searchQuery }, { showLoader: true }),
      error => this.errorHandlerService.handle(error)
    );
  }

  loadFolders(options: { page?: number; search?: any } = {}) {
    if (this.isOnFolder) {
      this.isLoading = true;
      this.pageNumberFolders = options.page || 1;
      this.folderService
        .folders(
          {
            context: ContextEnum.User,
            parentId: this.route.snapshot.params.folderId,
            search: (options.search || {}).name,
            limit: 14,
            page: this.pageNumberFolders,
            orderBy: { field: this.appService.listSorting, direction: this.appService.listDirection }
          },
          this.pageNumberFolders === 1 ? 'cache-and-network' : 'no-cache'
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          page => {
            this.folders = (this.pageNumberFolders === 1 ? [] : this.folders || []).concat(page.data);
            this.isLastPageFolders = page.current_page >= page.last_page;
          },
          error => this.errorHandlerService.handle(error)
        );
    }
  }

  openFoldersModal(options: { documents?: SlimDocument[]; folder?: Folder }) {
    if (options.documents) {
      this.documentsListingService
        .openFoldersModal(this.documents, options.documents, {
          context: ContextEnum.User,
          currentFolderId: this.currentFolder && this.currentFolder.id
        })
        .subscribe();
    } else if (options.folder) {
      this.foldersListingService.openFoldersModal(this.folders, options.folder).subscribe();
    }
  }

  openCreateFolderModal() {
    this.foldersListingService.openCreateFolderModal(ContextEnum.User, { parentId: this.currentFolder?.id }).subscribe(
      folder => this.folders.unshift(folder),
      error => this.errorHandlerService.handle(error)
    );
  }

  openUpdateFolderModal(folder: Folder) {
    this.foldersListingService.openUpdateFolderModal(folder).subscribe(newFolder => merge(folder, newFolder));
  }

  deleteFolder(folder: Folder) {
    this.foldersListingService.delete(this.folders, folder).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  resendSignatures(documents: SlimDocument[]) {
    this.documentsListingService.resendSignatures(documents).subscribe(
      () => this.notyService.success(this.translateService.instant('notyService.documentsResentToSignatories')),
      error => this.errorHandlerService.handle(error)
    );
  }

  toggleBlock(documents: SlimDocument[]) {
    this.documentsListingService.toggleBlock(documents).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  resendWebhook(document: SlimDocument) {
    this.documentService.resendWebhook({ documentId: document.id }).subscribe(
      () => this.notyService.success(this.translateService.instant('notyService.documentResendWebhook')),
      error => this.errorHandlerService.handle(error)
    );
  }

  clearFilters() {
    this.appService.documentStatusFilter = null;
    this.mobileSearch = '';
    this.searchQuery = null;
    this.isSearchOpen = false;
    this.loadDocuments({ page: 1 });
    this.loadFolders({ page: 1 });
  }

  toggleSearch(value: boolean) {
    if (this.documents) {
      this.isSearchOpen = value;
      if (this.isSearchOpen) {
        if (!this.isOnFolder) {
          if (this.documents.length === 0) this.loadDocuments({ search: ' ', page: 1 });
          this.documents.unshift(...(this.documentsWithoutOrg || []));
          this.documentsWithoutOrg = [];
        }
        this.searchQuery = { ...(this.searchQuery || {}), includeArchived: this.isOnFolder };
      } else {
        if (!this.isOnFolder) {
          this.loadDocuments({ page: 1 });
          this.documentsWithoutOrg = this.filterWithoutOrg(this.documents || []);
          this.documents = this.filterWithOrg(this.documents || []);
        }
        this.clearFilters();
      }
    }
  }

  exportDocuments(searchQuery: any) {
    if (!this.currentUser.email) {
      this.notyService.info(this.translateService.instant('cant_export_documents_selected_when_email_isnt_registered'));
      return;
    }

    this.isLoading = true;
    this.documentsListingService
      .exportDocuments(omit(this.documentsSearchParams(searchQuery), ['page', 'limit', 'sandbox']))
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {},
        error => this.errorHandlerService.handle(error)
      );
  }

  isShowingDocumentsWithoutOrg() {
    return !this.isOnFolder && (this.documentsWithoutOrg || []).length > 0;
  }

  routeToNewDocument() {
    this.router.navigate(['/documentos/novo'], { replaceUrl: true, state: { folder: this.currentFolder } });
  }

  protected filterWithOrg(documents: SlimDocument[]) {
    return documents.filter(doc => {
      const signer = (doc.signatures || []).find(sig => sig.user && this.currentUser && sig.user.id === this.currentUser.id);
      return signer && !!signer.organization_id;
    });
  }

  protected filterWithoutOrg(documents: SlimDocument[]) {
    return documents.filter(doc => {
      const signer = (doc.signatures || []).find(sig => sig.user && this.currentUser && sig.user.id === this.currentUser.id);
      return !signer || !signer.organization_id;
    });
  }

  private documentsSearchParams(searchQuery: any) {
    return {
      folderId: this.route.snapshot.params.folderId,
      status: this.appService.documentStatusFilter,
      limit: this.perPage,
      page: this.pageNumber,
      context: (searchQuery || {}).groupUuid ? ContextEnum.Group : ContextEnum.User,
      sandbox: this.appService.showSandbox,
      orderBy: { field: this.appService.listSorting, direction: this.appService.listDirection },
      ...(Object.keys(searchQuery || {}).length > 0 ? { status: undefined, ...searchQuery } : {})
    };
  }
}
