import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { from, Observable, Subscription } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import ngLocalePt from '@angular/common/locales/pt';
import ngLocalePtExtra from '@angular/common/locales/extra/pt';

import { StorageService } from './storage.service';
import { Logger } from './logger.service';
import * as enUS from '../../translations/en-US.json';
import * as ptBR from '../../translations/pt-BR.json';
import * as moment from 'moment-timezone';
import { Country, DateFormatEnum, LanguageEnum, Timezone } from '@app/models';
import { SelectOption } from '@app/shared';
import { lowerCase } from 'lodash';
import { map, toArray } from 'rxjs/operators';

const countryList = require('country-list');
const log = new Logger('I18nService');
const languageKey = 'language';

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
export function extract(s: string) {
  return s;
}

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  defaultLanguage!: string;
  supportedLanguages!: string[];

  private langChangeSubscription!: Subscription;

  constructor(private translateService: TranslateService, private storageService: StorageService) {
    registerLocaleData(ngLocalePt, 'pt', ngLocalePtExtra);

    // Embed languages to avoid extra HTTP requests
    translateService.setTranslation('en-US', enUS.default || enUS);
    translateService.setTranslation('pt-BR', ptBR.default || ptBR);
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   * @param defaultLanguage The default language to use.
   * @param supportedLanguages The list of supported languages.
   */
  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.language = '';

    // Warning: this subscription will always be alive for the app's lifetime
    this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.storageService.set(languageKey, event.lang);
    });
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   * @param language The IETF language code to set.
   */
  set language(language: string) {
    language = language || this.languageStorage() || this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = this.supportedLanguages.includes(language);

    // If no exact match is found, search without the region
    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = this.supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    // Fallback if language is not supported
    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    log.debug(`Language set to ${language}`);
    this.translateService.use(language);
  }

  changeLanguage(language: string) {
    this.language = language;
  }

  /**
   * Gets the current language.
   * @return The current language code.
   */
  get language(): string {
    return this.translateService.currentLang;
  }

  languageStorage(): string {
    return this.storageService.get(languageKey);
  }

  get supportedLanguagesAsSelectOptions(): SelectOption[] {
    return this.supportedLanguages.map(language => {
      return {
        key: language,
        value: this.translateService.instant(`language.${language.toLowerCase()}`),
        height: 46,
        fontSize: 16
      };
    });
  }

  get dateFormatOptions(): SelectOption[] {
    return Object.keys(DateFormatEnum).map(key => {
      const format = DateFormatEnum[key as keyof typeof DateFormatEnum];
      return {
        key: format,
        value: this.transformDateFormat(format),
        height: 46,
        fontSize: 16
      };
    });
  }

  transformDateFormat(format: string): string {
    if (!format) return;

    return format
      .replace(/DD/, 'dd')
      .replace(/MM/, 'MM')
      .replace(/YYYY/, 'yyyy')
      .replace(/_/g, '/');
  }

  get timezonesWithGMT(): SelectOption[] {
    const timezones = moment.tz.names();
    return timezones.map(tz => {
      const gmt = moment.tz(tz).format('Z'); // Gets the GMT of the timezone
      return {
        key: tz,
        value: `${tz} (GMT ${gmt})`,
        height: 46,
        fontSize: 16
      };
    });
  }

  getTimezoneOffset(location: string) {
    if (!location) return;
    const currentTime = moment.tz(location).format('Z');
    return `UTC${currentTime}`;
  }

  get timezone(): string {
    return moment.tz.guess(false);
  }

  get countriesWithCodes(): SelectOption[] {
    const countryNames = countryList.getNames();
    const countryList$ = countryNames.map((name: string) => {
      const code = countryList.getCode(name);
      return {
        key: code,
        value: this.translateService.instant(`countryCodes.${lowerCase(code)}`),
        height: 46,
        fontSize: 16,
        icon: `fi fi-${lowerCase(code)}`
      };
    });

    const prioritizedCountries = ['BR', 'US', 'PT'];
    const prioritized = countryList$.filter((country: SelectOption) => prioritizedCountries.includes(country.key));
    const others = countryList$.filter((country: SelectOption) => !prioritizedCountries.includes(country.key));

    return [...prioritized, ...others];
  }
}
