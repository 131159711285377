<div class="configuration-container mt-4 forceDefaultColors" *ngIf="invoices?.length > 0">
  <div class="invoices">
    <h3 class="mb-4">{{ 'settings.invoicesAndReceipts' | translate }}</h3>
    <ng-container *ngFor="let invoice of invoices">
      <div class="invoices__list" *ngIf="invoice.status != 'creating' && invoice.price > 0">
        <div
          class="invoices__list-item"
          [ngClass]="{
            status__paid: ['paid', 'authorized'].includes(invoice.status),
            status__canceled: ['canceled', 'disabled'].includes(invoice.status) || !invoice.status,
            status__warning: ['pending', 'captured', 'warning', 'refunded'].includes(invoice.status) || !invoice.status,
            status__error: ['expired', 'error'].includes(invoice.status)
          }"
        >
          <div class="product">
            <div class="product__name">{{ invoice.description }}</div>
            <div class="product__date">{{ invoice.due_date | ldate: 'mediumDate' }}</div>
          </div>
          <div class="status">
            <span class="status__label">{{ this.translateService.instant('status_' + invoice.status) }}</span>
          </div>
          <div class="value">{{ invoice.price / 100 | lcurrency: 'R$' }}</div>
          <div
            class="autentique-button"
            *ngIf="invoice.url_payment && !['canceled', 'disabled', 'expired', 'error', 'paid'].includes(invoice.status) && !invoice.url_nfse"
            (click)="openUrl(invoice.url_payment)"
          >
            {{ 'settings.accessInvoice' | translate }}
          </div>
          <div style="min-width: 115px" *ngIf="['canceled', 'disabled', 'expired', 'error'].includes(invoice.status) || (invoice.status === 'paid' && !invoice.url_nfse)"></div>
          <div ngbDropdown *ngIf="invoice.url_nfse">
            <label ngbDropdownToggle class="autentique-button autentique-button--edit">{{ 'settings.receipt' | translate }}</label>
            <div ngbDropdownMenu>
              <button class="downloadOption dropdown-item" (click)="openUrl(nfseUrl(invoice))" ngbDropdownItem>{{ 'settings.download' | translate }} PDF</button>
              <button class="downloadOption dropdown-item" (click)="openUrl(nfseUrl(invoice, 'xml'))" ngbDropdownItem>{{ 'settings.download' | translate }} XML</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="more" *ngIf="!isLoading && !invoiceIsLastPage">
      <button type="button" class="autentique-button autentique-button--edit" (click)="loadInvoices({ page: invoicePageNumber + 1 })">{{ 'settings.loadOlderInvoices' | translate }}</button>
    </div>
  </div>
</div>
