<div class="container forceDefaultColors" *ngIf="!whitelabelService.isEnterprise">
  <img class="autentique-logo" src="assets/logo-and-text-white.png" alt="" />
  <a routerLink="/cadastro" queryParamsHandling="preserve" class="create-link" *ngIf="backToRegister">{{ 'auth.dontHaveAcc' | translate }}</a>
  <a routerLink="/entrar" queryParamsHandling="preserve" class="create-link" *ngIf="!backToRegister">{{ 'auth.alreadyHaveAcc' | translate }}</a>

  <div class="create-terms">
    <a href="https://www.autentique.com.br/termos-de-uso/" class="create-terms-item">{{ 'external.useTerm' | translate }}</a>
    <a href="https://www.autentique.com.br/politica-de-privacidade/" class="create-terms-item">{{ 'external.privacyPolicy' | translate }}</a>
  </div>
</div>
