<div class="menu-header" *ngIf="whitelabelService.params.mobileNavigation">
  <a (click)="backClicked()" class="menu-header__button">{{ backButtonText }}</a>
  <a class="menu-header__account" routerLink="/menu/alterar/organizacao">
    <div class="menu-header__account-user">
      <div class="menu-header__account-user-name">{{ currentUser?.name }}</div>
      <div class="menu-header__account-user-org" *ngIf="currentUser">
        <div class="plan">{{ userService.getSubscriptionName(currentUser) }}</div>
        <app-documents-count *ngIf="currentUser" [user]="currentUser" class="mr-1 ml-1"></app-documents-count>
        <app-verifications-credits-count *ngIf="currentUser" [user]="currentUser"></app-verifications-credits-count>
      </div>
    </div>
    <div id="menu-header__account-avatar" class="menu-header__account-avatar">
      <app-autenticats [seed]="currentUser?.email || currentUser?.name"></app-autenticats>
    </div>
  </a>
</div>
