import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { filter, finalize, switchMap, takeWhile, tap } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { OrganizationGroup, OrganizationMember, OrganizationMemberInvitation, User } from '@app/models';
import { ErrorHandlerService, NotyService, OrganizationService, UserService } from '@app/services';
import { InviteOrganizationMembersModalService } from './invite-organization-members-modal/invite-organization-members-modal.service';
import { EditOrganizationMemberModalService, FormType } from './edit-organization-member-modal/edit-organization-member-modal.service';
import { AlertModalService } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';

type MembersListParameters = {
  page?: number;
  search?: string;
  group?: OrganizationGroup;
};

@Component({
  selector: 'app-configurations-organization-members',
  templateUrl: './configurations-organization-members.component.html',
  styleUrls: ['../configurations.scss', './configurations-organization-members.component.scss']
})
export class ConfigurationsOrganizationMembersComponent implements OnInit, OnDestroy {
  currentUser: User;
  isLoading = false;
  isShowingInvitations = false;
  isLastPage = false;
  isInvitationsLastPage = false;
  pageNumber: number;
  invitationsPageNumber: number;
  members: OrganizationMember[] = [];
  invitations: OrganizationMemberInvitation[] = [];
  groups: OrganizationGroup[] = [];
  invitationsResent: number[] = [];
  selectedGroup: OrganizationGroup;
  searchQuery = '';
  readonly perPage = 20;

  constructor(
    public userService: UserService,
    public organizationService: OrganizationService,
    public translateService: TranslateService,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private alertModalService: AlertModalService,
    private inviteOrganizationMembersModalService: InviteOrganizationMembersModalService,
    private editOrganizationMemberModalService: EditOrganizationMemberModalService
  ) {}

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;

        this.organizationService.organizationGroups({ page: 1, limit: 60, organizationId: this.currentUser.organization.id }).subscribe(
          page => (this.groups = page.data),
          error => this.errorHandlerService.handle(error)
        );
      });

    this.loadMembers();
    this.loadMembersInvitations();
  }

  ngOnDestroy() {}

  loadMembers(options: MembersListParameters = {}) {
    this.isLoading = true;
    this.pageNumber = options.page || 1;
    this.organizationService
      .organizationMembers({
        limit: this.perPage,
        page: this.pageNumber,
        search: (options.search || '').trim() || undefined,
        groupId: options.group ? options.group.id : undefined
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        page => {
          this.members = (this.pageNumber === 1 ? [] : this.members || []).concat(page.data);
          this.isLastPage = !page || (page.data || []).length < this.perPage;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  loadMembersInvitations(options: MembersListParameters = {}) {
    this.isLoading = true;
    this.invitationsPageNumber = options.page || 1;
    this.organizationService
      .organizationMembersInvitations({
        limit: this.perPage,
        page: this.invitationsPageNumber,
        search: (options.search || '').trim() || undefined,
        groupId: options.group ? options.group.id : undefined
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        page => {
          this.invitations = (this.invitationsPageNumber === 1 ? [] : this.invitations || []).concat(page.data);
          this.isInvitationsLastPage = !page || (page.data || []).length < this.perPage;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  loadData(options: MembersListParameters = {}) {
    return this.isShowingInvitations ? this.loadMembersInvitations(options) : this.loadMembers(options);
  }

  hasFullAccess(member: OrganizationMember) {
    const entries = Object.entries(member.permissions);
    return entries.length === entries.filter(([, value]) => value).length;
  }

  openInviteMembersModal() {
    this.inviteOrganizationMembersModalService.open({ organization: this.currentUser.organization }).subscribe(result => {
      const newMembers = result.filter(item => !!item);
      if (newMembers.length > 0) {
        this.loadMembersInvitations();
        this.isShowingInvitations = true;
      }

      if (newMembers.length > 1) {
        this.notyService.success(this.translateService.instant('notyService.invitationsSent'), 10000);
      } else if (newMembers.length === 1) {
        this.notyService.success(this.translateService.instant('notyService.invitationSent'), 10000);
      } else {
        this.notyService.error(this.translateService.instant('notyService.invitationExistingUser'));
      }
    });
  }

  openEditPermissionsModal(member: OrganizationMember) {
    this.editOrganizationMemberModalService.open({ type: FormType.Permissions, member }).subscribe(data => (this.members[this.members.findIndex(item => item.user.id === data.user.id)] = data));
  }

  openEditGroupModal(member: OrganizationMember) {
    this.editOrganizationMemberModalService.open({ type: FormType.Group, member }).subscribe(data => (this.members[this.members.findIndex(item => item.user.id === data.user.id)] = data));
  }

  deleteMember(member: OrganizationMember) {
    const isSelf = member.user && this.currentUser.id === member.user.id;
    this.alertModalService
      .warning({
        text: this.translateService.instant(isSelf ? 'alerts.leaveTheOrganization' : 'alerts.unlinkUser'),
        confirmButtonText: this.translateService.instant(isSelf ? 'button.leaveTheOrganization' : 'button.remove')
      })
      .pipe(
        tap(() => (this.isLoading = true)),
        switchMap(() => this.organizationService.canMemberBeRemovedFromGroup$(member)),
        tap(canBeRemoved => !canBeRemoved && this.notyService.error(this.translateService.instant('notyService.cannotRemoveLastAdmin'))),
        takeWhile(canBeRemoved => canBeRemoved),
        switchMap(() => this.organizationService.deleteMember({ id: member.user.id }))
      )
      .subscribe(
        () => {
          if (isSelf) {
            forkJoin([this.userService.getCurrentUser({ fetchPolicy: 'network-only' }), this.organizationService.getOrganizations({ fetchPolicy: 'network-only' })])
              .pipe(finalize(() => (this.isLoading = false)))
              .subscribe(() => this.router.navigate(['/documentos/todos']));
          } else {
            this.isLoading = false;
            this.loadMembers();
          }
        },
        error => {
          this.isLoading = false;
          this.errorHandlerService.handle(error);
        }
      );
  }

  deleteMemberInvitation(invitation: OrganizationMemberInvitation) {
    this.isLoading = true;
    this.organizationService.deleteMemberInvitation({ id: invitation.id }).subscribe(
      () => this.loadMembersInvitations(),
      error => {
        this.isLoading = false;
        this.errorHandlerService.handle(error);
      }
    );
  }

  resendInvitation(invitation: OrganizationMemberInvitation) {
    this.isLoading = true;
    this.organizationService
      .resendMemberInvitation({ id: invitation.id })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {
          this.invitationsResent.push(invitation.id);
          this.notyService.success(this.translateService.instant('notyService.invitationResent'), 10000);
        },
        error => this.errorHandlerService.handle(error)
      );
  }
}
