<div class="taxonomy-options" *ngIf="!isOnFoldersListing">
  <button class="btn-M btn-filled mb-3" routerLink="/documentos/novo" [disabled]="disableMainButton">
    <i class="far fa-file-plus"></i> <span>{{ 'menu.createDocument' | translate }}</span>
  </button>
  <a routerLink="/documentos/todos" class="taxonomy-option" routerLinkActive="current" [routerLinkActiveOptions]="{ exact: true }">
    <i class="fa-regular fa-file-alt"></i> {{ 'menu.myDocuments' | translate }}</a
  >
  <a routerLink="/documentos/grupo" class="taxonomy-option" routerLinkActive="current" [routerLinkActiveOptions]="{ exact: true }" *ngIf="currentUser?.currentPermissions.view_documents_gr">
    <i class="fa-regular fa-users-class"></i> {{ 'menu.wholeGroup' | translate }}
  </a>
  <a routerLink="/documentos/organizacao" class="taxonomy-option" routerLinkActive="current" [routerLinkActiveOptions]="{ exact: true }" *ngIf="currentUser?.currentPermissions.view_documents_oz">
    <i class="fa-regular fa-users"></i> {{ 'menu.wholeOrganization' | translate }}
  </a>
</div>
<div *ngIf="isOnFoldersListing" class="taxonomy-options">
  <button type="button" class="taxonomy-button" [disabled]="disableMainButton" (click)="newFolderButtonClick.emit()">{{ 'menu.newFolder' | translate }}</button>
  <a routerLink="/pastas" class="taxonomy-option" routerLinkActive="current" [ngClass]="{ current: listPath === 'default' }" [routerLinkActiveOptions]="{ exact: true }"
    ><i class="fa-regular fa-folder-open"></i> {{ 'menu.myFolders' | translate }}</a
  >
  <a
    routerLink="/pastas/grupo"
    class="taxonomy-option"
    [ngClass]="{ current: listPath === 'group' }"
    routerLinkActive="current"
    [routerLinkActiveOptions]="{ exact: true }"
    *ngIf="currentUser?.currentPermissions.view_folders_gr"
  >
    <i class="fa-regular fa-users-class"></i> {{ 'menu.groupFolders' | translate }}
  </a>
  <a
    routerLink="/pastas/organizacao"
    [ngClass]="{ current: listPath === 'organization' }"
    class="taxonomy-option"
    routerLinkActive="current"
    [routerLinkActiveOptions]="{ exact: true }"
    *ngIf="currentUser?.currentPermissions.view_folders_oz"
  >
    <i class="fa-regular fa-users"></i> {{ 'menu.organizationFolders' | translate }}
  </a>
</div>

<!-- Ordenação de pastas não está implementada na API -->
<!-- <p class="taxonomy-subtitle" *ngIf="!isOnFoldersListing">Exibição em</p>
<div class="taxonomy-custom sort" *ngIf="!isOnFoldersListing">
  <div ngbDropdown class="taxonomy-custom filter">
    <div class="t-select" id="order-filter" ngbDropdownToggle>{{ appService.listFormat == 'box'? 'Caixas' : 'Lista' }} <i class="fal fa-chevron-down"></i></div>
    <div ngbDropdownMenu aria-labelledby="order-filter">
      <label ngbDropdownItem class="type">
        <input type="radio" name="sort-type" [(ngModel)]="appService.listFormat" [value]="ListFormat.List" />
        Lista
      </label>
      <label ngbDropdownItem class="type">
        <input type="radio" name="sort-type" [(ngModel)]="appService.listFormat" [value]="ListFormat.Box" />
        Caixas
      </label>
    </div>
  </div>
</div> -->

<div class="taxonomy-options especial">
  <div class="taxonomy-subtitle especial">
    {{ 'menu.order' | translate }}
    <div class="taxonomy-custom sort">
      <div class="pipe"></div>
      <label class="type" title="{{ 'menu.ascending' | translate }}" [class.d-none]="appService.listDirection !== OrderByEnum.Asc">
        <span class="sort-span">{{ 'menu.crescent' | translate }}</span>
        <input type="radio" name="sort-type" class="checked" [(ngModel)]="appService.listDirection" [value]="OrderByEnum.Desc" (ngModelChange)="changeDirection($event)" />
        <i class="fa-regular fa-sort-up"></i>
      </label>
      <label class="type" title="{{ 'menu.descending' | translate }}" [class.d-none]="appService.listDirection !== OrderByEnum.Desc">
        <span class="sort-span">{{ 'menu.decrement' | translate }}</span>
        <input type="radio" name="sort-type" class="checked" [(ngModel)]="appService.listDirection" [value]="OrderByEnum.Asc" (ngModelChange)="changeDirection($event)" />
        <i class="fa-regular fa-sort-down"></i>
      </label>
    </div>
    <div class="taxonomy-custom sort mobile__d-none">
      <div class="pipe"></div>
      <label class="type">
        <input type="radio" name="sort-type" [class.checked]="appService.listFormat === ListFormat.List" (click)="changeFormatList(ListFormat.List)" />
        <i class="fa-regular fa-list-ul"></i>
      </label>
      <label class="type">
        <input type="radio" name="sort-type" [class.checked]="appService.listFormat === ListFormat.Box" (click)="changeFormatList(ListFormat.Box)" />
        <i class="fa-regular fa-grip-horizontal plus"></i>
      </label>
    </div>
  </div>

  <div class="taxonomy-custom sort especial">
    <div ngbDropdown class="taxonomy-custom filter">
      <div class="t-select" id="order-filter" ngbDropdownToggle>{{ listSortings[appService.listSorting] }} <i class="fal fa-chevron-down"></i></div>
      <div ngbDropdownMenu aria-labelledby="order-filter">
        <button ngbDropdownItem *ngFor="let sorting of listSortings | keyvalue" (click)="changeSorting(sorting.key)">{{ sorting.value }}</button>
      </div>
    </div>
  </div>

  <div class="taxonomy-subtitle taxonomy-subtitle--vertical" *ngIf="!isOnTrash && !isSearchOpen">
    {{ 'menu.filterBy' | translate }}
    <div class="taxonomy-custom sort">
      <div ngbDropdown class="taxonomy-custom filter">
        <div class="t-select" id="status-filter" ngbDropdownToggle>
          {{ (appService.documentStatusFilter ? 'documents.statusFilter.' + (appService.documentStatusFilter | lowercase) : 'menu.noFilter') | translate }}
          <i class="fal fa-chevron-down"></i>
        </div>
        <div ngbDropdownMenu aria-labelledby="order-filter">
          <button ngbDropdownItem (click)="changeFilter(null)">{{ 'menu.noFilter' | translate }}</button>
          <button ngbDropdownItem (click)="changeFilter(documentStatusFilters[1])">{{ 'documents.statusFilter.signed' | translate }}</button>
          <button ngbDropdownItem (click)="changeFilter(documentStatusFilters[0])">{{ 'documents.statusFilter.pending' | translate }}</button>
          <button ngbDropdownItem (click)="changeFilter(documentStatusFilters[2])">
            {{ 'documents.statusFilter.not_signed' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="">
  <p class="taxonomy-subtitle" *ngIf="currentUser && (isOnFoldersListing ? canShowFolders() : canShowDocuments())">{{ 'menu.organization' | translate }}</p>
  <div class="taxonomy-options" *ngIf="currentUser && (isOnFoldersListing ? canShowFolders() : canShowDocuments())">
    <app-search [(query)]="search" theme="clean" (queryChange)="searchRecords(search)" placeholder="{{ 'menu.searchByName' | translate }}"></app-search>
    <a
      class="taxonomy-option light"
      *ngFor="let item of organizationThings"
      [class.d-none]="!(isOnFoldersListing ? canShowFolders(item) : canShowDocuments(item))"
      [class.loading]="isLoading"
      [routerLink]="recordPath(item)"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="current"
      [ngClass]="{ current: isOnFoldersListing && ['member', 'group'].includes(listPath) && [route.snapshot.params.memberId, route.snapshot.params.groupId].includes(item.id) }"
    >
      <i class="fal fa-{{ item.type === 'member' ? 'user' : 'users-class' }}"></i> {{ item.name | truncate: 25 }}
    </a>
  </div>
</div>
