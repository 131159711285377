<input class="d-none" /><!--  Macete para nao dar foco nos botoes -->
<div class="modal-body {{ type }}">
  <p class="centermodal__title">{{ title }}</p>
  <img [src]="'assets/alerts/' + type + '.svg'" class="centermodal__img" *ngIf="type" />
  <div class="centermodal__message-body centermodal__message-body--margin-normal">
    <p class="centermodal__message" [innerHTML]="trustHtml(text)"></p>
    <div class="centermodal__message centermodal__message--credits" *ngIf="creditsPrice > 0">
      <p *ngIf="creditsPrice > 0" [innerHTML]="'shared.' + (creditsPrice === 1 ? 'actionWillConsumeOneCredit' : 'actionWillConsumeMultipleCredits') | translate: { credits: creditsPrice }"></p>
    </div>
    <div class="centermodal__message centermodal__message--order" *ngIf="orderItems.length > 0">
      <div class="centermodal__message centermodal__message--order-item" *ngFor="let orderItem of orderItems">
        {{ orderItem.title }}
        <span class="float-right pl-2">{{ orderItem.price }}</span>
      </div>
    </div>
    <div class="modal-alert mb-2" *ngFor="let infoText of bottomInfoTexts">
      <div class="modal-alert__icon"><i class="fal fa-exclamation-triangle"></i></div>
      <div class="modal-alert__content">
        <div class="modal-alert__description" [innerHTML]="trustHtml(infoText)"></div>
      </div>
    </div>
  </div>
  <div class="centermodal__button-group">
    <button type="button" class="btn-L btn-filled-cancel" (click)="modal.dismiss(AlertButtonType.close)">{{ closeButtonText }}</button>
    <button type="button" class="btn-L btn-filled" (click)="modal.close(AlertButtonType.confirm)">
      <span class="credits-badge" *ngIf="creditsPrice > 0"><i class="fal fa-shield-check"></i> -{{ creditsPrice }}</span>
      {{ confirmButtonText }}
    </button>
  </div>
</div>
