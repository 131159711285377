import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '@app/services';
import { LoaderService } from '@app/shared';

@Component({
  selector: 'app-stylesheet',
  templateUrl: './stylesheet.component.html',
  styleUrls: ['./stylesheet.component.scss']
})
export class StylesheetComponent implements OnInit {
  isLoading = false;

  constructor(private userService: UserService, private route: Router, private loaderService: LoaderService) {}

  ngOnInit() {
    this.isLoading = true;
    this.loaderService.show();
    this.userService.watchCurrentUser().subscribe(user => {
      if (!user || !user?.email.match(/@autentique.com.br$/)) {
        this.route.navigate(['/entrar'], { replaceUrl: true });
      }
      this.isLoading = false;
      this.loaderService.hide();
    });
  }
}
