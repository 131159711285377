<div class="forceDefaultColors page">
  <div class="header">
    <div class="header-logo">
      <img [src]="selectedPdfLogoImageUrl || pdfLogo || whitelabelService.logo" alt="" />
      <div class="header-text">
        <p>{{ 'whitelabel.electronicAuthenticationPage' | translate }} <span>3/3</span></p>
        <p>{{ 'whitelabel.dateTimeIn' | translate }} <span>GMT -03:00 Brasília</span></p>
        <p>{{ 'whitelabel.documentIdentifierCode' | translate }} <span>8d7b093870f4c956f36cc1b4b0b6740ed6d4ee2</span></p>
      </div>
    </div>
  </div>
  <div class="middle">
    <div class="middle-title">
      <p>{{ 'whitelabel.auditPage' | translate }}</p>
    </div>
    <div class="middle-content">
      <div class="middle-content-qrcode">
        <qrcode [cssClass]="'qrCode__image'" [width]="140" [qrdata]="'https://painel.autentique.com.br/documentos/a2b0a1ae008f6d2b625f2d3a8a9663bebc85b8b788c702c68'" [elementType]="'svg'"></qrcode>
      </div>
      <div class="middle-content-section">
        <div class="middle-content-section-text">
          <p>
            {{ 'whitelabel.originalSHA256Hash' | translate }}
            <span>43e75fe578e33baa1afbed0ada9916cfce66a38866b49609b742c8364fa5a69c</span>
          </p>
          <p>
            {{ 'whitelabel.validationLink' | translate }}
            <span>https://valida.ae/8d7b093870f4c956f36cc1b4b0b6740ed6d4ee25bdf0f7c8e</span>
          </p>
          <p>
            {{ 'whitelabel.lastUpdatedOn' | translate }} <span>{{ 'whitelabel.dateTimeExample' | translate }}</span>
          </p>
          <p>{{ 'whitelabel.completedSignatures' | translate }} <span>4/6</span></p>
          <p>{{ 'whitelabel.signedWithElectronicSignature' | translate }}</p>
        </div>
        <div class="middle-content-section-warning">
          <i class="fal fa-info-circle"></i>
          <p>{{ 'whitelabel.scanQRCodeOrAccessLink' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="signatures">
    <div class="signatures-title">
      <p>{{ 'whitelabel.signaturesInDocument' | translate }}</p>
    </div>
    <div class="signatures-section">
      <div class="signatures-section-signer">
        <app-blured-signer [selectedPdfLogoMiniImageUrl]="selectedPdfLogoMiniImageUrl || pdfLogoMini || whitelabelService.pdfLogoMini"></app-blured-signer>
      </div>
      <div class="signatures-section-signer">
        <app-blured-signer [selectedPdfLogoMiniImageUrl]="selectedPdfLogoMiniImageUrl || pdfLogoMini || whitelabelService.pdfLogoMini"></app-blured-signer>
      </div>
    </div>
  </div>
  <div class="historico">
    <div class="historico-title">
      <p>{{ 'whitelabel.history' | translate }}</p>
    </div>
    <div class="historico-events">
      <app-blured-event></app-blured-event>
      <app-blured-event></app-blured-event>
      <app-blured-event></app-blured-event>
      <app-blured-event></app-blured-event>
      <app-blured-event></app-blured-event>
      <app-blured-event></app-blured-event>
    </div>
  </div>
</div>
