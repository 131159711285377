<div
  class="field__holder field__holder-small m-0"
  [class.error]="hasPhoneError && ['whatsapp', 'sms'].includes(signer._extra.signerMainAttribute) && signer[signer._extra.signerMainAttribute]"
  #signerTooltip="ngbTooltip"
  triggers="hover"
  [autoClose]="true"
  [ngbTooltip]="isNewDocument ? tipSigner : null"
  container="body"
  placement="right"
>
  <ng-container *ngIf="['email', 'name'].includes(signer._extra.signerMainAttribute)">
    <input
      type="text"
      [name]="inputId"
      autocomplete="none"
      role="presentation"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      class="field none field__signer"
      [class.empty]="!signer[signer._extra.signerMainAttribute]"
      [(ngModel)]="signer[signer._extra.signerMainAttribute]"
      (ngModelChange)="modelChange(signer)"
      [appValidName]="signer._extra.signerMainAttribute === 'name'"
      [appValidEmail]="signer._extra.signerMainAttribute === 'email'"
      #inputModel="ngModel"
      #inputElement
      (focus)="signerTooltip.toggle()"
      [id]="inputId"
    />
  </ng-container>
  <ng-container *ngIf="['whatsapp', 'sms'].includes(signer._extra.signerMainAttribute)">
    <app-phone-number
      [id]="inputId"
      (focus)="signerTooltip.toggle()"
      inputClass="field field__signer"
      [(ngModel)]="signer[signer._extra.signerMainAttribute]"
      (ngModelChange)="modelChange(signer)"
      (validationError)="hasPhoneError = $event"
      name="phone"
      autocomplete="tel-national"
      #phoneInputModel="ngModel"
    ></app-phone-number>
    <small [hidden]="!hasPhoneError || !signer[signer._extra.signerMainAttribute]" class="text-danger">{{ 'error_invalid_phone_number' | translate }}</small>
  </ng-container>
  <div
    class="credits__content credits__content--inner-field credits__content--inner-field-sms"
    *ngIf="['sms'].includes(signer._extra.signerMainAttribute) && appService.creditPriceByType(CreditTypeEnum.DeliveryMethodSms) > 0"
  >
    <i class="fal fa-shield-check"></i>{{ appService.creditPriceByType(CreditTypeEnum.DeliveryMethodSms) }}
  </div>
  <div
    class="credits__content credits__content--inner-field credits__content--inner-field-whatsapp"
    *ngIf="['whatsapp'].includes(signer._extra.signerMainAttribute) && appService.creditPriceByType(CreditTypeEnum.DeliveryMethodWhatsapp) > 0"
  >
    <i class="fal fa-shield-check"></i>{{ appService.creditPriceByType(CreditTypeEnum.DeliveryMethodWhatsapp) }}
  </div>
  <label class="field__placeholder" [class.desculpa]="['whatsapp', 'sms'].includes(signer._extra.signerMainAttribute)" [for]="inputId">
    {{ 'documents.' + (signer._extra.signerMainAttribute === 'name' ? 'signatoryName' : signer._extra.signerMainAttribute === 'email' ? 'signatoryEmail' : 'signatoryPhone') | translate }}
  </label>

  <div class="signer-field-option" ngbDropdown placement="bottom-right">
    <span class="signer-field-option-title with-caret" ngbDropdownToggle [ngbTooltip]="tipSignerType" container="body" placement="right">{{
      'signer_attr_abbr_' + signer._extra.signerMainAttribute | lowercase | translate
    }}</span>
    <div ngbDropdownMenu>
      <ng-container *ngFor="let attr of signerMainAttributes">
        <button
          *ngIf="!['phone'].includes(attr) && (attr !== 'sms' || isCountryBR)"
          type="button"
          ngbDropdownItem
          class="d-flex"
          (click)="changeSignerMainAttribute(signer, attr); changeSigner.emit(signer)"
        >
          <div class="signer-field-option-fal">
            <i *ngIf="attr === 'email'" class="fal fa-envelope"></i>
            <i *ngIf="attr === 'name'" class="fal fa-link"></i>
            <i *ngIf="attr === 'sms'" class="fal fa-comment-alt"></i>
            <i *ngIf="attr === 'whatsapp'" class="fab fa-whatsapp" style="font-size: 16px"></i>
          </div>

          {{ 'signature.signerAttr.' + (attr | lowercase) | translate }}

          <div *ngIf="attr === 'sms' && appService.creditPriceByType(CreditTypeEnum.DeliveryMethodSms) > 0" class="credits__content" [ngbTooltip]="infoCreditsTooltip" container="body">
            <i class="fal fa-shield-check"></i>{{ appService.creditPriceByType(CreditTypeEnum.DeliveryMethodSms) }}
          </div>
          <div *ngIf="attr === 'whatsapp' && appService.creditPriceByType(CreditTypeEnum.DeliveryMethodWhatsapp) > 0" class="credits__content" [ngbTooltip]="infoCreditsTooltip" container="body">
            <i class="fal fa-shield-check"></i>{{ appService.creditPriceByType(CreditTypeEnum.DeliveryMethodWhatsapp) }}
          </div>
        </button>
      </ng-container>
    </div>
  </div>
</div>

<div class="signer-options">
  <div class="signer-options-role" ngbDropdown>
    <span class="signer-options-role-text">{{ 'documents.role' | translate }}</span>
    <span class="signer-options-role-current with-caret" ngbDropdownToggle>{{ 'sign_action_' + signer.action | lowercase | translate }}</span>
    <div ngbDropdownMenu>
      <button type="button" *ngFor="let role of documentService.roles" (click)="signer.action = role; changeSigner.emit(signer)" ngbDropdownItem>
        {{ 'signature.signAction.' + (role | lowercase) | translate }}
      </button>
    </div>
  </div>
  <ng-content></ng-content>
  <div class="signer-options-advanced">
    <div class="signer-options-advanced-validation" (click)="signer._extra.showOptions = !signer._extra.showOptions" *ngIf="!qualified">
      <span *ngIf="!signer._extra.showOptions">{{ 'documents.showMoreOptions' | translate }} <i class="fal fa-plus"></i></span>
      <span *ngIf="signer._extra.showOptions">{{ 'documents.hideOptions' | translate }} <i class="fal fa-minus"></i></span>
    </div>
    <div
      class="signer-options-advanced-option"
      container="body"
      placement="right"
      [class.active]="signer._extra.isLocked"
      [ngbTooltip]="tipSignerMemo"
      (click)="signer._extra.isLocked = !signer._extra.isLocked; changeSigner.emit(signer)"
      *ngIf="isNewDocument"
    >
      <i class="fal fa-lock-alt"></i>
    </div>
  </div>
  <div class="validation" *ngIf="currentUser && signer._extra.showOptions && !qualified">
    <div class="validation__list">
      <div class="validation__list-option" *ngIf="['email', 'name'].includes(signer._extra.signerMainAttribute) && isCountryBR">
        <div class="validation__checkbox" (pointerdown)="checkPremiumFeatures($event)">
          <input
            type="checkbox"
            class="checkbox__field"
            [disabled]="!currentUser.subscription?.has_premium_features"
            (ngModelChange)="changeSigner.emit(signer)"
            [(ngModel)]="signer._extra.showSmsVerification"
            [id]="'sms-' + inputId"
          />
          <label class="checkbox__label" [for]="'sms-' + inputId"></label>
          <label class="checkbox__label-text pro" [for]="'sms-' + inputId" [ngbTooltip]="verificationSmsTooltip" [attr.data-pro-text]="translateService.instant('shared.pro')" container="body">
            {{ 'documents.requireSMSValidation' | translate }}
          </label>
        </div>
        <div class="field__holder field__holder-small" [class.error]="signer._extra.phoneNumber && hasVerificationPhoneError" *ngIf="signer._extra.showSmsVerification">
          <app-phone-number
            inputClass="field"
            name="phoneNumber"
            [(ngModel)]="signer._extra.phoneNumber"
            (ngModelChange)="changeSigner.emit(signer)"
            (validationError)="hasVerificationPhoneError = $event"
            autocomplete="tel-national"
          ></app-phone-number>
          <small [hidden]="!signer._extra.phoneNumber || !hasVerificationPhoneError" class="text-danger">{{ 'error_invalid_phone_number' | translate }}</small>
          <label class="field__placeholder focus">{{ 'documents.signatoryPhoneNumber' | translate }}</label>
          <div class="credits__content input" [ngbTooltip]="infoCreditsTooltip" container="body" *ngIf="appService.creditPriceByType(CreditTypeEnum.SecurityVerificationSms) > 0">
            <i class="fal fa-shield-check"></i> {{ appService.creditPriceByType(CreditTypeEnum.SecurityVerificationSms) }}
          </div>
        </div>
      </div>
      <div class="validation__list-option">
        <div class="validation__checkbox">
          <input type="checkbox" class="checkbox__field" (ngModelChange)="changeSigner.emit(signer)" [(ngModel)]="signer._extra.showSecurityVerifications" [id]="'sv-' + inputId" />
          <label class="checkbox__label" [for]="'sv-' + inputId"></label>
          <label class="checkbox__label-text" [for]="'sv-' + inputId" [ngbTooltip]="verificationTypesTooltip" container="body">
            {{ 'documents.requirePhotoIDDocument' | translate }}
          </label>
        </div>
        <div class="field__holder field__holder-small" *ngIf="signer._extra.showSecurityVerifications">
          <app-select
            [(ngModel)]="signer._extra.documentType"
            placeholder="{{ 'appSelect.validationType' | translate }}"
            (ngModelChange)="changeSigner.emit(signer)"
            [disableSearch]="true"
            [options]="[
              {
                key: SecurityVerificationEnum.Manual,
                value: 'appSelect.manualApproval' | translate,
                tooltip: verificationManualTooltip,
                height: 44,
                fontSize: 16,
                credits: appService.creditPriceByType(CreditTypeEnum.SecurityVerificationManual)
              },
              {
                key: SecurityVerificationEnum.Upload,
                value: 'appSelect.photoIDDocumentValidation' | translate,
                tooltip: verificationUploadTooltip,
                height: 44,
                fontSize: 16,
                credits: appService.creditPriceByType(CreditTypeEnum.SecurityVerificationUpload)
              },
              {
                key: SecurityVerificationEnum.Live,
                value: 'appSelect.IDDocumentSelfieProofValidation' | translate,
                tooltip: verificationLiveTooltip,
                height: 44,
                fontSize: 16,
                credits: appService.creditPriceByType(CreditTypeEnum.SecurityVerificationLive)
              },
              {
                key: SecurityVerificationEnum.PfFacial,
                value: 'appSelect.SERPROBiometricsValidation' | translate,
                tooltip: verificationPfFacialTooltip,
                height: 44,
                fontSize: 16,
                credits: appService.creditPriceByType(CreditTypeEnum.SecurityVerificationPfFacial),
                hidden: !isCountryBR
              }
            ]"
          >
          </app-select>
        </div>
      </div>
      <div class="validation__list-option" *ngIf="isCountryBR">
        <div class="validation__checkbox" (pointerdown)="checkPremiumFeatures($event)" [ngbTooltip]="tipSignerCpf" container="body">
          <input
            type="checkbox"
            class="checkbox__field"
            [disabled]="!currentUser.subscription?.has_premium_features"
            (ngModelChange)="changeSigner.emit(signer)"
            [(ngModel)]="signer._extra.showCpfVerification"
            [id]="'cpf-' + inputId"
          />
          <label class="checkbox__label" [for]="'cpf-' + inputId"></label>
          <label class="checkbox__label-text pro" [for]="'cpf-' + inputId" [attr.data-pro-text]="translateService.instant('shared.pro')">{{ 'documents.setSignatoryCPF' | translate }}</label>
        </div>
        <div class="field__holder field__holder-small" *ngIf="signer._extra.showCpfVerification">
          <input
            class="field"
            [ngClass]="{ empty: !signer.configs.cpf }"
            type="tel"
            name="cpf"
            [(ngModel)]="signer.configs.cpf"
            [class.has-error]="validationErrors?.configs?.cpf"
            [appValidCpf]="!validationErrors?.configs?.cpf"
            mask="000.000.000-00"
            [dropSpecialCharacters]="false"
            [specialCharacters]="['.', '-']"
            (ngModelChange)="changeSigner.emit(signer)"
            autocomplete="none"
            role="presentation"
            tabindex="-1"
          />
          <label class="field__placeholder">{{ 'profile.cpf' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="suggestion" [class.suggestion--open]="contacts.length > 0" #suggestions>
  <div class="suggestion__gap"></div>
  <div class="suggestion__item-list">
    <a href="javascript:void(0)" class="suggestion__item" *ngFor="let contact of contacts" (click)="selectContact(contact)">
      <div class="suggestion__item-name" [innerHTML]="contact.name | highlight: signer[signer._extra.signerMainAttribute]"></div>
      <div class="suggestion__item-email" [innerHTML]="contact.email | highlight: signer[signer._extra.signerMainAttribute]"></div>
    </a>
  </div>
</div>

<ng-template #tipSigner>
  <div class="tooltip__title">{{ 'documents.signatories' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.peopleSigning' | translate }}</div>
</ng-template>
<ng-template #tipSignerType>
  <div class="tooltip__tip">{{ 'documents.deliveryType' | translate }}</div>
</ng-template>
<ng-template #tipSignerMemo>
  <div class="tooltip__tip">{{ 'documents.remember' | translate }}</div>
</ng-template>
<ng-template #tipSignerCpf>
  <div class="tooltip__title">{{ 'documents.setSignatoryCPF' | translate }}</div>
  <div class="tooltip__descr">
    {{ 'documents.signatoryCPFValidation' | translate }}
  </div>
</ng-template>
<ng-template #infoCreditsTooltip>
  <div class="tooltip__title">{{ 'documents.additionalValidationCredit' | translate }}</div>
  <div class="tooltip__descr">
    {{ 'documents.someProceduresCostMore' | translate }}
  </div>
</ng-template>
<ng-template #verificationSmsTooltip>
  <div class="tooltip__title">{{ 'documents.requireSMSValidation' | translate }}</div>
  <div class="tooltip__descr">
    {{ 'documents.validatePhoneNumber' | translate }}
  </div>
</ng-template>
<ng-template #verificationTypesTooltip>
  <div class="tooltip__title">{{ 'documents.requireIDDocument' | translate }}</div>
  <div class="tooltip__descr">
    {{ 'documents.attachIDAndProof' | translate }}
  </div>
</ng-template>
<ng-template #verificationManualTooltip>
  <div class="tooltip__title">{{ 'documents.manualApproval' | translate }}</div>
  <div class="tooltip__descr">
    {{ 'documents.manualApprovalDescr' | translate }}
  </div>
</ng-template>
<ng-template #verificationUploadTooltip>
  <div class="tooltip__title">{{ 'documents.photoIDDocument' | translate }}</div>
  <div class="tooltip__descr">
    {{ 'documents.attachPhotoIDDocument' | translate }}
  </div>
</ng-template>
<ng-template #verificationLiveTooltip>
  <div class="tooltip__title">{{ 'documents.photoIDDocumentSelfieProof' | translate }}</div>
  <div class="tooltip__descr">
    {{ 'documents.attachIDSelfieProof' | translate }}
  </div>
</ng-template>
<ng-template #verificationPfFacialTooltip>
  <div class="tooltip__title">{{ 'documents.SERPROBiometrics' | translate }}</div>
  <div class="tooltip__descr">
    {{ 'documents.takeSelfieSERPRO' | translate }}
  </div>
</ng-template>
