import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize } from 'rxjs/operators';
import { omit } from 'lodash';

import { untilDestroyed } from '@app/core';
import { slideDownUpTrigger } from '@app/core/animations';
import { AppService, DocumentService, ErrorHandlerService, NotyService, OrganizationService, UserService, WhitelabelService } from '@app/services';
import { ContextEnum, ListFormat, OrganizationGroup, SlimDocument, User } from '@app/models';
import { LoaderService } from '@app/shared';
import { DocumentsListingService } from '../shared/documents-listing.service';

@Component({
  selector: 'app-documents-group-list',
  templateUrl: './documents-group-list.component.html',
  styleUrls: ['./documents-group-list.component.scss'],
  animations: [slideDownUpTrigger]
})
export class DocumentsGroupListComponent implements OnInit, OnDestroy {
  ListFormat = ListFormat;
  documents: SlimDocument[];
  currentUser: User;
  currentGroup: OrganizationGroup;
  isLoading = false;
  isLastPage = false;
  pageNumber: number;
  searchQuery: any;
  mobileSearch = '';
  isSearchOpen = false;
  openTaxonomyMenu = false;
  readonly perPage = 60;

  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    public documentsListingService: DocumentsListingService,
    public translateService: TranslateService,
    public whitelabelService: WhitelabelService,
    private userService: UserService,
    private loaderService: LoaderService,
    private documentService: DocumentService,
    private organizationService: OrganizationService,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService
  ) {}

  ngOnInit() {
    this.documents = [].constructor(this.route.snapshot.params.groupId ? 7 : this.appService.getFirstPageCount('group') || 7);

    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        filter(user => !this.currentUser || this.currentUser.organization.id !== user.organization.id || this.currentUser.member.group.id !== user.member.group.id),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;
        const isAllowed = this.route.snapshot.params.groupId ? user.currentPermissions.view_group_documents_oz : user.currentPermissions.view_documents_gr;
        if (isAllowed) {
          if (!(this.documents || [])[0]) {
            this.isSearchOpen = this.documentsListingService.isSearchParams(this.route.snapshot.queryParams);
            this.searchQuery = this.documentsListingService.searchQueryFromQueryParams(this.route.snapshot.queryParams);
          }

          if (this.route.snapshot.params.groupId || this.currentUser.member.group.uuid) {
            this.organizationService.organizationGroup({ uuid: this.route.snapshot.params.groupId || this.currentUser.member.group.uuid }).subscribe(
              data => (this.currentGroup = data),
              error => this.errorHandlerService.handle(error)
            );
          }
          this.loadDocuments({ search: this.searchQuery });
        } else {
          this.userService.permissionCheck(isAllowed);
        }
      });
  }

  ngOnDestroy() {}

  loadDocuments(options: { page?: number; search?: any } = {}, extraOptions: { showLoader?: boolean } = {}) {
    this.isLoading = true;

    this.pageNumber = options.page || 1;
    const showLoader = extraOptions.showLoader;
    if (showLoader) {
      this.loaderService.show();
    }
    this.documentsListingService.pushSearchQueryToUrl(options.search);

    this.documentService
      .organizationGroupDocuments(this.documentsSearchParams(options.search), this.pageNumber === 1 ? 'cache-and-network' : 'no-cache')
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        page => {
          this.documents = page ? (this.pageNumber === 1 ? [] : this.documents || []).concat(page.data) : null;
          this.isLastPage = !page || (page.data || []).length < this.perPage;
          if (this.pageNumber === 1) {
            this.appService.addFirstPageCounts({ group: this.documents.length });
          }
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  sign(documents: SlimDocument[]) {
    const userDocuments = documents.filter(doc => this.documentsListingService.hasCurrentUser(doc));
    if (userDocuments.length === 0) {
      this.notyService.error(this.translateService.instant('notyService.cannotSignUnauthorized'));
    } else {
      this.documentsListingService.sign(userDocuments).subscribe(
        () => {
          if (userDocuments.filter(doc => (doc as any)._isSelected).length > 0) {
            this.notyService.info(this.translateService.instant('couldnt_sign_documents_selected'));
          }
        },
        error => this.errorHandlerService.handle(error)
      );
    }
  }

  delete(documents: SlimDocument[]) {
    this.documentsListingService.delete(this.documents, documents, { context: ContextEnum.Group, groupUuid: this.currentGroup.uuid }).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  transfer(documents: SlimDocument[]) {
    const currentGroupId = (this.currentGroup && this.currentGroup.id) || (this.currentUser && this.currentUser.member.group.id);
    this.documentsListingService.transfer(this.documents, documents, { context: ContextEnum.Group, ...(currentGroupId ? { currentGroupId } : {}) }).subscribe(
      () => this.loadDocuments({ page: 1, search: this.searchQuery }, { showLoader: true }),
      error => this.errorHandlerService.handle(error)
    );
  }

  openFoldersModal(options: { documents?: SlimDocument[] }) {
    this.documentsListingService.openFoldersModal(this.documents, options.documents, { context: ContextEnum.Group }).subscribe();
  }

  resendSignatures(documents: SlimDocument[]) {
    this.documentsListingService.resendSignatures(documents).subscribe(
      () => this.notyService.success(this.translateService.instant('notyService.documentsResentToSignatories')),
      error => this.errorHandlerService.handle(error)
    );
  }

  toggleBlock(documents: SlimDocument[]) {
    this.documentsListingService.toggleBlock(documents).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  resendWebhook(document: SlimDocument) {
    this.documentService.resendWebhook({ documentId: document.id }).subscribe(
      () => this.notyService.success(this.translateService.instant('notyService.documentResendWebhook')),
      error => this.errorHandlerService.handle(error)
    );
  }

  clearFilters() {
    this.appService.documentStatusFilter = null;
    this.mobileSearch = '';
    this.searchQuery = null;
    this.isSearchOpen = false;
    this.loadDocuments({ page: 1 });
  }

  toggleSearch(value: boolean) {
    this.isSearchOpen = value;
    if (!this.isSearchOpen) {
      this.clearFilters();
    }
  }

  exportDocuments(searchQuery: any) {
    if (!this.currentUser.email) {
      this.notyService.info(this.translateService.instant('cant_export_documents_selected_when_email_isnt_registered'));
      return;
    }

    this.isLoading = true;
    this.documentsListingService
      .exportDocuments(omit(this.documentsSearchParams(searchQuery), ['page', 'limit', 'sandbox']))
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {},
        error => this.errorHandlerService.handle(error)
      );
  }

  private documentsSearchParams(searchQuery: any) {
    return {
      ...(this.route.snapshot.params.groupId ? { groupUuid: this.route.snapshot.params.groupId } : {}),
      status: this.appService.documentStatusFilter,
      limit: this.perPage,
      page: this.pageNumber,
      context: ContextEnum.Group,
      sandbox: this.appService.showSandbox,
      orderBy: { field: this.appService.listSorting, direction: this.appService.listDirection },
      ...(Object.keys(searchQuery || {}).length > 0 ? { status: undefined, ...searchQuery } : {})
    };
  }
}
