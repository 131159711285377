<app-mobile-header-secondary title="Aparência"></app-mobile-header-secondary>

<div class="mobile mobile__page-in-construction">
  <div class="mobile__message mobile__message--red">
    <b>{{ 'external.availableForDesktop' | translate }}</b>
  </div>
</div>

<article class="configuration-item d-none d-lg-block">
  <div class="row mb-3">
    <div class="col-8">
      <div class="configuration-title mb-1">{{ 'settings.signatureEmail' | translate }}<span class="pro" [attr.data-pro-text]="translateService.instant('shared.pro')"></span></div>
      <div class="configuration-text mb-1">
        {{ 'settings.signatureEmailByAutentique' | translate }}
      </div>
      <!-- <a href="javascript:void(0)" class="configuration-link"><i class="fal fa-question-circle"></i> Ajuda sobre essa tela</a> -->
    </div>
    <div class="col-4 text-right d-none d-lg-block" *ngIf="allowedToEditEmailAppearence()">
      <button type="button" class="btn btn-link text-danger" *ngIf="isEditingEmail" (click)="isEditingEmail = false">{{ 'button.cancel' | translate }}</button>
      <button type="button" class="configuration-button" (click)="updateSignatureEmail()" [disabled]="emailForm.invalid || isLoading" *ngIf="isEditingEmail">{{ 'button.save' | translate }}</button>
      <button type="button" class="configuration-button" (click)="isEditingEmail = true" [disabled]="!currentUser?.subscription?.has_premium_features" *ngIf="!isEditingEmail">
        {{ 'button.edit' | translate }}
      </button>
    </div>
  </div>
  <div class="configuration-container">
    <div class="configuration-content" *ngIf="currentUser">
      <div class="alert alert-primary" role="alert" *ngIf="!allowedToEditEmailAppearence()">
        {{ 'settings.signatureEmailOverrideByOrgGroup' | translate }}
      </div>
      <form class="main-form" [formGroup]="emailForm" (ngSubmit)="updateSignatureEmail()" *ngIf="allowedToEditEmailAppearence()" novalidate [class.disabled]="!isEditingEmail">
        <!-- Para o submit rodar no Enter -->
        <button type="submit" class="d-none" [disabled]="emailForm.invalid || isLoading"></button>

        <div class="configuration-mail-holder edit" formGroupName="user">
          <div class="form" [class.d-none]="!currentUser || !isEditingEmail">
            <label class="field__holder select" [appFormControlWrap]="emailForm.get('user.template')">
              <select (change)="changeFooter($event.target.value)" class="field" formControlName="template">
                <option [ngValue]="template.key" *ngFor="let template of emailTemplates | keyvalue">{{ template.value.key | translate }}</option>
              </select>
              <p class="field__placeholder">{{ 'settings.emailTemplate' | translate }}</p>
            </label>
            <label class="field__holder textarea" [appFormControlWrap]="emailForm.get('user.title')">
              <textarea class="field" type="text" formControlName="title" autocomplete="none" role="presentation" required></textarea>
              <span class="field__placeholder">{{ 'settings.title' | translate }}</span>
              <ng-container [ngTemplateOutlet]="emailVariablesDropdownTemplate" [ngTemplateOutletContext]="{ input: emailForm.get('user.title') }"></ng-container>
            </label>
            <label class="field__holder textarea" [appFormControlWrap]="emailForm.get('user.text')">
              <textarea class="field" type="text" formControlName="text" autocomplete="none" role="presentation" required></textarea>
              <span class="field__placeholder">{{ 'settings.message' | translate }}</span>
              <ng-container [ngTemplateOutlet]="emailVariablesDropdownTemplate" [ngTemplateOutletContext]="{ input: emailForm.get('user.text') }"></ng-container>
            </label>

            <div class="d-flex mb-3" formArrayName="colors">
              <app-color-input class="mr-3" formControlName="0"></app-color-input>
              <app-color-input formControlName="1" *ngIf="emailForm.get('user.template').value && emailTemplates[emailForm.get('user.template').value].multiColor"></app-color-input>
            </div>

            <label class="btn btn-outline-primary mb-2">
              <input type="file" class="d-none" accept="image/png,image/jpg,image/jpeg" (change)="selectEmailImage($event.target['files'][0]); $any($event.target).value = ''" />
              {{ 'settings.imgHeader' | translate }}
            </label>
            <p [hidden]="!emailForm.get('user.image').hasError('server')" class="field__error-message mb-4" *ngIf="emailForm">{{ emailForm.get('user.image').getError('server') }}</p>
          </div>
          <div class="configuration-mail dick butt" *ngIf="currentUser">
            <div class="configuration-mail-bar">
              <div class="dot"></div>
            </div>
            <div class="configuration-mail-title">
              <input class="configuration-mail-title-field" type="text" formControlName="subject" autocomplete="none" role="presentation" *ngIf="isEditingEmail" />
              <input class="configuration-mail-title-field" type="text" [value]="currentUser.settings.email.subject | parseEmailVariables: currentUser" *ngIf="!isEditingEmail" />
              <ng-container [ngTemplateOutlet]="emailVariablesDropdownTemplate" [ngTemplateOutletContext]="{ input: emailForm.get('user.subject') }" *ngIf="isEditingEmail"></ng-container>
            </div>
            <div class="configuration-mail-signatory">
              <input class="configuration-mail-signatory-field" type="text" formControlName="sender" autocomplete="none" role="presentation" *ngIf="isEditingEmail" />
              <input class="configuration-mail-signatory-field" type="text" [value]="currentUser.settings.email.sender | parseEmailVariables: currentUser" *ngIf="!isEditingEmail" />
            </div>
            <div class="configuration-mail-body">
              <app-signature-email-preview
                [user]="currentUser"
                [emailSettings]="isEditingEmail ? emailForm.get('user').value : currentUser.settings.email"
                [selectedLang]="translateService.currentLang"
              ></app-signature-email-preview>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</article>

<ng-template #emailVariablesDropdownTemplate let-input="input">
  <div class="email-variables-dropdown" placement="bottom-right" ngbDropdown>
    <label ngbDropdownToggle><i class="fal fa-brackets-curly"></i></label>
    <div ngbDropdownMenu>
      <button type="button" *ngFor="let variable of emailVariables" (click)="addEmailVariableTo(variable, input)" ngbDropdownItem>
        {{ 'email_variable_' + variable | translate }}
      </button>
    </div>
  </div>
</ng-template>
