import { Component, Input, OnInit } from '@angular/core';
import { DocumentSignerHistory, SignerHistory, DeliveryMethodEnum, SignatureHistory } from '@app/models';
import { DocumentService } from '@app/services';
import { LdatePipe } from '@app/shared/pipes';
import { TranslateService } from '@ngx-translate/core';

export interface SignerData {
  public_id: string;
  name?: string;
  address?: string;
  cpf?: string;
}
export interface HistoryItem {
  signer: SignerData;
  data: EventData;
  date: string;
  filter: EventFilter;
}
export type EventColor = 'green' | 'blue' | 'red' | 'default' | 'yellow';
export interface EventData {
  color: EventColor;
  title: string;
  icon: string;
  description: string;
}
export interface EventFilter {
  first: boolean;
  type: 'email' | 'signed' | 'rejected' | 'viewed' | 'error' | 'other' | 'signed_unapproved' | 'biometric_approved' | 'biometric_rejected' | 'sms' | 'whatsapp';
}

@Component({
  selector: 'app-document-history-sidebar',
  templateUrl: './document-history-sidebar.component.html',
  styleUrls: ['./document-history-sidebar.component.scss']
})
export class DocumentHistoryComponent implements OnInit {
  @Input() lockUserData = false;
  documentData: {
    id?: string;
    created_at?: string;
    author?: {
      name?: string;
      email?: string;
      cpf?: string;
    };
  } = {
    id: null
  };

  isLoading = false;
  signatures: SignerHistory[];
  documentEvents: any[];
  history: Array<HistoryItem> = [];

  isFilterOpen = false;

  activeFilters = {
    type: {
      signed: true,
      rejected: true,
      viewed: true,
      signed_unapproved: true,
      biometric_approved: true,
      biometric_rejected: true,
      email: true,
      error: true,
      other: true,
      sms: true,
      whatsapp: true
    },
    signer: {
      author: true
    }
  };

  constructor(public translateService: TranslateService, public documentService: DocumentService, private ldatePipe: LdatePipe) {}

  ngOnInit() {
    this.isLoading = true;
  }

  createListProcess() {
    this.getDocumentData();
    this.iterateSignerEvents();
    this.iterateDocumentEvents();
    setTimeout(() => {
      this.sortHistory();
      setTimeout(() => {
        this.pushDate();
        this.isLoading = false;
      }, 50);
    }, 50);
  }

  getDocumentData() {
    this.history.push(this.returnCreateEvent());
  }

  fillFilterActiveWithSigners() {
    this.signatures.map((e: SignerHistory) => {
      this.activeFilters.signer[e.public_id] = true;
    });
  }

  refreshHistory() {
    const id = (this.documentData.id || 'empty').replace(/[^a-z0-9]/gi, '');
    this.documentService.documentHistory({ id }).subscribe(response => {
      this.signatures = response.signatures;
      this.documentEvents = response.signers_history;
      this.documentData.author = response.author;
      if (this.lockUserData) {
        const overwriteAttributes = ['name', 'email', 'phone', 'cpf'];
        this.signatures = response.signatures.map(sig => {
          if (sig.signed?.user) {
            overwriteAttributes.forEach(attr => Object.assign(sig.user, { [attr]: sig.signed.user[attr] }));
          }
          return sig;
        });

        const authorSignedSignature = this.signatures.find(sig => sig.signed?.user && sig.user.id === response.author.id);
        if (authorSignedSignature) {
          overwriteAttributes.forEach(attr => (this.documentData.author[attr] = authorSignedSignature.signed.user[attr]));
        }
      }

      this.isLoading = true;
      this.history = [];

      this.createListProcess();
      this.fillFilterActiveWithSigners();
    });
  }

  resetHistory() {
    this.signatures = [];
    this.history = [];
    this.isFilterOpen = false;
    this.activeFilters = {
      type: {
        signed: true,
        rejected: true,
        viewed: true,
        signed_unapproved: true,
        biometric_approved: true,
        biometric_rejected: true,
        email: true,
        error: true,
        other: true,
        sms: true,
        whatsapp: true
      },
      signer: {
        author: true
      }
    };
  }

  doesItHaveHistoryFilter(filter: string) {
    let hasFilter = false;
    this.history.map((e: HistoryItem) => {
      if (e.filter.type === filter) hasFilter = true;
    });
    return hasFilter;
  }

  iterateDocumentEvents() {
    this.documentEvents.map((e: DocumentSignerHistory) => {
      if (e.category === 'CREATE' && this.returnSignerHistoryMainIdentifier(e)) this.history.push(this.returnDocumentSignerHistoryCategoryCreate(e));
      if (e.category === 'DELETE' && this.returnSignerHistoryMainIdentifier(e)) this.history.push(this.returnDocumentSignerHistoryCategoryDelete(e));
      if (e.category === 'GENERATE_LINK' && this.returnSignerHistoryMainIdentifier(e)) this.history.push(this.returnDocumentSignerHistoryCategoryLink(e));
    });
  }

  iterateSignerEvents() {
    this.signatures.map((e: SignerHistory) => {
      if (e.signed !== null) this.history.push(this.returnSignedEvent(e));
      if (e.viewed !== null) this.history.push(this.returnViewedEvent(e));
      if (e.signed_unapproved !== null) this.history.push(this.returnBiometricUnapprovedEvent(e));
      if (e.rejected !== null) this.history.push(this.returnRefusedEvent(e));
      if (e.biometric_rejected !== null) this.history.push(this.returnBiometricRejectedEvent(e));
      if (e.biometric_approved !== null) this.history.push(this.returnBiometricApprovedEvent(e));
      if (e.signature_history !== null) this.iterateSignerHistoryEvents(e);
    });
  }

  returnSignerHistoryMainIdentifier(signer: DocumentSignerHistory) {
    return signer.user.name || signer.user.email || signer.user.user?.name || signer.user.user?.email || signer.user.user?.phone;
  }

  iterateSignerHistoryEvents(signer: SignerHistory) {
    if (signer.signature_history.length > 0)
      signer.signature_history.map((e: SignatureHistory, i: number) => {
        if (e.delivered !== null && e.email_type === 'SOLICITATION' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnSolicitationDeliveredHistoryEvent(signer, i));
        if (e.refused !== null && e.email_type === 'SOLICITATION' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnSolicitationRefusedHistoryEvent(signer, i));
        if (e.opened !== null && e.email_type === 'SOLICITATION' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnSolicitationOpenedHistoryEvent(signer, i));
        if (e.sent !== null && e.email_type === 'SOLICITATION' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnSolicitationSentHistoryEvent(signer, i));

        if (e.delivered !== null && e.email_type === 'RESEND' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnResendDeliveredHistoryEvent(signer, i));
        if (e.refused !== null && e.email_type === 'RESEND' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnResendRefusedHistoryEvent(signer, i));
        if (e.opened !== null && e.email_type === 'RESEND' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnResendOpenedHistoryEvent(signer, i));
        if (e.sent !== null && e.email_type === 'RESEND' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnResendSentHistoryEvent(signer, i));

        if (e.delivered !== null && e.email_type === 'REMINDER' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnReminderDeliveredHistoryEvent(signer, i));
        if (e.refused !== null && e.email_type === 'REMINDER' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnReminderRefusedHistoryEvent(signer, i));
        if (e.opened !== null && e.email_type === 'REMINDER' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnReminderOpenedHistoryEvent(signer, i));
        if (e.sent !== null && e.email_type === 'REMINDER' && e.type === DeliveryMethodEnum.DeliveryMethodEmail) this.history.push(this.returnReminderSentHistoryEvent(signer, i));

        if (e.delivered !== null && e.type === DeliveryMethodEnum.DeliveryMethodWhatsapp) this.history.push(this.returnWhatsappDeliveredHistoryEvent(signer, i));
        if (e.refused !== null && e.type === DeliveryMethodEnum.DeliveryMethodWhatsapp) this.history.push(this.returnWhatsappRefusedHistoryEvent(signer, i));
        if (e.opened !== null && e.type === DeliveryMethodEnum.DeliveryMethodWhatsapp) this.history.push(this.returnWhatsappOpenedHistoryEvent(signer, i));
        if (e.sent !== null && e.type === DeliveryMethodEnum.DeliveryMethodWhatsapp) this.history.push(this.returnWhatsappSentHistoryEvent(signer, i));

        if (e.delivered !== null && e.type === DeliveryMethodEnum.DeliveryMethodSms) this.history.push(this.returnSmsDeliveredHistoryEvent(signer, i));
        if (e.refused !== null && e.type === DeliveryMethodEnum.DeliveryMethodSms) this.history.push(this.returnSmsRefusedHistoryEvent(signer, i));
        if (e.sent !== null && e.type === DeliveryMethodEnum.DeliveryMethodSms) this.history.push(this.returnSmsSentHistoryEvent(signer, i));
      });
  }

  sortHistory() {
    this.history.sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0));
  }

  refreshList() {
    this.sortHistory();
    this.pushDate();
  }

  pushDate() {
    let flagDate = '';

    this.history.map((e: HistoryItem) => {
      e.filter.first = false;

      if (!this.activeFilters.signer[e.signer.public_id]) return 0;
      if (!this.activeFilters.type[e.filter.type]) return 0;

      if (this.ldatePipe.transform(e.date, 'shortDate') !== flagDate) {
        e.filter.first = true;
        flagDate = this.ldatePipe.transform(e.date, 'shortDate');
      }
    });
  }

  // Retorna objeto de HistoryItem quando o documento é criado
  returnCreateEvent() {
    return {
      signer: {
        public_id: 'author',
        name: this.documentData.author.name || '',
        address: this.documentData.author.email || '',
        cpf: this.documentData.author.cpf || ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-file-plus',
        title: this.translateService.instant('documentHistory.documentCreation'),
        description: this.translateService.instant('documentHistory.documentCreationDesc')
      },
      date: this.documentData.created_at,
      filter: {
        first: false,
        type: 'other'
      }
    } as HistoryItem;
  }

  /**
   * SIGNER EVENTS
   * 1: Signed
   * 2: View
   * 3: Refused
   * 4: BiometricUnapproved
   * 5: BiometricApproved
   * 6: BiometricRejected
   */
  // Retorna objeto de HistoryItem quando o Signatário assinou o documento
  returnSignedEvent(signer: SignerHistory) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.user?.name || '',
        address: signer.user?.email || signer.user?.phone || '',
        cpf: signer.user?.cpf || ''
      },
      data: {
        color: 'green',
        icon: 'fal fa-signature',
        title: this.translateService.instant('documentHistory.documentSigned'),
        description: this.translateService.instant('documentHistory.documentSignedDesc', {
          user: [signer.signed.user?.name, signer.signed.user?.email || signer.signed.user?.phone, signer.signed.user?.cpf].filter(e => !!e).join(', '),
          ip: signer.signed.ip,
          port: signer.signed?.port ? `:${signer.signed.port}` : ''
        })
      },
      date: signer.signed.created_at,
      filter: {
        first: false,
        type: 'signed'
      }
    } as HistoryItem;
  }

  // Retorna objeto de HistoryItem quando o Signatário visualizou o documento
  returnViewedEvent(signer: SignerHistory) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.user?.name || '',
        address: signer.user?.email || signer.user?.phone || '',
        cpf: signer.user?.cpf || ''
      },
      data: {
        color: 'blue',
        icon: 'fal fa-eye',
        title: this.translateService.instant('documentHistory.documentViewed'),
        description: this.translateService.instant('documentHistory.documentViewedDesc', {
          user: [signer.viewed.user?.name, signer.viewed.user?.email || signer.viewed.user?.phone, signer.viewed.user?.cpf].filter(e => !!e).join(', '),
          ip: signer.viewed.ip,
          port: signer.viewed?.port ? `:${signer.viewed.port}` : ''
        })
      },
      date: signer.viewed.created_at,
      filter: {
        first: false,
        type: 'viewed'
      }
    } as HistoryItem;
  }

  // Retorna objeto de HistoryItem quando o Signatário recusou o documento
  returnRefusedEvent(signer: SignerHistory) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.user?.name || '',
        address: signer.user?.email || signer.user?.phone || '',
        cpf: signer.user?.cpf || ''
      },
      data: {
        color: 'red',
        icon: 'fal fa-exclamation-triangle',
        title: this.translateService.instant('documentHistory.documentRejected'),
        description: this.translateService.instant('documentHistory.documentRejectedDesc', {
          user: [signer.rejected.user?.name, signer.rejected.user?.email || signer.rejected.user?.phone, signer.rejected.user?.cpf].filter(e => !!e).join(', '),
          ip: signer.rejected.ip,
          port: signer.rejected?.port ? `:${signer.rejected.port}` : ''
        })
      },
      date: signer.rejected.created_at,
      filter: {
        first: false,
        type: 'rejected'
      }
    } as HistoryItem;
  }

  // Retorna objeto de HistoryItem quando o Signatário entrou em processo de espera na validacao facial
  returnBiometricUnapprovedEvent(signer: SignerHistory) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.user?.name || '',
        address: signer.user?.email || signer.user?.phone || '',
        cpf: signer.user?.cpf || ''
      },
      data: {
        color: 'yellow',
        icon: 'fal fa-signature',
        title: this.translateService.instant('documentHistory.documentSignedAwaitingVerification'),
        description: this.translateService.instant('documentHistory.documentSignedAwaitingVerificationDesc', {
          user: [signer.signed_unapproved.user?.name, signer.signed_unapproved.user?.email || signer.signed_unapproved.user?.phone, signer.signed_unapproved.user?.cpf].filter(e => !!e).join(', '),
          ip: signer.signed_unapproved.ip,
          port: signer.signed_unapproved?.port ? `:${signer.signed_unapproved.port}` : ''
        })
      },
      date: signer.signed_unapproved.created_at,
      filter: {
        first: false,
        type: 'signed_unapproved'
      }
    } as HistoryItem;
  }

  // Retorna objeto de HistoryItem quando o Signatário foi aprovado na validação facial
  returnBiometricApprovedEvent(signer: SignerHistory) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.biometric_approved.user?.name || '',
        address: signer.biometric_approved.user?.email || signer.biometric_approved.user?.phone || '',
        cpf: signer.biometric_approved.user?.cpf || ''
      },
      data: {
        color: 'green',
        icon: 'fal fa-check',
        title: this.translateService.instant('documentHistory.signatureApproved'),
        description: this.translateService.instant('documentHistory.signatureApprovedDesc', {
          user: [signer.signed_unapproved.user?.name, signer.signed_unapproved.user?.email || signer.signed_unapproved.user?.phone, signer.signed_unapproved.user?.cpf].filter(e => !!e).join(', ')
        })
      },
      date: signer.biometric_approved.created_at,
      filter: {
        first: false,
        type: 'biometric_approved'
      }
    } as HistoryItem;
  }

  // Retorna objeto de HistoryItem quando o Signatário foi reprovado na validação facial
  returnBiometricRejectedEvent(signer: SignerHistory) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.biometric_rejected.user?.name || '',
        address: signer.biometric_rejected.user?.email || signer.biometric_rejected.user?.phone || '',
        cpf: signer.biometric_rejected.user?.cpf || ''
      },
      data: {
        color: 'red',
        icon: 'fal fa-exclamation-triangle',
        title: this.translateService.instant('documentHistory.signatureRejected'),
        description: this.translateService.instant('documentHistory.signatureRejectedDesc', {
          user: [signer.signed_unapproved.user?.name, signer.signed_unapproved.user?.email || signer.signed_unapproved.user?.phone, signer.signed_unapproved.user?.cpf].filter(e => !!e).join(', ')
        })
      },
      date: signer.biometric_rejected.created_at,
      filter: {
        first: false,
        type: 'biometric_rejected'
      }
    } as HistoryItem;
  }

  /**
   * SOLICITATION
   * 1: Delivered
   * 2: Refused
   * 3: Opened
   * 4: Sent
   */
  returnSolicitationDeliveredHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone || ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-envelope',
        title: this.translateService.instant('documentHistory.emailReceived'),
        description: this.translateService.instant('documentHistory.emailReceivedDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
        })
      },
      date: `${new Date(`${signer.signature_history[index].delivered.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'email'
      }
    } as HistoryItem;
  }
  returnSolicitationRefusedHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone || ''
      },
      data: {
        color: 'red',
        icon: 'fal fa-exclamation-triangle',
        title: this.translateService.instant('documentHistory.emailSendingError'),
        description: this.translateService.instant('documentHistory.emailSendingErrorDesc', {
          reason: signer.signature_history[index].reason
        })
      },
      date: `${new Date(`${signer.signature_history[index].refused.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'rejected'
      }
    } as HistoryItem;
  }
  returnSolicitationOpenedHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone || ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-envelope-open',
        title: this.translateService.instant('documentHistory.emailViewed'),
        description: this.translateService.instant('documentHistory.emailViewedDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
        })
      },
      date: `${new Date(`${signer.signature_history[index].opened.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'email'
      }
    } as HistoryItem;
  }
  returnSolicitationSentHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: '',
        address: ''
      },
      data: {
        icon: 'fal fa-envelope',
        color: 'default',
        title: this.translateService.instant('documentHistory.emailSent'),
        description: this.translateService.instant('documentHistory.emailSentDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
        })
      },
      date: `${new Date(`${signer.signature_history[index].sent.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'email'
      }
    } as HistoryItem;
  }

  /**
   * RESEND
   * 1: Delivered
   * 2: Refused
   * 3: Opened
   * 4: Sent
   */
  returnResendDeliveredHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone || ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-envelope',
        title: this.translateService.instant('documentHistory.emailResentReceived'),
        description:
          this.documentData.author !== null
            ? this.translateService.instant('documentHistory.emailResentReceivedDescBy', {
                id: signer.signature_history[index].id,
                author: this.documentData.author?.name || this.documentData.author?.email,
                user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
              })
            : this.translateService.instant('documentHistory.emailResentReceivedDesc', {
                id: signer.signature_history[index].id,
                user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
              })
      },
      date: `${new Date(`${signer.signature_history[index].delivered.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'email'
      }
    } as HistoryItem;
  }
  returnResendRefusedHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone || ''
      },
      data: {
        color: 'red',
        icon: 'fal fa-exclamation-triangle',
        title: this.translateService.instant('documentHistory.emailResendError'),
        description:
          this.documentData.author !== null
            ? this.translateService.instant('documentHistory.emailResendErrorDescBy', {
                id: signer.signature_history[index].id,
                author: this.documentData.author?.name || this.documentData.author?.email,
                reason: signer.signature_history[index].reason
              })
            : this.translateService.instant('documentHistory.emailResendErrorDesc', {
                reason: signer.signature_history[index].reason
              })
      },
      date: `${new Date(`${signer.signature_history[index].refused.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'error'
      }
    } as HistoryItem;
  }
  returnResendOpenedHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone || ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-envelope-open',
        title: this.translateService.instant('documentHistory.emailResentViewed'),
        description:
          this.documentData.author !== null
            ? this.translateService.instant('documentHistory.emailResentViewedDescBy', {
                id: signer.signature_history[index].id,
                author: this.documentData.author?.name || this.documentData.author?.email,
                user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
              })
            : this.translateService.instant('documentHistory.emailResentViewedDesc', {
                id: signer.signature_history[index].id,
                user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
              })
      },
      date: `${new Date(`${signer.signature_history[index].opened.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'email'
      }
    } as HistoryItem;
  }
  returnResendSentHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone || ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-envelope',
        title: this.translateService.instant('documentHistory.emailResent'),
        description:
          this.documentData.author !== null
            ? this.translateService.instant('documentHistory.emailResentDescBy', {
                id: signer.signature_history[index].id,
                author: this.documentData.author?.name || this.documentData.author?.email,
                user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
              })
            : this.translateService.instant('documentHistory.emailResentDescTo', {
                id: signer.signature_history[index].id,
                user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
              })
      },
      date: `${new Date(`${signer.signature_history[index].sent.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'email'
      }
    } as HistoryItem;
  }

  /**
   * REMINDER
   * 1: Delivered
   * 2: Refused
   * 3: Opened
   * 4: Sent
   */
  returnReminderDeliveredHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: '',
        address: ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-envelope',
        title: this.translateService.instant('documentHistory.reminderReceived'),
        description: this.translateService.instant('documentHistory.reminderReceivedDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
        })
      },
      date: `${new Date(`${signer.signature_history[index].delivered.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'email'
      }
    } as HistoryItem;
  }
  returnReminderRefusedHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone || ''
      },
      data: {
        color: 'red',
        icon: 'fal fa-exclamation-triangle',
        title: this.translateService.instant('documentHistory.reminderSendingError'),
        description: this.translateService.instant('documentHistory.reminderSendingErrorDesc', {
          reason: signer.signature_history[index].reason
        })
      },
      date: `${new Date(`${signer.signature_history[index].refused.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'error'
      }
    } as HistoryItem;
  }
  returnReminderOpenedHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: '',
        address: ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-envelope-open',
        title: this.translateService.instant('documentHistory.reminderViewed'),
        description: this.translateService.instant('documentHistory.reminderViewedDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
        })
      },
      date: `${new Date(`${signer.signature_history[index].opened.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'email'
      }
    } as HistoryItem;
  }
  returnReminderSentHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: '',
        address: ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-envelope',
        title: this.translateService.instant('documentHistory.signatureReminderSent'),
        description: this.translateService.instant('documentHistory.signatureReminderSentDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].user?.email || signer.signature_history[index].user?.phone || signer.user.email || signer.user.phone
        })
      },
      date: `${new Date(`${signer.signature_history[index].sent.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'email'
      }
    } as HistoryItem;
  }

  /**
   * WHATSAPP
   * 1: Delivered
   * 2: Refused
   * 3: Opened
   * 4: Sent
   */
  returnWhatsappDeliveredHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].phone || signer.user.phone || signer.user.email || ''
      },
      data: {
        color: 'default',
        icon: 'fa-brands fa-whatsapp',
        title: this.translateService.instant('documentHistory.whatsappReceived'),
        description: this.translateService.instant('documentHistory.whatsappReceivedDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].phone || signer.user.phone || signer.user.email
        })
      },
      date: `${new Date(`${signer.signature_history[index].delivered.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'whatsapp'
      }
    } as HistoryItem;
  }
  returnWhatsappRefusedHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].phone || signer.user.phone || signer.user.email || ''
      },
      data: {
        color: 'red',
        icon: 'fal fa-exclamation-triangle',
        title: this.translateService.instant('documentHistory.whatsappSendingError'),
        description: this.translateService.instant('documentHistory.whatsappSendingErrorDesc', {
          reason: signer.signature_history[index].reason
        })
      },
      date: `${new Date(`${signer.signature_history[index].refused.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'rejected'
      }
    } as HistoryItem;
  }
  returnWhatsappOpenedHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].phone || signer.user.phone || signer.user.email || ''
      },
      data: {
        color: 'default',
        icon: 'fa-brands fa-whatsapp',
        title: this.translateService.instant('documentHistory.whatsappViewed'),
        description: this.translateService.instant('documentHistory.whatsappViewedDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].phone || signer.user.phone || signer.user.email
        })
      },
      date: `${new Date(`${signer.signature_history[index].opened.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'whatsapp'
      }
    } as HistoryItem;
  }
  returnWhatsappSentHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: '',
        address: ''
      },
      data: {
        icon: 'fa-brands fa-whatsapp',
        color: 'default',
        title: this.translateService.instant('documentHistory.whatsappSent'),
        description: this.translateService.instant('documentHistory.whatsappSentDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].phone || signer.user.phone || signer.user.email
        })
      },
      date: `${new Date(`${signer.signature_history[index].sent.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'whatsapp'
      }
    } as HistoryItem;
  }

  /**
   * SMS
   * 1: Delivered
   * 2: Refused
   * 3: Sent
   */
  returnSmsDeliveredHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].phone || signer.user.phone || signer.user.email || ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-message-lines',
        title: this.translateService.instant('documentHistory.smsReceived'),
        description: this.translateService.instant('documentHistory.smsReceivedDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].phone || signer.user.phone || signer.user.email
        })
      },
      date: `${new Date(`${signer.signature_history[index].delivered.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'sms'
      }
    } as HistoryItem;
  }
  returnSmsRefusedHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: signer.signature_history[index].user?.name || signer.user.name || '',
        address: signer.signature_history[index].phone || signer.user.phone || signer.user.email || ''
      },
      data: {
        color: 'red',
        icon: 'fal fa-message-exclamation',
        title: this.translateService.instant('documentHistory.smsSendingError'),
        description: this.translateService.instant('documentHistory.smsSendingErrorDesc', {
          reason: signer.signature_history[index].reason
        })
      },
      date: `${new Date(`${signer.signature_history[index].refused.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'rejected'
      }
    } as HistoryItem;
  }
  returnSmsSentHistoryEvent(signer: SignerHistory, index: number) {
    return {
      signer: {
        public_id: signer.public_id,
        name: '',
        address: ''
      },
      data: {
        icon: 'fal fa-message-lines',
        color: 'default',
        title: this.translateService.instant('documentHistory.smsSent'),
        description: this.translateService.instant('documentHistory.smsSentDesc', {
          id: signer.signature_history[index].id,
          user: signer.signature_history[index].phone || signer.user.phone || signer.user.email
        })
      },
      date: `${new Date(`${signer.signature_history[index].sent.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'sms'
      }
    } as HistoryItem;
  }

  /**
   * Document ADD / REMOVE / GENERATE_LINK signers
   */

  returnDocumentSignerHistoryCategoryCreate(signer: DocumentSignerHistory) {
    return {
      signer: {
        public_id: signer.user.public_id,
        name: signer.author.user?.name || '',
        address: signer.author.user?.email || '',
        cpf: signer.author.user?.cpf || ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-user-plus',
        title: this.translateService.instant('documentHistory.signatoryAdded'),
        description: this.translateService.instant('documentHistory.signatoryAddedDesc', {
          signer: this.returnSignerHistoryMainIdentifier(signer)
        })
      },
      date: `${new Date(`${signer.created_at.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'other'
      }
    } as HistoryItem;
  }

  returnDocumentSignerHistoryCategoryDelete(signer: DocumentSignerHistory) {
    return {
      signer: {
        public_id: signer.user.public_id,
        name: signer.author.user?.name || '',
        address: signer.author.user?.email || '',
        cpf: signer.author.user?.cpf || ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-user-times',
        title: this.translateService.instant('documentHistory.signatoryRemoved'),
        description: this.translateService.instant('documentHistory.signatoryRemovedDesc', {
          signer: this.returnSignerHistoryMainIdentifier(signer)
        })
      },
      date: `${new Date(`${signer.created_at.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'other'
      }
    } as HistoryItem;
  }

  returnDocumentSignerHistoryCategoryLink(signer: DocumentSignerHistory) {
    return {
      signer: {
        public_id: signer.user.public_id,
        name: signer.author.user?.name || '',
        address: signer.author.user?.email || '',
        cpf: signer.author.user?.cpf || ''
      },
      data: {
        color: 'default',
        icon: 'fal fa-user-plus',
        title: this.translateService.instant('documentHistory.signatoryAddedByLink'),
        description: this.translateService.instant('documentHistory.signatoryAddedByLinkDesc', {
          signer: this.returnSignerHistoryMainIdentifier(signer)
        })
      },
      date: `${new Date(`${signer.created_at.replace(' ', 'T')}`).toJSON()}`,
      filter: {
        first: false,
        type: 'other'
      }
    } as HistoryItem;
  }
}
