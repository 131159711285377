<div class="modal-body">
  <p class="centermodal__title centermodal__title--red">{{ 'documents.documentsFinished' | translate }}</p>
  <img width="281" style="margin: 0 auto; display: block" [src]="'assets/alerts/warning.svg'" class="centermodal__img" />

  <div class="centermodal__message-body centermodal__message-body--margin-normal">
    <p class="centermodal__message">{{ 'documents.documentsFinishedMessage' | translate }}</p>
  </div>

  <div class="remain">
    <p class="remain__text" [innerHTML]="'documents.documentsRenewal' | translate: { days: remain.days, hours: remain.hours, minutes: remain.minutes }"></p>
  </div>

  <div class="centermodal__message-body centermodal__message-body--margin-normal">
    <p class="centermodal__message" *ngIf="!isChildOrganization()">
      <span>{{ 'documents.needToCreateMoreDocuments' | translate }}</span
      ><br />
      {{ 'documents.upgradeOrWait' | translate }}
    </p>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="modal.dismiss()">{{ 'button.cancel' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading" (click)="goToPayment()" *ngIf="!isChildOrganization()">
      {{ 'documents.checkPlans' | translate }}
    </button>
  </div>
</div>
