<div style="display: flex;gap: 70px;justify-content: center;background-color: var(--clr-structure-white);margin: 200px 50px;padding: 20px;border-radius: 10px;">
  <div>
    <div style="display: grid; grid-template-columns: repeat(5, 130px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-L btn-filled"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-filled"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-filled"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-filled state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: repeat(5, 130px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-L btn-outline"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-outline"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-outline"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-outline state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: repeat(5, 130px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-L btn-text"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-text state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-text state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-text state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-text state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-text"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-text state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-text state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-text state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-text state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-L btn-text"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-L btn-text state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
  </div>
  <div>
    <div style="display: grid; grid-template-columns: repeat(5, 110px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-M btn-filled"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-filled"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-filled"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-filled state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: repeat(5, 110px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-M btn-outline"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-outline"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-outline"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-outline state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: repeat(5, 110px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-M btn-text"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-text state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-text state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-text state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-text state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-text"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-text state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-text state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-text state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-text state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-M btn-text"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-M btn-text state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
  </div>
  <div>
    <div style="display: grid; grid-template-columns: repeat(5, 90px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-S btn-filled"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-filled"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-filled"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-filled state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: repeat(5, 90px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-S btn-outline"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-outline"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-outline"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-outline state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: repeat(5, 90px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-S btn-text"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-text state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-text state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-text state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-text state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-text"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-text state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-text state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-text state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-text state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-S btn-text"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-S btn-text state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
  </div>
  <div>
    <div style="display: grid; grid-template-columns: repeat(5, 70px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-XS btn-filled"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-filled"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-filled"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-filled state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: repeat(5, 70px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-XS btn-outline"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-outline"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-outline"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-outline state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
    <div style="display: grid; grid-template-columns: repeat(5, 70px); grid-gap: 10px; margin-bottom: 40px">
      <div>
        <button class="btn-XS btn-text"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-hover"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-focus"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-active"><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-disabled" disabled><span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-text"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-hover"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-focus"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-active"><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span></button>
      </div>
      <div>
        <button class="btn-XS btn-text"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-hover"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-focus"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-active"><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-disabled" disabled><span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-hover"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-focus"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-active"><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-disabled" disabled><i class="far fa-plus"></i> <span>Label</span> <i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-hover"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-focus"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-active"><i class="far fa-plus"></i></button>
      </div>
      <div>
        <button class="btn-XS btn-text state-disabled" disabled><i class="far fa-plus"></i></button>
      </div>
    </div>
  </div>
</div>
