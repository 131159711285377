import { Injectable, OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { concat, forkJoin, Observable, of } from 'rxjs';
import { delay, map, switchMap, tap, toArray } from 'rxjs/operators';
import { cloneDeep, flatten, pickBy, uniqBy } from 'lodash';

import { untilDestroyed } from '@app/core';
import { ActionEnum, AlertButtonType, ContextEnum, DeliveryMethodEnum, Document, DocumentStatus, DocumentStatusEnum, GraphQLResponse, SignatureTypesEnum, SlimDocument, User } from '@app/models';
import { AppService, DocumentService, NotyService, PadesService, UserService } from '@app/services';

import { AlertModalService, DocumentDeleteAlertModalService, SetMissingUserInfoModalService } from '@app/shared';
import { AcceptSignatureMutation } from 'src/generated/graphql.default';
import { TransferDocumentsModalService } from '../shared/transfer-documents-modal/transfer-documents-modal.service';
import { PreSignModalService } from '../shared/pre-sign-modal/pre-sign-modal.service';
import { ChooseSignatureModalService } from '../documents-show/choose-signature-modal/choose-signature-modal.service';
import { FoldersModalService } from '@app/pages/documents/shared/folders-modal/folders-modal.service';

export type CustomSlimDocument = SlimDocument & { _isSelected?: boolean };
type DeleteDocumentOptions = { memberId?: string; groupUuid?: string; context?: ContextEnum };
type FolderOptions = { context?: ContextEnum; currentFolderId?: string };

@Injectable({ providedIn: 'root' })
export class DocumentsListingService implements OnDestroy {
  readonly documentsSearchParams = ['status', 'name', 'signer', 'startDate', 'endDate', 'memberId', 'groupUuid', 'includeArchived', 'includeDeleted', 'orderBy[field]', 'orderBy[direction]'];
  readonly extraSearchParams = ['context', 'sandbox', 'limit', 'page'];
  readonly searchParamsTypeMap: { [k: string]: 'string' | 'number' | 'boolean' } = { includeArchived: 'boolean', includeDeleted: 'boolean', sandbox: 'boolean', limit: 'number', page: 'number' };
  private currentUser: User;

  constructor(
    private appService: AppService,
    private documentService: DocumentService,
    private userService: UserService,
    private modalService: NgbModal,
    private alertModalService: AlertModalService,
    private documentDeleteAlertModalService: DocumentDeleteAlertModalService,
    private notyService: NotyService,
    private translateService: TranslateService,
    private padesService: PadesService,
    private foldersModalService: FoldersModalService,
    private setMissingUserInfoModalService: SetMissingUserInfoModalService,
    private transferDocumentsModalService: TransferDocumentsModalService,
    private chooseSignatureModalService: ChooseSignatureModalService,
    private preSignModalService: PreSignModalService
  ) {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));
  }

  ngOnDestroy() {}

  isCurrentUserSignerPending(document: SlimDocument) {
    if (this.currentUser && document) {
      const signer = document.signatures.find(sig => sig.action && sig.user && this.currentUser && sig.user.id === this.currentUser.id);
      return signer && (document.sortable ? this.documentService.isSignerTurn(document, signer) : this.documentService.isSignerPending(signer, { ignoreEmailErrors: true }));
    }
  }

  getCurrentSigners(document: SlimDocument) {
    return this.documentService.getCurrentSigners(document, this.currentUser);
  }

  hasCurrentUser(document: SlimDocument) {
    return this.documentService.hasCurrentUser(this.currentUser, document);
  }

  isAdmin(document: SlimDocument) {
    return this.currentUser && document && this.documentService.isAdmin(this.currentUser, document);
  }

  isAllowedToSign(document?: SlimDocument) {
    return (
      this.currentUser &&
      this.currentUser.currentPermissions.sign_documents &&
      (!document ||
        (this.isCurrentUserSignerPending(document) && !(document as Document).is_blocked && !this.documentService.isPastDeadline(document) && !this.documentService.isPastLifecycle(document)))
    );
  }

  isAllowedToArchive(document?: SlimDocument) {
    return this.documentService.isAllowedToArchive(this.currentUser, document);
  }

  isAllowedToDelete(document?: SlimDocument) {
    return this.documentService.isAllowedToDelete(this.currentUser, document);
  }

  isAllowedToManage(document?: SlimDocument) {
    return this.documentService.isAllowedToManage(this.currentUser, document);
  }

  isAllowedToBlock(document?: SlimDocument) {
    return document && this.documentService.isAllowedToDelete(this.currentUser, document) && this.isAdmin(document) && !document.deadline_at;
  }

  isAllowedToToggleBlock(document?: SlimDocument) {
    return document && this.isAdmin(document);
  }

  isAllowedToResendWebhook(user?: User, document?: SlimDocument) {
    return user && user.organization.settings?.webhook_url?.length > 0 && this.isAdmin(document);
  }

  deletedByUserAt(document: SlimDocument, userId: string) {
    const currentSigner = document && document.signatures.find(signer => signer.user && signer.user.id && signer.user.id === userId);
    return (currentSigner && currentSigner.archived_at) || null;
  }

  sign(targetDocuments: CustomSlimDocument[]) {
    return new Observable<AcceptSignatureMutation['acceptSignature'][]>(subscriber => {
      this.userService.getCurrentUser({ fetchPolicy: 'cache-first' }).subscribe(
        currentUser => {
          if (currentUser.currentPermissions.sign_documents) {
            const documents = targetDocuments.filter(doc => this.isAllowedToSign(doc));
            this.setMissingUserInfoModalService
              .openIfMissingUserInfo({ ignoreEmailAndPassword: true, ignoreCpf: documents.length > 0 && documents.filter(doc => doc.ignore_cpf).length === documents.length })
              .pipe(
                map(() => documents.map(document => this.chooseSignatureModalService.open({ user: currentUser, signers: this.getCurrentSigners(document), document, dismissAsError: true }))),
                switchMap(modals => concat(...modals).pipe(toArray())),
                switchMap(() => this.documentService.authSignToken({ documentsIds: documents.map(doc => doc.id) })),
                switchMap(tokens => {
                  if (tokens.length === 0) {
                    return of([]);
                  }

                  const verificationSignatureIds = flatten(
                    documents.filter(doc => flatten(doc.signatures.map(signature => signature.verifications)).length > 0).map(doc => tokens.filter(token => token.document_id === doc.id))
                  )
                    .filter(token => !!token)
                    .map(token => token.signature_id);

                  let normalDocsTokens = flatten(documents.filter(doc => !doc.qualified).map(doc => tokens.filter(token => token.document_id === doc.id))).filter(
                    token => !!token && !verificationSignatureIds.includes(token.signature_id)
                  );
                  let padesDocsTokens = uniqBy(
                    flatten(documents.filter(doc => doc.qualified).map(doc => tokens.filter(token => token.document_id === doc.id))).filter(
                      token => !!token && !verificationSignatureIds.includes(token.signature_id)
                    ),
                    'document_id'
                  );

                  if (verificationSignatureIds.length > 0) {
                    this.notyService.info(this.translateService.instant('notyService.documentsRequireAdditionalVerification'));
                    if (normalDocsTokens.length + padesDocsTokens.length === 0) {
                      return of([]);
                    }
                  }

                  const firstDocument = documents.find(doc => doc.id === padesDocsTokens.concat(normalDocsTokens)[0].document_id);
                  const firstSignerIds = tokens.filter(token => token.document_id === firstDocument.id).map(token => token.signature_id);
                  let qualifiedDefaultUserType: SignatureTypesEnum;

                  // Assina o primeiro doc PAdES ou normal após modal, depois assina todos os documentos normais, após assina o resto dos documentos PAdES
                  return this.preSignModalService
                    .open({
                      action: ActionEnum.Sign,
                      document: firstDocument,
                      padesSignatureId: firstSignerIds[0],
                      dismissAsError: true
                    })
                    .pipe(
                      // Assina 1 doc PAdES ou normal
                      switchMap(({ type, padesSignature }) => {
                        if (firstDocument.qualified) {
                          qualifiedDefaultUserType = type;
                          return [SignatureTypesEnum.Safeid, SignatureTypesEnum.Vidaas].includes(type)
                            ? this.documentService.signCloudCert({ id: firstSignerIds[0], type }).pipe(map(data => [data]))
                            : this.documentService.signPades({ id: firstSignerIds[0], type, payload: padesSignature }).pipe(map(data => [data]));
                        } else {
                          return this.documentService.sign(firstSignerIds);
                        }
                      }),
                      tap(() => {
                        // Filtra fora o primeiro documento, já assinado
                        normalDocsTokens = normalDocsTokens.filter(token => token.document_id !== firstDocument.id);
                        padesDocsTokens = padesDocsTokens.filter(token => token.document_id !== firstDocument.id);
                      }),
                      switchMap(signedData =>
                        normalDocsTokens.length > 0 ? this.documentService.sign(normalDocsTokens.map(token => token.signature_id)).pipe(map(data => signedData.concat(data))) : of(signedData)
                      ),
                      map(signedData => {
                        // Prepara para assinar com PAdES e assina em seguida, um documento/signatário por vez
                        const certData = this.padesService.getStoredA1CertificateData(this.currentUser);
                        return [of(signedData)].concat(
                          padesDocsTokens.map(token =>
                            this.padesService
                              .prepareToSignPades({
                                user: currentUser,
                                signatureId: token.signature_id,
                                ...(certData ? { type: SignatureTypesEnum.A1, file: certData.file, password: certData.password } : { type: qualifiedDefaultUserType })
                              })
                              .pipe(
                                switchMap(({ type, padesSignature }) =>
                                  [SignatureTypesEnum.Safeid, SignatureTypesEnum.Vidaas].includes(type)
                                    ? this.documentService.signCloudCert({ id: token.signature_id, type })
                                    : this.documentService.signPades({ id: token.signature_id, type, payload: padesSignature })
                                ),
                                map(data => [data])
                              )
                          )
                        );
                      }),
                      switchMap(signRequest =>
                        concat(...signRequest).pipe(
                          toArray(),
                          map(signedDataArray => flatten(signedDataArray))
                        )
                      )
                    );
                }),
                tap(signedData => {
                  signedData.forEach(item => {
                    documents.forEach(document => {
                      delete document._isSelected;
                      const signatures = cloneDeep(document.signatures);
                      signatures
                        .filter(sig => sig.public_id === item.public_id)
                        .forEach(sig => {
                          sig.signed = item.signed;
                          sig.organization_id = item.organization_id;
                          sig.group_id = item.group_id;
                        });
                      document.signatures = signatures;
                    });
                  });
                })
              )
              .subscribe(
                signedData => {
                  subscriber.next(signedData);
                  subscriber.complete();
                },
                error => subscriber.error(error)
              );
          } else {
            subscriber.error({ errors: [{ message: 'without_permission_sign_documents' }] } as GraphQLResponse);
          }
        },
        error => subscriber.error(error)
      );
    });
  }

  resendSignatures(documents: CustomSlimDocument[]) {
    return new Observable<undefined>(subscriber => {
      const signers = flatten(
        (documents || []).map(doc =>
          doc.signatures.filter(
            sig =>
              sig.delivery_method !== DeliveryMethodEnum.DeliveryMethodLink &&
              doc.author.id !== sig.user?.id &&
              this.documentService.isSignerPending(sig, { ignoreEmailErrors: true }) &&
              !this.documentService.isSignerUnapproved(sig)
          )
        )
      );
      const creditsPrice = signers.reduce((total, sig) => total + this.appService.creditPriceByType(sig.delivery_method), 0);

      this.alertModalService
        .confirmation({ text: this.translateService.instant('alerts.resendDocument'), creditsPrice })
        .pipe(
          switchMap(() => (signers.length > 0 ? this.documentService.resendSignatures({ publicIds: signers.map(sig => sig.public_id) }) : of(null))),
          tap(() => (creditsPrice > 0 ? this.userService.currentUserCredits().subscribe() : null))
        )
        .subscribe(
          () => {
            documents.forEach(doc => delete doc._isSelected);
            signers.forEach(sig => (sig.email_events.refused = null));
            subscriber.next();
            subscriber.complete();
          },
          error => subscriber.error(error)
        );
    });
  }

  openFoldersModal(allDocuments: SlimDocument[], documents: SlimDocument[], options: FolderOptions = {}) {
    return this.foldersModalService.open({ ...options, targetType: 'document', itemsToMove: documents }).pipe(
      tap(() =>
        documents.forEach(doc =>
          allDocuments.splice(
            allDocuments.findIndex(item => item.id === doc.id),
            1
          )
        )
      )
    );
  }

  transfer(allDocuments: SlimDocument[], documents: SlimDocument[], options: { isRestoration?: boolean; context?: ContextEnum; currentGroupId?: number } = {}) {
    return this.transferDocumentsModalService.open({ documents, ...options }).pipe(
      tap(hasTransfered => {
        if (hasTransfered) {
          this.notyService.success(
            this.translateService.instant(
              documents.length > 1
                ? options.isRestoration
                  ? 'notyService.documentsRestoredSuccessPlural'
                  : 'notyService.documentsTransferredSuccessPlural'
                : options.isRestoration
                ? 'notyService.documentRestoredSuccess'
                : 'notyService.documentTransferredSuccess'
            )
          );
        }
      })
    );
  }

  toggleBlock(targetDocuments: SlimDocument[]) {
    return new Observable<undefined>(subscriber => {
      const isBlocked = this.documentService.isPastDeadline(targetDocuments[0]);
      const documentsToUnblock = targetDocuments.filter(doc => this.isAllowedToToggleBlock(doc) && this.documentService.isPastDeadline(doc));
      const documentsToBlock = targetDocuments.filter(doc => this.isAllowedToToggleBlock(doc) && !this.documentService.isPastDeadline(doc));
      this.alertModalService
        .confirmation({
          text:
            this.translateService.instant(
              isBlocked
                ? documentsToUnblock.concat(documentsToBlock).length > 1
                  ? 'alerts.unlockDocuments'
                  : 'alerts.unlockDocument'
                : documentsToUnblock.concat(documentsToBlock).length > 1
                ? 'alerts.lockDocuments'
                : 'alerts.lockDocument'
            ) + this.translateService.instant(isBlocked ? 'alerts.allowPendingSigners' : 'alerts.preventPendingSigners'),
          confirmButtonText: this.translateService.instant(isBlocked ? 'button.unblock' : 'button.block')
        })
        .pipe(
          switchMap(() => forkJoin([this.documentService.unblock(documentsToUnblock.map(doc => doc.id)), this.documentService.block(documentsToBlock.map(doc => doc.id))])),
          tap(() => documentsToUnblock.forEach(doc => (doc.deadline_at = null))),
          tap(([unblockedDocs, blockedDocs]) => documentsToBlock.forEach(doc => (doc.deadline_at = blockedDocs.find((item: any) => item.id === doc.id).deadline_at)))
        )
        .subscribe(
          () => {
            this.notyService.success(
              this.translateService.instant(
                documentsToUnblock.concat(documentsToBlock).length > 1
                  ? isBlocked
                    ? 'notyService.documentsUnlockedSuccessPlural'
                    : 'notyService.documentsLockedSuccessPlural'
                  : isBlocked
                  ? 'notyService.documentUnlockedSuccess'
                  : 'notyService.documentLockedSuccess'
              )
            );
            subscriber.next();
            subscriber.complete();
          },
          error => subscriber.error(error)
        );
    });
  }

  delete(allDocuments: SlimDocument[], targetDocuments: SlimDocument[], options: DeleteDocumentOptions = {}) {
    return new Observable<boolean[]>(subscriber => {
      const text = this.translateService.instant('alerts.' + ((targetDocuments || []).length > 1 ? 'deleteDocuments' : 'deleteDocument'));

      this.documentDeleteAlertModalService.open({ text, dismissAsError: true, showExtraBtn: targetDocuments.filter(doc => this.isAllowedToBlock(doc)).length > 0 }).subscribe(
        type => {
          const docIdsToBlock = type === AlertButtonType.confirmExtra ? targetDocuments.filter(doc => this.isAllowedToBlock(doc)).map(doc => doc.id) : [];
          const docIdsToNotBlock = targetDocuments.filter(doc => !docIdsToBlock.includes(doc.id)).map(doc => doc.id);

          this.documentService
            .delete(docIdsToNotBlock, { ...options, block: false })
            .pipe(
              switchMap(() => this.documentService.delete(docIdsToBlock, { ...options, block: true })),
              tap(() => {
                targetDocuments.forEach(doc => delete (doc as any)._isSelected);
                if (options.memberId) {
                  targetDocuments.forEach(doc => {
                    const organizationMemberSigner = doc.signatures.find(sig => sig.user && sig.user.id === options.memberId);
                    if (organizationMemberSigner) {
                      organizationMemberSigner.archived_at = new Date().toISOString();
                    }
                  });
                } else {
                  targetDocuments.forEach(doc =>
                    allDocuments.splice(
                      allDocuments.findIndex(item => item.id === doc.id),
                      1
                    )
                  );
                }
              })
            )
            .subscribe(
              result => {
                subscriber.next(result);
                subscriber.complete();
              },
              error => subscriber.error(error)
            );
        },
        () => subscriber.complete()
      );
    });
  }

  exportDocuments(searchParams: any) {
    return this.documentService.exportDocuments(searchParams).pipe(
      delay(4500),
      tap(isExported => {
        if (isExported) {
          this.notyService.success(this.translateService.instant('notyService.reportGenerating'), 10000);
        } else {
          this.notyService.error(this.translateService.instant('notyService.reportGenerationFailed'));
        }
      })
    );
  }

  documentStatusClasses(document: SlimDocument): DocumentStatus[] {
    const classes: DocumentStatus[] = [];

    if (this.documentService.isRejected(document)) {
      classes.push(DocumentStatus.Rejected);
    } else if (this.documentService.isExpired(document)) {
      classes.push(DocumentStatus.Expired);
    } else if (this.documentService.isSigned(document)) {
      classes.push(DocumentStatus.Signed);
    } else if (this.documentService.isPending(document)) {
      classes.push(DocumentStatus.Pending);
    } else if (this.documentService.isWarning(document)) {
      classes.push(DocumentStatus.Warning);
    }

    if (this.isCurrentUserSignerPending(document)) {
      classes.push(DocumentStatus.NotSigned);
    }

    return classes;
  }

  documentsFilter(documents: CustomSlimDocument[]) {
    const status = this.appService.documentStatusFilter;
    const filterMap = {
      [DocumentStatusEnum.Signed]: DocumentStatus.Signed,
      [DocumentStatusEnum.NotSigned]: DocumentStatus.NotSigned,
      [DocumentStatusEnum.Pending]: DocumentStatus.Pending
      // [DocumentStatusEnum.Rejected]: DocumentStatus.Rejected
    };
    return !status ? documents : documents.filter(document => this.documentStatusClasses(document).includes(filterMap[status]));
  }

  searchQueryFromQueryParams(queryParams: { [k: string]: string }) {
    const allowedQueryParams = this.documentsSearchParams.concat(this.extraSearchParams);
    const allowedKeys = Object.keys(queryParams || {}).filter(key => allowedQueryParams.includes(key));
    const searchQuery = this.appService.formDataToJSON(
      pickBy(queryParams, (value, key) => allowedKeys.includes(key)),
      this.searchParamsTypeMap
    );
    return Object.keys(searchQuery).length > 0 ? searchQuery : null;
  }

  pushSearchQueryToUrl(searchQuery: any) {
    if (history.pushState) {
      if (Object.keys(searchQuery || {}).length > 0) {
        history.pushState({}, null, `${location.protocol}//${location.host + location.pathname}?${new URLSearchParams(searchQuery).toString()}`);
      } else if (location.search) {
        history.pushState({}, null, `${location.protocol}//${location.host + location.pathname}`);
      }
    }
  }

  isSearchParams(queryParams: { [k: string]: string }) {
    return Object.keys(queryParams || {}).findIndex(key => this.documentsSearchParams.includes(key)) >= 0;
  }
}
