<header class="header">
  <a routerLink="/" class="header-brand">
    <img *ngIf="whitelabelService.isApplicationWhitelabelLoaded" [src]="whitelabelService.whitelabel?.pdf_logo_mini" alt="" />
  </a>
  <div class="header-navigation">
    <div routerLinkActive="tab">
      <a routerLink="/documentos" class="header-navigation-item" routerLinkActive="selected" [attr.data-name]="translateService.instant('menu.documents')">
        <i class="fa-regular fa-file-alt"></i>
      </a>
    </div>
    <div routerLinkActive="tab">
      <a routerLink="/pastas" class="header-navigation-item" routerLinkActive="selected" [attr.data-name]="translateService.instant('menu.folders')">
        <i class="fa-regular fa-folder"></i>
      </a>
    </div>
    <div routerLinkActive="tab">
      <a routerLink="/contatos" class="header-navigation-item" routerLinkActive="selected" [attr.data-name]="translateService.instant('menu.contacts')">
        <i class="fa-regular fa-book-user"></i>
      </a>
    </div>
    <a href="javascript:void(0)" class="header-navigation-item" [attr.data-name]="translateService.instant('menu.roadmap')" app-nolt>
      <i class="fa-regular fa-lightbulb-on"></i>
    </a>
    <div routerLinkActive="tab">
      <a routerLink="/perfil" class="header-navigation-item" routerLinkActive="selected" [attr.data-name]="translateService.instant('menu.settings')">
        <i class="fa-regular fa-cog"></i>
      </a>
    </div>
  </div>

  <app-verifications-credits-count *ngIf="currentUser" [user]="currentUser" class="mb-2"></app-verifications-credits-count>
  <app-documents-count *ngIf="currentUser" [user]="currentUser" class="mb-3"></app-documents-count>

  <div class="header__account" placement="right-bottom" [sameSizeSubmenus]="true" (mouseover)="dropdown.open()" (mouseleave)="dropdown.close()" appDropdown *ngIf="currentUser">
    <label id="header__account-avatar" class="header__account-avatar" appDropdownAnchor>
      <app-autenticats [seed]="currentUser?.email || currentUser?.name"></app-autenticats>
    </label>
    <div aria-labelledby="header__account-avatar" class="header__account-modal" appDropdownMenu>
      <div class="header__account-user">
        <p class="header__account-user-name" *ngIf="currentUser?.name">{{ currentUser.name }}</p>
        <p class="header__account-user-contact" *ngIf="currentUser?.email">{{ currentUser.email }}</p>
      </div>
      <label class="header__account-mode">
        <span class="header__account-mode-name">{{ 'menu.darkMode' | translate }}</span>
        <app-switch [(ngModel)]="appService.isDarkmode"></app-switch>
      </label>
      <div class="header__account-options">
        <a href="javascript:void(0)" class="header__account-user dropdown-item-descriptive" appDropdownItem submenuToggle="submenu1" *ngIf="!whitelabelService.isEnterprise">
          <div>
            <p class="header__account-user-company">{{ currentUser.organization?.id ? currentUser.organization.name : ('menu.noOrganization' | translate) }}</p>
            <p class="header__account-user-role">{{ userService.getSubscriptionName(currentUser) }}</p>
          </div>
        </a>
        <a routerLink="/perfil" class="header__account-option" appDropdownItem>
          <i class="fal fa-cog"></i>
          <span class="header__account-option-text">{{ 'menu.settings' | translate }}</span>
        </a>
        <!--
          <a routerLink="/recommendation" class="header__account-option" appDropdownItem>
            <i class="fal fa-heart"></i>
            <span class="header__account-option-text">{{ 'menu.indications' | translate }}</span>
          </a>
        -->
        <a href="javascript:void(0)" class="header__account-option" app-nolt appDropdownItem>
          <i class="fal fa-lightbulb-on"></i>
          <span class="header__account-option-text">{{ 'menu.featureRequests' | translate }}</span>
        </a>
        <a routerLink="/documentos/lixeira" class="header__account-option" appDropdownItem>
          <i class="fal fa-trash"></i>
          <span class="header__account-option-text">{{ 'menu.trash' | translate }}</span>
        </a>
        <hr class="header__account-hr" />
        <a routerLink (click)="logout()" class="header__account-option" appDropdownItem>
          <i class="fal fa-sign-out"></i>
          <span class="header__account-option-text">{{ 'auth.signOut' | translate }}</span>
        </a>
      </div>
    </div>

    <div appDropdownSubmenu="submenu1">
      <button type="button" appDropdownBackButton>{{ 'button.back' | translate }}</button>

      <div class="header__account-options">
        <a href="javascript:void(0)" class="header__account-option" *ngFor="let organization of organizations" (click)="selectDefaultOrganization(organization.id)" appDropdownItem>
          <span class="header__account-option-text">{{ organization.name }}</span>
        </a>
      </div>

      <div class="header__account-options-bottom">
        <a href="javascript:void(0)" class="header__account-option" (click)="openCreateOrganizationModal()" appDropdownItem>
          <span class="header__account-option-text">{{ 'menu.createNewOrganization' | translate }}</span>
        </a>
      </div>
    </div>
  </div>
</header>
