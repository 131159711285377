<header class="sidebar-header">
  <ng-container *ngIf="whitelabelService?.whitelabel">
    <ng-container *ngIf="!isWhitelabel()">
      <a *ngIf="currentUser && !isCurrentUserHidden()" routerLink="/" class="brand">
        <img class="squareLogo" src="assets/autentique-logo.svg" alt="" />
      </a>

      <a *ngIf="!isWhitelabel() && (!currentUser || isCurrentUserHidden())" href="https://autentique.com.br" class="brand brand-large">
        <img class="horizontalLogo" src="assets/Autentique.svg" alt="" />
      </a>
    </ng-container>

    <ng-container *ngIf="isWhitelabel() && !isLoading">
      <a *ngIf="currentUser && !isCurrentUserHidden()" class="brand" style="cursor: default;">
        <img class="squareLogo" [src]="whitelabelService.whitelabel.pdf_logo_mini || 'assets/autentique-logo.svg'" alt="" />
      </a>

      <a *ngIf="!currentUser || isCurrentUserHidden()">
        <img class="horizontalLogo horizontalLogo_logo" [src]="whitelabelService.whitelabel.logo || 'assets/Autentique.svg'" alt="" />
      </a>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="(currentUser && !isCurrentUserHidden()) || (currentUser && !isOnSigningPage() && isWhitelabel())">
    <ng-content *ngIf="whitelabelService.params.menuBackButton && !isLoading"></ng-content>
  </ng-container>

  <ng-container *ngIf="whitelabelService?.isApplicationWhitelabelLoaded">
    <app-verifications-credits-count [user]="currentUser" *ngIf="currentUser && !isCurrentUserHidden() && !isWhitelabel() && !isLoading" class="mr-1 ml-auto"></app-verifications-credits-count>
    <app-documents-count [user]="currentUser" *ngIf="currentUser && !isCurrentUserHidden() && !isWhitelabel() && !isLoading"></app-documents-count>
  </ng-container>
</header>
