import { AfterContentInit, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, finalize } from 'rxjs/operators';

import { untilDestroyed } from '@app/core';
import { AppService, ErrorHandlerService, FolderService, UserService } from '@app/services';
import { ContextEnum, Folder, SlimDocument, User } from '@app/models';
import { LoaderService } from '@app/shared/loader/loader.service';
import { SearchComponent } from '@app/shared/search/search.component';
import { CreateFolderModalService } from '../create-folder-modal/create-folder-modal.service';
import { TranslateService } from '@ngx-translate/core';

export type ModalResult = Folder;
export interface ModalPublicProperties {
  itemsToMove: SlimDocument[] | Folder[];
  context?: ContextEnum;
  currentFolderId?: string;
  targetType?: 'document' | 'folder';
  isNewDocument?: boolean;
}

@Component({
  selector: 'app-folders-modal',
  templateUrl: './folders-modal.component.html',
  styleUrls: ['./folders-modal.component.scss']
})
export class FoldersModalComponent implements ModalPublicProperties, AfterContentInit, OnDestroy {
  @Input() itemsToMove: SlimDocument[] | Folder[];
  @Input() context: ContextEnum;
  @Input() currentFolderId: string;
  @Input() targetType: ModalPublicProperties['targetType'] = 'document';
  @Input() isNewDocument: false;
  @ViewChild('searchComponent', { static: false }) searchComponent: SearchComponent;
  readonly ContextEnum = ContextEnum;
  currentUser: User;
  isLoading = false;
  isLastPage = false;
  pageNumber: number;
  search: string;
  folders: Folder[];
  selectedFolder: Folder;
  parentFolder: Folder;
  tabOptions: [ContextEnum, string][] = [[ContextEnum.User, this.translateService.instant('settings.personal')]];

  constructor(
    public modal: NgbActiveModal,
    public appService: AppService,
    private translateService: TranslateService,
    private modalService: NgbModal,
    private loaderService: LoaderService,
    private userService: UserService,
    private foldersService: FolderService,
    private errorHandlerService: ErrorHandlerService,
    private createFolderModalService: CreateFolderModalService
  ) {}

  ngAfterContentInit() {
    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(user => {
        this.currentUser = user;

        if (this.currentUser.currentPermissions.view_folders_gr) {
          this.tabOptions.push([ContextEnum.Group, this.translateService.instant('settings.group')]);
        }
        if (this.currentUser.currentPermissions.view_folders_oz) {
          this.tabOptions.push([ContextEnum.Organization, this.translateService.instant('settings.organization')]);
        }
        this.appService.folderContext = (this.tabOptions.find(option => option[0] === this.appService.folderContext) || [])[0] || ContextEnum.User;

        this.searchFolders();
      });
  }

  ngOnDestroy() {}

  searchFolders(options: { search?: string; pageNumber?: number; parentId?: string } = {}) {
    if (this.currentUser) {
      this.isLoading = true;
      this.loaderService.disableHttpLoader = true;
      this.pageNumber = options.pageNumber || 1;
      this.folders = this.pageNumber === 1 ? [] : this.folders;
      const parentId = options.hasOwnProperty('parentId') ? options.parentId : this.parentFolder?.id || null;

      const params = { search: options.search || '', page: this.pageNumber, limit: 12, parentId };
      const folders$ = {
        [ContextEnum.User]: this.foldersService.folders({ context: ContextEnum.User, ...params }),
        [ContextEnum.Group]: this.foldersService.folders({ context: ContextEnum.Group, ...params }),
        [ContextEnum.Organization]: this.foldersService.folders({ context: ContextEnum.Organization, ...params })
      }[this.appService.folderContext].pipe(
        finalize(() => {
          this.isLoading = false;
          setTimeout(() => (this.loaderService.disableHttpLoader = false));
        })
      );

      folders$.subscribe(
        page => {
          const itemsToMoveIds = (this.itemsToMove as any).map((item: { id: any }) => item?.id);
          this.folders = (this.pageNumber === 1 ? [] : this.folders).concat(page.data).filter(folder => this.targetType !== 'folder' || !itemsToMoveIds.includes(folder.id));
          this.isLastPage = page.current_page >= page.last_page;
        },
        error => this.errorHandlerService.handle(error)
      );
      return folders$;
    }
  }

  moveToFolder(folder?: Folder) {
    this.isLoading = true;
    const ids = (this.itemsToMove as any).map((item: { id: any }) => item?.id);

    let moveToFolder$;
    if (this.targetType === 'folder') {
      const variables = {
        context: this.appService.folderContext,
        ...(folder && { parentFolderId: folder.id })
      };

      moveToFolder$ = this.foldersService.moveFolder(ids, variables);
    } else {
      const currentFolderId = this.currentFolderId;
      const context = this.context;
      moveToFolder$ = folder
        ? this.foldersService.moveDocumentsToFolder(ids, { folderId: folder.id, ...(currentFolderId ? { currentFolderId } : {}), ...(context ? { context } : {}) })
        : this.foldersService.removeDocumentsFromFolder(ids, { currentFolderId, ...(context ? { context } : {}) });
    }

    moveToFolder$.pipe(finalize(() => (this.isLoading = false))).subscribe(
      () => this.modal.close(folder as ModalResult),
      error => this.errorHandlerService.handle(error)
    );
  }

  openCreateFolderModal() {
    if (this.isLoading) return;
    this.isLoading = true;

    this.createFolderModalService
      .open({ context: this.appService.folderContext, parentId: this.selectedFolder?.id || null, groupId: null })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(data => {
        if (!this.selectedFolder || (this.selectedFolder && this.parentFolder && this.selectedFolder.id === this.parentFolder.id)) {
          (this.folders || []).unshift(data);
        } else {
          this.selectedFolder.children_counter = (this.selectedFolder.children_counter || 0) + 1;
        }
      });
  }

  selectFolder(folder?: Folder, isParent?: boolean) {
    if (this.isLoading || (this.selectedFolder && folder && this.selectedFolder.id === folder.id)) return;

    this.selectedFolder = folder;
    if (!isParent && this.selectedFolder !== null && (!this.selectedFolder || this.selectedFolder.children_counter > 0)) {
      this.search = this.selectedFolder?.id ? '' : this.search;
      this.folders = [];
      this.parentFolder = undefined;
      this.searchFolders({ pageNumber: 1, parentId: this.selectedFolder?.id || null });
      if (this.selectedFolder) {
        this.foldersService.folder({ id: this.selectedFolder.id }).subscribe(selectedFolder => {
          this.parentFolder = selectedFolder;
          this.selectedFolder = selectedFolder;
        });
      }
    }
  }

  focusSearchInput() {
    if (window.innerWidth > 992) {
      this.searchComponent.focus();
    }
  }

  targetFolder(folder?: Folder) {
    if (this.isLoading && folder) return;
    this.isLoading = true;
    this.modal.close(folder as ModalResult);
  }
}
