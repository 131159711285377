import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { auditTime, switchMap } from 'rxjs/operators';
import { SelectService } from '@app/shared/select/select.service';

export interface SelectOption {
  key: any;
  value: any;
  tooltip?: string | TemplateRef<any>;
  height?: number;
  fontSize?: number;
  limitWidth?: number;
  credits?: number;
  icon?: string;
  hidden?: boolean;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements ControlValueAccessor, Validator, AfterViewInit, OnDestroy {
  @Input() formControlName: string;
  @Input() value: any = '';
  @Input() name = '';
  @Input() inputClass = '';
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() required = false;
  @Input() disableSearch = false;
  @Input() isLoading = false;
  @Input() options: SelectOption[];
  @Input() optionsRequest: (search: string) => Observable<SelectOption[]>;
  inputValue: any;
  creditsValue: any;
  selectedOption: SelectOption;
  onChange: (_: string) => void;
  onTouched: () => void;
  onValidationChange: () => void;
  readonly optionsLimit = 8;
  private keyUpChanges: BehaviorSubject<string>;
  @ViewChild('selectElement', { static: false }) private selectElement: ElementRef<HTMLInputElement>;
  @ViewChild('dropdownMenu', { static: false }) private dropdownMenuElement: ElementRef<HTMLDivElement>;
  @ViewChild('dropdown', { static: false }) private dropdown: NgbDropdown;

  constructor(private selectService: SelectService) {
    this.onChange = () => {};
    this.onTouched = () => {};
    this.onValidationChange = () => {};
    this.keyUpChanges = new BehaviorSubject<string>('');
  }

  ngAfterViewInit() {
    if (this.optionsRequest) {
      let firstTime = true;
      const savedOptions = this.selectService.getOptions(this.formControlName || this.name);

      this.keyUpChanges
        .pipe(
          auditTime(300),
          switchMap(value => (!(firstTime && savedOptions && this.value) ? this.optionsRequest(value) : of(null)))
        )
        .subscribe(data => {
          this.options = firstTime && savedOptions && this.value ? savedOptions : data;
          if (firstTime && this.value) {
            firstTime = false;
            this.writeValue(this.value);
          }
        });
    } else {
      setTimeout(() => this.writeValue(this.value));
    }
  }

  ngOnDestroy() {
    if (this.options?.length > 0 && (this.formControlName || this.name)) {
      this.selectService.saveOptions(this.formControlName || this.name, this.options);
    }
    this.keyUpChanges.unsubscribe();
  }

  writeValue(value: any) {
    this.value = value;
    this.selectedOption = (this.options || []).find(option => (typeof option.key === 'object' ? JSON.stringify(option.key) === JSON.stringify(this.value) : option.key === this.value));
    this.inputValue = this.parseHTMLToText((this.selectedOption && this.selectedOption.value) || '');
    if (!this.inputValue && this.options) this.value = null;
  }

  registerOnChange(fn: (_: any) => {}) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}) {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void) {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  onInputChange() {
    this.writeValue(this.value);
    this.onChange(this.value);
    if (!this.value) {
      this.keyUpChanges.next(this.value);
    }
  }

  onInputKey(event: KeyboardEvent) {
    const value = (event.target as any).value;
    const dropdownMenuQuery = this.getDropdownMenuQuery();
    if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
      if (!this.dropdown.isOpen()) {
        this.dropdown.open();
      }
      const dropdownItemIndex = Array.from(dropdownMenuQuery).findIndex(item => item.classList.contains('hover'));
      this.removeHoverClass();
      let dropdownItem: HTMLButtonElement;
      if (dropdownItemIndex >= 0) {
        dropdownItem = dropdownMenuQuery.item(dropdownItemIndex + (event.key === 'ArrowDown' ? 1 : -1)) || dropdownMenuQuery[dropdownItemIndex];
      } else {
        dropdownItem = dropdownMenuQuery[event.key === 'ArrowDown' ? 0 : dropdownMenuQuery.length - 1];
      }
      dropdownItem.classList.add('hover');
    } else if (event.key === 'Enter') {
      const dropdownItem = Array.from(dropdownMenuQuery).find(item => item.classList.contains('hover'));
      this.removeHoverClass();
      if (dropdownItem) {
        dropdownItem.click();
        this.dropdown.close();
      }
    } else if (!this.disableSearch) {
      this.removeHoverClass();
      this.inputValue = value;
      if (!this.optionsRequest || (!this.required && !this.inputValue)) {
        this.value = this.inputValue;
      }
      this.keyUpChanges.next(this.inputValue);
    }
  }

  onInputClick() {
    if (!this.dropdown.isOpen() && (this.options || this.optionsRequest)) {
      this.dropdown.open();
    }
  }

  onInputFocus() {
    if (!this.dropdown.isOpen() && (this.options || this.optionsRequest)) {
      this.dropdown.open();
    }
  }

  onInputBlur() {
    this.onTouched();
    // if (!this.selectedOption) {
    //   this.value = '';
    // }
  }

  onSelectOption(option: SelectOption) {
    this.writeValue(option.key);
    this.onChange(option.key);
  }

  getDropdownMenuQuery() {
    return this.dropdownMenuElement.nativeElement.querySelectorAll('.dropdown-item') as NodeListOf<HTMLButtonElement>;
  }

  removeHoverClass() {
    this.getDropdownMenuQuery().forEach(item => item.classList.remove('hover'));
  }

  validate(control: FormControl): ValidationErrors | null {
    return null; // { phoneError: { valid: false } };
  }

  private parseHTMLToText(html: string) {
    const element = document.createElement('span');
    element.innerHTML = html || '';
    return element.innerText;
  }
}
