import { Component, ElementRef, Input, OnChanges, AfterViewInit, SimpleChanges, ViewChild } from '@angular/core';
import { default as lottie } from 'lottie-web';

import { environment } from '@env/environment';

interface LottieOptions {
  container: HTMLElement;
  name: string;
  renderer?: 'svg' | 'canvas' | 'html';
  loop?: boolean;
  autoplay?: boolean;
  autoloadSegments?: boolean;
  path?: string;
  animationData?: any;
  rendererSettings?: {
    id?: string;
    className?: string;
    clearCanvas?: boolean;
    progressiveLoad?: boolean;
    hideOnTransparent?: boolean;
    context?: CanvasRenderingContext2D;
    preserveAspectRatio?: string;
  };
}

@Component({
  selector: 'app-lottie[name]',
  templateUrl: './lottie.component.html',
  styleUrls: ['./lottie.component.scss']
})
export class LottieComponent implements AfterViewInit, OnChanges {
  @Input() name: string | 'document-crash' | 'document-reject' | 'document-sad' | 'document-success' | 'face-happy' | 'face-neutral' | 'face-sad';
  @Input() loop: boolean;
  @Input() autoplay: boolean;
  @Input() width: number;
  @Input() height: number;
  @ViewChild('lottieContainer', { static: false }) lottieContainer: ElementRef;
  animation: any;
  private animationId: string;

  constructor() {}

  ngAfterViewInit() {
    setTimeout(() => this.reloadAnimation());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).find(key => !['width', 'height'].includes(key) && !changes[key].isFirstChange())) {
      setTimeout(() => this.reloadAnimation());
    }
  }

  play() {
    this.animation.play();
  }

  stop() {
    this.animation.stop();
  }

  replay() {
    this.stop();
    this.play();
  }

  reloadAnimation() {
    if (this.name) {
      if (this.animationId) {
        lottie.destroy(this.animationId);
      }
      this.animationId = this.name + '-' + Math.floor(Math.random() * 1000000);

      this.animation = lottie.loadAnimation({
        container: this.lottieContainer.nativeElement,
        name: this.animationId,
        path: '/assets/lottie-animations/' + this.name + '.json',
        renderer: 'svg',
        loop: typeof this.loop === 'boolean' ? this.loop : false,
        autoplay: typeof this.autoplay === 'boolean' ? this.autoplay : true
      } as LottieOptions);
    }
  }
}
