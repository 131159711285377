import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { DeliveryMethodEnum, PositionElementEnum, SecurityVerification, SecurityVerificationEnum, SignatureTypesEnum, Signer } from '@app/models';
import { AppService, DateService, DocumentService, NotyService, UserService } from '@app/services';
import { SignatureStepStatus, SignatureStepType } from './to-signature-steps.pipe';

@Component({
  selector: 'app-signer-card',
  templateUrl: './signer-card.component.html',
  styleUrls: ['./signer-card.component.scss']
})
export class SignerCardComponent implements OnInit {
  readonly SignatureStepStatus = SignatureStepStatus;
  readonly SignatureStepType = SignatureStepType;
  readonly SecurityVerificationEnum = SecurityVerificationEnum;
  readonly DeliveryMethodEnum = DeliveryMethodEnum;
  readonly certificateTypes = {
    [SignatureTypesEnum.A1]: 'A1',
    [SignatureTypesEnum.A3]: 'A3',
    [SignatureTypesEnum.Safeid]: 'A3',
    [SignatureTypesEnum.Vidaas]: 'A3'
  };
  @Input() signer: Signer;
  @Input() sortable = false;
  @Input() forceAutenticat = false;
  @Input() isAllowedUser = false;
  @Input() isSignerTurn = false;
  @Input() documentAuthorId: string;
  @Output() updateClick = new EventEmitter<Signer>();
  @Output() deleteClick = new EventEmitter<Signer>();
  @Output() createLinkClick = new EventEmitter<Signer>();
  @Output() consultCPFClick = new EventEmitter<Signer>();
  @Output() resendSignaturesClick = new EventEmitter<Signer>();
  @Output() certificateClick = new EventEmitter<Signer>();
  @Output() verifyBiometryClick = new EventEmitter<Signer>();

  constructor(public router: Router, public documentService: DocumentService, public userService: UserService, public appService: AppService, public dateService: DateService) {}

  ngOnInit() {}

  cpfMask(cpf: string) {
    const cpfSplit = cpf.split(/-|\./);
    return cpf ? `***.${cpfSplit[1]}.${cpfSplit[2]}-**` : cpf;
  }

  birthdayMask(birthday: string) {
    return birthday
      ? '**/' +
          birthday
            .split('/')
            .splice(1)
            .join('/')
      : birthday;
  }

  isCpf(value: string) {
    value = (value || '').trim().replace(/[^0-9]/g, '');
    return value.length === 11;
  }

  formatCpfCnpj(value: string) {
    value = (value || '').trim().replace(/[^0-9]/g, '');
    if (this.isCpf(value)) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  }

  isSignatureVisible() {
    return (this.signer.positions || []).filter(position => position.element === PositionElementEnum.Signature || position.element === PositionElementEnum.Initials).length > 0;
  }

  isWaitingSortable() {
    return this.sortable && !this.isSignerTurn;
  }

  canShowCompany(signer: Signer) {
    const companyName = signer.user && this.userService.getCompany(signer.user);
    return signer.user && companyName && companyName.toLowerCase().trim() !== 'minha organização';
  }

  copySignatureLink() {
    this.documentService.copySignatureLink(this.signer);
  }

  findVerification(signer: Signer, type?: SecurityVerificationEnum): SecurityVerification {
    return (signer.verifications || []).sort(value => (value.verified_at ? 1 : -1)).find(value => value && (!type || value.type === type)); // Prioriza verified_at undefined
  }

  filterVerifications(signer: Signer, type?: SecurityVerificationEnum[]): SecurityVerification[] {
    return (signer.verifications || []).sort(value => (value.verified_at ? 1 : -1)).filter(value => value && (!type || type.includes(value.type))); // Prioriza verified_at undefined
  }

  hasVerifiableData(signer: Signer) {
    return !!this.filterVerifications(signer, this.documentService.allowedManualVerifications)[0];
  }

  returnDeliveryMethodAddressText(signer: Signer) {
    return [DeliveryMethodEnum.DeliveryMethodWhatsapp, DeliveryMethodEnum.DeliveryMethodSms].includes(signer.delivery_method) ? 'O número de celular' : 'O e-mail';
  }

  returnEmailErrorMessage(message: string, error: string) {
    // remove first matching word + adds bold to first 3 numbers
    const newStr = message.replace(error + ':', '');
    const finalStr = newStr.replace(/(\d)(\d)(\d)/, match => {
      return `<b>${match}</b>`;
    });
    return finalStr;
  }

  hasMailError() {
    if (this.returnEmailEvent() && !this.signer.viewed && !this.signer.email_events.delivered) {
      return true;
    } else return false;
  }

  hasCelphoneError() {
    if (this.signer.email_events?.reason !== null && !this.signer?.viewed && !this.signer.email_events?.delivered) {
      if (this.signer.delivery_method === DeliveryMethodEnum.DeliveryMethodWhatsapp || this.signer.delivery_method === DeliveryMethodEnum.DeliveryMethodSms) {
        return true;
      }
    } else return false;
  }

  returnEmailEvent() {
    const stg = this.signer.email_events?.reason || '';
    if (stg.match(/bounce/i)) {
      return 'bounce';
    }
    if (stg.match(/deferred/i)) {
      return 'envio diferido';
    }
    if (stg.match(/blocked/i)) {
      return 'bloqueado';
    }
    if (stg.match(/dropped/i)) {
      return 'derrubado';
    }
    if (stg.match(/spamreport/i)) {
      return 'spam report';
    }
  }

  getStatusText(signer: Signer): string {
    if (this.documentService.isSignerSigned(signer)) {
      return 'signed';
    } else if (this.documentService.isSignerRejected(signer) && !this.hasMailError() && !this.hasCelphoneError()) {
      return 'rejected';
    } else if (this.documentService.isSignerBiometricRejected(signer)) {
      return 'biometricRejected';
    } else if (this.documentService.isSignerUnapproved(signer)) {
      return 'awaitingVerification';
    } else if (this.hasMailError() || this.hasCelphoneError()) {
      return 'sendingFailed';
    } else if (this.documentService.isSignerPending(signer) && !this.documentService.isSignerUnapproved(signer)) {
      return 'awaiting';
    }
    return '';
  }
}
