import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { kebabCase, merge, remove } from 'lodash';
import { finalize, tap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { untilDestroyed } from '@app/core';
import { User, AlertButtonType } from '@app/models';
import { AppService, ErrorHandlerService, NotyService, TemplateService, UserService } from '@app/services';
import { AlertModalService } from '@app/shared';
import { CreateTemplateVariableModalService } from './create-template-variable-modal/create-template-variable-modal.service';
import { EditTemplateVariableModalService } from './edit-template-variable-modal/edit-template-variable-modal.service';
import { TemplateNameModalService } from './template-name-modal/template-name-modal.service';
import { loadTinyMce } from './tinymce-load';
import { TranslateService } from '@ngx-translate/core';

declare var tinymce: any;

@Component({
  selector: 'app-documents-template',
  templateUrl: './documents-template.component.html',
  styleUrls: ['./documents-template.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class DocumentsTemplateComponent implements OnInit, OnDestroy {
  currentUser: User;

  isUnsaved = false;
  isRendered = false;
  isLoading = false;

  initialTemplateInputText = '';

  dragElement: any = '';

  modalQuery: any = {};
  attributeInputs: any = [];
  templateInput: any = {
    name: '',
    text: ''
  };

  constructor(
    public appService: AppService,
    private notyService: NotyService,
    private errorHandlerService: ErrorHandlerService,
    private templateService: TemplateService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private alertModalService: AlertModalService,
    private createTemplateVariableModalService: CreateTemplateVariableModalService,
    private editTemplateVariableModalService: EditTemplateVariableModalService,
    private templateNameModalService: TemplateNameModalService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));

    this.templateInput.id = this.activatedRoute.snapshot.paramMap.get('id');

    if (this.templateInput.id !== null) {
      const from = history.state.from;
      if (from === 'fill') {
        // Força o recarregamento da página, quando vinda da página de preenchimento de template
        window.location.reload(); // Eu pequei
      }

      this.isLoading = true;
      this.templateService
        .template({ id: this.templateInput.id })
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this.slugifyAttributeInputs();
            this.tinyMCEInitialization();
          })
        )
        .subscribe(
          template => {
            this.attributeInputs = template.attributes;
            this.templateInput = {
              id: template.id,
              name: template.name,
              text: template.text
            };
          },
          error => {
            this.errorHandlerService.handle(error);
            this.router.navigate(['/'], { replaceUrl: true });
          }
        );
    } else {
      this.tinyMCEInitialization();
    }
  }

  ngOnDestroy() {}

  openCreateVariableModal() {
    const existingNames = this.attributeInputs.map((obj: any) => obj.name).filter((name: string) => !!name);
    this.createTemplateVariableModalService.open({ variable: { required: true }, existingNames }).subscribe(variable => this.createVariable(variable));
  }

  openEditVariableModal(query: any) {
    const existingNames = this.attributeInputs
      .map((obj: any) => obj.name)
      .filter(
        (name: string) =>
          !!name &&
          query &&
          (query.name || '')
            .toLowerCase()
            .trim()
            .replace(/\s+/, ' ') !==
            name
              .toLowerCase()
              .trim()
              .replace(/\s+/, ' ')
      );
    this.editTemplateVariableModalService.open({ variable: query, existingNames }).subscribe(variable => this.saveEditVariable(merge(variable, { slug: query.slug })));
  }

  openTemplateNameModal() {
    this.templateNameModalService.open({ template: { name: this.templateInput.name || '' } }).subscribe(template => {
      this.templateInput.name = template.name;
      this.createTemplate();
    });
  }

  openUnsavedAlert() {
    this.alertModalService
      .warning({
        text: this.translateService.instant('alerts.goToBackWithoutSaving'),
        confirmButtonText: this.translateService.instant('button.save'),
        closeButtonText: this.translateService.instant('button.backWithoutSaving'),
        dismissAsError: true
      })
      .subscribe(
        () => this.openTemplateNameModal(),
        type => {
          if (type === AlertButtonType.close) {
            this.router.navigate(['/documentos/novo']);
          }
        }
      );
  }

  slugifyAttributeInputs() {
    this.attributeInputs.map((e: any) => {
      e.slug = kebabCase(e.name);
      e.position = parseInt(e.position);
    });
    this.attributeInputs.sort(this.comparePosition);
  }

  insertVariable(variable: any) {
    const variableDOM = `<span class="variable" data-original-variable="{%${this.parseVariableName(variable.name)}%}" contenteditable="false">${variable.name}</span>`;
    tinymce.activeEditor.execCommand('mceInsertContent', false, variableDOM);
  }

  removeVariable(variable: any) {
    const mceQuery = tinymce.activeEditor.dom;
    const element = mceQuery.select(`[data-original-variable="{%${this.parseVariableName(variable.name)}%}"]`);

    element.forEach((e: any) => {
      mceQuery.setAttrib(e, 'contenteditable', '');
      mceQuery.setAttrib(e, 'data-original-variable', '');
      mceQuery.setAttrib(e, 'class', '');
    });

    remove(this.attributeInputs, (o: any) => {
      return o.name === variable.name;
    });

    this.reevaluatePositions();
  }

  createVariable(value?: { name: string; type: string; position?: number; required?: boolean }) {
    if (value) {
      this.modalQuery = value;
    }
    this.modalQuery.slug = kebabCase(this.modalQuery.name) || this.modalQuery.name;

    if (this.modalQuery.name.length >= 100) {
      this.notyService.error(this.translateService.instant('notyService.invalidVariableNameLength'));
      return false;
    }

    const index = this.attributeInputs.findIndex((obj: any) => obj.slug === this.modalQuery.slug);

    if (index >= 0) {
      return false;
    }

    this.modalQuery.position = this.attributeInputs.length;
    this.attributeInputs.push(this.modalQuery);

    return true;
  }

  saveEditVariable(query: any) {
    this.modalQuery = query;

    const index = this.attributeInputs.findIndex((obj: any) => obj.slug === this.modalQuery.slug);

    const mceQuery = tinymce.activeEditor.dom;
    const element = mceQuery.select(`[data-original-variable="{%${this.parseVariableName(this.attributeInputs[index].name)}%}"]`);

    this.attributeInputs[index] = this.modalQuery;

    element.forEach((e: any) => {
      mceQuery.setAttrib(e, 'data-original-variable', `{%${this.parseVariableName(this.attributeInputs[index].name)}%}`);
      mceQuery.setHTML(e, this.attributeInputs[index].name);
    });

    this.modalQuery = {};
  }

  templateFocus() {
    if (!tinymce.activeEditor) return;
    tinymce.activeEditor.focus();
  }

  createTemplate() {
    const tempDataInputs = this.attributeInputs;
    tempDataInputs.map((e: any, i: number) => {
      e.position = i;
      delete e.slug;
      delete e.__typename;
      delete e.value;
    });

    this.templateInput.text = tinymce.activeEditor.getContent();
    if (!this.templateInput.text) {
      this.notyService.error(this.translateService.instant('notyService.emptyModelContent'));
    } else if (!this.templateInput.id) {
      delete this.templateInput.id;

      this.isLoading = true;
      this.templateService
        .create({ template: this.templateInput, attributes: tempDataInputs })
        .pipe(
          tap(() => (this.isUnsaved = false)),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(
          () => this.router.navigate(['/documentos/novo']),
          error => this.errorHandlerService.handle(error)
        );
    } else {
      this.isLoading = true;
      this.templateService
        .update({ template: this.templateInput, attributes: tempDataInputs })
        .pipe(
          tap(() => (this.isUnsaved = false)),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(
          () => this.router.navigate(['/documentos/novo']),
          error => this.errorHandlerService.handle(error)
        );
    }
  }

  setCurrentDraggableElement(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragElement = evt.target;
  }

  dragstart_handler(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  dragleave_handler(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();

    this.insertBetweenPosition(this.dragElement, evt.target.closest('.form__field'));
  }

  insertBetweenPosition(el1: any, el2: any) {
    const pos1 = el1.dataset.position;
    const pos2 = el2.dataset.position;

    // const index1 = this.attributeInputs.findIndex((obj: any) => obj.position === pos1);

    this.attributeInputs[pos1].position = parseInt(pos2) + 0.5;

    this.attributeInputs.sort(this.comparePosition);
    this.reevaluatePositions();
  }

  reevaluatePositions() {
    this.attributeInputs.map((e: any, i: number) => {
      this.attributeInputs[i].position = i;
    });
  }

  comparePosition(a: any, b: any) {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }

  sortByKey(array: any, key: any) {
    return array.sort((a: any, b: any) => {
      const x = a[key];
      const y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  tinyMCEInitialization() {
    // tinymce.activeEditor.execCommand('mceInsertContent', false, this.templateInput.text)

    const that = this;
    this.isRendered = true;

    loadTinyMce().subscribe(() => {
      tinymce.remove();

      tinymce.init({
        init_instance_callback: (editor: any) => {
          editor.fire('focus');
          editor.setContent(that.templateInput.text);
          that.initialTemplateInputText = that.templateInput.text;
        },
        setup: (editor: any) => {
          editor.on('Change', () => {
            setTimeout(() => {
              if (that.initialTemplateInputText === tinymce.activeEditor.getContent()) {
                this.isUnsaved = false;
                console.log('is saved');
              } else {
                this.isUnsaved = true;
                console.log('is unsaved');
              }
            });
          });

          editor.ui.registry.addButton('variable', {
            text: `<i style='font-family:"Font Awesome 6 Pro"' class="fal fa-brackets-curly"></i> Criar campo`,
            tooltip: 'Inserir variável',
            onAction: () => {
              const selectedValue = editor.selection.getContent({ format: 'text' }).trim();
              const existingVariable = this.attributeInputs.find((variable: any) => variable.name.toLowerCase().trim() === selectedValue.toLowerCase());

              if (existingVariable) {
                editor.selection.setContent('');
                that.insertVariable(existingVariable);
              } else if (that.createVariable({ name: selectedValue, position: that.attributeInputs.length, type: 'text', required: false })) {
                editor.selection.setContent(`<span class="variable" data-original-variable="{%${this.parseVariableName(selectedValue)}%}" contenteditable="false">${selectedValue}</span>`);
              }
            }
          });
        },
        branding: false,
        elementpath: false,
        inline: true,
        autofocus: true,
        language: 'pt_BR',
        fixed_toolbar_container: '.template__header-toolbox',
        custom_ui_selector: '#tinymce-selection-toolbar',
        quickbars_selection_toolbar: 'variable',
        quickbars_image_toolbar: false,
        quickbars_insert_toolbar: false,
        placeholder: 'Digite aqui ...',
        resize: 'both',
        selector: '#tinymce',
        event_root: '#tinymce__root',
        min_height: 500,
        relative_urls: true,
        document_base_url: `${environment.panelUrl}/vendors/tinymce/`,
        base_url: `${environment.panelUrl}/vendors/tinymce/`,
        content_css: `${environment.panelUrl}/assets/tinymce-custom.css`,
        content_style: `.variable {
          background: #FDFF32;
          outline: 0!important;
          cursor: default;
        }
        `,
        suffix: '.min',
        menubar: 'edit view insert format',
        paste_data_images: true,
        plugins: ['print', 'searchreplace visualblocks', 'fullscreen table paste quickbars code lists'],
        toolbar: 'fontselect | fontsizeselect | bold italic underline strikethrough | numlist bullist | alignleft aligncenter alignright alignjustify | table | outdent indent | code',
        font_formats:
          'Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva',
        fontsize_formats: '11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt'
      });
    });
  }

  private parseVariableName(name: string) {
    return (name || '').replace(/\"/g, "'");
  }
}
