<div class="signature forceDefaultColors" [class.initials]="type === PositionElementEnum.Initials" *ngIf="user">
  <div
    class="signature__handwriting {{ (font || '').toLowerCase() }}"
    *ngIf="
      format === SignatureFormat.Handwriting ||
      (type === PositionElementEnum.Initials && (format === SignatureFormat.Eletronic || (format === SignatureFormat.Image && !imageUrl) || (format === SignatureFormat.Draw && !drawingUrl)))
    "
  >
    {{ type === PositionElementEnum.Initials ? (user.name | initialsName) : (user.name | signatureName) }}
  </div>
  <div class="signature__eletronic" *ngIf="type !== PositionElementEnum.Initials && format === SignatureFormat.Eletronic">{{ 'signature.electronicallySigned' | translate }}</div>
  <div class="signature__img" *ngIf="format === SignatureFormat.Image && imageUrl" [ngStyle]="{ backgroundImage: 'url(' + imageUrl + ')' }"></div>
  <div class="signature__img" *ngIf="format === SignatureFormat.Draw && drawingUrl" [ngStyle]="{ backgroundImage: 'url(' + drawingUrl + ')' }"></div>

  <div class="signature__line" *ngIf="type !== PositionElementEnum.Initials"></div>

  <div class="signature__name" *ngIf="type !== PositionElementEnum.Initials">
    <strong>{{ user?.name }}</strong>
  </div>
  <div class="signature__extra" *ngIf="type !== PositionElementEnum.Initials && user && user.company">{{ user.company }}</div>
  <div class="signature__extra" *ngIf="type !== PositionElementEnum.Initials && user && !user.company">{{ user.cpf }}</div>
  <div class="signature__role" *ngIf="type !== PositionElementEnum.Initials">{{ 'signature.signer' | translate }}</div>

  <div class="signature__title" *ngIf="type !== PositionElementEnum.Initials">
    <div class="fal fa-signature"></div>
    {{ 'signature.signature' | translate }}
  </div>
  <div class="signature__title" *ngIf="type === PositionElementEnum.Initials">
    <div class="fal fa-spell-check"></div>
    {{ 'signature.initials' | translate }}
  </div>
</div>
