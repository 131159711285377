import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { DndDropEvent } from 'ngx-drag-drop';
import { cloneDeep, deburr } from 'lodash';

import { environment } from '@env/environment';
import { I18nService, Logger, untilDestroyed } from '@app/core';
import { AppService, DocumentService, ErrorHandlerService, NotyService, TemplateService, UserService, WhitelabelService } from '@app/services';
import { GooglePickerService } from '@app/services/google-picker.service';
import {
  CustomSignerInput,
  DocumentInput,
  FloatingField,
  OrderByEnum,
  PositionElementEnum,
  ReminderEnum,
  SecurityVerificationEnum,
  SignerMainAttributes,
  SlimTemplate,
  User,
  Whitelabel
} from '@app/models';
import { AlertModalService, LoaderService, PdfViewerComponent, SelectOption, SetMissingUserInfoModalService } from '@app/shared';
import { ValidEmailDirective, ValidNameDirective } from '@app/shared/validators';
import { DuplicateTemplateModalService } from './duplicate-template-modal/duplicate-template-modal.service';
import { UniqueSignersPipe } from '../shared/unique-signers.pipe';
import { DocumentFormService } from '../shared/document-form.service';
import { DocumentConfigsFormComponent } from '../shared/document-configs-form/document-configs-form.component';
import { AlertDocumentsLimitModalService } from '../shared/alert-documents-limit-modal/alert-documents-limit-modal.service';
import { AlertCreditsLimitModalService } from '../shared/alert-credits-limit-modal/alert-credits-limit-modal.service';
import { idColors } from '../shared/floating-fields-id-colors';

const log = new Logger('DocumentsNewComponent');

enum FormSteps {
  signers,
  mobileFile,
  signaturePositions,
  extraConfigurations
}

@Component({
  selector: 'app-documents-new',
  templateUrl: './documents-new.component.html',
  styleUrls: ['./documents-new.component.scss']
})
export class DocumentsNewComponent implements OnInit, AfterViewInit, OnDestroy {
  document = { name: '', expiration: {}, configs: {}, locale: {} } as DocumentInput;
  signers: CustomSignerInput[] = [];
  path: string;
  file: File;
  mergeFiles: { file: File; path: string }[] = [];
  isMergeFiles = false;
  currentUser: User;
  formStep = FormSteps.signers;
  isLoading = false;
  isUploadingFile = false;
  isSettingFloatingFields = false;
  templates: SlimTemplate[];
  templatesPageNumber: number;
  isTemplatesLastPage = false;
  validationErrors: { [k: string]: any } = {};
  isQualified = false;
  isMobile: boolean;
  isDocumentWhitelabelLoaded: boolean = false;
  countriesFiltered: SelectOption[] = [];
  searchLocaleInputText = '';
  backLink = '/documentos/todos';
  showAddMeToList = true;
  readonly isElectron = environment.electron;
  readonly FormSteps = FormSteps;
  readonly ReminderEnum = ReminderEnum;
  readonly PositionElementEnum = PositionElementEnum;
  readonly SecurityVerificationEnum = SecurityVerificationEnum;
  readonly idColors = idColors;
  readonly countries: SelectOption[] = [];
  readonly languages: SelectOption[] = [];
  readonly acceptedFileMimeTypes = [
    'application/pdf',
    'text/rtf',
    'text/html',
    'text/plain',
    'application/msword',
    'application/excel',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.google-apps.document',
    'application/vnd.google-apps.presentation',
    'application/vnd.google-apps.spreadsheet'
  ];
  @ViewChild('pdfViewer', { static: false }) pdfViewerComponent!: PdfViewerComponent;
  @ViewChild('configsForm', { static: false }) private configsFormComponent!: DocumentConfigsFormComponent;
  @ViewChild('documentUploadWrapper', { static: false }) private documentUploadWrapper: ElementRef<HTMLElement>;

  documentWhitelabel: Whitelabel = {
    domain: null,
    sendgrid_domain: null,
    sendgrid_email_user: null,
    is_darkmode: false,
    main_color: '#1768e1',
    logo: '/assets/Autentique.svg',
    pdf_logo: '/assets/autentique-logo.svg',
    pdf_logo_mini: '/assets/autentique-logo.svg'
  };

  private lastFileName: string;
  private navigationState: { [k: string]: any };

  constructor(
    public documentService: DocumentService,
    public appService: AppService,
    public translateService: TranslateService,
    private userService: UserService,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private loaderService: LoaderService,
    private templateService: TemplateService,
    private whitelabelService: WhitelabelService,
    private alertModalService: AlertModalService,
    private googlePickerService: GooglePickerService,
    private router: Router,
    private documentFormService: DocumentFormService,
    private uniqueSigners: UniqueSignersPipe,
    private duplicateTemplateModalService: DuplicateTemplateModalService,
    private setMissingUserInfoModalService: SetMissingUserInfoModalService,
    private alertDocumentsLimitModal: AlertDocumentsLimitModalService,
    private alertCreditsLimitModal: AlertCreditsLimitModalService,
    private i18nService: I18nService
  ) {
    this.navigationState = router.getCurrentNavigation().extras.state || {};
    this.languages = this.i18nService.supportedLanguagesAsSelectOptions || null;
    this.countries = this.i18nService.countriesWithCodes || null;
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.updateBackLink();
    });
  }

  ngOnInit() {
    this.isMobile = window.innerWidth <= 992;
    this.countriesFiltered = this.countries;

    this.userService
      .watchCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe(user => (this.currentUser = user));

    this.userService.getCurrentUser().subscribe(user => {
      if (user && !user.currentPermissions.create_documents) {
        this.userService.permissionCheck(user.currentPermissions.create_documents);
      } else if (user && (!user.email || !user.email_verified_at)) {
        this.setMissingUserInfoModalService.openIfMissingUserInfo({ ignoreCpf: true, dismissAsError: true }).subscribe(
          () => {},
          () => this.router.navigate(['/'])
        );
      } else if (user && this.navigationState.document && this.navigationState.signers) {
        this.document = this.navigationState.document;
        this.isQualified = this.document?.qualified;
        this.signers = this.navigationState.signers;
        this.formStep = this.isMobile ? FormSteps.mobileFile : FormSteps.signers;
        if (this.navigationState.file) {
          setTimeout(() => this.selectFile(this.navigationState.file, false));
        }
      } else if (user) {
        this.document.qualified = this.appService.documentParams.qualified || false;
        this.document.sortable = this.document.qualified || this.appService.documentParams.sortable || false;
        this.document.refusable = this.appService.documentParams.refusable || false;
        this.document.footer = this.appService.documentParams.footer || null;
        this.document.stop_on_rejected = typeof this.appService.documentParams.stop_on_rejected === 'boolean' ? this.appService.documentParams.stop_on_rejected : true;
        this.document.new_signature_style = this.appService.documentParams.new_signature_style || false;
        this.document.show_audit_page = this.appService.documentParams.show_audit_page !== false;
        this.document.locale.country = this.appService.documentParams.locale?.country || user.locale?.country;
        this.document.locale.language = this.appService.documentParams.locale?.language || user.locale?.language;
        this.changeDocumentConfigCountry(this.document.locale.country); // set document configs based on country
        // premium features:
        this.document.reminder = user.subscription.has_premium_features ? this.appService.documentParams.reminder : null;
        this.document.expiration = user.subscription.has_premium_features ? cloneDeep(this.appService.documentParams.expiration) : null;
        this.document.configs = user.subscription.has_premium_features ? cloneDeep(this.appService.documentParams.configs) : null;
        this.document.scrolling_required = user.subscription.has_premium_features ? this.appService.documentParams.scrolling_required : false;
        if (user.subscription.has_premium_features) {
          this.document.cc = (this.appService.lockedCC || '').split(/,|\s/).map(email => ({ email: email.trim() }));
          this.document.reply_to = this.appService.lockedReplyTo || '';
        }
        this.appService.lockedSigners.map(signer => {
          // prevenir erros com signers ja salvos
          const signerClone = cloneDeep(signer);
          if (!user.subscription.has_premium_features) {
            signerClone._extra.showSmsVerification = false;
            signerClone._extra.showCpfVerification = false;
          }
          if (!user.subscription.has_premium_features || (!signerClone._extra?.documentType && !signerClone._extra?.phoneNumber)) {
            signerClone._extra.documentType = SecurityVerificationEnum.Upload;
            signerClone._extra.phoneNumber = '';
          }
          if (this.document?.locale?.country !== 'BR' && signerClone._extra.documentType === SecurityVerificationEnum.PfFacial) {
            signerClone._extra.showSecurityVerifications = false;
            signerClone._extra.documentType = SecurityVerificationEnum.Manual;
          }
          this.signers.push(signerClone);
        });
      }

      if (this.isWhitelabel()) {
        this.whitelabelService.loadCurrentWhitelabel().subscribe(whitelabel => {
          this.documentWhitelabel = whitelabel;
          this.documentWhitelabel.logo = whitelabel.logo || this.whitelabelService.logo;
          this.documentWhitelabel.pdf_logo = whitelabel.pdf_logo || this.whitelabelService.pdfLogo;
          this.documentWhitelabel.pdf_logo_mini = whitelabel.pdf_logo_mini || this.whitelabelService.pdfLogoMini;
          this.isDocumentWhitelabelLoaded = true;
        });
      } else {
        this.documentWhitelabel.logo = this.whitelabelService.logo;
        this.documentWhitelabel.pdf_logo = this.whitelabelService.pdfLogo;
        this.documentWhitelabel.pdf_logo_mini = this.whitelabelService.pdfLogoMini;
        this.isDocumentWhitelabelLoaded = true;
      }

      setTimeout(() => this.onSignerChange(this.signers[this.signers.length - 1]));
    });

    this.loadTemplates();
  }

  ngAfterViewInit() {
    this.documentUploadWrapper.nativeElement.addEventListener('dragover', event => event.preventDefault(), false);
    this.documentUploadWrapper.nativeElement.addEventListener(
      'drop',
      event => {
        event.stopPropagation();
        event.preventDefault();
        const files: FileList = event.dataTransfer.files;

        if (files) {
          if (files[0]) {
            this.removeFile();
            this.addMultipleFiles(files, false);
          } else {
            this.notyService.error(this.translateService.instant('notyService.fileNotAdded'));
          }
        } else {
          this.notyService.error(this.translateService.instant('notyService.invalidFileType'));
        }
      },
      false
    );
  }

  ngOnDestroy() {
    this.resetWhitelabelInfo();
  }

  setFormStep(step: FormSteps) {
    this.signers = this.uniqueSigners.transform(this.signers || [], this.document.qualified);
    this.pdfViewerComponent.removeFloatingFieldsWhenQualifiedDocument();
    this.addNewSigner();

    if (step === FormSteps.mobileFile || step === FormSteps.signaturePositions) {
      if (this.uniqueSigners.transform(this.signers, this.document.qualified).length === 0) {
        this.notyService.error(this.translateService.instant('notyService.noSignatoriesAdded'));
      } else if (step === FormSteps.signaturePositions && !this.file) {
        if (this.isMergeFiles) {
          this.submitMergeFiles();
          this.formStep = step;
        } else {
          this.notyService.error(this.translateService.instant('notyService.noFileSelected'));
        }
      } else {
        this.formStep = step;
        setTimeout(() => window.scrollTo(0, 0));
      }
    } else if (step === FormSteps.extraConfigurations) {
      this.formStep = step;
      // Se o navigationState tiver folderId e folderName sera o FormComponent com o folder selecionado
      if (this.navigationState?.folder) {
        this.configsFormComponent.folder = this.navigationState.folder;
      }
    } else {
      this.formStep = step;
    }
  }

  submitDocument() {
    if (!this.file && this.isMergeFiles) {
      this.notyService.error(this.translateService.instant('notyService.filesNotMerged'));
    } else if (!this.file) {
      this.notyService.error(this.translateService.instant('notyService.noFileSelected'));
    } else if (this.uniqueSigners.transform(this.signers, this.document.qualified).length === 0) {
      this.notyService.error(this.translateService.instant('notyService.noSignatoriesAdded'));
    } else if (!this.validateDocument(this.document, this.signers)) {
      this.formStep = FormSteps.signers;
    } else if (!this.path) {
      this.setMissingUserInfoModalService
        .openIfMissingUserInfo({ ignoreCpf: true })
        .pipe(
          tap(() => this.startUploadFlags()),
          switchMap(() => this.uploadFile(this.file)),
          tap(
            () => {},
            error => this.errorHandlerService.handle(error)
          ),
          tap(() => (this.isLoading = true)),
          switchMap(() => this.createDocument()),
          finalize(() => this.finalizeUploadFlags())
        )
        .subscribe(data => {
          this.router.navigate(['/documentos/', data.id], { state: { fromCreateDocument: true } });
          this.sendDocumentIdWithPostMessage(data.id);
        });
    } else {
      this.setMissingUserInfoModalService
        .openIfMissingUserInfo({ ignoreCpf: true })
        .pipe(
          tap(() => (this.isLoading = true)),
          switchMap(() => this.createDocument()),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(data => {
          this.router.navigate(['/documentos/', data.id], { state: { fromCreateDocument: true } });
          this.sendDocumentIdWithPostMessage(data.id);
        });
    }
  }

  sendDocumentIdWithPostMessage(documentId: string) {
    if (!documentId.length) return;
    window.parent.postMessage(documentId, '*');
  }

  createDocument() {
    const document = this.configsFormComponent.parsedDocumentInput();
    const signers = this.documentFormService.transformToSignerInputs(this.document, this.signers, this.pdfViewerComponent.getFloatingFields());
    const folderId = this.configsFormComponent.folder?.id;
    if (!this.currentUser.subscription.has_premium_features) {
      signers.forEach(signer => delete signer.configs);
    } else {
      this.appService.lockedReplyTo = !!this.appService.lockedReplyTo ? document.reply_to : '';
    }
    if (!document.qualified) {
      this.appService.documentParams = document;
    }

    return this.documentService.create({ document, signers, path: this.path, folderId }).pipe(
      tap(
        () => this.userService.currentUserCredits().subscribe(),
        error => {
          this.addNewSigner(); //
          this.onSignerChange(this.signers[this.signers.length - 1]); // Moves empty signer to end so it doesn't mess de validationErrors' positions

          const handledError = this.errorHandlerService.handle(error, { ignoreGraphqlErrorNotifications: true, validationMode: true, ignoreNotificationOn: ['unavailable_verifications_credits'] });
          this.validationErrors = handledError.validation || {};
          if (Object.keys(this.validationErrors).length > 0) {
            if (this.validationErrors.signers) {
              this.validationErrors.signers.forEach((currElement: any, index: any) => {
                if (currElement.configs && currElement.configs.cpf) {
                  this.signers[index]._extra.showOptions = true;
                  this.signers[index]._extra.showCpfVerification = true;
                }
              });
              this.formStep = FormSteps.signers;
            } else {
              this.formStep = FormSteps.extraConfigurations;
            }
          } else if (handledError.message === 'could_not_upload_file') {
            this.removeFile();
          } else if (handledError.message === 'unavailable_verifications_credits') {
            this.alertCreditsLimitModal.open({ currentUser: this.currentUser }).subscribe();
          } else {
            this.formStep = FormSteps.signers;
            this.notyService.error(this.translateService.instant('error_unknown'));
          }
        }
      ),
      untilDestroyed(this)
    );
  }

  uploadFile(file: File) {
    this.loaderService.disableHttpLoader = true;
    return this.documentService.uploadFile({ file }).pipe(
      tap(data => (this.path = data.path)),
      finalize(() => (this.loaderService.disableHttpLoader = false)),
      untilDestroyed(this)
    );
  }

  selectFile(file: File, isFileMerge: boolean) {
    this.file = file;
    if (this.file) {
      const fileSizeMB = this.file.size / 1024 / 1024;
      const userHasPaidPlan = !this.userService.isSubscriptionFree(this.currentUser);
      if (!this.acceptedFileMimeTypes.includes(this.file.type)) {
        this.notyService.error(this.translateService.instant('notyService.invalidFileTypeSent'), 15000);
        this.removeFile();
      } else if (fileSizeMB > 20 || (!userHasPaidPlan && fileSizeMB > 5)) {
        this.notyService.error(this.translateService.instant('notyService.' + (userHasPaidPlan ? 'fileSizeExceedCorporatePlan' : 'fileSizeExceedFreePlan')));
        this.removeFile();
      } else {
        if (!isFileMerge) {
          this.setDocumentNameFromFile(this.file);
        }
        this.startUploadFlags();
        this.uploadFile(this.file)
          .pipe(finalize(() => this.finalizeUploadFlags()))
          .subscribe(
            data => {
              const mergeFile = {
                file,
                path: data.path
              };
              this.mergeFiles.push(mergeFile);
              if (isFileMerge) {
                this.isMergeFile();
              } else {
                this.setPdfViewer({ url: this.appService.storagePath(data.path) }).subscribe(
                  () => (this.formStep = this.formStep === FormSteps.mobileFile ? FormSteps.signaturePositions : this.formStep)
                );
              }
            },
            error => {
              if (error?.graphQLErrors && error?.graphQLErrors[0]?.message === 'unavailable_credits') this.alertDocumentsLimitModal.open({ currentUser: this.currentUser }).subscribe();
              else this.errorHandlerService.handle(error);
              this.removeFile();
            }
          );
      }
    }
  }

  addMultipleFiles(files: FileList, isFileMerge: boolean) {
    const initialMergeFileLength = this.mergeFiles.length;
    const filesLength = files.length;
    if (files) {
      this.file = files[0];
      if (!isFileMerge) {
        this.setDocumentNameFromFile(this.file);
      }

      if (files.length === 1) {
        this.selectFile(files[0], isFileMerge);
      } else {
        let blockAlertDocumentsLimitModal = false;
        this.startUploadFlags();
        Array.from(files).forEach(file => {
          this.uploadFile(file)
            .pipe(finalize(() => this.finalizeUploadFlags()))
            .subscribe(
              data => {
                const mergeFile = {
                  file,
                  path: data.path
                };
                this.mergeFiles.push(mergeFile);
                if (this.mergeFiles.length - initialMergeFileLength === filesLength) {
                  this.isMergeFile();
                }
              },
              error => {
                if (blockAlertDocumentsLimitModal) return;
                if (error?.graphQLErrors && error?.graphQLErrors[0]?.message === 'unavailable_credits') {
                  this.alertDocumentsLimitModal.open({ currentUser: this.currentUser }).subscribe();
                } else {
                  this.errorHandlerService.handle(error);
                }
                this.removeFile();
                blockAlertDocumentsLimitModal = true;
              }
            );
        });
      }
    }
  }

  loadTemplates(options: { page?: number } = {}) {
    this.isLoading = true;
    this.templatesPageNumber = options.page || 1;
    this.templateService
      .allTemplates({ limit: 20, page: this.templatesPageNumber, orderBy: { field: 'created_at', direction: OrderByEnum.Desc } })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        page => {
          this.templates = (this.templatesPageNumber === 1 ? [] : this.templates || []).concat(page.data);
          this.isTemplatesLastPage = page.current_page >= page.last_page;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  openDuplicateTemplateModal(template: SlimTemplate) {
    this.duplicateTemplateModalService.open({ template }).subscribe(newTemplate => this.templates.unshift(newTemplate));
  }

  deleteTemplate(template: SlimTemplate) {
    this.alertModalService
      .warning({
        text: this.translateService.instant('alerts.deleteTemplate'),
        confirmButtonText: this.translateService.instant('button.exclude')
      })
      .subscribe(() => {
        this.isLoading = true;
        this.templateService
          .delete({ id: template.id })
          .pipe(finalize(() => (this.isLoading = false)))
          .subscribe(
            () =>
              this.templates.splice(
                this.templates.findIndex(item => item.id === template.id),
                1
              ),
            error => this.errorHandlerService.handle(error)
          );
      });
  }

  openGooglePicker(isFileMerge: boolean) {
    this.googlePickerService.open().subscribe(
      googleFile => {
        const fileName = googleFile.data.type !== 'application/pdf' ? googleFile.name || '' : (googleFile.name || '').replace(/\.[A-z0-9]+$/, '') + '.pdf';
        const file = new File([googleFile.data], fileName, { type: googleFile.data.type, lastModified: googleFile.lastModified });
        this.selectFile(file, isFileMerge);
      },
      () => {}
    );
  }

  addNewSigner(email?: string, invertOrder?: boolean) {
    const mainAttribute = SignerMainAttributes.Email;
    this.signers[invertOrder ? 'unshift' : 'push']({
      [mainAttribute]: email || '',
      action: this.documentService.roles[0],
      configs: {},
      _extra: { signerMainAttribute: mainAttribute, phoneNumber: '', documentType: SecurityVerificationEnum.Manual },
      security_verifications: []
    });
  }

  setFloatingFieldFor(signer: CustomSignerInput, element: PositionElementEnum, idColor: string) {
    this.isSettingFloatingFields = true;
    this.pdfViewerComponent
      .floatingFieldsSettingModeFor({ element, idColor, identification: this.uniqueSigners.identification(signer) })
      .pipe(finalize(() => (this.isSettingFloatingFields = false)))
      .subscribe();
  }

  cancelSettingFloatingFields() {
    this.pdfViewerComponent.disableFloatingFieldsSettingMode();
    this.isSettingFloatingFields = false;
  }

  onSignerChange(signer: CustomSignerInput) {
    this.validationErrors = {};

    const signerFieldsLength = this.signers.length;
    const usedSignerFieldsLength = this.signers.filter(thisSigner => !!thisSigner[thisSigner._extra.signerMainAttribute]).length;
    const currentSignerIndex = this.signers.findIndex(thisSigner => thisSigner === signer);

    if (signerFieldsLength === usedSignerFieldsLength) {
      this.addNewSigner();
    } else if (signerFieldsLength > usedSignerFieldsLength + 1) {
      this.signers
        .map((thisSigner, index) => (!thisSigner[thisSigner._extra.signerMainAttribute] ? index : -1))
        .filter(index => index >= 0 && index !== currentSignerIndex)
        .forEach(index => this.signers.splice(index, 1));
    }

    if (signer) {
      setTimeout(() => this.storeLockedSigners());
    }
  }

  storeLockedSigners() {
    this.appService.lockedSigners = this.signers.filter(signer => signer._extra.isLocked && signer[signer._extra.signerMainAttribute]);
  }

  removeFile(step?: FormSteps) {
    this.file = undefined;
    this.path = undefined;
    this.mergeFiles = [];
    this.isMergeFiles = false;
    this.formStep = step || FormSteps.signers;
    this.signers.forEach(signer => delete signer.positions);
    this.pdfViewerComponent.reset();
    this.pdfViewerComponent.clearFloatingFields();
    setTimeout(() => window.scrollTo(0, 0));
  }

  isMergeFile() {
    this.file = undefined;
    this.path = undefined;
    this.signers.forEach(signer => delete signer.positions);
    this.pdfViewerComponent.reset();
    this.pdfViewerComponent.clearFloatingFields();
    this.isMergeFiles = true;
  }

  isMergeFileMobile(step?: FormSteps) {
    this.formStep = step || FormSteps.signers;
    this.isMergeFile();
  }

  deleteMergeFile(index: number) {
    this.mergeFiles.splice(index, 1);
    if (this.mergeFiles.length === 0) {
      this.isMergeFiles = false;
      this.removeFile();
    }
  }

  submitMergeFiles() {
    this.startUploadFlags();
    this.file = this.mergeFiles[0].file;
    const mergeFilesPath = this.mergeFiles.map(file => file.path);
    this.appService
      .waitUntilUrlsExist(mergeFilesPath.map(path => this.appService.storagePath(path)))
      .pipe(
        switchMap(() => (mergeFilesPath.length > 1 ? this.documentService.mergeFiles({ files: mergeFilesPath }) : of(this.mergeFiles[0]))),
        tap(data => (this.path = data.path)),
        switchMap(data => this.setPdfViewer({ url: this.appService.storagePath(data.path) })),
        finalize(() => this.finalizeUploadFlags())
      )
      .subscribe(
        () => (this.formStep = this.formStep === FormSteps.mobileFile ? FormSteps.signaturePositions : this.formStep),
        error => this.errorHandlerService.handle(error)
      );
  }

  onDragAndDropSigner(event: DndDropEvent) {
    this.signers.splice(event.index, 0, this.signers.splice(event.data, 1)[0]);
  }

  onDragAndDropMergeFile(event: DndDropEvent) {
    this.mergeFiles.splice(event.index, 0, this.mergeFiles.splice(event.data, 1)[0]);
  }

  disableFloatingFieldsSettingMode() {
    if (this.isSettingFloatingFields) {
      this.isSettingFloatingFields = false;
      this.pdfViewerComponent.disableFloatingFieldsSettingMode();
    }
  }

  selectFloatingFieldInput(field: FloatingField) {
    this.signers.forEach(signer => (signer._extra.isSelected = field && this.uniqueSigners.identification(signer) === field.identification));
  }

  fillTemplate(id: string) {
    this.router.navigate(['/modelos', id], { state: { document: this.document, signers: this.signers, folder: this.navigationState?.folder } });
  }

  checkPremiumFeatures(event: MouseEvent) {
    if (!this.currentUser || !this.currentUser.subscription) {
      event.preventDefault();
    } else if (!this.currentUser.subscription.has_premium_features) {
      event.preventDefault();
      this.notyService.error(this.translateService.instant('notyService.notAvailableFreePlan'));
    }
  }

  setQualifiedSignature(value: boolean) {
    this.isQualified = value;
  }

  isMergeFilesTooLarge() {
    if (this.currentUser) {
      const maxKB = this.currentUser.subscription.has_premium_features ? 20000 : 5000;
      return (this.mergeFiles || []).reduce((total, file) => total + file.file.size, 0) / 1024 > maxKB;
    }
  }

  changeLocaleInput(type: 'language' | 'country', value: string): void {
    if (type === 'language') {
      this.document.locale.language = value;
    } else if (type === 'country') {
      this.document.locale.country = value;
      this.changeDocumentConfigCountry(this.document.locale.country);
    }
  }

  changeDocumentConfigCountry(value: string): void {
    if (value !== 'BR') {
      this.document.new_signature_style = true;
      this.document.ignore_cpf = true;

      this.signers.forEach(signer => {
        signer._extra.showSecurityVerifications = false;
        signer._extra.showSmsVerification = false;
        signer._extra.documentType = SecurityVerificationEnum.Manual;

        if (signer._extra.signerMainAttribute === SignerMainAttributes.Sms) {
          signer._extra.signerMainAttribute = SignerMainAttributes.Email;
        }
      });
    }
  }

  filterDropdown(e: any) {
    window.scrollTo(window.scrollX, window.scrollY + 1);
    const searchString = e.toLowerCase();
    if (!searchString) {
      this.countriesFiltered = this.countries.slice();
      return;
    } else {
      this.countriesFiltered = this.countries.filter(country => deburr(country.value.toLowerCase()).indexOf(deburr(searchString.toLowerCase())) > -1);
    }
    window.scrollTo(window.scrollX, window.scrollY - 1);
  }

  resetWhitelabelInfo() {
    this.documentWhitelabel = {
      domain: null,
      sendgrid_domain: null,
      sendgrid_email_user: null,
      is_darkmode: false,
      main_color: '#1768e1',
      logo: '/assets/Autentique.svg',
      pdf_logo: '/assets/autentique-logo.svg',
      pdf_logo_mini: '/assets/autentique-logo.svg'
    };
  }

  isWhitelabel() {
    // return true;
    return !this.appService.isAutentiqueDomain() && !!this.whitelabelService?.whitelabel?.domain;
  }

  updateBackLink(): void {
    const url = this.appService.previousUrl;
    if ((url || '').includes('/pastas/')) {
      this.backLink = url;
    } else {
      this.backLink = '/documentos/todos';
    }
  }

  private setPdfViewer(options: { url?: string; file?: File }) {
    return this.pdfViewerComponent.setPdf(options).pipe(
      tap(
        () => {},
        error => {
          this.notyService.error(this.translateService.instant('notyService.fileConversionError'), false);
          log.error('Error converting file', error);
          this.removeFile();
        }
      )
    );
  }

  private setDocumentNameFromFile(file: File) {
    const fileName = (file.name || '').replace(/\.[A-z0-9]+$/, '');
    if (fileName && (!this.document.name || this.document.name === this.lastFileName)) {
      this.lastFileName = fileName;
      this.document.name = this.lastFileName;
    }
  }

  private startUploadFlags() {
    this.isLoading = true;
    this.isUploadingFile = true;
  }

  private finalizeUploadFlags() {
    this.isLoading = false;
    this.isUploadingFile = false;
  }

  private validateDocument(document: DocumentInput, signers: CustomSignerInput[]): boolean {
    this.validationErrors = {};
    signers.forEach((signer, index) => {
      if (signer._extra.signerMainAttribute === SignerMainAttributes.Name && signer.name && !ValidNameDirective.isNameValid(signer.name)) {
        this.validationErrors.signers = this.validationErrors.signers || [];
        this.validationErrors.signers[index] = 'error_invalid_name';
      } else if (signer._extra.signerMainAttribute === SignerMainAttributes.Email && signer.email && !ValidEmailDirective.isEmailValid(signer.email)) {
        this.validationErrors.signers = this.validationErrors.signers || [];
        this.validationErrors.signers[index] = 'error_invalid_email';
      }
    });

    return Object.keys(this.validationErrors).length === 0;
  }

  @HostListener('window:keydown', ['$event']) private onKeyDown(event: KeyboardEvent) {
    if ((event.key || '').toLowerCase() === 'escape') {
      this.disableFloatingFieldsSettingMode();
    }
  }
}
