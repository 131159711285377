import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  UUID: string;
  EmailScalar: string;
  TimestampScalar: string;
  CPFScalar: string;
  /** CNPJ is represented as a string type in the format 00.000.000/0000-00 */
  CNPJScalar: string;
  DateScalar: string;
  JSONScalar: any;
  PhoneScalar: string;
  ArrayScalar: any;
  /**
   * The `Upload` special type represents a file to be uploaded in the same HTTP request as specified by
   *  [graphql-multipart-request-spec](https://github.com/jaydenseric/graphql-multipart-request-spec).
   */
  Upload: any;
}



export type AcceptSignatureUnion = HashToSign | Signature;

export interface Action {
  name: ActionEnum;
}

export enum ActionEnum {
  Sign = 'SIGN',
  Approve = 'APPROVE',
  Recognize = 'RECOGNIZE',
  SignAsPart = 'SIGN_AS_PART',
  SignAsAWitness = 'SIGN_AS_A_WITNESS',
  SignAsIntervener = 'SIGN_AS_INTERVENER',
  AcknowledgeReceipt = 'ACKNOWLEDGE_RECEIPT',
  EndorseInBlack = 'ENDORSE_IN_BLACK',
  EndorseInWhite = 'ENDORSE_IN_WHITE'
}

export interface AddressInput {
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  ibge_code?: Maybe<Scalars['String']>;
}

export interface Affiliate {
  id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  referral_code?: Maybe<Scalars['String']>;
  referred_to?: Maybe<Scalars['String']>;
  is_receiving_payments?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  referral_configuration?: Maybe<Scalars['String']>;
  payment_info?: Maybe<Scalars['String']>;
}

export interface ApiOptionsInput {
  webhook_url?: Maybe<Scalars['String']>;
  webhook_format: WebhookFormatEnum;
}


export interface Attribute {
  id: Scalars['UUID'];
  name: Scalars['String'];
  position?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
}

export interface AttributeInput {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  position: Scalars['Int'];
  type: Scalars['String'];
  required: Scalars['Boolean'];
}

export interface Author {
  id: Scalars['UUID'];
  organization_id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  company?: Maybe<Scalars['String']>;
}

export interface AuthType {
  access_token: Scalars['String'];
  token_type: Scalars['String'];
  expires_in: Scalars['Int'];
}

export interface CardData {
  last_digits?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
}

export interface ChildOrganization {
  id: Scalars['Int'];
  uuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['CNPJScalar']>;
  default_email_templates_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  settings?: Maybe<Setting>;
  groups?: Maybe<Array<Maybe<Group>>>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface ChildOrganizationInput {
  name?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  webhook_url?: Maybe<Scalars['String']>;
  default_email_templates_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
}

export enum CloudSignaturesEnum {
  Safeid = 'SAFEID',
  Vidaas = 'VIDAAS',
  Birdid = 'BIRDID'
}


export interface Contact {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  cpf?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
}

export interface ContactInput {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  cpf?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** Your datetime should be in ISO-8601 format */
  birthday?: Maybe<Scalars['String']>;
}

export interface ContactPagination {
  /** List of Contacts on the current page */
  data?: Maybe<Array<Maybe<Contact>>>;
  /** Number of total Contacts selected by the query */
  total?: Maybe<Scalars['Int']>;
  /** Number of Contacts returned per page */
  per_page: Scalars['Int'];
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** Last page of Contacts */
  last_page?: Maybe<Scalars['Int']>;
  /** Number of the first Contacts returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last Contact returned */
  to?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
}

export enum ContextEnum {
  User = 'USER',
  Organization = 'ORGANIZATION',
  Group = 'GROUP'
}


export interface CreditPackType {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  best?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  amount_promo?: Maybe<Scalars['Float']>;
  is_promo?: Maybe<Scalars['Boolean']>;
}

export interface CreditType {
  value?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Int']>;
}

export interface Customer {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  cpf?: Maybe<Scalars['CPFScalar']>;
  cnpj?: Maybe<Scalars['CNPJScalar']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  ibge_code?: Maybe<Scalars['String']>;
  stripe_id?: Maybe<Scalars['String']>;
  iugu_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  updated_at?: Maybe<Scalars['TimestampScalar']>;
  deleted_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface CustomerInput {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  cpf?: Maybe<Scalars['CPFScalar']>;
  cnpj?: Maybe<Scalars['CNPJScalar']>;
}

export interface DataSource {
  name?: Maybe<Scalars['String']>;
  voucher?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateScalar']>;
}

export enum DataSourceEnum {
  Cpf = 'CPF',
  Cnpj = 'CNPJ'
}

export enum DateFormatEnum {
  DdMmYyyy = 'DD_MM_YYYY',
  MmDdYyyy = 'MM_DD_YYYY'
}


export interface DefaultEmailTemplates {
  ptBR?: Maybe<EmailTemplate>;
  enUS?: Maybe<EmailTemplate>;
}

export enum DeliveryMethodEnum {
  DeliveryMethodEmail = 'DELIVERY_METHOD_EMAIL',
  DeliveryMethodLink = 'DELIVERY_METHOD_LINK',
  DeliveryMethodSms = 'DELIVERY_METHOD_SMS',
  DeliveryMethodWhatsapp = 'DELIVERY_METHOD_WHATSAPP'
}

export interface Document {
  id: Scalars['UUID'];
  name: Scalars['String'];
  refusable?: Maybe<Scalars['Boolean']>;
  stop_on_rejected?: Maybe<Scalars['Boolean']>;
  qualified?: Maybe<Scalars['Boolean']>;
  ignore_cpf?: Maybe<Scalars['Boolean']>;
  sortable?: Maybe<Scalars['Boolean']>;
  is_blocked?: Maybe<Scalars['Boolean']>;
  sandbox?: Maybe<Scalars['Boolean']>;
  scrolling_required?: Maybe<Scalars['Boolean']>;
  signers_history?: Maybe<Array<Maybe<SignerHistory>>>;
  locale?: Maybe<Locale>;
  created_at: Scalars['TimestampScalar'];
  deleted_at?: Maybe<Scalars['TimestampScalar']>;
  deadline_at?: Maybe<Scalars['TimestampScalar']>;
  lifecycle_in?: Maybe<Scalars['TimestampScalar']>;
  email_template_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['TimestampScalar'];
  processed_at?: Maybe<Scalars['TimestampScalar']>;
  expiration_at?: Maybe<Scalars['TimestampScalar']>;
  notify_in?: Maybe<Scalars['Int']>;
  reminder?: Maybe<ReminderEnum>;
  message?: Maybe<Scalars['String']>;
  footer?: Maybe<FooterEnum>;
  cc?: Maybe<Array<Maybe<Scalars['EmailScalar']>>>;
  reply_to?: Maybe<Scalars['EmailScalar']>;
  configs?: Maybe<DocumentConfig>;
  signatures_count?: Maybe<Scalars['Int']>;
  signed_count?: Maybe<Scalars['Int']>;
  rejected_count?: Maybe<Scalars['Int']>;
  signatures?: Maybe<Array<Maybe<Signature>>>;
  author: Author;
  files?: Maybe<File>;
  hashes?: Maybe<Hash>;
  new_signature_style?: Maybe<Scalars['Boolean']>;
  show_audit_page?: Maybe<Scalars['Boolean']>;
}

export interface DocumentConfig {
  notification_finished?: Maybe<Scalars['Boolean']>;
  notification_signed?: Maybe<Scalars['Boolean']>;
  signature_appearance?: Maybe<SignatureAppearanceEnum>;
  reply_to?: Maybe<Scalars['EmailScalar']>;
  keep_metadata?: Maybe<Scalars['Boolean']>;
  lock_user_data?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  date_format?: Maybe<DateFormatEnum>;
}

export interface DocumentConfigInput {
  notification_finished?: Maybe<Scalars['Boolean']>;
  notification_signed?: Maybe<Scalars['Boolean']>;
  signature_appearance?: Maybe<SignatureAppearanceEnum>;
  keep_metadata?: Maybe<Scalars['Boolean']>;
  lock_user_data?: Maybe<Scalars['Boolean']>;
}

export interface DocumentInput {
  name: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  refusable?: Maybe<Scalars['Boolean']>;
  ignore_cpf?: Maybe<Scalars['Boolean']>;
  stop_on_rejected?: Maybe<Scalars['Boolean']>;
  qualified?: Maybe<Scalars['Boolean']>;
  sortable?: Maybe<Scalars['Boolean']>;
  scrolling_required?: Maybe<Scalars['Boolean']>;
  reminder?: Maybe<ReminderEnum>;
  new_signature_style?: Maybe<Scalars['Boolean']>;
  show_audit_page?: Maybe<Scalars['Boolean']>;
  /** Your datetime should be in ISO-8601 format */
  deadline_at?: Maybe<Scalars['String']>;
  email_template_id?: Maybe<Scalars['Int']>;
  expiration?: Maybe<ExpirationInput>;
  footer?: Maybe<FooterEnum>;
  cc?: Maybe<Array<Maybe<EmailInput>>>;
  reply_to?: Maybe<Scalars['EmailScalar']>;
  locale?: Maybe<LocaleInput>;
  configs?: Maybe<DocumentConfigInput>;
}

export interface DocumentPagination {
  /** List of Documents on the current page */
  data?: Maybe<Array<Maybe<Document>>>;
  /** Number of total Documents selected by the query */
  total?: Maybe<Scalars['Int']>;
  /** Number of Documents returned per page */
  per_page: Scalars['Int'];
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** Last page of Documents */
  last_page?: Maybe<Scalars['Int']>;
  /** Number of the first Documents returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last Document returned */
  to?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
}

export enum DocumentReportColumnEnum {
  Name = 'NAME',
  CreatedAt = 'CREATED_AT',
  ExpirationAt = 'EXPIRATION_AT',
  Signatures = 'SIGNATURES',
  Status = 'STATUS',
  Sha = 'SHA',
  Author = 'AUTHOR',
  Link = 'LINK'
}

export interface DocumentSignature {
  document_id: Scalars['UUID'];
  signature_id: Scalars['UUID'];
  signature_public_id: Scalars['UUID'];
  action?: Maybe<Action>;
}

export enum DocumentStatusEnum {
  Pending = 'PENDING',
  Signed = 'SIGNED',
  NotSigned = 'NOT_SIGNED',
  Deleted = 'DELETED'
}

export interface EmailEvent {
  id?: Maybe<Scalars['String']>;
  sent?: Maybe<Scalars['String']>;
  opened?: Maybe<Scalars['String']>;
  refused?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  author?: Maybe<User>;
  type?: Maybe<EmailTypeEnum>;
}

export interface EmailInput {
  /** inputs.Email.fields.name */
  email: Scalars['String'];
}


export interface EmailTemplate {
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  image?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
}

export interface EmailTemplates {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateTypeEnum>;
  /** @deprecated Use attributes referring to the specific language instead. */
  email?: Maybe<EmailTemplate>;
  ptBR?: Maybe<EmailTemplate>;
  enUS?: Maybe<EmailTemplate>;
}

export interface EmailTemplatesContentInput {
  title?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  template?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  footer?: Maybe<Scalars['String']>;
}

export interface EmailTemplatesInput {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<EmailTemplateTypeEnum>;
  ptBR?: Maybe<EmailTemplatesContentInput>;
  enUS?: Maybe<EmailTemplatesContentInput>;
}

export interface EmailTemplatesPagination {
  /** List of EmailTemplates on the current page */
  data?: Maybe<Array<Maybe<EmailTemplates>>>;
  /** Number of total EmailTemplates selected by the query */
  total?: Maybe<Scalars['Int']>;
  /** Number of EmailTemplates returned per page */
  per_page: Scalars['Int'];
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** Last page of EmailTemplates */
  last_page?: Maybe<Scalars['Int']>;
  /** Number of the first EmailTemplates returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last EmailTemplates returned */
  to?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
}

export enum EmailTemplateTypeEnum {
  Solicitation = 'SOLICITATION',
  Completed = 'COMPLETED',
  SignatureCompleted = 'SIGNATURE_COMPLETED'
}

export interface EmailType {
  email?: Maybe<Scalars['String']>;
  has_password?: Maybe<Scalars['Boolean']>;
  email_verified_at?: Maybe<Scalars['TimestampScalar']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  deleted_at?: Maybe<Scalars['TimestampScalar']>;
}

export enum EmailTypeEnum {
  Solicitation = 'SOLICITATION',
  Reminder = 'REMINDER',
  Resend = 'RESEND'
}

export interface EnterpriseMember {
  id: Scalars['Int'];
  user?: Maybe<User>;
  group?: Maybe<Group>;
  permissions?: Maybe<Permission>;
  api_token?: Maybe<Token>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface EnterpriseMemberInput {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  birthday?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  group_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<PermissionInput>;
  locale?: Maybe<LocaleInput>;
}

export interface Escavador {
  body?: Maybe<Scalars['String']>;
}

export interface Event {
  user?: Maybe<User>;
  ip?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  /** @deprecated Use ip intead. */
  ipv4?: Maybe<Scalars['String']>;
  /** @deprecated Use ip intead. */
  ipv6?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  geolocation?: Maybe<Geolocation>;
  /** Autentique's Privacy */
  user_agent?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface ExpirationInput {
  days_before: Scalars['Int'];
  notify_at: Scalars['DateScalar'];
}

export interface File {
  original?: Maybe<Scalars['String']>;
  signed?: Maybe<Scalars['String']>;
  certified?: Maybe<Scalars['String']>;
  pades?: Maybe<Scalars['String']>;
}

export interface Folder {
  id: Scalars['UUID'];
  name: Scalars['String'];
  slug: Scalars['String'];
  /** @deprecated Deprecated due to use of context field. */
  type: FolderTypeEnum;
  context: ContextEnum;
  parentFolder?: Maybe<Folder>;
  path?: Maybe<Scalars['String']>;
  subfolders?: Maybe<Array<Maybe<Folder>>>;
  children_counter?: Maybe<Scalars['Int']>;
  created_at: Scalars['TimestampScalar'];
  updated_at: Scalars['TimestampScalar'];
}

export interface FolderInput {
  name: Scalars['String'];
}

export interface FolderPagination {
  /** List of Folders on the current page */
  data?: Maybe<Array<Maybe<Folder>>>;
  /** Number of total Folders selected by the query */
  total?: Maybe<Scalars['Int']>;
  /** Number of Folders returned per page */
  per_page: Scalars['Int'];
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** Last page of Folders */
  last_page?: Maybe<Scalars['Int']>;
  /** Number of the first Folders returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last Folder returned */
  to?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
}

export enum FolderTypeEnum {
  Default = 'DEFAULT',
  Organization = 'ORGANIZATION',
  Group = 'GROUP'
}

export enum FontEnum {
  Autograf = 'AUTOGRAF',
  Fathur = 'FATHUR',
  Robertson = 'ROBERTSON'
}

export enum FooterEnum {
  Bottom = 'BOTTOM',
  Left = 'LEFT',
  Right = 'RIGHT'
}

export interface Geolocation {
  country?: Maybe<Scalars['String']>;
  countryISO?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateISO?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
}

export interface Group {
  id: Scalars['Int'];
  uuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['CNPJScalar']>;
  overwrite_template_group?: Maybe<Scalars['Boolean']>;
  overwrite_template?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use email_templates instead. */
  email_template?: Maybe<EmailTemplates>;
  email_templates?: Maybe<Array<Maybe<EmailTemplates>>>;
  permissions?: Maybe<Permission>;
  styles?: Maybe<GroupStyle>;
  /** Autentique's Privacy */
  members?: Maybe<Array<Maybe<Member>>>;
  members_count?: Maybe<Scalars['Int']>;
  is_default?: Maybe<Scalars['Boolean']>;
}

export interface GroupInput {
  name?: Maybe<Scalars['String']>;
  overwrite_template_group?: Maybe<Scalars['Boolean']>;
  overwrite_template?: Maybe<Scalars['Boolean']>;
}

export interface GroupPagination {
  /** List of Groups on the current page */
  data?: Maybe<Array<Maybe<Group>>>;
  /** Number of total Groups selected by the query */
  total?: Maybe<Scalars['Int']>;
  /** Number of Groups returned per page */
  per_page: Scalars['Int'];
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** Last page of Groups */
  last_page?: Maybe<Scalars['Int']>;
  /** Number of the first Groups returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last Group returned */
  to?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
}

export interface GroupStyle {
  overwrite_email?: Maybe<Scalars['Boolean']>;
  overwrite_name?: Maybe<Scalars['Boolean']>;
}

export interface GroupStyleInput {
  company?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  overwrite_email?: Maybe<Scalars['Boolean']>;
  email_templates_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  overwrite_name?: Maybe<Scalars['Boolean']>;
}

export interface Hash {
  md5?: Maybe<Scalars['String']>;
  sha1?: Maybe<Scalars['String']>;
  sha2?: Maybe<Scalars['String']>;
}

export interface HashToSign {
  hash: Scalars['String'];
}

export interface Invitation {
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  group?: Maybe<Group>;
  permissions?: Maybe<Permission>;
}

export interface InvitationPagination {
  /** List of Invitations on the current page */
  data?: Maybe<Array<Maybe<Invitation>>>;
  /** Number of total Invitations selected by the query */
  total?: Maybe<Scalars['Int']>;
  /** Number of Invitations returned per page */
  per_page: Scalars['Int'];
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** Last page of Invitations */
  last_page?: Maybe<Scalars['Int']>;
  /** Number of the first Invitations returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last Invitation returned */
  to?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
}

export interface Invoice {
  id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['TimestampScalar']>;
  price?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  payment_method?: Maybe<Scalars['String']>;
  url_payment?: Maybe<Scalars['String']>;
  url_nfse?: Maybe<Scalars['String']>;
}

export interface IuguCard {
  id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  item_type?: Maybe<Scalars['String']>;
  customer_id?: Maybe<Scalars['String']>;
  data?: Maybe<IuguCardData>;
}

export interface IuguCardData {
  brand?: Maybe<Scalars['String']>;
  holder_name?: Maybe<Scalars['String']>;
  display_number?: Maybe<Scalars['String']>;
  bin?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  last_digits?: Maybe<Scalars['String']>;
  first_digits?: Maybe<Scalars['String']>;
  masked_number?: Maybe<Scalars['String']>;
}

export interface IuguCardInput {
  set_as_default: Scalars['Boolean'];
  token: Scalars['String'];
  description: Scalars['String'];
}

export interface IuguCharge {
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  pdf?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  bank_slip?: Maybe<IuguChargeBankSlip>;
  credit_card?: Maybe<IuguChargeCreditCard>;
  pix?: Maybe<IuguChargePix>;
  errors?: Maybe<Scalars['String']>;
}

export interface IuguChargeBankSlip {
  digitable_line?: Maybe<Scalars['String']>;
  barcode_data?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  bank_slip_bank?: Maybe<Scalars['Int']>;
  bank_slip_error_code?: Maybe<Scalars['String']>;
  bank_slip_error_message?: Maybe<Scalars['String']>;
}

export interface IuguChargeCreditCard {
  success?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  bin?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  info_message?: Maybe<Scalars['String']>;
}

export interface IuguChargePix {
  qrcode?: Maybe<Scalars['String']>;
  qrcode_text?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  payer_name?: Maybe<Scalars['String']>;
  payer_cpf_cnpj?: Maybe<Scalars['Int']>;
}

export interface IuguCustomer {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  updated_at?: Maybe<Scalars['TimestampScalar']>;
  cc_emails?: Maybe<Scalars['String']>;
  cpf_cnpj?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  complement?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone_prefix?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  default_payment?: Maybe<IuguCard>;
}

export interface IuguInvoice {
  id?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  total_paid?: Maybe<Scalars['String']>;
  total_cents?: Maybe<Scalars['Int']>;
  total_paid_cents?: Maybe<Scalars['Int']>;
  paid_at?: Maybe<Scalars['TimestampScalar']>;
  payable_with?: Maybe<IuguPaymentEnum>;
  secure_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  updated_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface IuguOrder {
  active?: Maybe<Scalars['Boolean']>;
  suspended?: Maybe<Scalars['Boolean']>;
  cycled_at?: Maybe<Scalars['TimestampScalar']>;
  expires_at?: Maybe<Scalars['TimestampScalar']>;
  payable_with?: Maybe<IuguPaymentEnum>;
  plan_identifier?: Maybe<Scalars['String']>;
  plan_name?: Maybe<Scalars['String']>;
  price_cents?: Maybe<Scalars['Int']>;
  recent_invoices?: Maybe<Scalars['JSONScalar']>;
  subitems?: Maybe<Array<Maybe<Scalars['JSONScalar']>>>;
}

export interface IuguOrderInput {
  plan: Scalars['String'];
  payable: IuguPaymentEnum;
}

export enum IuguPaymentEnum {
  CreditCard = 'CREDIT_CARD',
  BankSlip = 'BANK_SLIP',
  Pix = 'PIX',
  All = 'ALL'
}

export interface IuguPlan {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  documents?: Maybe<Scalars['Int']>;
  hidden?: Maybe<Scalars['Boolean']>;
  interval?: Maybe<Scalars['String']>;
  identifier?: Maybe<Scalars['String']>;
}


export interface Link {
  id: Scalars['String'];
  short_link?: Maybe<Scalars['String']>;
}

export interface Locale {
  country?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  date_format?: Maybe<DateFormatEnum>;
}

export interface LocaleInput {
  country?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  date_format?: Maybe<DateFormatEnum>;
}

export interface Member {
  /** @deprecated Deprecated in this version. */
  id: Scalars['String'];
  /** @deprecated Deprecated in this version. */
  name?: Maybe<Scalars['String']>;
  /** @deprecated Deprecated in this version. */
  email?: Maybe<Scalars['EmailScalar']>;
  /** @deprecated Deprecated in this version. */
  role?: Maybe<Role>;
  user?: Maybe<User>;
  /** Autentique's Privacy */
  organization?: Maybe<Organization>;
  group?: Maybe<Group>;
  permissions?: Maybe<Permission>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  deleted_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface MemberInput {
  email?: Maybe<Scalars['EmailScalar']>;
  group_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<PermissionInput>;
}

export interface MemberPagination {
  /** List of Members on the current page */
  data?: Maybe<Array<Maybe<Member>>>;
  /** Number of total Members selected by the query */
  total?: Maybe<Scalars['Int']>;
  /** Number of Members returned per page */
  per_page: Scalars['Int'];
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** Last page of Members */
  last_page?: Maybe<Scalars['Int']>;
  /** Number of the first Members returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last Member returned */
  to?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
}

export interface Mutation {
  buyCredits?: Maybe<Invoice>;
  updateUser?: Maybe<User>;
  updateUserDetails?: Maybe<User>;
  updateUserSignature?: Maybe<User>;
  updateUserInitials?: Maybe<User>;
  updateUserSignatureEmail?: Maybe<User>;
  updateApiOptions?: Maybe<Scalars['Boolean']>;
  updateNotifications?: Maybe<Scalars['Boolean']>;
  updateUserDefaultOrganization?: Maybe<Organization>;
  updateUserLocale?: Maybe<User>;
  changeUserPassword?: Maybe<Scalars['Boolean']>;
  /** Obtain authorization from the holder to use the private key. */
  authorizeApp?: Maybe<Scalars['Boolean']>;
  authorizeSign?: Maybe<Scalars['Boolean']>;
  checkAuthorization?: Maybe<Scalars['Boolean']>;
  createCustomer?: Maybe<Customer>;
  updateCustomer?: Maybe<Customer>;
  createSubscription?: Maybe<Order>;
  updateSubscription?: Maybe<Order>;
  cancelSubscription?: Maybe<Scalars['Boolean']>;
  createIuguCustomer?: Maybe<IuguCustomer>;
  updateIuguCustomer?: Maybe<IuguCustomer>;
  createIuguOrder?: Maybe<IuguOrder>;
  cancelIuguOrder?: Maybe<Scalars['Boolean']>;
  duplicateIuguInvoice?: Maybe<IuguInvoice>;
  updateIuguOrder?: Maybe<IuguOrder>;
  createOrganization?: Maybe<Organization>;
  createOrganizationMember?: Maybe<Scalars['Boolean']>;
  updateOrganizationMember?: Maybe<Member>;
  deleteOrganizationMember?: Maybe<Scalars['Boolean']>;
  updateOrganization?: Maybe<Organization>;
  createEmailTemplate?: Maybe<EmailTemplates>;
  deleteEmailTemplate?: Maybe<Scalars['Boolean']>;
  updateEmailTemplate?: Maybe<EmailTemplates>;
  createGroup?: Maybe<Group>;
  updateGroup?: Maybe<Group>;
  updateGroupPermissions?: Maybe<Group>;
  updateGroupStyles?: Maybe<Group>;
  deleteGroup?: Maybe<Scalars['Boolean']>;
  createDocument?: Maybe<Document>;
  updateDocument?: Maybe<Document>;
  deleteDocument?: Maybe<Scalars['Boolean']>;
  clearDocumentCache?: Maybe<Scalars['Boolean']>;
  moveDocumentToFolder?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use mutation moveDocumentToFolder with (folder_id: null) to do the same as this mutation. */
  moveDocumentToRoot?: Maybe<Scalars['Boolean']>;
  transferDocument?: Maybe<Scalars['Boolean']>;
  lifecycleRescue?: Maybe<Scalars['Boolean']>;
  changeSignature?: Maybe<Signature>;
  acceptSignature?: Maybe<AcceptSignatureUnion>;
  approveBiometric?: Maybe<Signature>;
  rejectBiometric?: Maybe<Signature>;
  resendSignatureEmails?: Maybe<Scalars['Boolean']>;
  resendSignatures?: Maybe<Scalars['Boolean']>;
  updateSignature?: Maybe<Signature>;
  sendSecurityCode?: Maybe<Scalars['Boolean']>;
  checkSecurityVerification?: Maybe<SecurityVerificationType>;
  updateSecurityVerification?: Maybe<SecurityVerificationType>;
  generateSecurityVerificationCode?: Maybe<SecurityVerificationPayloadType>;
  createSigner?: Maybe<Signature>;
  deleteSigner?: Maybe<Scalars['Boolean']>;
  createLinkToSignature?: Maybe<Link>;
  revokeLinkFromSignature?: Maybe<Scalars['Boolean']>;
  createFolder?: Maybe<Folder>;
  updateFolder?: Maybe<Folder>;
  deleteFolder?: Maybe<Scalars['Boolean']>;
  moveFolder?: Maybe<Scalars['Boolean']>;
  createApiToken?: Maybe<Array<Maybe<Token>>>;
  deleteApiToken?: Maybe<Scalars['Boolean']>;
  createTemplate?: Maybe<Template>;
  updateTemplate?: Maybe<Template>;
  deleteTemplate?: Maybe<Scalars['Boolean']>;
  createWebhook?: Maybe<Webhook>;
  updateWebhook?: Maybe<Webhook>;
  deleteWebhook?: Maybe<Scalars['Boolean']>;
  resendWebhook?: Maybe<Scalars['Boolean']>;
  escavadorToUser?: Maybe<Scalars['Boolean']>;
  documentReport?: Maybe<Scalars['Boolean']>;
  resendInvitation?: Maybe<Scalars['Boolean']>;
  deleteInvitation?: Maybe<Scalars['Boolean']>;
  uploadFile?: Maybe<Storage>;
  mergeFiles?: Maybe<Storage>;
  createContact?: Maybe<Contact>;
  updateContact?: Maybe<Contact>;
  deleteContact?: Maybe<Scalars['Boolean']>;
  updateWhitelabelAppearance?: Maybe<Whitelabel>;
  updateWhitelabelDomain?: Maybe<Whitelabel>;
  updateWhitelabelSendgrid?: Maybe<WhitelabelSendgrid>;
  whitelabelSendgridValidate?: Maybe<WhitelabelSendgridDns>;
  activateWhitelabelDomain?: Maybe<Whitelabel>;
  activateWhitelabelSendgrid?: Maybe<Whitelabel>;
}


export interface MutationBuyCreditsArgs {
  package: Scalars['String'];
  payment_method?: Maybe<PaymentMethodsEnum>;
  card_token?: Maybe<Scalars['String']>;
}


export interface MutationUpdateUserArgs {
  user: UserInput;
}


export interface MutationUpdateUserDetailsArgs {
  user: UserDetailInput;
}


export interface MutationUpdateUserSignatureArgs {
  user: UserSignatureInput;
}


export interface MutationUpdateUserInitialsArgs {
  user: UserSignatureInput;
}


export interface MutationUpdateUserSignatureEmailArgs {
  user: EmailTemplatesContentInput;
}


export interface MutationUpdateApiOptionsArgs {
  options: ApiOptionsInput;
}


export interface MutationUpdateNotificationsArgs {
  notification: NotificationInput;
}


export interface MutationUpdateUserDefaultOrganizationArgs {
  organization_id: Scalars['Int'];
}


export interface MutationUpdateUserLocaleArgs {
  locale: LocaleInput;
}


export interface MutationChangeUserPasswordArgs {
  password: PasswordInput;
}


export interface MutationAuthorizeAppArgs {
  cpf_cnpj: Scalars['String'];
  type?: Maybe<CloudSignaturesEnum>;
  otp?: Maybe<Scalars['String']>;
}


export interface MutationAuthorizeSignArgs {
  cpf_cnpj: Scalars['String'];
  password: Scalars['String'];
}


export interface MutationCreateCustomerArgs {
  customer: CustomerInput;
  address: AddressInput;
}


export interface MutationUpdateCustomerArgs {
  customer?: Maybe<CustomerInput>;
  address?: Maybe<AddressInput>;
}


export interface MutationCreateSubscriptionArgs {
  plan: Scalars['String'];
  payment_method: PaymentMethodsEnum;
  card_token?: Maybe<Scalars['String']>;
}


export interface MutationUpdateSubscriptionArgs {
  plan?: Maybe<Scalars['String']>;
  payment_method?: Maybe<PaymentMethodsEnum>;
  card_token?: Maybe<Scalars['String']>;
}


export interface MutationCreateIuguCustomerArgs {
  token?: Maybe<Scalars['String']>;
  customer?: Maybe<CustomerInput>;
  address?: Maybe<AddressInput>;
}


export interface MutationUpdateIuguCustomerArgs {
  token?: Maybe<Scalars['String']>;
  payable_with?: Maybe<IuguPaymentEnum>;
  customer?: Maybe<CustomerInput>;
  address?: Maybe<AddressInput>;
}


export interface MutationCreateIuguOrderArgs {
  order?: Maybe<IuguOrderInput>;
}


export interface MutationDuplicateIuguInvoiceArgs {
  id: Scalars['UUID'];
}


export interface MutationUpdateIuguOrderArgs {
  order?: Maybe<IuguOrderInput>;
}


export interface MutationCreateOrganizationArgs {
  organization: OrganizationInput;
}


export interface MutationCreateOrganizationMemberArgs {
  member: MemberInput;
}


export interface MutationUpdateOrganizationMemberArgs {
  id: Scalars['UUID'];
  member: MemberInput;
}


export interface MutationDeleteOrganizationMemberArgs {
  id: Scalars['UUID'];
}


export interface MutationUpdateOrganizationArgs {
  organization: OrganizationInput;
}


export interface MutationCreateEmailTemplateArgs {
  email: EmailTemplatesInput;
}


export interface MutationDeleteEmailTemplateArgs {
  id: Scalars['Int'];
}


export interface MutationUpdateEmailTemplateArgs {
  id: Scalars['Int'];
  email: EmailTemplatesInput;
}


export interface MutationCreateGroupArgs {
  group: GroupInput;
  permissions: PermissionInput;
  styles: GroupStyleInput;
}


export interface MutationUpdateGroupArgs {
  id: Scalars['Int'];
  group: GroupInput;
}


export interface MutationUpdateGroupPermissionsArgs {
  group_id: Scalars['Int'];
  permissions: PermissionInput;
}


export interface MutationUpdateGroupStylesArgs {
  group_id: Scalars['Int'];
  styles: GroupStyleInput;
}


export interface MutationDeleteGroupArgs {
  id: Scalars['Int'];
}


export interface MutationCreateDocumentArgs {
  document: DocumentInput;
  signers: Array<SignerInput>;
  path: Scalars['String'];
  folder_id?: Maybe<Scalars['UUID']>;
}


export interface MutationUpdateDocumentArgs {
  id: Scalars['UUID'];
  document: UpdateDocumentInput;
}


export interface MutationDeleteDocumentArgs {
  id: Scalars['UUID'];
  member_id?: Maybe<Scalars['String']>;
  group_uuid?: Maybe<Scalars['String']>;
  context?: Maybe<ContextEnum>;
}


export interface MutationClearDocumentCacheArgs {
  id: Scalars['UUID'];
}


export interface MutationMoveDocumentToFolderArgs {
  document_id: Scalars['UUID'];
  folder_id?: Maybe<Scalars['UUID']>;
  current_folder_id?: Maybe<Scalars['UUID']>;
  context?: Maybe<ContextEnum>;
}


export interface MutationMoveDocumentToRootArgs {
  document_id: Scalars['UUID'];
  folder_id: Scalars['UUID'];
}


export interface MutationTransferDocumentArgs {
  id: Scalars['UUID'];
  organization_id: Scalars['Int'];
  group_id: Scalars['Int'];
  current_group_id?: Maybe<Scalars['Int']>;
  context?: Maybe<ContextEnum>;
}


export interface MutationLifecycleRescueArgs {
  document_id: Scalars['UUID'];
}


export interface MutationChangeSignatureArgs {
  public_id: Scalars['UUID'];
  signature: SignatureInput;
}


export interface MutationAcceptSignatureArgs {
  id: Scalars['UUID'];
  type?: Maybe<SignatureTypesEnum>;
  action?: Maybe<PadesActionEnum>;
  payload?: Maybe<Scalars['String']>;
}


export interface MutationApproveBiometricArgs {
  verification_id?: Maybe<Scalars['Int']>;
  public_id?: Maybe<Scalars['UUID']>;
}


export interface MutationRejectBiometricArgs {
  verification_id?: Maybe<Scalars['Int']>;
  public_id?: Maybe<Scalars['UUID']>;
  reason?: Maybe<Scalars['String']>;
}


export interface MutationResendSignatureEmailsArgs {
  public_ids?: Maybe<Array<Scalars['UUID']>>;
  document_id?: Maybe<Array<Scalars['UUID']>>;
}


export interface MutationResendSignaturesArgs {
  public_ids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
}


export interface MutationUpdateSignatureArgs {
  public_id: Scalars['UUID'];
  signature: SignatureInput;
}


export interface MutationSendSecurityCodeArgs {
  id: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
}


export interface MutationCheckSecurityVerificationArgs {
  id: Scalars['Int'];
  security_code?: Maybe<Scalars['String']>;
}


export interface MutationUpdateSecurityVerificationArgs {
  id?: Maybe<Scalars['Int']>;
  security_verification?: Maybe<SecurityVerificationInput>;
  file?: Maybe<Scalars['Upload']>;
  file_type?: Maybe<SecurityVerificationFileTypeEnum>;
}


export interface MutationGenerateSecurityVerificationCodeArgs {
  id?: Maybe<Scalars['Int']>;
}


export interface MutationCreateSignerArgs {
  document_id: Scalars['UUID'];
  signer: SignerInput;
}


export interface MutationDeleteSignerArgs {
  public_id: Scalars['UUID'];
  document_id: Scalars['UUID'];
}


export interface MutationCreateLinkToSignatureArgs {
  public_id: Scalars['UUID'];
}


export interface MutationRevokeLinkFromSignatureArgs {
  public_id: Scalars['UUID'];
}


export interface MutationCreateFolderArgs {
  folder: FolderInput;
  group_id?: Maybe<Scalars['Int']>;
  context?: Maybe<ContextEnum>;
  parent_id?: Maybe<Scalars['UUID']>;
}


export interface MutationUpdateFolderArgs {
  id: Scalars['UUID'];
  folder: FolderInput;
}


export interface MutationDeleteFolderArgs {
  id: Scalars['UUID'];
}


export interface MutationMoveFolderArgs {
  source_folder_id: Scalars['UUID'];
  target_folder_id?: Maybe<Scalars['UUID']>;
  context?: Maybe<ContextEnum>;
}


export interface MutationDeleteApiTokenArgs {
  access_token: Scalars['String'];
}


export interface MutationCreateTemplateArgs {
  template: TemplateInput;
  attributes?: Maybe<Array<Maybe<AttributeInput>>>;
}


export interface MutationUpdateTemplateArgs {
  template: TemplateInput;
  attributes?: Maybe<Array<Maybe<AttributeInput>>>;
}


export interface MutationDeleteTemplateArgs {
  id: Scalars['UUID'];
}


export interface MutationCreateWebhookArgs {
  url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
}


export interface MutationUpdateWebhookArgs {
  id?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
}


export interface MutationDeleteWebhookArgs {
  id?: Maybe<Scalars['Int']>;
}


export interface MutationResendWebhookArgs {
  document_id?: Maybe<Scalars['UUID']>;
}


export interface MutationEscavadorToUserArgs {
  public_id: Scalars['UUID'];
  escavador: Scalars['String'];
}


export interface MutationDocumentReportArgs {
  member_id?: Maybe<Scalars['UUID']>;
  folder_id?: Maybe<Scalars['UUID']>;
  group_uuid?: Maybe<Scalars['String']>;
  context?: Maybe<ContextEnum>;
  status?: Maybe<DocumentStatusEnum>;
  name?: Maybe<Scalars['String']>;
  signer?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  include_deleted?: Maybe<Scalars['Boolean']>;
  include_archived?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<OrderBy>;
  columns: Array<Maybe<DocumentReportColumnEnum>>;
}


export interface MutationResendInvitationArgs {
  id: Scalars['Int'];
}


export interface MutationDeleteInvitationArgs {
  id: Scalars['Int'];
}


export interface MutationUploadFileArgs {
  file: Scalars['Upload'];
}


export interface MutationMergeFilesArgs {
  files: Array<Scalars['String']>;
}


export interface MutationCreateContactArgs {
  contact: ContactInput;
}


export interface MutationUpdateContactArgs {
  contact?: Maybe<ContactInput>;
}


export interface MutationDeleteContactArgs {
  id: Scalars['Int'];
}


export interface MutationUpdateWhitelabelAppearanceArgs {
  whitelabel: WhitelabelAppearanceInput;
}


export interface MutationUpdateWhitelabelDomainArgs {
  domain?: Maybe<Scalars['String']>;
}


export interface MutationUpdateWhitelabelSendgridArgs {
  whitelabel: WhitelabelSendgridInput;
}


export interface MutationWhitelabelSendgridValidateArgs {
  sendgrid_domain: Scalars['String'];
}

export interface Notification {
  signature_you: Scalars['Boolean'];
  signature_others: Scalars['Boolean'];
}

export interface NotificationInput {
  signature_you: Scalars['Boolean'];
  signature_others: Scalars['Boolean'];
}

export interface Order {
  plan_id?: Maybe<Scalars['String']>;
  plan_name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  suspended?: Maybe<Scalars['Boolean']>;
  payment_method?: Maybe<PaymentMethodsEnum>;
  card_data?: Maybe<CardData>;
  subitems?: Maybe<Array<Maybe<OrderSubItem>>>;
  latest_invoice?: Maybe<Invoice>;
  cycled_at?: Maybe<Scalars['TimestampScalar']>;
  expires_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface OrderBy {
  field: Scalars['String'];
  direction: OrderByEnum;
}

export enum OrderByEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface OrderSubItem {
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
}

export interface Organization {
  id: Scalars['Int'];
  uuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['CNPJScalar']>;
  /** @deprecated Deprecated after stripe integration. */
  customer_id?: Maybe<Scalars['String']>;
  settings?: Maybe<Setting>;
  parent?: Maybe<ParentOrganization>;
  /** @deprecated Deprecated in this version. */
  overwrite_email: Scalars['Boolean'];
  /** @deprecated Deprecated in this version. */
  overwrite_template: Scalars['Boolean'];
  /** @deprecated Deprecated in this version. */
  overwrite_name: Scalars['Boolean'];
  /**
   * Autentique's Privacy
   * @deprecated Deprecated in this version.
   */
  members?: Maybe<Array<Maybe<Member>>>;
  /** Autentique's Privacy */
  groups?: Maybe<Array<Maybe<Group>>>;
  subscription?: Maybe<Subscription>;
  billing_customer?: Maybe<Customer>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  deleted_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface OrganizationInput {
  name?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
}

export interface OrganizationUsageReport {
  documents?: Maybe<Scalars['Int']>;
  credits?: Maybe<Scalars['Int']>;
}

export enum PadesActionEnum {
  Start = 'START',
  Complete = 'COMPLETE'
}

export interface ParentOrganization {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}

export interface PasswordInput {
  current?: Maybe<Scalars['String']>;
  new?: Maybe<Scalars['String']>;
  new_confirmation?: Maybe<Scalars['String']>;
}

export enum PaymentMethodsEnum {
  CreditCard = 'CREDIT_CARD',
  Boleto = 'BOLETO',
  Pix = 'PIX'
}

export interface Permission {
  overwrite_permissions?: Maybe<Scalars['Boolean']>;
  create_documents?: Maybe<Scalars['Boolean']>;
  sign_documents?: Maybe<Scalars['Boolean']>;
  delete_documents?: Maybe<Scalars['Boolean']>;
  archive_documents?: Maybe<Scalars['Boolean']>;
  view_documents_gr?: Maybe<Scalars['Boolean']>;
  view_folders_gr?: Maybe<Scalars['Boolean']>;
  actions_folders_gr?: Maybe<Scalars['Boolean']>;
  actions_documents_gr?: Maybe<Scalars['Boolean']>;
  actions_templates_gr?: Maybe<Scalars['Boolean']>;
  actions_members_oz?: Maybe<Scalars['Boolean']>;
  actions_groups_oz?: Maybe<Scalars['Boolean']>;
  actions_webhooks_oz?: Maybe<Scalars['Boolean']>;
  view_documents_oz?: Maybe<Scalars['Boolean']>;
  view_member_documents_oz?: Maybe<Scalars['Boolean']>;
  view_group_documents_oz?: Maybe<Scalars['Boolean']>;
  view_folders_oz?: Maybe<Scalars['Boolean']>;
  view_member_folders_oz?: Maybe<Scalars['Boolean']>;
  view_group_folders_oz?: Maybe<Scalars['Boolean']>;
  actions_documents_oz?: Maybe<Scalars['Boolean']>;
  view_invoices_oz?: Maybe<Scalars['Boolean']>;
  change_plan_oz?: Maybe<Scalars['Boolean']>;
  actions_folders_oz?: Maybe<Scalars['Boolean']>;
  change_appearances_oz?: Maybe<Scalars['Boolean']>;
  change_whitelabel_oz?: Maybe<Scalars['Boolean']>;
  enterprise_access?: Maybe<Scalars['Boolean']>;
}

export interface PermissionInput {
  overwrite_permissions?: Maybe<Scalars['Boolean']>;
  create_documents?: Maybe<Scalars['Boolean']>;
  sign_documents?: Maybe<Scalars['Boolean']>;
  delete_documents?: Maybe<Scalars['Boolean']>;
  archive_documents?: Maybe<Scalars['Boolean']>;
  view_documents_gr?: Maybe<Scalars['Boolean']>;
  view_folders_gr?: Maybe<Scalars['Boolean']>;
  actions_folders_gr?: Maybe<Scalars['Boolean']>;
  actions_documents_gr?: Maybe<Scalars['Boolean']>;
  actions_templates_gr?: Maybe<Scalars['Boolean']>;
  actions_members_oz?: Maybe<Scalars['Boolean']>;
  actions_groups_oz?: Maybe<Scalars['Boolean']>;
  actions_webhooks_oz?: Maybe<Scalars['Boolean']>;
  view_documents_oz?: Maybe<Scalars['Boolean']>;
  view_member_documents_oz?: Maybe<Scalars['Boolean']>;
  view_group_documents_oz?: Maybe<Scalars['Boolean']>;
  view_folders_oz?: Maybe<Scalars['Boolean']>;
  view_member_folders_oz?: Maybe<Scalars['Boolean']>;
  view_group_folders_oz?: Maybe<Scalars['Boolean']>;
  actions_documents_oz?: Maybe<Scalars['Boolean']>;
  view_invoices_oz?: Maybe<Scalars['Boolean']>;
  change_plan_oz?: Maybe<Scalars['Boolean']>;
  actions_folders_oz?: Maybe<Scalars['Boolean']>;
  change_appearances_oz?: Maybe<Scalars['Boolean']>;
}


export enum PipefyPlansEnum {
  Free = 'FREE',
  Unlimited = 'UNLIMITED'
}

export interface Plan {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  interval?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  stripe_id?: Maybe<Scalars['String']>;
  iugu_id?: Maybe<Scalars['String']>;
}

export enum PositionElementEnum {
  Signature = 'SIGNATURE',
  Name = 'NAME',
  Initials = 'INITIALS',
  Date = 'DATE',
  Cpf = 'CPF'
}

export interface PositionInput {
  x: Scalars['String'];
  y: Scalars['String'];
  z: Scalars['Int'];
  angle?: Maybe<Scalars['Float']>;
  element?: Maybe<PositionElementEnum>;
}

export interface Provider {
  name: Scalars['String'];
}

export interface PublicDocument {
  id: Scalars['UUID'];
  name: Scalars['String'];
  refusable?: Maybe<Scalars['Boolean']>;
  stop_on_rejected?: Maybe<Scalars['Boolean']>;
  qualified?: Maybe<Scalars['Boolean']>;
  ignore_cpf?: Maybe<Scalars['Boolean']>;
  sortable?: Maybe<Scalars['Boolean']>;
  is_blocked?: Maybe<Scalars['Boolean']>;
  sandbox?: Maybe<Scalars['Boolean']>;
  scrolling_required?: Maybe<Scalars['Boolean']>;
  signers_history?: Maybe<Array<Maybe<SignerHistory>>>;
  created_at: Scalars['TimestampScalar'];
  deadline_at?: Maybe<Scalars['TimestampScalar']>;
  lifecycle_in?: Maybe<Scalars['TimestampScalar']>;
  email_template_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['TimestampScalar'];
  expiration_at?: Maybe<Scalars['TimestampScalar']>;
  notify_in?: Maybe<Scalars['Int']>;
  reminder?: Maybe<ReminderEnum>;
  footer?: Maybe<FooterEnum>;
  configs?: Maybe<DocumentConfig>;
  signatures?: Maybe<Array<Maybe<PublicSignature>>>;
  author: Author;
  files?: Maybe<File>;
  hashes?: Maybe<Hash>;
  new_signature_style?: Maybe<Scalars['Boolean']>;
  show_audit_page?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Locale>;
}

export interface PublicSignature {
  public_id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  delivery_method?: Maybe<DeliveryMethodEnum>;
  email_events?: Maybe<EmailEvent>;
  email_history?: Maybe<Array<Maybe<EmailEvent>>>;
  signature_history?: Maybe<Array<Maybe<SignatureHistory>>>;
  positions?: Maybe<Array<Maybe<SignaturePosition>>>;
  configs?: Maybe<SignatureConfig>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  updated_at?: Maybe<Scalars['TimestampScalar']>;
  archived_at?: Maybe<Scalars['TimestampScalar']>;
  certificate?: Maybe<SignedCertificate>;
  viewed?: Maybe<Event>;
  signed?: Maybe<Event>;
  rejected?: Maybe<Event>;
  signed_unapproved?: Maybe<Event>;
  biometric_approved?: Maybe<Event>;
  biometric_rejected?: Maybe<Event>;
  parent?: Maybe<Scalars['String']>;
  action?: Maybe<Action>;
  document: PublicDocument;
  user?: Maybe<PublicUser>;
  user_data?: Maybe<UserData>;
  verifications?: Maybe<Array<Maybe<SecurityVerificationType>>>;
}

export interface PublicUser {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  phone?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['CPFScalar']>;
  company?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateScalar']>;
  escavador?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  member?: Maybe<Member>;
  created_at: Scalars['TimestampScalar'];
  /** @deprecated Deprecated in this version. */
  auth_methods?: Maybe<Array<Maybe<Provider>>>;
  settings?: Maybe<Setting>;
  /** @deprecated Deprecated in this version. */
  group?: Maybe<Group>;
}

export interface Query {
  me?: Maybe<User>;
  contacts?: Maybe<ContactPagination>;
  referrals?: Maybe<Referral>;
  notifications?: Maybe<Notification>;
  dataSource?: Maybe<DataSource>;
  newHash?: Maybe<Scalars['String']>;
  hasCloudCert?: Maybe<UserCloudCert>;
  iuguPlans?: Maybe<Array<Maybe<IuguPlan>>>;
  iuguOrder?: Maybe<IuguOrder>;
  iuguCustomer?: Maybe<IuguCustomer>;
  organizations?: Maybe<Array<Maybe<Organization>>>;
  organization?: Maybe<Organization>;
  organizationGroups?: Maybe<GroupPagination>;
  organizationGroup?: Maybe<Group>;
  organizationMembers?: Maybe<MemberPagination>;
  organizationMember?: Maybe<Member>;
  emailTemplates?: Maybe<EmailTemplatesPagination>;
  defaultEmailTemplate?: Maybe<DefaultEmailTemplates>;
  invitations?: Maybe<InvitationPagination>;
  document?: Maybe<Document>;
  documents?: Maybe<DocumentPagination>;
  signatures?: Maybe<Array<Maybe<DocumentSignature>>>;
  folder?: Maybe<Folder>;
  folders?: Maybe<FolderPagination>;
  webhook?: Maybe<Webhook>;
  webhooks?: Maybe<Array<Maybe<Webhook>>>;
  webhooksType?: Maybe<Array<Maybe<WebhookType>>>;
  template?: Maybe<Template>;
  templates?: Maybe<TemplatePagination>;
  escavadorFindById?: Maybe<Scalars['String']>;
  escavadorFindByName?: Maybe<Scalars['String']>;
  howMuchDoesItCost?: Maybe<Array<Maybe<CreditType>>>;
  creditsPack?: Maybe<Array<Maybe<CreditPackType>>>;
  plans?: Maybe<Array<Maybe<Plan>>>;
  order?: Maybe<Order>;
  customer?: Maybe<Customer>;
  whitelabel?: Maybe<Whitelabel>;
  whitelabelDomainVerified?: Maybe<Scalars['Boolean']>;
  whitelabelSendgridDns?: Maybe<WhitelabelSendgridDns>;
  tokens?: Maybe<Array<Maybe<Token>>>;
}


export interface QueryContactsArgs {
  limit?: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}


export interface QueryReferralsArgs {
  page: Scalars['Int'];
  per_page: Scalars['Int'];
}


export interface QueryDataSourceArgs {
  cpf?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  type: DataSourceEnum;
}


export interface QueryOrganizationGroupsArgs {
  organization_id?: Maybe<Scalars['Int']>;
  limit?: Scalars['Int'];
  page?: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
}


export interface QueryOrganizationGroupArgs {
  uuid: Scalars['String'];
}


export interface QueryOrganizationMembersArgs {
  limit: Scalars['Int'];
  page: Scalars['Int'];
  group_id?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}


export interface QueryOrganizationMemberArgs {
  member_id: Scalars['UUID'];
}


export interface QueryEmailTemplatesArgs {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
  type?: Maybe<EmailTemplateTypeEnum>;
}


export interface QueryDefaultEmailTemplateArgs {
  type?: Maybe<EmailTemplateTypeEnum>;
}


export interface QueryInvitationsArgs {
  limit?: Scalars['Int'];
  page?: Scalars['Int'];
  group_id?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}


export interface QueryDocumentArgs {
  id?: Maybe<Scalars['UUID']>;
  public_id?: Maybe<Scalars['UUID']>;
}


export interface QueryDocumentsArgs {
  member_id?: Maybe<Scalars['UUID']>;
  folder_id?: Maybe<Scalars['UUID']>;
  group_uuid?: Maybe<Scalars['String']>;
  context?: Maybe<ContextEnum>;
  status?: Maybe<DocumentStatusEnum>;
  limit?: Scalars['Int'];
  page?: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  signer?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  include_deleted?: Maybe<Scalars['Boolean']>;
  include_archived?: Maybe<Scalars['Boolean']>;
  orderBy?: Maybe<OrderBy>;
  showSandbox?: Maybe<Scalars['Boolean']>;
  organization_id?: Maybe<Scalars['Int']>;
}


export interface QuerySignaturesArgs {
  documents?: Maybe<Array<Maybe<Scalars['UUID']>>>;
}


export interface QueryFolderArgs {
  id: Scalars['UUID'];
}


export interface QueryFoldersArgs {
  group_uuid?: Maybe<Scalars['String']>;
  member_id?: Maybe<Scalars['UUID']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  context?: Maybe<ContextEnum>;
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
  parent_id?: Maybe<Scalars['UUID']>;
}


export interface QueryWebhookArgs {
  id?: Maybe<Scalars['Int']>;
}


export interface QueryTemplateArgs {
  id: Scalars['UUID'];
}


export interface QueryTemplatesArgs {
  limit: Scalars['Int'];
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
}


export interface QueryEscavadorFindByIdArgs {
  identifier: Scalars['String'];
}


export interface QueryEscavadorFindByNameArgs {
  name: Scalars['String'];
}


export interface QueryWhitelabelDomainVerifiedArgs {
  domain: Scalars['String'];
}


export interface QueryWhitelabelSendgridDnsArgs {
  sendgrid_domain: Scalars['String'];
}

export interface Referral {
  id?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  referral_code?: Maybe<Scalars['String']>;
  referral_to?: Maybe<Scalars['String']>;
  total_amount?: Maybe<Scalars['Float']>;
  is_receiving_payments?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  affiliates?: Maybe<Array<Maybe<Affiliate>>>;
  referral_configuration?: Maybe<Scalars['String']>;
  payment_info?: Maybe<Scalars['String']>;
  monthly_amount?: Maybe<Scalars['String']>;
  recent_extracts?: Maybe<Scalars['String']>;
}

export enum ReminderEnum {
  Daily = 'DAILY',
  Weekly = 'WEEKLY'
}

export interface Role {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
}

export enum RoleEnum {
  Admin = 'ADMIN',
  User = 'USER'
}

export enum SecurityVerificationEnum {
  Sms = 'SMS',
  Upload = 'UPLOAD',
  Live = 'LIVE',
  PfFacial = 'PF_FACIAL',
  Manual = 'MANUAL'
}

export enum SecurityVerificationFileTypeEnum {
  Selfie = 'SELFIE',
  Front = 'FRONT',
  Back = 'BACK'
}

export interface SecurityVerificationInput {
  type?: Maybe<SecurityVerificationEnum>;
  verify_phone?: Maybe<Scalars['PhoneScalar']>;
}

export interface SecurityVerificationPayloadType {
  url?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['TimestampScalar']>;
  failcode?: Maybe<Scalars['Int']>;
}

export interface SecurityVerificationType {
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<SecurityVerificationEnum>;
  verify_phone?: Maybe<Scalars['String']>;
  payload?: Maybe<SecurityVerificationPayloadType>;
  user?: Maybe<SecurityVerificationUserType>;
  verified_at?: Maybe<Scalars['TimestampScalar']>;
  max_attempt?: Maybe<Scalars['Int']>;
  logs_attempt?: Maybe<Scalars['ArrayScalar']>;
}

export interface SecurityVerificationUserType {
  images?: Maybe<Scalars['JSONScalar']>;
  confidence?: Maybe<Scalars['Int']>;
}

export interface Session {
  ip_address?: Maybe<Scalars['String']>;
  user_agent?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface Setting {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<EmailTemplate>;
  format?: Maybe<Scalars['String']>;
  webhook_url?: Maybe<Scalars['String']>;
  webhook_format?: Maybe<WebhookFormatEnum>;
  signature?: Maybe<Scalars['String']>;
  signature_draw?: Maybe<Scalars['String']>;
  initials_format?: Maybe<Scalars['String']>;
  initials?: Maybe<Scalars['String']>;
  initials_draw?: Maybe<Scalars['String']>;
  font?: Maybe<Scalars['String']>;
  signature_text?: Maybe<Scalars['String']>;
  has_signature_appearance?: Maybe<Scalars['Boolean']>;
  has_initials_appearance?: Maybe<Scalars['Boolean']>;
}

export interface Signature {
  public_id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  delivery_method?: Maybe<DeliveryMethodEnum>;
  email_events?: Maybe<EmailEvent>;
  email_history?: Maybe<Array<Maybe<EmailEvent>>>;
  positions?: Maybe<Array<Maybe<SignaturePosition>>>;
  configs?: Maybe<SignatureConfig>;
  organization_id?: Maybe<Scalars['Int']>;
  group_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  updated_at?: Maybe<Scalars['TimestampScalar']>;
  archived_at?: Maybe<Scalars['TimestampScalar']>;
  action?: Maybe<Action>;
  link?: Maybe<Link>;
  certificate?: Maybe<SignedCertificate>;
  viewed?: Maybe<Event>;
  signed?: Maybe<Event>;
  rejected?: Maybe<Event>;
  signed_unapproved?: Maybe<Event>;
  biometric_approved?: Maybe<Event>;
  biometric_rejected?: Maybe<Event>;
  user?: Maybe<User>;
  user_data?: Maybe<UserData>;
  parent?: Maybe<Scalars['String']>;
  verifications?: Maybe<Array<Maybe<SecurityVerificationType>>>;
  /** Autentique's Privacy */
  folder?: Maybe<Folder>;
  /** Autentique's Privacy */
  organization?: Maybe<Organization>;
}

export enum SignatureAppearanceEnum {
  Draw = 'DRAW',
  Handwriting = 'HANDWRITING',
  Eletronic = 'ELETRONIC',
  Image = 'IMAGE'
}

export interface SignatureConfig {
  overwrite_name?: Maybe<Scalars['String']>;
  overwrite_date?: Maybe<Scalars['DateScalar']>;
  cpf?: Maybe<Scalars['String']>;
}

export interface SignatureConfigInput {
  overwrite_name?: Maybe<Scalars['String']>;
  overwrite_date?: Maybe<Scalars['String']>;
}

export interface SignatureHistory {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<DeliveryMethodEnum>;
  sent?: Maybe<Scalars['String']>;
  delivered?: Maybe<Scalars['String']>;
  opened?: Maybe<Scalars['String']>;
  refused?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  email_type?: Maybe<EmailTypeEnum>;
  phone?: Maybe<Scalars['PhoneScalar']>;
  user?: Maybe<PublicUser>;
}

export interface SignatureInput {
  email?: Maybe<Scalars['EmailScalar']>;
  positions?: Maybe<Array<Maybe<PositionInput>>>;
  configs?: Maybe<SignatureConfigInput>;
}

export interface SignaturePosition {
  x: Scalars['String'];
  y: Scalars['String'];
  z: Scalars['Int'];
  angle?: Maybe<Scalars['Float']>;
  element?: Maybe<PositionElementEnum>;
}

export enum SignatureTypesEnum {
  A1 = 'A1',
  A3 = 'A3',
  Safeid = 'SAFEID',
  Vidaas = 'VIDAAS',
  Birdid = 'BIRDID'
}

export interface SignedCertificate {
  type?: Maybe<SignatureTypesEnum>;
  name?: Maybe<Scalars['String']>;
  /** Document number (TAX ID) */
  document?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
}

export interface SignerConfigInput {
  cpf?: Maybe<Scalars['String']>;
}

export interface SignerHistory {
  created_at?: Maybe<Scalars['TimestampScalar']>;
  user?: Maybe<PublicSignature>;
  author?: Maybe<PublicSignature>;
  category?: Maybe<SignerHistoryEnum>;
}

export enum SignerHistoryEnum {
  Create = 'CREATE',
  Delete = 'DELETE',
  GenerateLink = 'GENERATE_LINK'
}

export interface SignerInput {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  phone?: Maybe<Scalars['String']>;
  delivery_method?: Maybe<DeliveryMethodEnum>;
  positions?: Maybe<Array<Maybe<PositionInput>>>;
  action: ActionEnum;
  configs?: Maybe<SignerConfigInput>;
  security_verifications?: Maybe<Array<Maybe<SecurityVerificationInput>>>;
}

export interface Social {
  email?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
  deleted_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface SpamIncident {
  id?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  action?: Maybe<SpamIncidentActionEnum>;
  signers_today?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['TimestampScalar']>;
}

export enum SpamIncidentActionEnum {
  Pending = 'PENDING',
  Ignore = 'IGNORE',
  AllowAll = 'ALLOW_ALL',
  BlockUser = 'BLOCK_USER'
}

export interface Statistic {
  documents: Scalars['Int'];
  sent: Scalars['Int'];
  signatures: Scalars['Int'];
}

export interface Storage {
  path: Scalars['String'];
  size?: Maybe<Scalars['Int']>;
}

export interface Subscription {
  name?: Maybe<Scalars['String']>;
  documents: Scalars['Int'];
  credits?: Maybe<Scalars['Int']>;
  bonus: Scalars['Int'];
  has_premium_features: Scalars['Boolean'];
  gateway_name?: Maybe<Scalars['String']>;
  gateway_id?: Maybe<Scalars['String']>;
  plan_id?: Maybe<Scalars['String']>;
  /** @deprecated Use `gateway_id` field instead. */
  iugu_id?: Maybe<Scalars['String']>;
  /** @deprecated Use `plan_id` field instead. */
  iugu_plan?: Maybe<Scalars['String']>;
  created_at: Scalars['TimestampScalar'];
  ends_at?: Maybe<Scalars['TimestampScalar']>;
  bought_at?: Maybe<Scalars['TimestampScalar']>;
  expires_at?: Maybe<Scalars['TimestampScalar']>;
}

export interface Template {
  id: Scalars['UUID'];
  user: User;
  name: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  created_at: Scalars['TimestampScalar'];
  updated_at: Scalars['TimestampScalar'];
  attributes?: Maybe<Array<Maybe<Attribute>>>;
}

export interface TemplateInput {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  text: Scalars['String'];
}

export interface TemplatePagination {
  /** List of Templates on the current page */
  data?: Maybe<Array<Maybe<Template>>>;
  /** Number of total Templates selected by the query */
  total?: Maybe<Scalars['Int']>;
  /** Number of Templates returned per page */
  per_page: Scalars['Int'];
  /** Current page of the cursor */
  current_page: Scalars['Int'];
  /** Last page of Templates */
  last_page?: Maybe<Scalars['Int']>;
  /** Number of the first Templates returned */
  from?: Maybe<Scalars['Int']>;
  /** Number of the last Template returned */
  to?: Maybe<Scalars['Int']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean'];
}


export interface Token {
  access_token: Scalars['String'];
  token_type: Scalars['String'];
  env: Scalars['String'];
  parent_organization_id?: Maybe<Scalars['Int']>;
  parent_organization_name?: Maybe<Scalars['String']>;
  created_at: Scalars['TimestampScalar'];
}

export interface TokenInput {
  name: Scalars['String'];
}

export enum TypeEnum {
  Link = 'LINK',
  Signature = 'SIGNATURE'
}

export interface UpdateDocumentInput {
  name?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  refusable?: Maybe<Scalars['Boolean']>;
  ignore_cpf?: Maybe<Scalars['Boolean']>;
  stop_on_rejected?: Maybe<Scalars['Boolean']>;
  sortable?: Maybe<Scalars['Boolean']>;
  scrolling_required?: Maybe<Scalars['Boolean']>;
  reminder?: Maybe<ReminderEnum>;
  new_signature_style?: Maybe<Scalars['Boolean']>;
  show_audit_page?: Maybe<Scalars['Boolean']>;
  expiration?: Maybe<ExpirationInput>;
  footer?: Maybe<FooterEnum>;
  cc?: Maybe<Array<Maybe<EmailInput>>>;
  locale?: Maybe<UpdateDocumentLocaleInput>;
  configs?: Maybe<DocumentConfigInput>;
  /** Your datetime should be in ISO-8601 format */
  deadline_at?: Maybe<Scalars['String']>;
  email_template_id?: Maybe<Scalars['Int']>;
}

export interface UpdateDocumentLocaleInput {
  timezone?: Maybe<Scalars['String']>;
  date_format?: Maybe<DateFormatEnum>;
}


export interface User {
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  phone?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['TimestampScalar']>;
  cpf?: Maybe<Scalars['CPFScalar']>;
  cnpj?: Maybe<Scalars['CNPJScalar']>;
  company?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateScalar']>;
  founder: Scalars['Boolean'];
  user_hash?: Maybe<Scalars['String']>;
  nolt?: Maybe<Scalars['String']>;
  escavador?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  member?: Maybe<Member>;
  created_at: Scalars['TimestampScalar'];
  updated_at?: Maybe<Scalars['TimestampScalar']>;
  /** @deprecated Deprecated in this version. */
  auth_methods?: Maybe<Array<Maybe<Provider>>>;
  settings?: Maybe<Setting>;
  locale?: Maybe<Locale>;
  /** @deprecated Deprecated in this version. */
  statistics: Statistic;
  subscription?: Maybe<Subscription>;
  /** @deprecated Deprecated in this version. */
  group?: Maybe<Group>;
  /** @deprecated Deprecated in this version. */
  permissions?: Maybe<Permission>;
  /** @deprecated Deprecated in this version. */
  customer_id?: Maybe<Scalars['String']>;
  /** Autentique's Privacy */
  emails?: Maybe<Array<Maybe<EmailType>>>;
  /** Autentique's Privacy */
  socials?: Maybe<Array<Maybe<Social>>>;
  /** Autentique's Privacy */
  members?: Maybe<Array<Maybe<Member>>>;
  /** Autentique's Privacy */
  sessions?: Maybe<Array<Maybe<Session>>>;
  referred_by?: Maybe<Scalars['UUID']>;
}

export interface UserCloudCert {
  /** The serial number of the certificate */
  serial_number?: Maybe<Scalars['String']>;
  /** The type of the certificate */
  type?: Maybe<CloudSignaturesEnum>;
}

export interface UserData {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  phone?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['CPFScalar']>;
  cnpj?: Maybe<Scalars['CNPJScalar']>;
  company?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateScalar']>;
  signature_format?: Maybe<Scalars['String']>;
  initials_format?: Maybe<Scalars['String']>;
  signature_url?: Maybe<Scalars['String']>;
  initials_url?: Maybe<Scalars['String']>;
  font?: Maybe<FontEnum>;
}

export interface UserDetailInput {
  email: Scalars['EmailScalar'];
  password: Scalars['String'];
}

export interface UserInput {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailScalar']>;
  password?: Maybe<Scalars['String']>;
  cpf?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationInput>;
}

export interface UserSignatureInput {
  format: Scalars['String'];
  file_image?: Maybe<Scalars['Upload']>;
  file_draw?: Maybe<Scalars['Upload']>;
  font: FontEnum;
  signature_text?: Maybe<Scalars['String']>;
}


export interface Webhook {
  id: Scalars['Int'];
  url: Scalars['String'];
  type: Scalars['String'];
  created_at: Scalars['TimestampScalar'];
  updated_at: Scalars['TimestampScalar'];
}

export enum WebhookFormatEnum {
  Urlencoded = 'URLENCODED',
  Json = 'JSON'
}

export interface WebhookType {
  id: Scalars['Int'];
  name: Scalars['String'];
  created_at: Scalars['TimestampScalar'];
  updated_at: Scalars['TimestampScalar'];
}

export interface Whitelabel {
  domain?: Maybe<Scalars['String']>;
  sendgrid_domain?: Maybe<Scalars['String']>;
  sendgrid_email_user?: Maybe<Scalars['String']>;
  is_darkmode?: Maybe<Scalars['Boolean']>;
  main_color?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  pdf_logo?: Maybe<Scalars['String']>;
  pdf_logo_mini?: Maybe<Scalars['String']>;
}

export interface WhitelabelAppearanceInput {
  main_color?: Maybe<Scalars['String']>;
  is_darkmode?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['Upload']>;
  pdf_logo?: Maybe<Scalars['Upload']>;
  pdf_logo_mini?: Maybe<Scalars['Upload']>;
}

export interface WhitelabelSendgrid {
  whitelabel?: Maybe<Whitelabel>;
  sendgrid_dns?: Maybe<WhitelabelSendgridDns>;
}

export interface WhitelabelSendgridDns {
  valid?: Maybe<Scalars['Boolean']>;
  dns?: Maybe<Array<Maybe<WhitelabelSendgridDnsRecord>>>;
}

export interface WhitelabelSendgridDnsRecord {
  valid?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
}

export interface WhitelabelSendgridInput {
  sendgrid_domain?: Maybe<Scalars['String']>;
  sendgrid_email_user?: Maybe<Scalars['String']>;
}

export type SignatureEventFragment = (
  Pick<Event, 'ip' | 'port' | 'reason' | 'created_at'>
  & { geolocation?: Maybe<Pick<Geolocation, 'country' | 'countryISO' | 'state' | 'stateISO' | 'city' | 'zipcode' | 'latitude' | 'longitude'>> }
);

export type EventFragment = (
  Pick<Event, 'ip' | 'port' | 'reason' | 'created_at'>
  & { geolocation?: Maybe<Pick<Geolocation, 'country' | 'countryISO' | 'state' | 'stateISO' | 'city' | 'zipcode' | 'latitude' | 'longitude'>> }
);

export type EmailEventFragment = Pick<EmailEvent, 'delivered' | 'opened' | 'reason' | 'refused' | 'sent'>;

export type DocumentAuthorFragment = Pick<Author, 'id' | 'email' | 'name' | 'company' | 'organization_id'>;

export type DocumentFileFragment = Pick<File, 'original' | 'signed' | 'certified' | 'pades'>;

export type SignatureTokenFragment = Pick<DocumentSignature, 'document_id' | 'signature_id' | 'signature_public_id'>;

export type SlimOrganizationFragment = Pick<Organization, 'id' | 'name'>;

export type MemberPermissionsBaseFragment = Pick<Permission, 'create_documents' | 'archive_documents' | 'delete_documents' | 'sign_documents' | 'view_documents_gr' | 'view_folders_gr' | 'actions_documents_gr' | 'actions_folders_gr' | 'actions_templates_gr' | 'view_documents_oz' | 'view_folders_oz' | 'view_member_documents_oz' | 'view_member_folders_oz' | 'view_group_documents_oz' | 'view_group_folders_oz' | 'view_invoices_oz' | 'actions_documents_oz' | 'actions_folders_oz' | 'actions_members_oz' | 'actions_groups_oz' | 'actions_webhooks_oz' | 'change_appearances_oz' | 'change_plan_oz'>;

export type MemberPermissionsFragment = (
  Pick<Permission, 'change_whitelabel_oz'>
  & MemberPermissionsBaseFragment
);

export type GroupPermissionsFragment = (
  Pick<Permission, 'overwrite_permissions'>
  & MemberPermissionsFragment
);

export type OrganizationGroupFragment = (
  Pick<Group, 'id' | 'uuid' | 'name' | 'cnpj' | 'company' | 'members_count' | 'is_default' | 'overwrite_template' | 'overwrite_template_group'>
  & { styles?: Maybe<Pick<GroupStyle, 'overwrite_email' | 'overwrite_name'>>, email_templates?: Maybe<Array<Maybe<Pick<EmailTemplates, 'id' | 'name' | 'type'>>>>, permissions?: Maybe<GroupPermissionsFragment> }
);

export type OrganizationMemberFragment = { user?: Maybe<Pick<User, 'id' | 'name' | 'email'>>, group?: Maybe<OrganizationGroupFragment>, permissions?: Maybe<MemberPermissionsBaseFragment> };

export type EmailDetailsFragment = Pick<EmailTemplate, 'colors' | 'footer' | 'image' | 'sender' | 'subject' | 'template' | 'text' | 'title'>;

export type EmailTemplateFragment = (
  Pick<EmailTemplates, 'id' | 'name' | 'type'>
  & { ptBR?: Maybe<EmailDetailsFragment>, enUS?: Maybe<EmailDetailsFragment> }
);

export type UserStatisticsFragment = Pick<Statistic, 'documents' | 'sent' | 'signatures'>;

export type UserSettingsFragment = (
  Pick<Setting, 'format' | 'initials_format' | 'font' | 'signature' | 'signature_draw' | 'initials' | 'initials_draw' | 'has_signature_appearance' | 'has_initials_appearance'>
  & { email?: Maybe<EmailDetailsFragment> }
);

export type UserOrganizationFragment = (
  Pick<Organization, 'id' | 'uuid' | 'name' | 'cnpj' | 'customer_id'>
  & { billing_customer?: Maybe<Pick<Customer, 'iugu_id' | 'stripe_id'>>, settings?: Maybe<Pick<Setting, 'webhook_url' | 'webhook_format'>>, parent?: Maybe<Pick<ParentOrganization, 'id' | 'name'>> }
);

export type UserBasicFragment = (
  Pick<User, 'id' | 'name' | 'email' | 'cpf' | 'birthday' | 'founder' | 'company' | 'cnpj' | 'phone' | 'created_at' | 'email_verified_at' | 'user_hash' | 'nolt' | 'escavador'>
  & { locale?: Maybe<Pick<Locale, 'country' | 'date_format' | 'language' | 'timezone'>> }
);

export type UserFragment = (
  { subscription?: Maybe<Pick<Subscription, 'iugu_id' | 'iugu_plan' | 'credits' | 'name' | 'documents' | 'bonus' | 'has_premium_features' | 'created_at' | 'expires_at'>>, settings?: Maybe<UserSettingsFragment>, organization?: Maybe<UserOrganizationFragment>, member?: Maybe<OrganizationMemberFragment> }
  & UserBasicFragment
);

export type PositionsFragment = Pick<SignaturePosition, 'element' | 'x' | 'y' | 'z' | 'angle'>;

export type VerificationsFragment = (
  Pick<SecurityVerificationType, 'id' | 'type' | 'verified_at' | 'verify_phone' | 'max_attempt' | 'logs_attempt'>
  & { user?: Maybe<UserVerificationFragment>, payload?: Maybe<VerificationPayloadFragment> }
);

export type UserVerificationFragment = Pick<SecurityVerificationUserType, 'confidence' | 'images'>;

export type VerificationPayloadFragment = Pick<SecurityVerificationPayloadType, 'url' | 'reference' | 'expiry' | 'failcode'>;

export type BasicSignerFragment = (
  Pick<Signature, 'public_id' | 'organization_id' | 'group_id'>
  & { signed?: Maybe<EventFragment>, signed_unapproved?: Maybe<EventFragment> }
);

export type WhitelabelFragment = Pick<Whitelabel, 'domain' | 'main_color' | 'is_darkmode' | 'logo' | 'pdf_logo' | 'pdf_logo_mini' | 'sendgrid_domain' | 'sendgrid_email_user'>;

export type SendgridDnsFragment = (
  Pick<WhitelabelSendgridDns, 'valid'>
  & { dns?: Maybe<Array<Maybe<Pick<WhitelabelSendgridDnsRecord, 'valid' | 'type' | 'host' | 'data' | 'reason'>>>> }
);

export type InvoiceFragment = Pick<Invoice, 'description' | 'due_date' | 'payment_method' | 'price' | 'status' | 'id' | 'url_nfse' | 'url_payment'>;

export type UserApiTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type UserApiTokensQuery = { tokens?: Maybe<Array<Maybe<ApiTokenFragment>>> };

export type CreateApiTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateApiTokenMutation = { createApiToken?: Maybe<Array<Maybe<ApiTokenFragment>>> };

export type DeleteApiTokenMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type DeleteApiTokenMutation = Pick<Mutation, 'deleteApiToken'>;

export type ApiTokenFragment = Pick<Token, 'env' | 'token_type' | 'created_at' | 'access_token' | 'parent_organization_id' | 'parent_organization_name'>;

export type DocumentsQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
  context?: Maybe<ContextEnum>;
  folderId?: Maybe<Scalars['UUID']>;
  memberId?: Maybe<Scalars['UUID']>;
  groupUuid?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  signer?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  includeArchived?: Maybe<Scalars['Boolean']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  status?: Maybe<DocumentStatusEnum>;
  orderBy?: Maybe<OrderBy>;
  sandbox?: Maybe<Scalars['Boolean']>;
}>;


export type DocumentsQuery = { documents?: Maybe<DocumentsPageFragment> };

export type AuthDocumentQueryVariables = Exact<{
  id?: Maybe<Scalars['UUID']>;
  publicId?: Maybe<Scalars['UUID']>;
}>;


export type AuthDocumentQuery = { document?: Maybe<AuthDocumentFragment> };

export type AuthSignTokensQueryVariables = Exact<{
  documentsIds?: Maybe<Array<Maybe<Scalars['UUID']>> | Maybe<Scalars['UUID']>>;
}>;


export type AuthSignTokensQuery = { signatures?: Maybe<Array<Maybe<SignatureTokenFragment>>> };

export type DataSourceQueryVariables = Exact<{
  cpf?: Maybe<Scalars['String']>;
  cnpj?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  type: DataSourceEnum;
}>;


export type DataSourceQuery = { dataSource?: Maybe<Pick<DataSource, 'name' | 'birthday' | 'voucher'>> };

export type ExportDocumentsMutationVariables = Exact<{
  columns: Array<Maybe<DocumentReportColumnEnum>> | Maybe<DocumentReportColumnEnum>;
  context?: Maybe<ContextEnum>;
  folderId?: Maybe<Scalars['UUID']>;
  memberId?: Maybe<Scalars['UUID']>;
  groupUuid?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  signer?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  includeArchived?: Maybe<Scalars['Boolean']>;
  includeDeleted?: Maybe<Scalars['Boolean']>;
  status?: Maybe<DocumentStatusEnum>;
  orderBy?: Maybe<OrderBy>;
}>;


export type ExportDocumentsMutation = Pick<Mutation, 'documentReport'>;

export type CurrentCreditsPriceForDocumentQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentCreditsPriceForDocumentQuery = { howMuchDoesItCost?: Maybe<Array<Maybe<Pick<CreditType, 'value' | 'cost'>>>> };

export type MergeFilesMutationVariables = Exact<{
  files: Array<Scalars['String']> | Scalars['String'];
}>;


export type MergeFilesMutation = { mergeFiles?: Maybe<Pick<Storage, 'path' | 'size'>> };

export type CreateDocumentMutationVariables = Exact<{
  document: DocumentInput;
  signers: Array<SignerInput> | SignerInput;
  path: Scalars['String'];
  folderId?: Maybe<Scalars['UUID']>;
}>;


export type CreateDocumentMutation = { createDocument?: Maybe<Pick<Document, 'id'>> };

export type UpdateDocumentMutationVariables = Exact<{
  id: Scalars['UUID'];
  document: UpdateDocumentInput;
}>;


export type UpdateDocumentMutation = { updateDocument?: Maybe<BasicDocumentFragment> };

export type ResendSignaturesMutationVariables = Exact<{
  publicIds?: Maybe<Array<Scalars['UUID']> | Scalars['UUID']>;
}>;


export type ResendSignaturesMutation = Pick<Mutation, 'resendSignatures'>;

export type AcceptSignatureMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AcceptSignatureMutation = { acceptSignature?: Maybe<BasicSignerFragment> };

export type ApproveBiometricMutationVariables = Exact<{
  publicId: Scalars['UUID'];
  verificationId: Scalars['Int'];
}>;


export type ApproveBiometricMutation = { approveBiometric?: Maybe<BasicSignerFragment> };

export type RejectBiometricMutationVariables = Exact<{
  publicId: Scalars['UUID'];
  verificationId: Scalars['Int'];
}>;


export type RejectBiometricMutation = { rejectBiometric?: Maybe<BasicSignerFragment> };

export type CreateSignerMutationVariables = Exact<{
  documentId: Scalars['UUID'];
  signer: SignerInput;
}>;


export type CreateSignerMutation = { createSigner?: Maybe<SignerFragment> };

export type UpdateSignerMutationVariables = Exact<{
  publicId: Scalars['UUID'];
  signer: SignatureInput;
}>;


export type UpdateSignerMutation = { changeSignature?: Maybe<(
    Pick<Signature, 'public_id' | 'email' | 'parent'>
    & { user?: Maybe<Pick<User, 'name' | 'phone' | 'cpf' | 'birthday'>>, positions?: Maybe<Array<Maybe<PositionsFragment>>> }
  )> };

export type UpdateSignerPositionsMutationVariables = Exact<{
  publicId: Scalars['UUID'];
  signer: SignatureInput;
}>;


export type UpdateSignerPositionsMutation = { updateSignature?: Maybe<(
    Pick<Signature, 'public_id'>
    & { positions?: Maybe<Array<Maybe<PositionsFragment>>> }
  )> };

export type CreateSignerLinkMutationVariables = Exact<{
  publicId: Scalars['UUID'];
}>;


export type CreateSignerLinkMutation = { createLinkToSignature?: Maybe<Pick<Link, 'id' | 'short_link'>> };

export type DeleteSignerLinkMutationVariables = Exact<{
  publicId: Scalars['UUID'];
}>;


export type DeleteSignerLinkMutation = Pick<Mutation, 'revokeLinkFromSignature'>;

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['UUID'];
  memberId?: Maybe<Scalars['String']>;
  groupUuid?: Maybe<Scalars['String']>;
  context?: Maybe<ContextEnum>;
}>;


export type DeleteDocumentMutation = Pick<Mutation, 'deleteDocument'>;

export type DeleteSignerMutationVariables = Exact<{
  documentId: Scalars['UUID'];
  signerPublicId: Scalars['UUID'];
}>;


export type DeleteSignerMutation = Pick<Mutation, 'deleteSigner'>;

export type ClearDocumentPdfCacheMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ClearDocumentPdfCacheMutation = Pick<Mutation, 'clearDocumentCache'>;

export type SendSecurityCodeMutationVariables = Exact<{
  id: Scalars['Int'];
  type: Scalars['String'];
}>;


export type SendSecurityCodeMutation = Pick<Mutation, 'sendSecurityCode'>;

export type CheckSecurityVerificationMutationVariables = Exact<{
  id: Scalars['Int'];
  securityCode?: Maybe<Scalars['String']>;
}>;


export type CheckSecurityVerificationMutation = { checkSecurityVerification?: Maybe<VerificationsFragment> };

export type GenerateSecurityVerificationCodeMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GenerateSecurityVerificationCodeMutation = { generateSecurityVerificationCode?: Maybe<VerificationPayloadFragment> };

export type LifecycleRescueMutationVariables = Exact<{
  documentId: Scalars['UUID'];
}>;


export type LifecycleRescueMutation = Pick<Mutation, 'lifecycleRescue'>;

export type ResendWebhookMutationVariables = Exact<{
  documentId: Scalars['UUID'];
}>;


export type ResendWebhookMutation = Pick<Mutation, 'resendWebhook'>;

export type DocumentsPageFragment = (
  Pick<DocumentPagination, 'current_page' | 'last_page' | 'total'>
  & { data?: Maybe<Array<Maybe<SlimDocumentFragment>>> }
);

export type SlimDocumentFragment = (
  { configs?: Maybe<Pick<DocumentConfig, 'signature_appearance'>>, signatures?: Maybe<Array<Maybe<(
    Pick<Signature, 'public_id' | 'email' | 'name' | 'delivery_method' | 'organization_id' | 'group_id' | 'created_at' | 'updated_at' | 'archived_at'>
    & { certificate?: Maybe<Pick<SignedCertificate, 'type' | 'email' | 'document' | 'name'>>, action?: Maybe<Pick<Action, 'name'>>, email_events?: Maybe<EmailEventFragment>, viewed?: Maybe<Pick<Event, 'created_at'>>, signed?: Maybe<Pick<Event, 'created_at'>>, rejected?: Maybe<Pick<Event, 'created_at'>>, signed_unapproved?: Maybe<Pick<Event, 'created_at'>>, biometric_approved?: Maybe<Pick<Event, 'created_at'>>, biometric_rejected?: Maybe<Pick<Event, 'created_at'>>, user_data?: Maybe<Pick<UserData, 'name' | 'email' | 'phone'>>, user?: Maybe<Pick<User, 'id'>>, verifications?: Maybe<Array<Maybe<VerificationsFragment>>> }
  )>>> }
  & BasicDocumentFragment
);

export type AuthDocumentFragment = (
  Pick<Document, 'is_blocked' | 'cc' | 'reply_to' | 'message' | 'notify_in' | 'footer'>
  & { author: DocumentAuthorFragment, hashes?: Maybe<Pick<Hash, 'sha2'>>, configs?: Maybe<Pick<DocumentConfig, 'notification_signed' | 'notification_finished' | 'signature_appearance' | 'lock_user_data'>>, signatures?: Maybe<Array<Maybe<SignerFragment>>> }
  & BasicDocumentFragment
);

export type BasicDocumentFragment = (
  Pick<Document, 'id' | 'name' | 'ignore_cpf' | 'refusable' | 'sortable' | 'qualified' | 'sandbox' | 'stop_on_rejected' | 'scrolling_required' | 'reminder' | 'new_signature_style' | 'show_audit_page' | 'created_at' | 'updated_at' | 'expiration_at' | 'deadline_at' | 'lifecycle_in'>
  & { author: Pick<Author, 'id'>, files?: Maybe<DocumentFileFragment>, locale?: Maybe<Pick<Locale, 'timezone' | 'date_format' | 'country' | 'language'>> }
);

export type SignerFragment = (
  Pick<Signature, 'public_id' | 'email' | 'name' | 'organization_id' | 'group_id' | 'parent' | 'created_at' | 'updated_at' | 'archived_at' | 'delivery_method'>
  & { certificate?: Maybe<Pick<SignedCertificate, 'type' | 'email' | 'document' | 'name'>>, action?: Maybe<Pick<Action, 'name'>>, email_events?: Maybe<EmailEventFragment>, email_history?: Maybe<Array<Maybe<(
    Pick<EmailEvent, 'type' | 'id'>
    & EmailEventFragment
  )>>>, configs?: Maybe<Pick<SignatureConfig, 'cpf' | 'overwrite_name' | 'overwrite_date'>>, viewed?: Maybe<EventFragment>, signed?: Maybe<EventFragment>, rejected?: Maybe<EventFragment>, signed_unapproved?: Maybe<EventFragment>, biometric_approved?: Maybe<EventFragment>, biometric_rejected?: Maybe<EventFragment>, positions?: Maybe<Array<Maybe<PositionsFragment>>>, link?: Maybe<Pick<Link, 'id' | 'short_link'>>, user_data?: Maybe<Pick<UserData, 'name' | 'email' | 'cpf' | 'birthday' | 'company' | 'phone' | 'signature_format' | 'initials_format' | 'signature_url' | 'initials_url' | 'font'>>, user?: Maybe<(
    Pick<User, 'id' | 'escavador'>
    & { organization?: Maybe<Pick<Organization, 'id' | 'name'>>, member?: Maybe<{ user?: Maybe<Pick<User, 'id'>>, group?: Maybe<(
        Pick<Group, 'id' | 'company'>
        & { styles?: Maybe<Pick<GroupStyle, 'overwrite_email' | 'overwrite_name'>> }
      )> }>, settings?: Maybe<Pick<Setting, 'format' | 'initials_format' | 'font' | 'signature' | 'signature_draw' | 'initials' | 'initials_draw'>> }
  )>, verifications?: Maybe<Array<Maybe<VerificationsFragment>>> }
);

export type EmailTemplatesQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
}>;


export type EmailTemplatesQuery = { emailTemplates?: Maybe<EmailTemplatesPageFragment> };

export type SlimEmailTemplatesQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
  type?: Maybe<EmailTemplateTypeEnum>;
}>;


export type SlimEmailTemplatesQuery = { emailTemplates?: Maybe<{ data?: Maybe<Array<Maybe<Pick<EmailTemplates, 'id' | 'name' | 'type'>>>> }> };

export type DeleteEmailTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEmailTemplateMutation = Pick<Mutation, 'deleteEmailTemplate'>;

export type EmailTemplatesPageFragment = (
  Pick<EmailTemplatesPagination, 'current_page' | 'last_page' | 'total'>
  & { data?: Maybe<Array<Maybe<EmailTemplateFragment>>> }
);

export type DefaultEmailTemplateQueryVariables = Exact<{
  type?: Maybe<EmailTemplateTypeEnum>;
}>;


export type DefaultEmailTemplateQuery = { defaultEmailTemplate?: Maybe<{ enUS?: Maybe<EmailDetailsFragment>, ptBR?: Maybe<EmailDetailsFragment> }> };

export type FoldersQueryVariables = Exact<{
  context?: Maybe<ContextEnum>;
  parentId?: Maybe<Scalars['UUID']>;
  memberId?: Maybe<Scalars['UUID']>;
  groupUuid?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
}>;


export type FoldersQuery = { folders?: Maybe<FoldersPageFragment> };

export type FolderQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type FolderQuery = { folder?: Maybe<FolderFragment> };

export type CreateFolderMutationVariables = Exact<{
  context?: Maybe<ContextEnum>;
  groupId?: Maybe<Scalars['Int']>;
  parentId?: Maybe<Scalars['UUID']>;
  folder: FolderInput;
}>;


export type CreateFolderMutation = { createFolder?: Maybe<FolderFragment> };

export type MoveDocumentToFolderMutationVariables = Exact<{
  documentId: Scalars['UUID'];
  folderId?: Maybe<Scalars['UUID']>;
  currentFolderId?: Maybe<Scalars['UUID']>;
  context?: Maybe<ContextEnum>;
}>;


export type MoveDocumentToFolderMutation = Pick<Mutation, 'moveDocumentToFolder'>;

export type MoveFolderMutationVariables = Exact<{
  id: Scalars['UUID'];
  parentFolderId?: Maybe<Scalars['UUID']>;
  context?: Maybe<ContextEnum>;
}>;


export type MoveFolderMutation = Pick<Mutation, 'moveFolder'>;

export type UpdateFolderMutationVariables = Exact<{
  id: Scalars['UUID'];
  folder: FolderInput;
}>;


export type UpdateFolderMutation = { updateFolder?: Maybe<FolderFragment> };

export type DeleteFolderMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteFolderMutation = Pick<Mutation, 'deleteFolder'>;

export type FoldersPageFragment = (
  Pick<FolderPagination, 'current_page' | 'last_page' | 'total'>
  & { data?: Maybe<Array<Maybe<FolderSlimFragment>>> }
);

export type FolderSlimFragment = Pick<Folder, 'id' | 'name' | 'context' | 'children_counter' | 'created_at' | 'updated_at'>;

export type FolderFragment = (
  { parentFolder?: Maybe<FolderSlimFragment> }
  & FolderSlimFragment
);

export type OrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationsQuery = { organizations?: Maybe<Array<Maybe<SlimOrganizationFragment>>> };

export type OrganizationGroupsQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
  organizationId?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type OrganizationGroupsQuery = { organizationGroups?: Maybe<OrganizationGroupsPageFragment> };

export type SlimOrganizationGroupsQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
  organizationId?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type SlimOrganizationGroupsQuery = { organizationGroups?: Maybe<SlimOrganizationGroupsPageFragment> };

export type OrganizationMembersQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
}>;


export type OrganizationMembersQuery = { organizationMembers?: Maybe<OrganizationMembersPageFragment> };

export type OrganizationGroupQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type OrganizationGroupQuery = { organizationGroup?: Maybe<OrganizationGroupFragment> };

export type OrganizationMemberQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type OrganizationMemberQuery = { organizationMember?: Maybe<OrganizationMemberFragment> };

export type OrganizationGroupsTotalQueryVariables = Exact<{
  organizationId?: Maybe<Scalars['Int']>;
}>;


export type OrganizationGroupsTotalQuery = { organizationGroups?: Maybe<Pick<GroupPagination, 'total'>> };

export type OrganizationMembersInvitationsQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
}>;


export type OrganizationMembersInvitationsQuery = { invitations?: Maybe<OrganizationMembersInvitationsPageFragment> };

export type CreateOrganizationMutationVariables = Exact<{
  organization: OrganizationInput;
}>;


export type CreateOrganizationMutation = { createOrganization?: Maybe<SlimOrganizationFragment> };

export type CreateOrganizationGroupMutationVariables = Exact<{
  group: GroupInput;
  permissions: PermissionInput;
  styles: GroupStyleInput;
}>;


export type CreateOrganizationGroupMutation = { createGroup?: Maybe<OrganizationGroupFragment> };

export type CreateOrganizationMemberMutationVariables = Exact<{
  member: MemberInput;
}>;


export type CreateOrganizationMemberMutation = Pick<Mutation, 'createOrganizationMember'>;

export type SelectDefaultOrganizationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SelectDefaultOrganizationMutation = { updateUserDefaultOrganization?: Maybe<SlimOrganizationFragment> };

export type UpdateOrganizationMutationVariables = Exact<{
  organization: OrganizationInput;
}>;


export type UpdateOrganizationMutation = { updateOrganization?: Maybe<SlimOrganizationFragment> };

export type UpdateOrganizationGroupMutationVariables = Exact<{
  id: Scalars['Int'];
  group: GroupInput;
}>;


export type UpdateOrganizationGroupMutation = { updateGroup?: Maybe<OrganizationGroupFragment> };

export type UpdateOrganizationGroupPermissionsMutationVariables = Exact<{
  id: Scalars['Int'];
  permissions: PermissionInput;
}>;


export type UpdateOrganizationGroupPermissionsMutation = { updateGroupPermissions?: Maybe<OrganizationGroupFragment> };

export type UpdateOrganizationGroupStylesMutationVariables = Exact<{
  id: Scalars['Int'];
  styles: GroupStyleInput;
}>;


export type UpdateOrganizationGroupStylesMutation = { updateGroupStyles?: Maybe<OrganizationGroupFragment> };

export type UpdateOrganizationMemberMutationVariables = Exact<{
  id: Scalars['UUID'];
  member: MemberInput;
}>;


export type UpdateOrganizationMemberMutation = { updateOrganizationMember?: Maybe<OrganizationMemberFragment> };

export type TransferDocumentMutationVariables = Exact<{
  documentId: Scalars['UUID'];
  organizationId: Scalars['Int'];
  groupId: Scalars['Int'];
  currentGroupId?: Maybe<Scalars['Int']>;
  context?: Maybe<ContextEnum>;
}>;


export type TransferDocumentMutation = Pick<Mutation, 'transferDocument'>;

export type DeleteOrganizationGroupMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteOrganizationGroupMutation = Pick<Mutation, 'deleteGroup'>;

export type DeleteOrganizationMemberMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteOrganizationMemberMutation = Pick<Mutation, 'deleteOrganizationMember'>;

export type DeleteOrganizationMemberInvitationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteOrganizationMemberInvitationMutation = Pick<Mutation, 'deleteInvitation'>;

export type ResendOrganizationMemberInvitationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ResendOrganizationMemberInvitationMutation = Pick<Mutation, 'resendInvitation'>;

export type OrganizationMembersPageFragment = (
  Pick<MemberPagination, 'current_page' | 'last_page' | 'total'>
  & { data?: Maybe<Array<Maybe<OrganizationMemberFragment>>> }
);

export type OrganizationMembersInvitationsPageFragment = (
  Pick<InvitationPagination, 'current_page' | 'last_page' | 'total'>
  & { data?: Maybe<Array<Maybe<(
    Pick<Invitation, 'id'>
    & { user?: Maybe<Pick<User, 'id' | 'name' | 'email'>>, group?: Maybe<OrganizationGroupFragment>, permissions?: Maybe<MemberPermissionsFragment> }
  )>>> }
);

export type OrganizationGroupsPageFragment = (
  Pick<GroupPagination, 'current_page' | 'last_page' | 'total'>
  & { data?: Maybe<Array<Maybe<OrganizationGroupFragment>>> }
);

export type SlimOrganizationGroupsPageFragment = { data?: Maybe<Array<Maybe<Pick<Group, 'id' | 'name'>>>> };

export type CurrentUserHasCloudCertQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserHasCloudCertQuery = { hasCloudCert?: Maybe<Pick<UserCloudCert, 'serial_number' | 'type'>> };

export type AuthorizeCloudCertApplicationMutationVariables = Exact<{
  cpfCnpj: Scalars['String'];
  type: CloudSignaturesEnum;
  otp?: Maybe<Scalars['String']>;
}>;


export type AuthorizeCloudCertApplicationMutation = Pick<Mutation, 'authorizeApp'>;

export type AuthorizeCloudCertSignatureMutationVariables = Exact<{
  cpfCnpj: Scalars['String'];
  password: Scalars['String'];
}>;


export type AuthorizeCloudCertSignatureMutation = Pick<Mutation, 'authorizeSign'>;

export type StartPadesSignatureMutationVariables = Exact<{
  id: Scalars['UUID'];
  type: SignatureTypesEnum;
  payload: Scalars['String'];
}>;


export type StartPadesSignatureMutation = { acceptSignature?: Maybe<PadesSignatureHashFragment> };

export type AcceptPadesSignatureMutationVariables = Exact<{
  id: Scalars['UUID'];
  type: SignatureTypesEnum;
  payload: Scalars['String'];
}>;


export type AcceptPadesSignatureMutation = { acceptSignature?: Maybe<BasicSignerFragment> };

export type AcceptCloudCertSignatureMutationVariables = Exact<{
  id: Scalars['UUID'];
  type: SignatureTypesEnum;
}>;


export type AcceptCloudCertSignatureMutation = { acceptSignature?: Maybe<BasicSignerFragment> };

export type CheckAuthorizationVidaasMutationVariables = Exact<{ [key: string]: never; }>;


export type CheckAuthorizationVidaasMutation = Pick<Mutation, 'checkAuthorization'>;

export type PadesSignatureHashFragment = Pick<HashToSign, 'hash'>;

export type CreditsPackQueryVariables = Exact<{ [key: string]: never; }>;


export type CreditsPackQuery = { creditsPack?: Maybe<Array<Maybe<Pick<CreditPackType, 'name' | 'description' | 'quantity' | 'amount' | 'amount_promo' | 'is_promo' | 'best'>>>> };

export type PlansQueryVariables = Exact<{ [key: string]: never; }>;


export type PlansQuery = { plans?: Maybe<Array<Maybe<Pick<Plan, 'active' | 'amount' | 'currency' | 'interval' | 'name' | 'id' | 'stripe_id' | 'iugu_id'>>>> };

export type OrderQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderQuery = { order?: Maybe<OrderFragment> };

export type CustomerQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerQuery = { customer?: Maybe<CustomerFragment> };

export type CreateCustomerMutationVariables = Exact<{
  address: AddressInput;
  customer: CustomerInput;
}>;


export type CreateCustomerMutation = { createCustomer?: Maybe<CustomerFragment> };

export type UpdateCustomerMutationVariables = Exact<{
  address: AddressInput;
  customer: CustomerInput;
}>;


export type UpdateCustomerMutation = { updateCustomer?: Maybe<CustomerFragment> };

export type CreateSubscriptionMutationVariables = Exact<{
  card_token?: Maybe<Scalars['String']>;
  payment_method: PaymentMethodsEnum;
  plan: Scalars['String'];
}>;


export type CreateSubscriptionMutation = { createSubscription?: Maybe<OrderFragment> };

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscriptionMutation = Pick<Mutation, 'cancelSubscription'>;

export type UpdateSubscriptionMutationVariables = Exact<{
  card_token?: Maybe<Scalars['String']>;
  payment_method?: Maybe<PaymentMethodsEnum>;
  plan?: Maybe<Scalars['String']>;
}>;


export type UpdateSubscriptionMutation = { updateSubscription?: Maybe<OrderFragment> };

export type BuyCreditsMutationVariables = Exact<{
  card_token?: Maybe<Scalars['String']>;
  package: Scalars['String'];
  payment_method?: Maybe<PaymentMethodsEnum>;
}>;


export type BuyCreditsMutation = { buyCredits?: Maybe<InvoiceFragment> };

export type CustomerFragment = Pick<Customer, 'city' | 'cnpj' | 'complement' | 'country' | 'cpf' | 'created_at' | 'deleted_at' | 'district' | 'email' | 'ibge_code' | 'id' | 'name' | 'number' | 'state' | 'street' | 'updated_at' | 'zip_code'>;

export type OrderFragment = (
  Pick<Order, 'active' | 'cycled_at' | 'expires_at' | 'payment_method' | 'plan_id' | 'plan_name' | 'price' | 'suspended'>
  & { latest_invoice?: Maybe<InvoiceFragment>, card_data?: Maybe<Pick<CardData, 'last_digits' | 'month' | 'year'>>, subitems?: Maybe<Array<Maybe<Pick<OrderSubItem, 'description' | 'quantity' | 'price'>>>> }
);

export type ReferralsQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type ReferralsQuery = { referrals?: Maybe<(
    Pick<Referral, 'id' | 'email' | 'full_name' | 'recent_extracts' | 'total_amount' | 'referral_code' | 'created_at'>
    & { affiliates?: Maybe<Array<Maybe<Pick<Affiliate, 'id' | 'email' | 'full_name' | 'payment_info' | 'is_receiving_payments' | 'created_at'>>>> }
  )> };

export type ExportAffiliatesQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type ExportAffiliatesQuery = { referrals?: Maybe<(
    Pick<Referral, 'id'>
    & { affiliates?: Maybe<Array<Maybe<Pick<Affiliate, 'id' | 'email' | 'full_name' | 'payment_info' | 'is_receiving_payments' | 'created_at'>>>> }
  )> };

export type TemplatesQueryVariables = Exact<{
  limit: Scalars['Int'];
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderBy>;
}>;


export type TemplatesQuery = { templates?: Maybe<TemplatesPageFragment> };

export type TemplateQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type TemplateQuery = { template?: Maybe<TemplateFragment> };

export type CreateTemplateMutationVariables = Exact<{
  template: TemplateInput;
  attributes?: Maybe<Array<Maybe<AttributeInput>> | Maybe<AttributeInput>>;
}>;


export type CreateTemplateMutation = { createTemplate?: Maybe<SlimTemplateFragment> };

export type UpdateTemplateMutationVariables = Exact<{
  template: TemplateInput;
  attributes?: Maybe<Array<Maybe<AttributeInput>> | Maybe<AttributeInput>>;
}>;


export type UpdateTemplateMutation = { updateTemplate?: Maybe<Pick<Template, 'id'>> };

export type DeleteTemplateMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteTemplateMutation = Pick<Mutation, 'deleteTemplate'>;

export type TemplatesPageFragment = (
  Pick<TemplatePagination, 'current_page' | 'last_page' | 'total'>
  & { data?: Maybe<Array<Maybe<SlimTemplateFragment>>> }
);

export type SlimTemplateFragment = (
  Pick<Template, 'id' | 'name' | 'created_at' | 'updated_at'>
  & { user: Pick<User, 'name'> }
);

export type TemplateFragment = (
  Pick<Template, 'text'>
  & { attributes?: Maybe<Array<Maybe<Pick<Attribute, 'id' | 'name' | 'type' | 'required' | 'position'>>>> }
  & SlimTemplateFragment
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  Pick<Query, 'newHash'>
  & { me?: Maybe<UserFragment> }
);

export type CurrentUserOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserOrganizationQuery = (
  Pick<Query, 'newHash'>
  & { me?: Maybe<(
    Pick<User, 'id'>
    & { organization?: Maybe<UserOrganizationFragment> }
  )> }
);

export type CurrentUserCreditsQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserCreditsQuery = { me?: Maybe<(
    Pick<User, 'id'>
    & { subscription?: Maybe<Pick<Subscription, 'documents' | 'bonus' | 'credits'>> }
  )> };

export type NotificationsInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationsInfoQuery = { notifications?: Maybe<Pick<Notification, 'signature_others' | 'signature_you'>> };

export type ContactsQueryVariables = Exact<{
  limit: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type ContactsQuery = { contacts?: Maybe<(
    Pick<ContactPagination, 'total' | 'per_page' | 'current_page' | 'last_page' | 'from' | 'to'>
    & { data?: Maybe<Array<Maybe<Pick<Contact, 'name' | 'email' | 'id' | 'cpf' | 'phone' | 'birthday'>>>> }
  )> };

export type UpdateCurrentUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type UpdateCurrentUserMutation = { updateUser?: Maybe<UserBasicFragment> };

export type SetCurrentUserMissingInfoMutationVariables = Exact<{
  user: UserDetailInput;
}>;


export type SetCurrentUserMissingInfoMutation = { updateUserDetails?: Maybe<UserBasicFragment> };

export type UpdateCurrentUserPasswordMutationVariables = Exact<{
  newPasswordInfo: PasswordInput;
}>;


export type UpdateCurrentUserPasswordMutation = Pick<Mutation, 'changeUserPassword'>;

export type UpdateCurrentUserLocaleMutationVariables = Exact<{
  locale: LocaleInput;
}>;


export type UpdateCurrentUserLocaleMutation = { updateUserLocale?: Maybe<UserBasicFragment> };

export type UpdateNotificationsInfoMutationVariables = Exact<{
  notification: NotificationInput;
}>;


export type UpdateNotificationsInfoMutation = Pick<Mutation, 'updateNotifications'>;

export type UpdateApiOptionsMutationVariables = Exact<{
  options: ApiOptionsInput;
}>;


export type UpdateApiOptionsMutation = Pick<Mutation, 'updateApiOptions'>;

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteContactMutation = Pick<Mutation, 'deleteContact'>;

export type WhitelabelQueryVariables = Exact<{ [key: string]: never; }>;


export type WhitelabelQuery = { whitelabel?: Maybe<WhitelabelFragment> };

export type WhitelabelSendgridDnsQueryVariables = Exact<{
  sendgrid_domain: Scalars['String'];
}>;


export type WhitelabelSendgridDnsQuery = { whitelabelSendgridDns?: Maybe<SendgridDnsFragment> };

export type WhitelabelDomainVerifiedQueryVariables = Exact<{
  domain: Scalars['String'];
}>;


export type WhitelabelDomainVerifiedQuery = Pick<Query, 'whitelabelDomainVerified'>;

export type UpdateWhitelabelAppearanceMutationVariables = Exact<{
  whitelabel: WhitelabelAppearanceInput;
}>;


export type UpdateWhitelabelAppearanceMutation = { updateWhitelabelAppearance?: Maybe<WhitelabelFragment> };

export type UpdateWhitelabelDomainMutationVariables = Exact<{
  domain?: Maybe<Scalars['String']>;
}>;


export type UpdateWhitelabelDomainMutation = { updateWhitelabelDomain?: Maybe<WhitelabelFragment> };

export type UpdateWhitelabelSendgridMutationVariables = Exact<{
  whitelabelSendgrid: WhitelabelSendgridInput;
}>;


export type UpdateWhitelabelSendgridMutation = { updateWhitelabelSendgrid?: Maybe<{ whitelabel?: Maybe<WhitelabelFragment>, sendgrid_dns?: Maybe<SendgridDnsFragment> }> };

export type WhitelabelSendgridValidateMutationVariables = Exact<{
  sendgrid_domain: Scalars['String'];
}>;


export type WhitelabelSendgridValidateMutation = { whitelabelSendgridValidate?: Maybe<SendgridDnsFragment> };

export type ActivateWhitelabelDomainMutationVariables = Exact<{ [key: string]: never; }>;


export type ActivateWhitelabelDomainMutation = { activateWhitelabelDomain?: Maybe<WhitelabelFragment> };

export type ActivateWhitelabelSendgridMutationVariables = Exact<{ [key: string]: never; }>;


export type ActivateWhitelabelSendgridMutation = { activateWhitelabelSendgrid?: Maybe<WhitelabelFragment> };

export type UploadDocumentFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadDocumentFileMutation = { uploadFile?: Maybe<Pick<Storage, 'path'>> };

export type UpdateSecurityVerificationMutationVariables = Exact<{
  id: Scalars['Int'];
  securityVerification?: Maybe<SecurityVerificationInput>;
  fileType?: Maybe<SecurityVerificationFileTypeEnum>;
  file?: Maybe<Scalars['Upload']>;
}>;


export type UpdateSecurityVerificationMutation = { updateSecurityVerification?: Maybe<VerificationsFragment> };

export type CreateEmailTemplateMutationVariables = Exact<{
  email: EmailTemplatesInput;
}>;


export type CreateEmailTemplateMutation = { createEmailTemplate?: Maybe<EmailTemplateFragment> };

export type UpdateEmailTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
  email: EmailTemplatesInput;
}>;


export type UpdateEmailTemplateMutation = { updateEmailTemplate?: Maybe<EmailTemplateFragment> };

export type UpdateCurrentUserSignatureMutationVariables = Exact<{
  user: UserSignatureInput;
}>;


export type UpdateCurrentUserSignatureMutation = { updateUserSignature?: Maybe<(
    Pick<User, 'id'>
    & { settings?: Maybe<UserSettingsFragment> }
  )> };

export type UpdateCurrentUserInitialsMutationVariables = Exact<{
  user: UserSignatureInput;
}>;


export type UpdateCurrentUserInitialsMutation = { updateUserInitials?: Maybe<(
    Pick<User, 'id'>
    & { settings?: Maybe<UserSettingsFragment> }
  )> };

export type UpdateCurrentUserSignatureEmailMutationVariables = Exact<{
  user: EmailTemplatesContentInput;
}>;


export type UpdateCurrentUserSignatureEmailMutation = { updateUserSignatureEmail?: Maybe<(
    Pick<User, 'id'>
    & { settings?: Maybe<UserSettingsFragment> }
  )> };

export const SignatureEventFragmentDoc = gql`
    fragment signatureEvent on Event {
  ip
  port
  reason
  created_at
  geolocation {
    country
    countryISO
    state
    stateISO
    city
    zipcode
    latitude
    longitude
  }
}
    `;
export const SignatureTokenFragmentDoc = gql`
    fragment signatureToken on DocumentSignature {
  document_id
  signature_id
  signature_public_id
}
    `;
export const SlimOrganizationFragmentDoc = gql`
    fragment slimOrganization on Organization {
  id
  name
}
    `;
export const UserStatisticsFragmentDoc = gql`
    fragment userStatistics on Statistic {
  documents
  sent
  signatures
}
    `;
export const UserBasicFragmentDoc = gql`
    fragment userBasic on User {
  id
  name
  email
  cpf
  birthday
  founder
  company
  cnpj
  phone
  created_at
  email_verified_at
  user_hash
  nolt
  escavador
  locale {
    country
    date_format
    language
    timezone
  }
}
    `;
export const EmailDetailsFragmentDoc = gql`
    fragment emailDetails on EmailTemplate {
  colors
  footer
  image
  sender
  subject
  template
  text
  title
}
    `;
export const UserSettingsFragmentDoc = gql`
    fragment userSettings on Setting {
  format
  initials_format
  font
  signature
  signature_draw
  initials
  initials_draw
  has_signature_appearance
  has_initials_appearance
  email {
    ...emailDetails
  }
}
    ${EmailDetailsFragmentDoc}`;
export const UserOrganizationFragmentDoc = gql`
    fragment userOrganization on Organization {
  id
  uuid
  name
  cnpj
  customer_id
  billing_customer {
    iugu_id
    stripe_id
  }
  settings {
    webhook_url
    webhook_format
  }
  parent {
    id
    name
  }
}
    `;
export const MemberPermissionsBaseFragmentDoc = gql`
    fragment memberPermissionsBase on Permission {
  create_documents
  archive_documents
  delete_documents
  sign_documents
  view_documents_gr
  view_folders_gr
  actions_documents_gr
  actions_folders_gr
  actions_templates_gr
  view_documents_oz
  view_folders_oz
  view_member_documents_oz
  view_member_folders_oz
  view_group_documents_oz
  view_group_folders_oz
  view_invoices_oz
  actions_documents_oz
  actions_folders_oz
  actions_members_oz
  actions_groups_oz
  actions_webhooks_oz
  change_appearances_oz
  change_plan_oz
}
    `;
export const MemberPermissionsFragmentDoc = gql`
    fragment memberPermissions on Permission {
  ...memberPermissionsBase
  change_whitelabel_oz
}
    ${MemberPermissionsBaseFragmentDoc}`;
export const GroupPermissionsFragmentDoc = gql`
    fragment groupPermissions on Permission {
  overwrite_permissions
  ...memberPermissions
}
    ${MemberPermissionsFragmentDoc}`;
export const OrganizationGroupFragmentDoc = gql`
    fragment organizationGroup on Group {
  id
  uuid
  name
  cnpj
  company
  members_count
  is_default
  overwrite_template
  overwrite_template_group
  styles {
    overwrite_email
    overwrite_name
  }
  email_templates {
    id
    name
    type
  }
  permissions {
    ...groupPermissions
  }
}
    ${GroupPermissionsFragmentDoc}`;
export const OrganizationMemberFragmentDoc = gql`
    fragment organizationMember on Member {
  user {
    id
    name
    email
  }
  group {
    ...organizationGroup
  }
  permissions {
    ...memberPermissionsBase
  }
}
    ${OrganizationGroupFragmentDoc}
${MemberPermissionsBaseFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment user on User {
  ...userBasic
  subscription {
    iugu_id
    iugu_plan
    credits
    name
    documents
    bonus
    has_premium_features
    created_at
    expires_at
  }
  settings {
    ...userSettings
  }
  organization {
    ...userOrganization
  }
  member {
    ...organizationMember
  }
}
    ${UserBasicFragmentDoc}
${UserSettingsFragmentDoc}
${UserOrganizationFragmentDoc}
${OrganizationMemberFragmentDoc}`;
export const EventFragmentDoc = gql`
    fragment event on Event {
  ip
  port
  reason
  created_at
  geolocation {
    country
    countryISO
    state
    stateISO
    city
    zipcode
    latitude
    longitude
  }
}
    `;
export const BasicSignerFragmentDoc = gql`
    fragment basicSigner on Signature {
  public_id
  organization_id
  group_id
  signed {
    ...event
  }
  signed_unapproved {
    ...event
  }
}
    ${EventFragmentDoc}`;
export const WhitelabelFragmentDoc = gql`
    fragment whitelabel on Whitelabel {
  domain
  main_color
  is_darkmode
  logo
  pdf_logo
  pdf_logo_mini
  sendgrid_domain
  sendgrid_email_user
}
    `;
export const SendgridDnsFragmentDoc = gql`
    fragment sendgridDns on WhitelabelSendgridDns {
  valid
  dns {
    valid
    type
    host
    data
    reason
  }
}
    `;
export const ApiTokenFragmentDoc = gql`
    fragment apiToken on Token {
  env
  token_type
  created_at
  access_token
  parent_organization_id
  parent_organization_name
}
    `;
export const DocumentFileFragmentDoc = gql`
    fragment documentFile on File {
  original
  signed
  certified
  pades
}
    `;
export const BasicDocumentFragmentDoc = gql`
    fragment basicDocument on Document {
  id
  name
  ignore_cpf
  refusable
  sortable
  qualified
  sandbox
  stop_on_rejected
  scrolling_required
  reminder
  new_signature_style
  show_audit_page
  author {
    id
  }
  files {
    ...documentFile
  }
  created_at
  updated_at
  expiration_at
  deadline_at
  lifecycle_in
  locale {
    timezone
    date_format
    country
    language
  }
}
    ${DocumentFileFragmentDoc}`;
export const EmailEventFragmentDoc = gql`
    fragment emailEvent on EmailEvent {
  delivered
  opened
  reason
  refused
  sent
}
    `;
export const UserVerificationFragmentDoc = gql`
    fragment userVerification on SecurityVerificationUserType {
  confidence
  images
}
    `;
export const VerificationPayloadFragmentDoc = gql`
    fragment verificationPayload on SecurityVerificationPayloadType {
  url
  reference
  expiry
  failcode
}
    `;
export const VerificationsFragmentDoc = gql`
    fragment verifications on SecurityVerificationType {
  id
  type
  user {
    ...userVerification
  }
  payload {
    ...verificationPayload
  }
  verified_at
  verify_phone
  max_attempt
  logs_attempt
}
    ${UserVerificationFragmentDoc}
${VerificationPayloadFragmentDoc}`;
export const SlimDocumentFragmentDoc = gql`
    fragment slimDocument on Document {
  ...basicDocument
  configs {
    signature_appearance
  }
  signatures {
    public_id
    email
    name
    delivery_method
    organization_id
    group_id
    certificate {
      type
      email
      document
      name
    }
    created_at
    updated_at
    archived_at
    action {
      name
    }
    email_events {
      ...emailEvent
    }
    viewed {
      created_at
    }
    signed {
      created_at
    }
    rejected {
      created_at
    }
    signed_unapproved {
      created_at
    }
    biometric_approved {
      created_at
    }
    biometric_rejected {
      created_at
    }
    user_data {
      name
      email
      phone
    }
    user {
      id
    }
    verifications {
      ...verifications
    }
  }
}
    ${BasicDocumentFragmentDoc}
${EmailEventFragmentDoc}
${VerificationsFragmentDoc}`;
export const DocumentsPageFragmentDoc = gql`
    fragment documentsPage on DocumentPagination {
  current_page
  last_page
  total
  data {
    ...slimDocument
  }
}
    ${SlimDocumentFragmentDoc}`;
export const DocumentAuthorFragmentDoc = gql`
    fragment documentAuthor on Author {
  id
  email
  name
  company
  organization_id
}
    `;
export const PositionsFragmentDoc = gql`
    fragment positions on SignaturePosition {
  element
  x
  y
  z
  angle
}
    `;
export const SignerFragmentDoc = gql`
    fragment signer on Signature {
  public_id
  email
  name
  organization_id
  group_id
  parent
  certificate {
    type
    email
    document
    name
  }
  created_at
  updated_at
  archived_at
  action {
    name
  }
  delivery_method
  email_events {
    ...emailEvent
  }
  email_history {
    ...emailEvent
    type
    id
  }
  configs {
    cpf
    overwrite_name
    overwrite_date
  }
  viewed {
    ...event
  }
  signed {
    ...event
  }
  rejected {
    ...event
  }
  signed_unapproved {
    ...event
  }
  biometric_approved {
    ...event
  }
  biometric_rejected {
    ...event
  }
  positions {
    ...positions
  }
  link {
    id
    short_link
  }
  user_data {
    name
    email
    cpf
    birthday
    company
    phone
    signature_format
    initials_format
    signature_url
    initials_url
    font
  }
  user {
    id
    escavador
    organization {
      id
      name
    }
    member {
      user {
        id
      }
      group {
        id
        company
        styles {
          overwrite_email
          overwrite_name
        }
      }
    }
    settings {
      format
      initials_format
      font
      signature
      signature_draw
      initials
      initials_draw
    }
  }
  verifications {
    ...verifications
  }
}
    ${EmailEventFragmentDoc}
${EventFragmentDoc}
${PositionsFragmentDoc}
${VerificationsFragmentDoc}`;
export const AuthDocumentFragmentDoc = gql`
    fragment authDocument on Document {
  ...basicDocument
  is_blocked
  cc
  reply_to
  message
  notify_in
  footer
  author {
    ...documentAuthor
  }
  hashes {
    sha2
  }
  configs {
    notification_signed
    notification_finished
    signature_appearance
    lock_user_data
  }
  signatures {
    ...signer
  }
}
    ${BasicDocumentFragmentDoc}
${DocumentAuthorFragmentDoc}
${SignerFragmentDoc}`;
export const EmailTemplateFragmentDoc = gql`
    fragment emailTemplate on EmailTemplates {
  id
  name
  type
  ptBR {
    ...emailDetails
  }
  enUS {
    ...emailDetails
  }
}
    ${EmailDetailsFragmentDoc}`;
export const EmailTemplatesPageFragmentDoc = gql`
    fragment emailTemplatesPage on EmailTemplatesPagination {
  current_page
  last_page
  total
  data {
    ...emailTemplate
  }
}
    ${EmailTemplateFragmentDoc}`;
export const FolderSlimFragmentDoc = gql`
    fragment folderSlim on Folder {
  id
  name
  context
  children_counter
  created_at
  updated_at
}
    `;
export const FoldersPageFragmentDoc = gql`
    fragment foldersPage on FolderPagination {
  current_page
  last_page
  total
  data {
    ...folderSlim
  }
}
    ${FolderSlimFragmentDoc}`;
export const FolderFragmentDoc = gql`
    fragment folder on Folder {
  ...folderSlim
  parentFolder {
    ...folderSlim
  }
}
    ${FolderSlimFragmentDoc}`;
export const OrganizationMembersPageFragmentDoc = gql`
    fragment organizationMembersPage on MemberPagination {
  current_page
  last_page
  total
  data {
    ...organizationMember
  }
}
    ${OrganizationMemberFragmentDoc}`;
export const OrganizationMembersInvitationsPageFragmentDoc = gql`
    fragment organizationMembersInvitationsPage on InvitationPagination {
  current_page
  last_page
  total
  data {
    id
    user {
      id
      name
      email
    }
    group {
      ...organizationGroup
    }
    permissions {
      ...memberPermissions
    }
  }
}
    ${OrganizationGroupFragmentDoc}
${MemberPermissionsFragmentDoc}`;
export const OrganizationGroupsPageFragmentDoc = gql`
    fragment organizationGroupsPage on GroupPagination {
  current_page
  last_page
  total
  data {
    ...organizationGroup
  }
}
    ${OrganizationGroupFragmentDoc}`;
export const SlimOrganizationGroupsPageFragmentDoc = gql`
    fragment slimOrganizationGroupsPage on GroupPagination {
  data {
    id
    name
  }
}
    `;
export const PadesSignatureHashFragmentDoc = gql`
    fragment padesSignatureHash on HashToSign {
  hash
}
    `;
export const CustomerFragmentDoc = gql`
    fragment customer on Customer {
  city
  cnpj
  complement
  country
  cpf
  created_at
  deleted_at
  district
  email
  ibge_code
  id
  name
  number
  state
  street
  updated_at
  zip_code
}
    `;
export const InvoiceFragmentDoc = gql`
    fragment invoice on Invoice {
  description
  due_date
  payment_method
  price
  status
  id
  url_nfse
  url_payment
}
    `;
export const OrderFragmentDoc = gql`
    fragment order on Order {
  active
  cycled_at
  expires_at
  latest_invoice {
    ...invoice
  }
  card_data {
    last_digits
    month
    year
  }
  payment_method
  plan_id
  plan_name
  price
  subitems {
    description
    quantity
    price
  }
  suspended
}
    ${InvoiceFragmentDoc}`;
export const SlimTemplateFragmentDoc = gql`
    fragment slimTemplate on Template {
  id
  name
  created_at
  updated_at
  user {
    name
  }
}
    `;
export const TemplatesPageFragmentDoc = gql`
    fragment templatesPage on TemplatePagination {
  current_page
  last_page
  total
  data {
    ...slimTemplate
  }
}
    ${SlimTemplateFragmentDoc}`;
export const TemplateFragmentDoc = gql`
    fragment template on Template {
  ...slimTemplate
  text
  attributes {
    id
    name
    type
    required
    position
  }
}
    ${SlimTemplateFragmentDoc}`;
export const UserApiTokensDocument = gql`
    query UserApiTokens {
  tokens {
    ...apiToken
  }
}
    ${ApiTokenFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UserApiTokensGQL extends Apollo.Query<UserApiTokensQuery, UserApiTokensQueryVariables> {
    document = UserApiTokensDocument;

  }
export const CreateApiTokenDocument = gql`
    mutation CreateApiToken {
  createApiToken {
    ...apiToken
  }
}
    ${ApiTokenFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateApiTokenGQL extends Apollo.Mutation<CreateApiTokenMutation, CreateApiTokenMutationVariables> {
    document = CreateApiTokenDocument;

  }
export const DeleteApiTokenDocument = gql`
    mutation DeleteApiToken($accessToken: String!) {
  deleteApiToken(access_token: $accessToken)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteApiTokenGQL extends Apollo.Mutation<DeleteApiTokenMutation, DeleteApiTokenMutationVariables> {
    document = DeleteApiTokenDocument;

  }
export const DocumentsDocument = gql`
    query Documents($limit: Int!, $page: Int!, $context: ContextEnum, $folderId: UUID, $memberId: UUID, $groupUuid: String, $search: String, $name: String, $signer: String, $startDate: String, $endDate: String, $includeArchived: Boolean, $includeDeleted: Boolean, $status: DocumentStatusEnum, $orderBy: OrderBy, $sandbox: Boolean) {
  documents(limit: $limit, page: $page, context: $context, folder_id: $folderId, member_id: $memberId, group_uuid: $groupUuid, search: $search, status: $status, name: $name, signer: $signer, start_date: $startDate, end_date: $endDate, include_archived: $includeArchived, include_deleted: $includeDeleted, orderBy: $orderBy, showSandbox: $sandbox) {
    ...documentsPage
  }
}
    ${DocumentsPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DocumentsGQL extends Apollo.Query<DocumentsQuery, DocumentsQueryVariables> {
    document = DocumentsDocument;

  }
export const AuthDocumentDocument = gql`
    query AuthDocument($id: UUID, $publicId: UUID) {
  document(id: $id, public_id: $publicId) {
    ...authDocument
  }
}
    ${AuthDocumentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthDocumentGQL extends Apollo.Query<AuthDocumentQuery, AuthDocumentQueryVariables> {
    document = AuthDocumentDocument;

  }
export const AuthSignTokensDocument = gql`
    query AuthSignTokens($documentsIds: [UUID]) {
  signatures(documents: $documentsIds) {
    ...signatureToken
  }
}
    ${SignatureTokenFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthSignTokensGQL extends Apollo.Query<AuthSignTokensQuery, AuthSignTokensQueryVariables> {
    document = AuthSignTokensDocument;

  }
export const DataSourceDocument = gql`
    query DataSource($cpf: String, $cnpj: String, $birthday: String, $type: DataSourceEnum!) {
  dataSource(cpf: $cpf, cnpj: $cnpj, birthday: $birthday, type: $type) {
    name
    birthday
    voucher
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DataSourceGQL extends Apollo.Query<DataSourceQuery, DataSourceQueryVariables> {
    document = DataSourceDocument;

  }
export const ExportDocumentsDocument = gql`
    mutation ExportDocuments($columns: [DocumentReportColumnEnum]!, $context: ContextEnum, $folderId: UUID, $memberId: UUID, $groupUuid: String, $search: String, $name: String, $signer: String, $startDate: String, $endDate: String, $includeArchived: Boolean, $includeDeleted: Boolean, $status: DocumentStatusEnum, $orderBy: OrderBy) {
  documentReport(columns: $columns, context: $context, folder_id: $folderId, member_id: $memberId, group_uuid: $groupUuid, search: $search, name: $name, signer: $signer, status: $status, start_date: $startDate, end_date: $endDate, include_archived: $includeArchived, include_deleted: $includeDeleted, orderBy: $orderBy)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExportDocumentsGQL extends Apollo.Mutation<ExportDocumentsMutation, ExportDocumentsMutationVariables> {
    document = ExportDocumentsDocument;

  }
export const CurrentCreditsPriceForDocumentDocument = gql`
    query CurrentCreditsPriceForDocument {
  howMuchDoesItCost {
    value
    cost
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentCreditsPriceForDocumentGQL extends Apollo.Query<CurrentCreditsPriceForDocumentQuery, CurrentCreditsPriceForDocumentQueryVariables> {
    document = CurrentCreditsPriceForDocumentDocument;

  }
export const MergeFilesDocument = gql`
    mutation MergeFiles($files: [String!]!) {
  mergeFiles(files: $files) {
    path
    size
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MergeFilesGQL extends Apollo.Mutation<MergeFilesMutation, MergeFilesMutationVariables> {
    document = MergeFilesDocument;

  }
export const CreateDocumentDocument = gql`
    mutation CreateDocument($document: DocumentInput!, $signers: [SignerInput!]!, $path: String!, $folderId: UUID) {
  createDocument(document: $document, signers: $signers, path: $path, folder_id: $folderId) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDocumentGQL extends Apollo.Mutation<CreateDocumentMutation, CreateDocumentMutationVariables> {
    document = CreateDocumentDocument;

  }
export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($id: UUID!, $document: UpdateDocumentInput!) {
  updateDocument(id: $id, document: $document) {
    ...basicDocument
  }
}
    ${BasicDocumentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDocumentGQL extends Apollo.Mutation<UpdateDocumentMutation, UpdateDocumentMutationVariables> {
    document = UpdateDocumentDocument;

  }
export const ResendSignaturesDocument = gql`
    mutation ResendSignatures($publicIds: [UUID!]) {
  resendSignatures(public_ids: $publicIds)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendSignaturesGQL extends Apollo.Mutation<ResendSignaturesMutation, ResendSignaturesMutationVariables> {
    document = ResendSignaturesDocument;

  }
export const AcceptSignatureDocument = gql`
    mutation AcceptSignature($id: UUID!) {
  acceptSignature(id: $id) {
    ...basicSigner
  }
}
    ${BasicSignerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptSignatureGQL extends Apollo.Mutation<AcceptSignatureMutation, AcceptSignatureMutationVariables> {
    document = AcceptSignatureDocument;

  }
export const ApproveBiometricDocument = gql`
    mutation ApproveBiometric($publicId: UUID!, $verificationId: Int!) {
  approveBiometric(public_id: $publicId, verification_id: $verificationId) {
    ...basicSigner
  }
}
    ${BasicSignerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ApproveBiometricGQL extends Apollo.Mutation<ApproveBiometricMutation, ApproveBiometricMutationVariables> {
    document = ApproveBiometricDocument;

  }
export const RejectBiometricDocument = gql`
    mutation RejectBiometric($publicId: UUID!, $verificationId: Int!) {
  rejectBiometric(public_id: $publicId, verification_id: $verificationId) {
    ...basicSigner
  }
}
    ${BasicSignerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RejectBiometricGQL extends Apollo.Mutation<RejectBiometricMutation, RejectBiometricMutationVariables> {
    document = RejectBiometricDocument;

  }
export const CreateSignerDocument = gql`
    mutation CreateSigner($documentId: UUID!, $signer: SignerInput!) {
  createSigner(document_id: $documentId, signer: $signer) {
    ...signer
  }
}
    ${SignerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSignerGQL extends Apollo.Mutation<CreateSignerMutation, CreateSignerMutationVariables> {
    document = CreateSignerDocument;

  }
export const UpdateSignerDocument = gql`
    mutation UpdateSigner($publicId: UUID!, $signer: SignatureInput!) {
  changeSignature(public_id: $publicId, signature: $signer) {
    public_id
    email
    parent
    user {
      name
      phone
      cpf
      birthday
    }
    positions {
      ...positions
    }
  }
}
    ${PositionsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSignerGQL extends Apollo.Mutation<UpdateSignerMutation, UpdateSignerMutationVariables> {
    document = UpdateSignerDocument;

  }
export const UpdateSignerPositionsDocument = gql`
    mutation UpdateSignerPositions($publicId: UUID!, $signer: SignatureInput!) {
  updateSignature(public_id: $publicId, signature: $signer) {
    public_id
    positions {
      ...positions
    }
  }
}
    ${PositionsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSignerPositionsGQL extends Apollo.Mutation<UpdateSignerPositionsMutation, UpdateSignerPositionsMutationVariables> {
    document = UpdateSignerPositionsDocument;

  }
export const CreateSignerLinkDocument = gql`
    mutation CreateSignerLink($publicId: UUID!) {
  createLinkToSignature(public_id: $publicId) {
    id
    short_link
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSignerLinkGQL extends Apollo.Mutation<CreateSignerLinkMutation, CreateSignerLinkMutationVariables> {
    document = CreateSignerLinkDocument;

  }
export const DeleteSignerLinkDocument = gql`
    mutation DeleteSignerLink($publicId: UUID!) {
  revokeLinkFromSignature(public_id: $publicId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSignerLinkGQL extends Apollo.Mutation<DeleteSignerLinkMutation, DeleteSignerLinkMutationVariables> {
    document = DeleteSignerLinkDocument;

  }
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($id: UUID!, $memberId: String, $groupUuid: String, $context: ContextEnum) {
  deleteDocument(id: $id, member_id: $memberId, group_uuid: $groupUuid, context: $context)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDocumentGQL extends Apollo.Mutation<DeleteDocumentMutation, DeleteDocumentMutationVariables> {
    document = DeleteDocumentDocument;

  }
export const DeleteSignerDocument = gql`
    mutation DeleteSigner($documentId: UUID!, $signerPublicId: UUID!) {
  deleteSigner(document_id: $documentId, public_id: $signerPublicId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSignerGQL extends Apollo.Mutation<DeleteSignerMutation, DeleteSignerMutationVariables> {
    document = DeleteSignerDocument;

  }
export const ClearDocumentPdfCacheDocument = gql`
    mutation ClearDocumentPdfCache($id: UUID!) {
  clearDocumentCache(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClearDocumentPdfCacheGQL extends Apollo.Mutation<ClearDocumentPdfCacheMutation, ClearDocumentPdfCacheMutationVariables> {
    document = ClearDocumentPdfCacheDocument;

  }
export const SendSecurityCodeDocument = gql`
    mutation SendSecurityCode($id: Int!, $type: String!) {
  sendSecurityCode(id: $id, type: $type)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendSecurityCodeGQL extends Apollo.Mutation<SendSecurityCodeMutation, SendSecurityCodeMutationVariables> {
    document = SendSecurityCodeDocument;

  }
export const CheckSecurityVerificationDocument = gql`
    mutation CheckSecurityVerification($id: Int!, $securityCode: String) {
  checkSecurityVerification(id: $id, security_code: $securityCode) {
    ...verifications
  }
}
    ${VerificationsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckSecurityVerificationGQL extends Apollo.Mutation<CheckSecurityVerificationMutation, CheckSecurityVerificationMutationVariables> {
    document = CheckSecurityVerificationDocument;

  }
export const GenerateSecurityVerificationCodeDocument = gql`
    mutation GenerateSecurityVerificationCode($id: Int!) {
  generateSecurityVerificationCode(id: $id) {
    ...verificationPayload
  }
}
    ${VerificationPayloadFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateSecurityVerificationCodeGQL extends Apollo.Mutation<GenerateSecurityVerificationCodeMutation, GenerateSecurityVerificationCodeMutationVariables> {
    document = GenerateSecurityVerificationCodeDocument;

  }
export const LifecycleRescueDocument = gql`
    mutation LifecycleRescue($documentId: UUID!) {
  lifecycleRescue(document_id: $documentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LifecycleRescueGQL extends Apollo.Mutation<LifecycleRescueMutation, LifecycleRescueMutationVariables> {
    document = LifecycleRescueDocument;

  }
export const ResendWebhookDocument = gql`
    mutation ResendWebhook($documentId: UUID!) {
  resendWebhook(document_id: $documentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendWebhookGQL extends Apollo.Mutation<ResendWebhookMutation, ResendWebhookMutationVariables> {
    document = ResendWebhookDocument;

  }
export const EmailTemplatesDocument = gql`
    query EmailTemplates($limit: Int!, $page: Int!, $search: String, $orderBy: OrderBy) {
  emailTemplates(limit: $limit, page: $page, search: $search, orderBy: $orderBy) {
    ...emailTemplatesPage
  }
}
    ${EmailTemplatesPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EmailTemplatesGQL extends Apollo.Query<EmailTemplatesQuery, EmailTemplatesQueryVariables> {
    document = EmailTemplatesDocument;

  }
export const SlimEmailTemplatesDocument = gql`
    query SlimEmailTemplates($limit: Int!, $page: Int!, $search: String, $orderBy: OrderBy, $type: EmailTemplateTypeEnum) {
  emailTemplates(limit: $limit, page: $page, search: $search, orderBy: $orderBy, type: $type) {
    data {
      id
      name
      type
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SlimEmailTemplatesGQL extends Apollo.Query<SlimEmailTemplatesQuery, SlimEmailTemplatesQueryVariables> {
    document = SlimEmailTemplatesDocument;

  }
export const DeleteEmailTemplateDocument = gql`
    mutation DeleteEmailTemplate($id: Int!) {
  deleteEmailTemplate(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteEmailTemplateGQL extends Apollo.Mutation<DeleteEmailTemplateMutation, DeleteEmailTemplateMutationVariables> {
    document = DeleteEmailTemplateDocument;

  }
export const DefaultEmailTemplateDocument = gql`
    query DefaultEmailTemplate($type: EmailTemplateTypeEnum) {
  defaultEmailTemplate(type: $type) {
    enUS {
      ...emailDetails
    }
    ptBR {
      ...emailDetails
    }
  }
}
    ${EmailDetailsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DefaultEmailTemplateGQL extends Apollo.Query<DefaultEmailTemplateQuery, DefaultEmailTemplateQueryVariables> {
    document = DefaultEmailTemplateDocument;

  }
export const FoldersDocument = gql`
    query Folders($context: ContextEnum, $parentId: UUID, $memberId: UUID, $groupUuid: String, $limit: Int!, $page: Int!, $search: String, $orderBy: OrderBy) {
  folders(context: $context, parent_id: $parentId, member_id: $memberId, group_uuid: $groupUuid, limit: $limit, page: $page, search: $search, orderBy: $orderBy) {
    ...foldersPage
  }
}
    ${FoldersPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FoldersGQL extends Apollo.Query<FoldersQuery, FoldersQueryVariables> {
    document = FoldersDocument;

  }
export const FolderDocument = gql`
    query Folder($id: UUID!) {
  folder(id: $id) {
    ...folder
  }
}
    ${FolderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FolderGQL extends Apollo.Query<FolderQuery, FolderQueryVariables> {
    document = FolderDocument;

  }
export const CreateFolderDocument = gql`
    mutation CreateFolder($context: ContextEnum, $groupId: Int, $parentId: UUID, $folder: FolderInput!) {
  createFolder(context: $context, group_id: $groupId, parent_id: $parentId, folder: $folder) {
    ...folder
  }
}
    ${FolderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFolderGQL extends Apollo.Mutation<CreateFolderMutation, CreateFolderMutationVariables> {
    document = CreateFolderDocument;

  }
export const MoveDocumentToFolderDocument = gql`
    mutation MoveDocumentToFolder($documentId: UUID!, $folderId: UUID, $currentFolderId: UUID, $context: ContextEnum) {
  moveDocumentToFolder(document_id: $documentId, folder_id: $folderId, current_folder_id: $currentFolderId, context: $context)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MoveDocumentToFolderGQL extends Apollo.Mutation<MoveDocumentToFolderMutation, MoveDocumentToFolderMutationVariables> {
    document = MoveDocumentToFolderDocument;

  }
export const MoveFolderDocument = gql`
    mutation MoveFolder($id: UUID!, $parentFolderId: UUID, $context: ContextEnum) {
  moveFolder(source_folder_id: $id, target_folder_id: $parentFolderId, context: $context)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MoveFolderGQL extends Apollo.Mutation<MoveFolderMutation, MoveFolderMutationVariables> {
    document = MoveFolderDocument;

  }
export const UpdateFolderDocument = gql`
    mutation UpdateFolder($id: UUID!, $folder: FolderInput!) {
  updateFolder(id: $id, folder: $folder) {
    ...folder
  }
}
    ${FolderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFolderGQL extends Apollo.Mutation<UpdateFolderMutation, UpdateFolderMutationVariables> {
    document = UpdateFolderDocument;

  }
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($id: UUID!) {
  deleteFolder(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFolderGQL extends Apollo.Mutation<DeleteFolderMutation, DeleteFolderMutationVariables> {
    document = DeleteFolderDocument;

  }
export const OrganizationsDocument = gql`
    query Organizations {
  organizations {
    ...slimOrganization
  }
}
    ${SlimOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationsGQL extends Apollo.Query<OrganizationsQuery, OrganizationsQueryVariables> {
    document = OrganizationsDocument;

  }
export const OrganizationGroupsDocument = gql`
    query OrganizationGroups($limit: Int!, $page: Int!, $organizationId: Int, $search: String) {
  organizationGroups(limit: $limit, page: $page, organization_id: $organizationId, search: $search) {
    ...organizationGroupsPage
  }
}
    ${OrganizationGroupsPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationGroupsGQL extends Apollo.Query<OrganizationGroupsQuery, OrganizationGroupsQueryVariables> {
    document = OrganizationGroupsDocument;

  }
export const SlimOrganizationGroupsDocument = gql`
    query SlimOrganizationGroups($limit: Int!, $page: Int!, $organizationId: Int, $search: String) {
  organizationGroups(limit: $limit, page: $page, organization_id: $organizationId, search: $search) {
    ...slimOrganizationGroupsPage
  }
}
    ${SlimOrganizationGroupsPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SlimOrganizationGroupsGQL extends Apollo.Query<SlimOrganizationGroupsQuery, SlimOrganizationGroupsQueryVariables> {
    document = SlimOrganizationGroupsDocument;

  }
export const OrganizationMembersDocument = gql`
    query OrganizationMembers($limit: Int!, $page: Int!, $search: String, $groupId: Int) {
  organizationMembers(limit: $limit, page: $page, search: $search, group_id: $groupId) {
    ...organizationMembersPage
  }
}
    ${OrganizationMembersPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationMembersGQL extends Apollo.Query<OrganizationMembersQuery, OrganizationMembersQueryVariables> {
    document = OrganizationMembersDocument;

  }
export const OrganizationGroupDocument = gql`
    query OrganizationGroup($uuid: String!) {
  organizationGroup(uuid: $uuid) {
    ...organizationGroup
  }
}
    ${OrganizationGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationGroupGQL extends Apollo.Query<OrganizationGroupQuery, OrganizationGroupQueryVariables> {
    document = OrganizationGroupDocument;

  }
export const OrganizationMemberDocument = gql`
    query OrganizationMember($id: UUID!) {
  organizationMember(member_id: $id) {
    ...organizationMember
  }
}
    ${OrganizationMemberFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationMemberGQL extends Apollo.Query<OrganizationMemberQuery, OrganizationMemberQueryVariables> {
    document = OrganizationMemberDocument;

  }
export const OrganizationGroupsTotalDocument = gql`
    query OrganizationGroupsTotal($organizationId: Int) {
  organizationGroups(limit: 1, page: 1, organization_id: $organizationId) {
    total
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationGroupsTotalGQL extends Apollo.Query<OrganizationGroupsTotalQuery, OrganizationGroupsTotalQueryVariables> {
    document = OrganizationGroupsTotalDocument;

  }
export const OrganizationMembersInvitationsDocument = gql`
    query OrganizationMembersInvitations($limit: Int!, $page: Int!, $search: String, $groupId: Int) {
  invitations(limit: $limit, page: $page, search: $search, group_id: $groupId) {
    ...organizationMembersInvitationsPage
  }
}
    ${OrganizationMembersInvitationsPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrganizationMembersInvitationsGQL extends Apollo.Query<OrganizationMembersInvitationsQuery, OrganizationMembersInvitationsQueryVariables> {
    document = OrganizationMembersInvitationsDocument;

  }
export const CreateOrganizationDocument = gql`
    mutation CreateOrganization($organization: OrganizationInput!) {
  createOrganization(organization: $organization) {
    ...slimOrganization
  }
}
    ${SlimOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrganizationGQL extends Apollo.Mutation<CreateOrganizationMutation, CreateOrganizationMutationVariables> {
    document = CreateOrganizationDocument;

  }
export const CreateOrganizationGroupDocument = gql`
    mutation CreateOrganizationGroup($group: GroupInput!, $permissions: PermissionInput!, $styles: GroupStyleInput!) {
  createGroup(group: $group, permissions: $permissions, styles: $styles) {
    ...organizationGroup
  }
}
    ${OrganizationGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrganizationGroupGQL extends Apollo.Mutation<CreateOrganizationGroupMutation, CreateOrganizationGroupMutationVariables> {
    document = CreateOrganizationGroupDocument;

  }
export const CreateOrganizationMemberDocument = gql`
    mutation CreateOrganizationMember($member: MemberInput!) {
  createOrganizationMember(member: $member)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrganizationMemberGQL extends Apollo.Mutation<CreateOrganizationMemberMutation, CreateOrganizationMemberMutationVariables> {
    document = CreateOrganizationMemberDocument;

  }
export const SelectDefaultOrganizationDocument = gql`
    mutation SelectDefaultOrganization($id: Int!) {
  updateUserDefaultOrganization(organization_id: $id) {
    ...slimOrganization
  }
}
    ${SlimOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SelectDefaultOrganizationGQL extends Apollo.Mutation<SelectDefaultOrganizationMutation, SelectDefaultOrganizationMutationVariables> {
    document = SelectDefaultOrganizationDocument;

  }
export const UpdateOrganizationDocument = gql`
    mutation UpdateOrganization($organization: OrganizationInput!) {
  updateOrganization(organization: $organization) {
    ...slimOrganization
  }
}
    ${SlimOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationGQL extends Apollo.Mutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables> {
    document = UpdateOrganizationDocument;

  }
export const UpdateOrganizationGroupDocument = gql`
    mutation UpdateOrganizationGroup($id: Int!, $group: GroupInput!) {
  updateGroup(id: $id, group: $group) {
    ...organizationGroup
  }
}
    ${OrganizationGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationGroupGQL extends Apollo.Mutation<UpdateOrganizationGroupMutation, UpdateOrganizationGroupMutationVariables> {
    document = UpdateOrganizationGroupDocument;

  }
export const UpdateOrganizationGroupPermissionsDocument = gql`
    mutation UpdateOrganizationGroupPermissions($id: Int!, $permissions: PermissionInput!) {
  updateGroupPermissions(group_id: $id, permissions: $permissions) {
    ...organizationGroup
  }
}
    ${OrganizationGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationGroupPermissionsGQL extends Apollo.Mutation<UpdateOrganizationGroupPermissionsMutation, UpdateOrganizationGroupPermissionsMutationVariables> {
    document = UpdateOrganizationGroupPermissionsDocument;

  }
export const UpdateOrganizationGroupStylesDocument = gql`
    mutation UpdateOrganizationGroupStyles($id: Int!, $styles: GroupStyleInput!) {
  updateGroupStyles(group_id: $id, styles: $styles) {
    ...organizationGroup
  }
}
    ${OrganizationGroupFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationGroupStylesGQL extends Apollo.Mutation<UpdateOrganizationGroupStylesMutation, UpdateOrganizationGroupStylesMutationVariables> {
    document = UpdateOrganizationGroupStylesDocument;

  }
export const UpdateOrganizationMemberDocument = gql`
    mutation UpdateOrganizationMember($id: UUID!, $member: MemberInput!) {
  updateOrganizationMember(id: $id, member: $member) {
    ...organizationMember
  }
}
    ${OrganizationMemberFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationMemberGQL extends Apollo.Mutation<UpdateOrganizationMemberMutation, UpdateOrganizationMemberMutationVariables> {
    document = UpdateOrganizationMemberDocument;

  }
export const TransferDocumentDocument = gql`
    mutation TransferDocument($documentId: UUID!, $organizationId: Int!, $groupId: Int!, $currentGroupId: Int, $context: ContextEnum) {
  transferDocument(id: $documentId, organization_id: $organizationId, group_id: $groupId, current_group_id: $currentGroupId, context: $context)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TransferDocumentGQL extends Apollo.Mutation<TransferDocumentMutation, TransferDocumentMutationVariables> {
    document = TransferDocumentDocument;

  }
export const DeleteOrganizationGroupDocument = gql`
    mutation DeleteOrganizationGroup($id: Int!) {
  deleteGroup(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOrganizationGroupGQL extends Apollo.Mutation<DeleteOrganizationGroupMutation, DeleteOrganizationGroupMutationVariables> {
    document = DeleteOrganizationGroupDocument;

  }
export const DeleteOrganizationMemberDocument = gql`
    mutation DeleteOrganizationMember($id: UUID!) {
  deleteOrganizationMember(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOrganizationMemberGQL extends Apollo.Mutation<DeleteOrganizationMemberMutation, DeleteOrganizationMemberMutationVariables> {
    document = DeleteOrganizationMemberDocument;

  }
export const DeleteOrganizationMemberInvitationDocument = gql`
    mutation DeleteOrganizationMemberInvitation($id: Int!) {
  deleteInvitation(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOrganizationMemberInvitationGQL extends Apollo.Mutation<DeleteOrganizationMemberInvitationMutation, DeleteOrganizationMemberInvitationMutationVariables> {
    document = DeleteOrganizationMemberInvitationDocument;

  }
export const ResendOrganizationMemberInvitationDocument = gql`
    mutation ResendOrganizationMemberInvitation($id: Int!) {
  resendInvitation(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendOrganizationMemberInvitationGQL extends Apollo.Mutation<ResendOrganizationMemberInvitationMutation, ResendOrganizationMemberInvitationMutationVariables> {
    document = ResendOrganizationMemberInvitationDocument;

  }
export const CurrentUserHasCloudCertDocument = gql`
    query CurrentUserHasCloudCert {
  hasCloudCert {
    serial_number
    type
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserHasCloudCertGQL extends Apollo.Query<CurrentUserHasCloudCertQuery, CurrentUserHasCloudCertQueryVariables> {
    document = CurrentUserHasCloudCertDocument;

  }
export const AuthorizeCloudCertApplicationDocument = gql`
    mutation AuthorizeCloudCertApplication($cpfCnpj: String!, $type: CloudSignaturesEnum!, $otp: String) {
  authorizeApp(cpf_cnpj: $cpfCnpj, type: $type, otp: $otp)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthorizeCloudCertApplicationGQL extends Apollo.Mutation<AuthorizeCloudCertApplicationMutation, AuthorizeCloudCertApplicationMutationVariables> {
    document = AuthorizeCloudCertApplicationDocument;

  }
export const AuthorizeCloudCertSignatureDocument = gql`
    mutation AuthorizeCloudCertSignature($cpfCnpj: String!, $password: String!) {
  authorizeSign(cpf_cnpj: $cpfCnpj, password: $password)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuthorizeCloudCertSignatureGQL extends Apollo.Mutation<AuthorizeCloudCertSignatureMutation, AuthorizeCloudCertSignatureMutationVariables> {
    document = AuthorizeCloudCertSignatureDocument;

  }
export const StartPadesSignatureDocument = gql`
    mutation StartPadesSignature($id: UUID!, $type: SignatureTypesEnum!, $payload: String!) {
  acceptSignature(id: $id, action: START, type: $type, payload: $payload) {
    ...padesSignatureHash
  }
}
    ${PadesSignatureHashFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StartPadesSignatureGQL extends Apollo.Mutation<StartPadesSignatureMutation, StartPadesSignatureMutationVariables> {
    document = StartPadesSignatureDocument;

  }
export const AcceptPadesSignatureDocument = gql`
    mutation AcceptPadesSignature($id: UUID!, $type: SignatureTypesEnum!, $payload: String!) {
  acceptSignature(id: $id, action: COMPLETE, type: $type, payload: $payload) {
    ...basicSigner
  }
}
    ${BasicSignerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptPadesSignatureGQL extends Apollo.Mutation<AcceptPadesSignatureMutation, AcceptPadesSignatureMutationVariables> {
    document = AcceptPadesSignatureDocument;

  }
export const AcceptCloudCertSignatureDocument = gql`
    mutation AcceptCloudCertSignature($id: UUID!, $type: SignatureTypesEnum!) {
  acceptSignature(id: $id, action: START, type: $type) {
    ...basicSigner
  }
}
    ${BasicSignerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptCloudCertSignatureGQL extends Apollo.Mutation<AcceptCloudCertSignatureMutation, AcceptCloudCertSignatureMutationVariables> {
    document = AcceptCloudCertSignatureDocument;

  }
export const CheckAuthorizationVidaasDocument = gql`
    mutation CheckAuthorizationVidaas {
  checkAuthorization
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CheckAuthorizationVidaasGQL extends Apollo.Mutation<CheckAuthorizationVidaasMutation, CheckAuthorizationVidaasMutationVariables> {
    document = CheckAuthorizationVidaasDocument;

  }
export const CreditsPackDocument = gql`
    query CreditsPack {
  creditsPack {
    name
    description
    quantity
    amount
    amount_promo
    is_promo
    best
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreditsPackGQL extends Apollo.Query<CreditsPackQuery, CreditsPackQueryVariables> {
    document = CreditsPackDocument;

  }
export const PlansDocument = gql`
    query Plans {
  plans {
    active
    amount
    currency
    interval
    name
    id
    stripe_id
    iugu_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PlansGQL extends Apollo.Query<PlansQuery, PlansQueryVariables> {
    document = PlansDocument;

  }
export const OrderDocument = gql`
    query Order {
  order {
    ...order
  }
}
    ${OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderGQL extends Apollo.Query<OrderQuery, OrderQueryVariables> {
    document = OrderDocument;

  }
export const CustomerDocument = gql`
    query Customer {
  customer {
    ...customer
  }
}
    ${CustomerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CustomerGQL extends Apollo.Query<CustomerQuery, CustomerQueryVariables> {
    document = CustomerDocument;

  }
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($address: AddressInput!, $customer: CustomerInput!) {
  createCustomer(address: $address, customer: $customer) {
    ...customer
  }
}
    ${CustomerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCustomerGQL extends Apollo.Mutation<CreateCustomerMutation, CreateCustomerMutationVariables> {
    document = CreateCustomerDocument;

  }
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($address: AddressInput!, $customer: CustomerInput!) {
  updateCustomer(address: $address, customer: $customer) {
    ...customer
  }
}
    ${CustomerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCustomerGQL extends Apollo.Mutation<UpdateCustomerMutation, UpdateCustomerMutationVariables> {
    document = UpdateCustomerDocument;

  }
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($card_token: String, $payment_method: PaymentMethodsEnum!, $plan: String!) {
  createSubscription(card_token: $card_token, payment_method: $payment_method, plan: $plan) {
    ...order
  }
}
    ${OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSubscriptionGQL extends Apollo.Mutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables> {
    document = CreateSubscriptionDocument;

  }
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription {
  cancelSubscription
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CancelSubscriptionGQL extends Apollo.Mutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables> {
    document = CancelSubscriptionDocument;

  }
export const UpdateSubscriptionDocument = gql`
    mutation UpdateSubscription($card_token: String, $payment_method: PaymentMethodsEnum, $plan: String) {
  updateSubscription(card_token: $card_token, payment_method: $payment_method, plan: $plan) {
    ...order
  }
}
    ${OrderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSubscriptionGQL extends Apollo.Mutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables> {
    document = UpdateSubscriptionDocument;

  }
export const BuyCreditsDocument = gql`
    mutation BuyCredits($card_token: String, $package: String!, $payment_method: PaymentMethodsEnum) {
  buyCredits(card_token: $card_token, package: $package, payment_method: $payment_method) {
    ...invoice
  }
}
    ${InvoiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class BuyCreditsGQL extends Apollo.Mutation<BuyCreditsMutation, BuyCreditsMutationVariables> {
    document = BuyCreditsDocument;

  }
export const ReferralsDocument = gql`
    query Referrals($limit: Int!, $page: Int!) {
  referrals(per_page: $limit, page: $page) {
    id
    email
    full_name
    recent_extracts
    total_amount
    referral_code
    created_at
    affiliates {
      id
      email
      full_name
      payment_info
      is_receiving_payments
      created_at
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ReferralsGQL extends Apollo.Query<ReferralsQuery, ReferralsQueryVariables> {
    document = ReferralsDocument;

  }
export const ExportAffiliatesDocument = gql`
    query ExportAffiliates($limit: Int!, $page: Int!) {
  referrals(per_page: $limit, page: $page) {
    id
    affiliates {
      id
      email
      full_name
      payment_info
      is_receiving_payments
      created_at
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ExportAffiliatesGQL extends Apollo.Query<ExportAffiliatesQuery, ExportAffiliatesQueryVariables> {
    document = ExportAffiliatesDocument;

  }
export const TemplatesDocument = gql`
    query Templates($limit: Int!, $page: Int!, $search: String, $orderBy: OrderBy) {
  templates(limit: $limit, page: $page, search: $search, orderBy: $orderBy) {
    ...templatesPage
  }
}
    ${TemplatesPageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplatesGQL extends Apollo.Query<TemplatesQuery, TemplatesQueryVariables> {
    document = TemplatesDocument;

  }
export const TemplateDocument = gql`
    query Template($id: UUID!) {
  template(id: $id) {
    ...template
  }
}
    ${TemplateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TemplateGQL extends Apollo.Query<TemplateQuery, TemplateQueryVariables> {
    document = TemplateDocument;

  }
export const CreateTemplateDocument = gql`
    mutation CreateTemplate($template: TemplateInput!, $attributes: [AttributeInput]) {
  createTemplate(template: $template, attributes: $attributes) {
    ...slimTemplate
  }
}
    ${SlimTemplateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTemplateGQL extends Apollo.Mutation<CreateTemplateMutation, CreateTemplateMutationVariables> {
    document = CreateTemplateDocument;

  }
export const UpdateTemplateDocument = gql`
    mutation UpdateTemplate($template: TemplateInput!, $attributes: [AttributeInput]) {
  updateTemplate(template: $template, attributes: $attributes) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTemplateGQL extends Apollo.Mutation<UpdateTemplateMutation, UpdateTemplateMutationVariables> {
    document = UpdateTemplateDocument;

  }
export const DeleteTemplateDocument = gql`
    mutation DeleteTemplate($id: UUID!) {
  deleteTemplate(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTemplateGQL extends Apollo.Mutation<DeleteTemplateMutation, DeleteTemplateMutationVariables> {
    document = DeleteTemplateDocument;

  }
export const CurrentUserDocument = gql`
    query CurrentUser {
  me {
    ...user
  }
  newHash
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserGQL extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
    document = CurrentUserDocument;

  }
export const CurrentUserOrganizationDocument = gql`
    query CurrentUserOrganization {
  me {
    id
    organization {
      ...userOrganization
    }
  }
  newHash
}
    ${UserOrganizationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserOrganizationGQL extends Apollo.Query<CurrentUserOrganizationQuery, CurrentUserOrganizationQueryVariables> {
    document = CurrentUserOrganizationDocument;

  }
export const CurrentUserCreditsDocument = gql`
    query CurrentUserCredits {
  me {
    id
    subscription {
      documents
      bonus
      credits
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentUserCreditsGQL extends Apollo.Query<CurrentUserCreditsQuery, CurrentUserCreditsQueryVariables> {
    document = CurrentUserCreditsDocument;

  }
export const NotificationsInfoDocument = gql`
    query NotificationsInfo {
  notifications {
    signature_others
    signature_you
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationsInfoGQL extends Apollo.Query<NotificationsInfoQuery, NotificationsInfoQueryVariables> {
    document = NotificationsInfoDocument;

  }
export const ContactsDocument = gql`
    query Contacts($limit: Int!, $search: String, $page: Int) {
  contacts(limit: $limit, search: $search, page: $page) {
    data {
      name
      email
      id
      cpf
      phone
      birthday
    }
    total
    per_page
    current_page
    last_page
    from
    to
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactsGQL extends Apollo.Query<ContactsQuery, ContactsQueryVariables> {
    document = ContactsDocument;

  }
export const UpdateCurrentUserDocument = gql`
    mutation UpdateCurrentUser($user: UserInput!) {
  updateUser(user: $user) {
    ...userBasic
  }
}
    ${UserBasicFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCurrentUserGQL extends Apollo.Mutation<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables> {
    document = UpdateCurrentUserDocument;

  }
export const SetCurrentUserMissingInfoDocument = gql`
    mutation SetCurrentUserMissingInfo($user: UserDetailInput!) {
  updateUserDetails(user: $user) {
    ...userBasic
  }
}
    ${UserBasicFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetCurrentUserMissingInfoGQL extends Apollo.Mutation<SetCurrentUserMissingInfoMutation, SetCurrentUserMissingInfoMutationVariables> {
    document = SetCurrentUserMissingInfoDocument;

  }
export const UpdateCurrentUserPasswordDocument = gql`
    mutation UpdateCurrentUserPassword($newPasswordInfo: PasswordInput!) {
  changeUserPassword(password: $newPasswordInfo)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCurrentUserPasswordGQL extends Apollo.Mutation<UpdateCurrentUserPasswordMutation, UpdateCurrentUserPasswordMutationVariables> {
    document = UpdateCurrentUserPasswordDocument;

  }
export const UpdateCurrentUserLocaleDocument = gql`
    mutation UpdateCurrentUserLocale($locale: LocaleInput!) {
  updateUserLocale(locale: $locale) {
    ...userBasic
  }
}
    ${UserBasicFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCurrentUserLocaleGQL extends Apollo.Mutation<UpdateCurrentUserLocaleMutation, UpdateCurrentUserLocaleMutationVariables> {
    document = UpdateCurrentUserLocaleDocument;

  }
export const UpdateNotificationsInfoDocument = gql`
    mutation UpdateNotificationsInfo($notification: NotificationInput!) {
  updateNotifications(notification: $notification)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateNotificationsInfoGQL extends Apollo.Mutation<UpdateNotificationsInfoMutation, UpdateNotificationsInfoMutationVariables> {
    document = UpdateNotificationsInfoDocument;

  }
export const UpdateApiOptionsDocument = gql`
    mutation UpdateApiOptions($options: ApiOptionsInput!) {
  updateApiOptions(options: $options)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateApiOptionsGQL extends Apollo.Mutation<UpdateApiOptionsMutation, UpdateApiOptionsMutationVariables> {
    document = UpdateApiOptionsDocument;

  }
export const DeleteContactDocument = gql`
    mutation deleteContact($id: Int!) {
  deleteContact(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteContactGQL extends Apollo.Mutation<DeleteContactMutation, DeleteContactMutationVariables> {
    document = DeleteContactDocument;

  }
export const WhitelabelDocument = gql`
    query Whitelabel {
  whitelabel {
    ...whitelabel
  }
}
    ${WhitelabelFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WhitelabelGQL extends Apollo.Query<WhitelabelQuery, WhitelabelQueryVariables> {
    document = WhitelabelDocument;

  }
export const WhitelabelSendgridDnsDocument = gql`
    query WhitelabelSendgridDns($sendgrid_domain: String!) {
  whitelabelSendgridDns(sendgrid_domain: $sendgrid_domain) {
    ...sendgridDns
  }
}
    ${SendgridDnsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WhitelabelSendgridDnsGQL extends Apollo.Query<WhitelabelSendgridDnsQuery, WhitelabelSendgridDnsQueryVariables> {
    document = WhitelabelSendgridDnsDocument;

  }
export const WhitelabelDomainVerifiedDocument = gql`
    query WhitelabelDomainVerified($domain: String!) {
  whitelabelDomainVerified(domain: $domain)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class WhitelabelDomainVerifiedGQL extends Apollo.Query<WhitelabelDomainVerifiedQuery, WhitelabelDomainVerifiedQueryVariables> {
    document = WhitelabelDomainVerifiedDocument;

  }
export const UpdateWhitelabelAppearanceDocument = gql`
    mutation UpdateWhitelabelAppearance($whitelabel: WhitelabelAppearanceInput!) {
  updateWhitelabelAppearance(whitelabel: $whitelabel) {
    ...whitelabel
  }
}
    ${WhitelabelFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateWhitelabelAppearanceGQL extends Apollo.Mutation<UpdateWhitelabelAppearanceMutation, UpdateWhitelabelAppearanceMutationVariables> {
    document = UpdateWhitelabelAppearanceDocument;
    client = 'upload';
  }
export const UpdateWhitelabelDomainDocument = gql`
    mutation UpdateWhitelabelDomain($domain: String) {
  updateWhitelabelDomain(domain: $domain) {
    ...whitelabel
  }
}
    ${WhitelabelFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateWhitelabelDomainGQL extends Apollo.Mutation<UpdateWhitelabelDomainMutation, UpdateWhitelabelDomainMutationVariables> {
    document = UpdateWhitelabelDomainDocument;

  }
export const UpdateWhitelabelSendgridDocument = gql`
    mutation UpdateWhitelabelSendgrid($whitelabelSendgrid: WhitelabelSendgridInput!) {
  updateWhitelabelSendgrid(whitelabel: $whitelabelSendgrid) {
    whitelabel {
      ...whitelabel
    }
    sendgrid_dns {
      ...sendgridDns
    }
  }
}
    ${WhitelabelFragmentDoc}
${SendgridDnsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateWhitelabelSendgridGQL extends Apollo.Mutation<UpdateWhitelabelSendgridMutation, UpdateWhitelabelSendgridMutationVariables> {
    document = UpdateWhitelabelSendgridDocument;

  }
export const WhitelabelSendgridValidateDocument = gql`
    mutation WhitelabelSendgridValidate($sendgrid_domain: String!) {
  whitelabelSendgridValidate(sendgrid_domain: $sendgrid_domain) {
    ...sendgridDns
  }
}
    ${SendgridDnsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WhitelabelSendgridValidateGQL extends Apollo.Mutation<WhitelabelSendgridValidateMutation, WhitelabelSendgridValidateMutationVariables> {
    document = WhitelabelSendgridValidateDocument;

  }
export const ActivateWhitelabelDomainDocument = gql`
    mutation ActivateWhitelabelDomain {
  activateWhitelabelDomain {
    ...whitelabel
  }
}
    ${WhitelabelFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivateWhitelabelDomainGQL extends Apollo.Mutation<ActivateWhitelabelDomainMutation, ActivateWhitelabelDomainMutationVariables> {
    document = ActivateWhitelabelDomainDocument;

  }
export const ActivateWhitelabelSendgridDocument = gql`
    mutation ActivateWhitelabelSendgrid {
  activateWhitelabelSendgrid {
    ...whitelabel
  }
}
    ${WhitelabelFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ActivateWhitelabelSendgridGQL extends Apollo.Mutation<ActivateWhitelabelSendgridMutation, ActivateWhitelabelSendgridMutationVariables> {
    document = ActivateWhitelabelSendgridDocument;

  }
export const UploadDocumentFileDocument = gql`
    mutation UploadDocumentFile($file: Upload!) {
  uploadFile(file: $file) {
    path
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadDocumentFileGQL extends Apollo.Mutation<UploadDocumentFileMutation, UploadDocumentFileMutationVariables> {
    document = UploadDocumentFileDocument;
    client = 'upload';
  }
export const UpdateSecurityVerificationDocument = gql`
    mutation UpdateSecurityVerification($id: Int!, $securityVerification: SecurityVerificationInput, $fileType: SecurityVerificationFileTypeEnum, $file: Upload) {
  updateSecurityVerification(id: $id, security_verification: $securityVerification, file_type: $fileType, file: $file) {
    ...verifications
  }
}
    ${VerificationsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSecurityVerificationGQL extends Apollo.Mutation<UpdateSecurityVerificationMutation, UpdateSecurityVerificationMutationVariables> {
    document = UpdateSecurityVerificationDocument;
    client = 'upload';
  }
export const CreateEmailTemplateDocument = gql`
    mutation CreateEmailTemplate($email: EmailTemplatesInput!) {
  createEmailTemplate(email: $email) {
    ...emailTemplate
  }
}
    ${EmailTemplateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateEmailTemplateGQL extends Apollo.Mutation<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables> {
    document = CreateEmailTemplateDocument;
    client = 'upload';
  }
export const UpdateEmailTemplateDocument = gql`
    mutation UpdateEmailTemplate($id: Int!, $email: EmailTemplatesInput!) {
  updateEmailTemplate(id: $id, email: $email) {
    ...emailTemplate
  }
}
    ${EmailTemplateFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmailTemplateGQL extends Apollo.Mutation<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables> {
    document = UpdateEmailTemplateDocument;
    client = 'upload';
  }
export const UpdateCurrentUserSignatureDocument = gql`
    mutation UpdateCurrentUserSignature($user: UserSignatureInput!) {
  updateUserSignature(user: $user) {
    id
    settings {
      ...userSettings
    }
  }
}
    ${UserSettingsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCurrentUserSignatureGQL extends Apollo.Mutation<UpdateCurrentUserSignatureMutation, UpdateCurrentUserSignatureMutationVariables> {
    document = UpdateCurrentUserSignatureDocument;
    client = 'upload';
  }
export const UpdateCurrentUserInitialsDocument = gql`
    mutation UpdateCurrentUserInitials($user: UserSignatureInput!) {
  updateUserInitials(user: $user) {
    id
    settings {
      ...userSettings
    }
  }
}
    ${UserSettingsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCurrentUserInitialsGQL extends Apollo.Mutation<UpdateCurrentUserInitialsMutation, UpdateCurrentUserInitialsMutationVariables> {
    document = UpdateCurrentUserInitialsDocument;
    client = 'upload';
  }
export const UpdateCurrentUserSignatureEmailDocument = gql`
    mutation UpdateCurrentUserSignatureEmail($user: EmailTemplatesContentInput!) {
  updateUserSignatureEmail(user: $user) {
    id
    settings {
      ...userSettings
    }
  }
}
    ${UserSettingsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCurrentUserSignatureEmailGQL extends Apollo.Mutation<UpdateCurrentUserSignatureEmailMutation, UpdateCurrentUserSignatureEmailMutationVariables> {
    document = UpdateCurrentUserSignatureEmailDocument;
    client = 'upload';
  }
